import { isIosDevice } from './userAgentInfo';

export const copyToClipboard = (text: string, refElem: HTMLElement): Promise<void> => {
  if (!navigator.clipboard.writeText) {
    const textArea = document.createElement('textarea');
    refElem.setAttribute('style', 'visibility: \'hidden\'');
    textArea.value = text;
    refElem.appendChild(textArea);

    // Hack for copying to clipboard in ios
    if (isIosDevice()) {
      const range = document.createRange();
      range.selectNodeContents(textArea);
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      textArea.setSelectionRange(0, 999999);
    } else {
      textArea.select();
    }

    document.execCommand('copy');
    refElem.removeChild(textArea);
  } else {
    navigator.clipboard.writeText(text); // Firefox and Chrome only
  }

  return Promise.resolve();
};
