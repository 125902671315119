import React, { Component, RefObject } from 'react';
import { subscribeConfirm, unsubscribeConfirm } from './confirmEventEmitter';
import { EventSubscription } from 'fbemitter';
import ConfirmationManager from './components/ConfirmationManager';

export default class EventtedConfirmationManager extends Component {
  private manager: RefObject<{ setConfirm: (confirmProps?: unknown) => unknown }>;
  private subscription: EventSubscription;

  constructor(props) {
    super(props);

    this.confirm = this.confirm.bind(this);
    this.manager = React.createRef();
  }

  componentDidMount() {
    this.subscription = subscribeConfirm(this.confirm);
  }

  componentWillUnmount() {
    unsubscribeConfirm(this.subscription);
  }

  confirm(confirmProps) {
    this.manager.current?.setConfirm(confirmProps);
  }

  render() {
    return <ConfirmationManager ref={this.manager} />;
  }
}
