import * as React from 'react';
import { Action } from 'redux';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { IState } from '@myblueprint-spaces/redux';
import { AuthenticationActions, AuthenticationData } from '@myblueprint-spaces/redux/lib/authentication';

export default function useSaveAuthenticationData() {
  const dispatch = useDispatch<ThunkDispatch<IState, unknown, Action>>();
  return React.useCallback(
    ({ expiry, settings }: AuthenticationData) => dispatch(AuthenticationActions.saveAuthenticationData({ expiry, settings })),
    [dispatch]
  );
}
