import { Shadows, ITextButtonProps } from '@myblueprint-spaces/papier-core';
import styled from 'styled-components';
import { IButtonCustomProps } from 'src/Common/Button/types';
import React from 'react';
import { IconHTMLButton } from './types';

type ICleanButtonProps = (ITextButtonProps | IconHTMLButton) & IButtonCustomProps & { children?: React.ReactChild, ref?: React.RefObject<HTMLButtonElement> };

const CleanButton = styled.button.attrs((props: ICleanButtonProps) => ({ type: 'button', 'data-test': `btn-${props.dataTest  || props['data-test']}` }))`
  background-color: transparent;
  cursor: pointer;
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
  display: block;
  appearance: none;
  border-radius: 0.25rem;
  text-transform: inherit;
  transition: box-shadow 0.2s ease-in-out;

  &::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }

  &:focus:not(:active) {
    ${Shadows.primaryFloatingFocus}
  }

  :disabled {
    cursor: not-allowed;
  }
`;

export default CleanButton;
