import { ReduxGlobals } from '@myblueprint-spaces/redux';
import React, { createContext, ReactNode, useCallback, useContext, useEffect, useRef }  from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { toSnakeCase } from '@myblueprint-spaces/modules';
import { AnalyticsBrowser } from '@segment/analytics-next';
import GlobalsHooks from 'src/modules/hooks/redux/global';
import { Product } from '@myblueprint-spaces/redux/lib/api/common/globals/enums';
import ProductContext from './ProductContext';

export interface IAnalyticsContext {
  analytics: React.MutableRefObject<AnalyticsBrowser | null>;
  reset: () => void;
}

const AnalyticsContext = createContext<IAnalyticsContext>({} as IAnalyticsContext);

export const AnalyticsContextProvider = ({ children }: { children: ReactNode | ReactNode[] }) => {
  const { productType } = useContext(ProductContext);
  const appConfig = GlobalsHooks.useConfig();
  const analytics = useRef<AnalyticsBrowser | null>(null);
  const segmentKey = appConfig?.integrations?.webSegmentKey;

  useEffect(() => {
    if (segmentKey) {
      const a = AnalyticsBrowser.load({ writeKey: segmentKey });
      analytics.current = a;
    }
  }, [segmentKey]);

  const track = useCallback((event: string, properties?: Record<string, unknown>) => {
    // Segment track
    analytics.current?.track?.(event, properties);
    // GA4 track
    window.gtag?.('event', toSnakeCase(event), properties);
  }, [analytics.current, window.gtag]);

  const identify = useCallback((userId, traits, integrations) => {
    analytics.current?.identify(userId, traits, integrations);
    datadogRum.setUser({
      id: userId,
      email: traits.email
    });
  }, [analytics.current]);

  const reset = useCallback(() => {
    analytics.current?.reset();
    productType === Product.Spaces && window.Intercom?.('shutdown');
    datadogRum.removeUser();
  }, [analytics.current, productType]);

  useEffect(() => {
    ReduxGlobals.setSegmentFunctions({
      track,
      identify: identify,
      reset: reset,
      type: 'web'
    });

    return () => ReduxGlobals.setSegmentFunctions({
      track: null,
      identify: null,
      reset: null,
      type: null
    });
  }, [analytics.current, track]);

  return (
    <AnalyticsContext.Provider value={{ analytics, reset }}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export default AnalyticsContext;
