import { Colors } from '@myblueprint-spaces/papier-core';
import styled from 'styled-components';
import { IInfoBarIconContainerProps, IInfoBarContainerProps, ISubtextContainerProps } from './types';

export const InfoBarContainer = styled.div((props: IInfoBarContainerProps) => {
  const { color, size } = props;

  return `
    border: 0.0625rem solid ${Colors[color]};
    border-radius: 0.25rem;
    background-color: ${Colors.white};
    line-height: 1.15rem;
    padding: ${size === 'xsmall'
    ? '0.3125rem'
    : size === 'small'
      ? '0.625rem 0.5rem'
      : size === 'medium'
        ? '0.625rem 0.75rem'
        : size === 'large'
          ? '0.875rem 0.75rem'
          : '1.5rem 1rem'};
  `;
});

export const TextContainer = styled.div`
  flex: 1;
`;

export const IconContainer = styled.div((props: IInfoBarIconContainerProps) => {
  const { size } = props;
  return `
  margin-right: ${['xsmall', 'small'].includes(size) ? '0.25' : '0.5'}rem;
  `;
});

export const SubtextContainer = styled.div((props: ISubtextContainerProps) => {
  const { size } = props;
  return `
  flex: 1;
  margin-left: ${['xsmall', 'small'].includes(size)
    ? '1.25'
    : size === 'medium'
      ? '2'
      : size === 'large'
        ? '2'
        : '2.5'}rem;
  `;
});
