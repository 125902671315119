import React, { Component } from 'react';
import { subscribeToasts, unsubscribeToasts } from './toastEventEmitter';
import { generateId } from '@myblueprint-spaces/papier-web/lib/modules/browsers/generateId';
import { EventSubscription } from 'fbemitter';
import ToastManager, { ToastProps } from 'src/Common/ToastManager';

export default class EventtedToastManager extends Component {
  private toastManager: React.RefObject<{ pushToast: (toast: ToastProps) => void }>;
  private subscription: EventSubscription;

  constructor(props) {
    super(props);

    this.generateToast = this.generateToast.bind(this);
    this.toastManager = React.createRef();
  }

  componentDidMount() {
    this.subscription = subscribeToasts(this.generateToast);
  }

  componentWillUnmount() {
    unsubscribeToasts(this.subscription);
  }

  generateToast(toastProps) {
    const { id, ...toast } = toastProps || {};
    this.toastManager?.current?.pushToast({
      id: id || generateId(),
      ...toast
    });
  }

  render() {
    return <ToastManager ref={this.toastManager} />;
  }
}
