import { Breakpoints, Colors, shadow3 } from '@myblueprint-spaces/papier-core';
import styled from 'styled-components';
import { IDialogAriaModalProps } from './types';
import CustomAriaModal from '../CustomAriaModal';

const timing = 250;
const easing = 'ease-in-out';

export const StyledAriaModal = styled(CustomAriaModal).attrs(({ state, size, windowSize, fullScreenOnMobile, ...other }: IDialogAriaModalProps) => {
  let width = size === 'small'
    ? '29rem'
    : size === 'large'
      ? '44rem'
      : '36.5rem';
  let minHeight = 'auto';
  let borderRadius = '1rem';
  let boxShadow = shadow3;
  if (fullScreenOnMobile && windowSize.width <= Breakpoints.medium) {
    minHeight = windowSize.height+'rem';
    width = '100%';
    borderRadius = '0';
    boxShadow = 'none';
  }
  let opacity = 0;
  let transform = 'translateY(0)';
  switch (state) {
    case 'entering':
      opacity = 0;
      transform = 'translateY(0.625rem)';
      break;
    case 'entered':
      opacity = 1;
      transform = 'translateY(0)';
      break;
    case 'exiting':
      opacity = 1;
      transform = 'translateY(0.625rem)';
      break;
    case 'exited':
      opacity = 0;
      transform = 'translateY(0.625rem)';
      break;
  }
  return {
    ...other,
    dialogStyle: {
      minHeight,
      width,
      maxWidth: '100%',
      borderRadius,
      backgroundColor: Colors.white,
      boxShadow,
      transition: `opacity ${timing}ms ${easing}, background-color ${timing}ms ${easing}, transform ${timing}ms ${easing}`,
      opacity,
      transform
    }
  };
})``;

export const TopButtonWrapper = styled.div`
  margin: 0.5rem;
`;
