import { Colors, FontTypes, FontWeights, ITypographyProps } from '@myblueprint-spaces/papier-core';
import * as PropTypes from 'prop-types';
import * as React from 'react';
import { WebTypography } from './styles';

const Typography = (props: ITypographyProps & { className?: string }): React.ReactElement => {
  const { dataTest } = props;
  return (
    <WebTypography data-test={dataTest} {...props} />
  );
};

Typography.propTypes = {
  id: PropTypes.string,
  color: PropTypes.oneOf(Object.keys(Colors)),
  type: PropTypes.oneOf(Object.keys(FontTypes)),
  italic: PropTypes.bool,
  weight: (props: ITypographyProps, propName: string, componentName: string) => {
    const propValue: string = props[propName];
    if (!propValue) {
      return;
    } else if (!FontWeights[propValue]) {
      return new Error(`Invalid prop '${propName}' supplied to '${componentName}'. Expected a valid weight.`);
    }
  },
  ellipsis: PropTypes.bool,
  style: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string
  ]),
  clamp: PropTypes.number
};

export default Typography;
