import * as React from 'react';
import { RovingTabIndexContext } from './Provider';

// Invokes focus() on ref as a layout effect whenever focused
// changes from false to true.
export default function useFocusEffect(
  focused: boolean | null | undefined,
  ref: React.RefObject<HTMLElement>
) {
  const { state } = React.useContext(RovingTabIndexContext) || {};

  const check = (isFocussed: boolean, lastActionOrigin?: string, allIds?: string[]) => {
    // To avoid forcing focus into list when list scrolls from external changes, we check if the current focusable element is handled by us OR if the focus is lost in page.
    // Disabled or not...
    if (isFocussed && ref.current && (
      lastActionOrigin !== 'system'
      || (lastActionOrigin === 'system' && (['HTML', 'BODY'].includes(document.activeElement?.tagName ?? 'HTML'))
        || allIds?.includes(document.activeElement?.getAttribute('id') ?? ''))
    )) {
      ref.current.focus({ preventScroll: lastActionOrigin !== 'keyboard' });
    }
  };

  const checkFocused = React.useRef<(isFocused: boolean) => void>(check);

  React.useEffect(() => {
    const { lastActionOrigin, allIds } = state;
    checkFocused.current = (isFocussed) => check(isFocussed, lastActionOrigin as string, allIds);
  }, [state]);

  React.useLayoutEffect(() => {
    checkFocused.current?.(!!focused);
  }, [focused]);
}
