// Implemented using ideas from
// https://blog.bitsrc.io/5-steps-create-a-size-position-observer-in-react-40123107f278
import { baseFontSize } from '@myblueprint-spaces/papier-core';
import * as React from 'react';

export interface IWindowSize {
  width: number;
  height: number;
}

export interface IWindowSizeProps {
  windowSize: IWindowSize;
}

const withWindowSize = <P extends object>(WrappedComponent: React.ComponentType<P & IWindowSizeProps>) => {
  return class WindowSizeObserver extends React.Component<P, IWindowSize> {
    public constructor(props: P) {
      super(props);
      this.state = {
        width: window.innerWidth / baseFontSize,
        height: window.innerHeight / baseFontSize
      };
      this.handleResize = this.handleResize.bind(this);
    }

    public componentDidMount() {
      window.addEventListener('resize', this.handleResize, false);
      window.addEventListener('orientationchange', this.handleResize, false);
      this.update(window.innerWidth, window.innerHeight);
    }

    public componentWillUnmount() {
      window.removeEventListener('resize', this.handleResize);
      window.removeEventListener('orientationchange', this.handleResize);
    }

    public render() {
      return <WrappedComponent windowSize={this.state} {...this.props} />;
    }

    public update(newWidth: number, newHeight: number) {
      const { width, height } = this.state;
      const newScaledWidth = newWidth / baseFontSize;
      const newScaledHeight = newHeight / baseFontSize;

      if (width !== newScaledWidth || height !== newScaledHeight) {
        this.setState({
          width: newScaledWidth,
          height: newScaledHeight
        });
      }
    }

    public handleResize() {
      this.update(window.innerWidth, window.innerHeight);
    }
  };
};

export default withWindowSize;
