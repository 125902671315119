import * as React from 'react';
import TextBox from 'src/Common/TextBox';
import { IPasswordTexBoxProps } from './types';
import { useTranslation } from 'react-i18next';
import useId from 'src/modules/hooks/useId';
import * as PropTypes from 'prop-types';
import { IconObj, TextBoxSizes } from 'src/Atoms/types';

const PasswordTextBox = (props: IPasswordTexBoxProps): React.ReactElement => {
  const { id, password, onChange, label, dataTest, ...rest } = props;
  const [showPassword, setShowPassword] = React.useState(false);
  const { t } = useTranslation(['Spaces.Common']);
  const usedId = useId(id);

  // Checking for Enter and Space
  const handleKeyPress = (event) => {
    if (event.keyCode === 13 || event.keyCode === 32 ) {
      event.preventDefault();
      event.stopPropagation();
      setShowPassword(!showPassword);
    }
  };

  const iconObj: IconObj = {
    type: 'preview',
    position: 'right',
    color: showPassword ? 'black3' : 'grey1',
    onClick: () => setShowPassword(!showPassword),
    title: showPassword ? t('Spaces.Common:ShowPassword') : t('Spaces.Common:HidePassword'),
    'aria-expanded': showPassword,
    'aria-controls': usedId,
    onKeyPress: handleKeyPress,
    dataTest: 'preview'
  };

  return (
    <TextBox
      id={usedId}
      type={showPassword ? 'text' : 'password'}
      label={label}
      value={password.value}
      onChange={onChange}
      icon={iconObj}
      dataTest={dataTest}
      {...rest}
    />
  );
};

PasswordTextBox.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  password: PropTypes.object,
  validation: PropTypes.shape({
    state: PropTypes.oneOf(['success', 'error']),
    message: PropTypes.string
  }),
  dataTest: PropTypes.string,
  size: PropTypes.oneOf(TextBoxSizes),
  bottomMargin: PropTypes.bool,
  name: PropTypes.string,
  labelRight: PropTypes.element,
  autoComplete: PropTypes.string
};

export default PasswordTextBox;
