import { Colors } from '@myblueprint-spaces/papier-core';
import styled from 'styled-components';
import { IProgressBarProps } from './types';

export const StyledProgress = styled.progress(({ size, color = 'primary1' }: IProgressBarProps) => {
  let height: number;
  switch (size) {
    case 'small':
      height = 0.25;
      break;
    default:
    case 'medium':
      height = 0.5;
      break;
    case 'large':
      height = 0.75;
      break;
    case 'xlarge':
      height = 1;
      break;
  }
  const borderRadius = height / 2;

  return `
    display: block;
    height: ${height}rem;
    border-radius: ${borderRadius}rem;
    width: 100%;
    color: ${Colors[color]};
    background-color: ${Colors.grey2};
    transition: all 0.2s ease-in-out;
    appearance: none;
    border: none;

    &::-webkit-progress-bar {
      background-color: ${Colors.grey2};
      border-radius: ${borderRadius}rem;
    }

    &::-webkit-progress-value {
      background-color: ${Colors[color]};
      border-radius: ${borderRadius}rem;
      transition: width 0.2s ease-in-out;
    }

    &::-ms-fill {
      border-radius: ${borderRadius}rem;
      border: none;
    }

    &::-ms-value {
      border-radius: ${borderRadius}rem;
      border: none;
    }

    &::-moz-progress-bar {
      appearance: none;
      height: ${height}rem;
      width: 100%;
      border-radius: ${borderRadius}rem;
      background-color: ${Colors[color]};
    }
  `;
});
