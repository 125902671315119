import styled from 'styled-components';
import { Colors, Breakpoints, Shadows } from '@myblueprint-spaces/papier-core';

export const ButtonTextWrapper = styled.div`
  display: block;
  margin-top: 0.5rem;

  @media (max-width: ${Breakpoints.medium}em) {
    margin-left: 1rem;
    margin-top: 0;
    text-align: left;
  }
`;

export const StyledIcon = styled.img`
  display: block;
  width: 3rem;
  height: 3rem;
  margin-left: auto;
  margin-right: auto;  

  @media (max-width: ${Breakpoints.medium}em) {
    width: 2rem;
    height: 2rem;
    margin: auto 0;
  }
`;

export const StyledRegionDialogItem = styled.div(({ selected } : { selected: boolean }) => `
  display: block;
  border-radius: 0.5rem;
  padding: 1rem;
  border: 0.0625rem solid ${selected ? Colors.primary1 : Colors.grey3};
  width: 100%;
  text-align: center;
  cursor: pointer;
  box-sizing: border-box;

  &:focus-within {
    border: 0;
    background-color: ${Colors.grey3};
    ${Shadows.primaryFloatingFocus};
  }
  
  &:hover {
    background-color: ${Colors.primary2};
  }  

  @media (max-width: ${Breakpoints.medium}em) {
    margin-bottom: 0.5rem;
  }
`);
