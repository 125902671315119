import { Breakpoints } from '@myblueprint-spaces/papier-core';
import Icon from 'src/Common/Icon';
import styled from 'styled-components';

export const HeaderBlockContainer = styled.div`
  text-align: center;
  padding: 1rem 0 1.5rem;
`;

export const HeaderBlockImageContainer = styled.div`
  width: 12.5rem;
  height: 12.5rem;
  @media (max-width: ${Breakpoints.medium}em) {
    width: 10rem;
    height: 10rem;
  }
  margin: 0 auto 1.5rem auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HeaderBlockDefaultIcon = styled(Icon).attrs(() => ({
  type: 'image',
  size: 'large',
  color: 'grey1'
}))`
  display: inline-block;
  margin: 0;
`;

export const HeaderBlockTitle = styled.div`
  margin: 0 0 0.5rem;
`;
