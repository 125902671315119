import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import ExternalLink from 'src/Atoms/ExternalLink';
import MarkdownHeader from './components/MarkdownHeader';
import Typography from 'src/Common/Typography';
import MarkdownStrong from './components/MarkdownStrong';
import { FontWeights, FontWeightNames } from '@myblueprint-spaces/papier-core';

const Markdown = ({ boldWeight, ...rest }: { boldWeight: FontWeightNames } & ReactMarkdown.ReactMarkdownProps): React.ReactElement => {
  const markdownDownStrong = ({ children }) => <MarkdownStrong boldWeight={boldWeight}>{children}</MarkdownStrong>;

  markdownDownStrong.propTypes = {
    children: PropTypes.any
  };

  const defaultTypography = ({ children }) => <Typography weight="regular">{children}</Typography>;

  defaultTypography.propTypes = {
    children: PropTypes.any
  };

  return (
    <ReactMarkdown unwrapDisallowed renderers={
      {
        link: ExternalLink,
        heading: MarkdownHeader,
        paragraph: defaultTypography,
        strong: markdownDownStrong
      }
    } {...rest} />
  );
};

Markdown.propTypes = {
  boldWeight: (props, propName, componentName) => {
    const propValue = props[propName];
    if (!propValue) {
      return;
    } else if (!FontWeights[propValue]) {
      return new Error(`Invalid prop '${propName}' supplied to '${componentName}'. Expected a valid weight.`);
    }
  }
};

export default Markdown;
