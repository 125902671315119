import styled, { StyledComponent } from 'styled-components';
import { commonInputStyles, getTextInputCss } from 'src/modules';
import { IBaseTextBoxProps, WithRequired } from './types';

export const BaseTextArea: StyledComponent<'textarea', IBaseTextBoxProps, Record<string, unknown>> = styled('textarea')((props: WithRequired<IBaseTextBoxProps, 'size'>) => {
  const { size, valueSize } = props;

  const { topSpacing: marginTop, height: singleInputHeight, defaultMarginBottom, textAreaMarginBottom, inputLabelHeight, textAreaToInputRatio } = getTextInputCss(props);

  // Height of a single textbox * textareaToInputRadio, minus any extra spacing
  const minHeight: number = ((singleInputHeight + inputLabelHeight + defaultMarginBottom) * textAreaToInputRatio)
    - inputLabelHeight - defaultMarginBottom - textAreaMarginBottom - marginTop;

  return `
    ${commonInputStyles(size, valueSize)}
    resize: vertical;
    height: auto;
    min-height: ${minHeight}rem;
    margin-top: ${marginTop}rem;
    margin-bottom: ${textAreaMarginBottom}rem;
  `;
});
