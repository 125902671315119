import { AccountType } from '@myblueprint-spaces/appstate';
import { enumToString } from '@myblueprint-spaces/modules';
import { ExternalUserType } from '@myblueprint-spaces/redux/lib/authentication';
import { ScopeType } from '@myblueprint-spaces/redux/lib/scopes';

export function getAccountTypeEnum(accountTypeStr: string): AccountType | undefined {
  switch (accountTypeStr.toLowerCase()) {
    case 'teacher':
      return AccountType.Teacher;
    case 'student':
      return AccountType.Student;
    case 'family':
      return AccountType.Family;
    case 'admin':
      return AccountType.Admin;
    default:
      return undefined;
  }
}

export function getAccountTypeStr(accountTypeEnum?: AccountType): string {
  switch (accountTypeEnum) {
    case AccountType.Teacher:
      return enumToString(AccountType, AccountType.Teacher)?.toLowerCase() || '';
    case AccountType.Student:
      return enumToString(AccountType, AccountType.Student)?.toLowerCase() || '';
    case AccountType.Family:
      return enumToString(AccountType, AccountType.Family)?.toLowerCase() || '';
    case AccountType.Admin:
      return enumToString(AccountType, AccountType.Admin)?.toLowerCase() || '';
    default:
      return '';
  }
}

export function getAccountTypeStrCamelCase(accountTypeEnum): string {
  const accountTypeString = getAccountTypeStr(accountTypeEnum);

  return accountTypeString.replace(/./, accountTypeString.charAt(0).toUpperCase());
}

/**
 * The function returns the ScopeType enum value
 * for the corresponding AcccountType enum value
 *
 * @param {AccountType} accountType - AccountType enum value
 *
 * @returns {ScopeType} Returns corresponding ScopeType enum value
 */
export function getScopeTypeFromAccountType(accountType: AccountType): ScopeType | undefined {
  switch (accountType) {
    case AccountType.Student:
      return ScopeType.Student;
    case AccountType.Teacher:
      return ScopeType.Teacher;
    case AccountType.Family:
      return ScopeType.Family;
    case AccountType.Admin:
      return ScopeType.Admin;
    default:
      return undefined;
  }
}

/**
 * The function returns the AcccountType enum value
 * for the corresponding ScopeType enum value
 *
 * @param {ScopeType} scopeType - ScopeType enum value
 *
 * @returns {AccountType} Returns corresponding AccountType enum value
 */
export function getAccountTypeFromScopeType(scopeType?: ScopeType | null): AccountType | undefined {
  switch (scopeType) {
    case ScopeType.Student:
      return AccountType.Student;
    case ScopeType.Teacher:
      return AccountType.Teacher;
    case ScopeType.Family:
      return AccountType.Family;
    case ScopeType.Admin:
      return AccountType.Admin;
    default:
      return undefined;
  }
}

/**
 * The function returns the AccountType enum value
 * for the corresponding ExternalUserType enum value
 *
 * @param {ExternalUserType} externalUserType - ExternalUserType enum value
 *
 * @returns {AccountType} Returns corresponding AccountType enum value
 */

export function getAccountTypeFromExternalUserType(externalUserType?: ExternalUserType): AccountType | undefined {
  switch (externalUserType) {
    case ExternalUserType.Student:
      return AccountType.Student;
    case ExternalUserType.Teacher:
      return AccountType.Teacher;
    case ExternalUserType.Family:
      return AccountType.Family;
    case ExternalUserType.RegularSchoolAdmin:
      return AccountType.Admin;
    default:
      return undefined;
  }
}

/**
 * The function returns the ScopeType enum value
 * for the corresponding ExternalUserType enum value
 *
 * @param {ExternalUserType} externalUserType - ExternalUserType enum value
 *
 * @returns {ScopeType} Returns corresponding ScopeType enum value
 */

export function getScopeTypeFromExternalUserType(externalUserType?: ExternalUserType): ScopeType | undefined {
  switch (externalUserType) {
    case ExternalUserType.Student:
      return ScopeType.Student;
    case ExternalUserType.Teacher:
      return ScopeType.Teacher;
    case ExternalUserType.Family:
      return ScopeType.Family;
    case ExternalUserType.RegularSchoolAdmin:
      return ScopeType.Admin;
    default:
      return undefined;
  }
}
