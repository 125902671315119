import { ITypographyProps } from '@myblueprint-spaces/papier-core';
import * as PropTypes from 'prop-types';
import * as React from 'react';
import styled from 'styled-components';
import CleanButton from './CleanButton';
import { StyledLink } from './ExternalLink';

export interface ILinkButtonProps extends ITypographyProps {
  onClick: (e: unknown) => void;
  className?: string;
  inline?: boolean;
  dataTest?: string;
  children?: React.ReactNode;
  role?: string;
  forwardRef?: React.Ref<HTMLButtonElement>;
  tabIndex?: number;
  onKeyDown?: (e: unknown) => void;
  title?: string;
  disabled?: boolean;
}

const InlineCleanButton = styled(CleanButton)`
  display: ${(props: { inline?: boolean }) => !props.inline ? 'block' : 'inline-block'};
`;

export function LinkButton({ onClick, className, inline = true, dataTest, forwardRef, tabIndex, onKeyDown, title, disabled, ...rest }: ILinkButtonProps & React.AriaAttributes): React.ReactElement {
  return (
    <InlineCleanButton onClick={onClick} className={className} inline={inline} data-test={`btn-${dataTest}`} ref={forwardRef} tabIndex={tabIndex} onKeyDown={onKeyDown} title={title} disabled={disabled}>
      <StyledLink {...rest as ILinkButtonProps} disabled={disabled} />
    </InlineCleanButton>
  );
}

LinkButton.defaultProps = {
  inline: true
};

LinkButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  inline: PropTypes.bool,
  dataTest: PropTypes.string.isRequired,
  forwardRef: PropTypes.object,
  tabIndex: PropTypes.number,
  onKeyDown: PropTypes.func,
  title: PropTypes.string,
  disabled: PropTypes.bool
};

const MemoedLinkButton = React.memo(LinkButton);

const RefedLinkButton = React.forwardRef<HTMLButtonElement, ILinkButtonProps>((props, ref) => <MemoedLinkButton {...props} forwardRef={ref} />);

export default React.memo(RefedLinkButton);
