import styled from 'styled-components';
import { Colors, Shadows } from '@myblueprint-spaces/papier-core';

export const PillElem = styled.p(({ color, textColor, onClick, disabled }: {color?: string; textColor?: string, onClick?: (e?: unknown) => void, disabled?: boolean}) => {
  return `
    background: ${color};
    color: ${Colors[textColor]};
    display: inline-block;
    border-radius: 125rem;
    padding: 0.125rem 0.75rem;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    ${onClick && !disabled ? 'cursor: pointer;' : ''}

    & > span {      
      text-overflow: ellipsis;
    }

    :focus {
      ${Shadows.primaryFloatingFocus}
    }
  `;
});
