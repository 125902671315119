import styled, { keyframes, css } from 'styled-components';
import { Colors } from '@myblueprint-spaces/papier-core';
import { ITextSkeletonProps } from './types';
import React from 'react';

export const pulseAnimation = keyframes`
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
`;

export const StyledTextSkeleton: React.FunctionComponent<ITextSkeletonProps> = styled('div').attrs(({ color, comment, width, marginBottom }: ITextSkeletonProps) => ({
  style: {
    width: width,
    backgroundColor: color || (comment ? Colors.grey1 : Colors.grey3),
    marginBottom: marginBottom
  }
}))(({ comment }: ITextSkeletonProps) => {
  return css`
    height: ${comment ? '0.5rem' : '0.75rem'};
    max-width: 100%;

    ${comment ? `
    margin-bottom: 0.5rem;
    &:last-child {
       margin-bottom: 0;
    }` : ''}

    animation: ${pulseAnimation} 1s infinite ease-in-out;
  `;
});
