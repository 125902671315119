import { Colors, Shadows } from '@myblueprint-spaces/papier-core';
import CleanButton from 'src/Atoms/CleanButton';
import Icon from 'src/Common/Icon';
import Typography from 'src/Common/Typography';
import withRelativePosition from 'src/modules/hocs/withRelativePosition';
import styled from 'styled-components';
import { IPopoverStyleProps, IPopoverIconStyleProps } from './types';
import Checkbox from 'src/Common/Checkbox';
import React from 'react';

export const PopoverContainer = withRelativePosition('Popovers', 'remove-open-popover')(styled.div(({ hasPointer }: { hasPointer: boolean }) => `
    position: absolute;
    z-index: 3000;
    visibility: hidden;
    overflow: visible;

    // in case of Pointer we cannot use 'Shadows' otherwise Pointer is not covered
    // TODO check PR to see what got changed in here, we are missing shadow for non-pointer scenarios
    ${hasPointer ? 'filter: drop-shadow(0 0.125rem 0.25rem rgba(37,42,51,0.28));' : ''}
`));

export const PopoverElem = styled.div`
    border-radius: 0.25rem;
    background-color: ${Colors.white};
    ${Shadows.shadow3}
    padding: 0.5rem 0;
    width: 17.5rem;
`;

export const CustomCheckbox = styled(Checkbox)`
  justify-content: space-between;
`;

export const Pointer = styled.span`
  position: absolute;
  left: calc(50% - 0.25rem);
  top: -0.625rem;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0.625rem 0.625rem 0.625rem;
  border-color: transparent transparent ${Colors.white} transparent;
`;

export const CustomLink = styled.a.attrs(({ focusHoverEvent }: IPopoverStyleProps) => {
  return {
    style: {
      backgroundColor: focusHoverEvent ? Colors.grey4 : Colors.white
    }
  };
})`text-decoration: none;`;

export const PopoverDivider = styled.hr.attrs(({ role }: IPopoverStyleProps) => {
  return {
    role: role
  };
})`
  border: 0;
  height: 0;
  border-top: 0.0625rem solid ${Colors.grey3};
`;

export const MenuItemButton = styled(CleanButton).attrs(({ focusHoverEvent }: IPopoverStyleProps) => {
  return {
    style: {
      backgroundColor: focusHoverEvent ? Colors.grey4 : Colors.white
    }
  };
})`
  padding: 0.375rem 1rem;
  cursor: pointer;
  min-height: 2.5rem;
  width: 100%;
  border-radius: 0;
  text-align: left;
  transition: background-color 0.1s ease-in-out;
  background: ${Colors.white};
`;

export const MenuItemElem = (styled.div.attrs(({ focusHoverEvent, role }: IPopoverStyleProps) => {
  if (focusHoverEvent) {
    return {
      role: role,
      style: {
        backgroundColor: Colors.grey4
      }
    };
  }
  return {
    role: role
  };
})`
  padding: 0.375rem 1rem;
  cursor: pointer;
  min-height: 2.5rem;
  border-radius: 0;
  text-align: left;
  transition: background-color 0.1s ease-in-out;
  `
);

export const PopoverTypography: React.FunctionComponent<IPopoverStyleProps> = styled(Typography).attrs(({ focusHoverEvent }: IPopoverStyleProps) => {
  if (focusHoverEvent) {
    return {
      color: 'black1'
    };
  }
  return {};
})`
  transition: color 0.1s ease-in-out
`;

export const PopoverIcon: React.FunctionComponent<IPopoverIconStyleProps> = styled(Icon).attrs(({ focusHoverEvent }: IPopoverIconStyleProps) => {
  if (focusHoverEvent) {
    return {
      color: 'primary1'
    };
  }
  return {};
})(({ position }: { position?: 'left' | 'right' }) => `
  transition: color 0.1s ease-in-out
  ${position === 'left' ? 'padding: 0 0.5rem 0 0.75rem;' : 'padding: 0 0.75rem 0 0.5rem;'}
`);
