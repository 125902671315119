import { Colors, Shadows,Transparencies } from '@myblueprint-spaces/papier-core';
import styled from 'styled-components';
import { ITooltipProps } from './types';

export const TooltipContainer = styled.div`
  position: absolute;
`;

export const TooltipElem = styled.div.attrs({ role: 'tooltip' })(({ dark, size }: ITooltipProps) => {
  let vPad: number;
  let hPad: number;

  switch (size) {
    case 'small':
      vPad = 0.375;
      hPad = 0.5;
      break;
    default:
    case 'medium':
      vPad = 0.375;
      hPad = 0.75;
      break;
    case 'large':
      vPad = 0.625;
      hPad = 1;
      break;
    case 'xlarge':
      vPad = 0.75;
      hPad = 1;
      break;
  }

  return `
    padding: ${vPad}rem ${hPad}rem;
    ${Shadows.shadow1}
    border-radius: 0.25rem;
    position: relative;
    max-width : 40rem;
    z-index: 4000;
    background-color:${dark ? Transparencies.black2 : Colors.white};
    transition-delay: 500ms;
  `;
});
