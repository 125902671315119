import CanadaRegion from './../assets/icon_ca.png';
import InternationalRegion from './../assets/icon_intl.png';
import USRegion from './../assets/icon_us.png';

export const getRegionAsset = (key) => {
  switch (key) {
    case 'ca':
      return CanadaRegion;
    case 'intl':
      return InternationalRegion;
    case 'us':
      return USRegion;
  }
};
