import React, { useEffect } from 'react';
import Notifications from './NotificationsPanel';
import NotificationHooks from './hooks';
import { INotificationsContainerProps } from './types';
import { useNotificationFilter } from './components/NotificationFilterContext';

const NotificationsPanelContainer = (props: INotificationsContainerProps) => {
  const notificationFilter = useNotificationFilter();
  const unreadCount = NotificationHooks.useUnreadCount(notificationFilter);
  const hasNewCount = NotificationHooks.useHasNewCount();
  const hasNewWithContext = NotificationHooks.useHasNew(notificationFilter);
  const markAllRead = NotificationHooks.useMarkAllRead();
  const loadNotifications = NotificationHooks.useLoadNotifications();

  useEffect(() => {
    notificationFilter && loadNotifications(notificationFilter);
  }, [loadNotifications, notificationFilter]);

  const allProps = {
    ...props,
    unreadCount,
    hasNewCount,
    hasNewWithContext,
    markAllRead
  };

  return <Notifications {...allProps} />;
};

export default NotificationsPanelContainer;
