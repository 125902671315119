import { Grid, IRowProps, flexJustifyMap } from '@myblueprint-spaces/papier-core';
import styled from 'styled-components';

const RowColumnElem = styled.div((props: IRowProps) => {
  const { expanded, align, nested, collapse, span } = props;
  let style = `
  max-width: ${expanded ? 'none' : Grid.maxWidth + 'rem'};
  ${expanded ? 'flex: 0 0 100%;' : 'display: flex;'}
  ${span ? 'height: 100%;' : ''}
  margin-right: auto;
  margin-left: auto;
`;

  if (align) {
    style += `justify-content: ${flexJustifyMap[align] || flexJustifyMap.left};`;
  }

  if (nested && !collapse) {
    style += `margin-left: ${Grid.gutter}rem; margin-right: ${Grid.gutter}rem`;
  }

  return style;
});

export { RowColumnElem };
