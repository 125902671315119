import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { RightButtonWrapper, LeftButtonWrapper, UpperWrapper, LowerWrapper, ConfirmWrapper } from './styles';
import Typography from 'src/Common/Typography';
import Button from 'src/Common/Button';
import Dialog from '../../Dialog';
import Row from 'src/Common/Grid/Row';
import Column from 'src/Common/Grid/Column';
import TextBox from 'src/Common/TextBox';
import RowColumn from 'src/Common/Grid/RowColumn';
import CovalentGrid from 'src/Atoms/CovalentGrid';
import { Breakpoints, ButtonColorsType, ButtonSizeType } from '@myblueprint-spaces/papier-core';
import { IRequireConfirmationProps } from './types';
import { withWindowSize } from 'src/modules';
import { DialogSize } from '../../types';
import { Avatar } from 'src/Common/Avatar/Avatar';

const RequireConfirmation = ({ title, dialogTitle, subtitle, info, windowSize, text, avatarSource, onClose, size, visible, promptText, confirmText, confirmButtonProps, ...rest }: IRequireConfirmationProps) => {
  const { t } = useTranslation(['AccountSettings.Common', 'AccountSettings.Teacher', 'AccountSettings.Family', 'Common', 'Spaces', 'Classroom.Teacher']);
  const [confirmDeleteText, setConfirmDeleteText] = React.useState('');
  const isSmall = windowSize.width < Breakpoints.medium;
  const promptTextUsed = promptText || t('Common:DeleteDialog.Confirm');
  const defaultConfirmButtonProps = React.useMemo(() => ({
    async: true,
    expanded: true,
    color: 'danger' as ButtonColorsType,
    dataTest: 'yes',
    disabled: confirmDeleteText !== (confirmText || t('Common:DeleteLower')),
    text: t('Common:ConfirmDialog.YesDelete'),
    size: (isSmall ? 'small' : 'medium') as ButtonSizeType
  }), [confirmDeleteText, confirmText, isSmall]);

  const dialogSubtitleType = isSmall ? 'compact': 'body2';
  const dialogProps = {
    visible,
    title: dialogTitle ?? (title as string),
    size: size  ||  isSmall ? 'small' : 'medium' as DialogSize,
    fullScreenOnMobile: false,
    type: 'error',
    onClose: onClose,
    ...rest
  };
  React.useEffect(() => {
    if (!visible) setConfirmDeleteText('');
  }, [visible, setConfirmDeleteText]);

  return (
    <Dialog
      {...dialogProps}
    >
      <UpperWrapper>
        <RowColumn align="center" expanded>
          <Typography as={typeof title == 'string' ? 'p' : 'div'} type={ isSmall ? 'body2' : 'header4'} color="danger1" weight="demibold">{title}</Typography>
        </RowColumn>
        { subtitle && <RowColumn align="center" expanded>
          <Typography as={typeof subtitle == 'string' ? 'p' : 'div'} type={ isSmall ? 'compact' : 'body2'} color="black3">{subtitle}</Typography>
        </RowColumn>}
      </UpperWrapper>
      <RowColumn collapse expanded align="center">
        <ConfirmWrapper>
          {(avatarSource || text)
          && <RowColumn align="justify">
            <div style={{ marginBottom: '1rem' }}>
              <CovalentGrid verticalAlign="middle">
                { avatarSource && <Avatar avatarSource={avatarSource} size={isSmall ? 'small' : 'medium'} description={text} useGenericAlt noMargin />}
                {text && <Typography type={ isSmall ? 'subtitle1' : 'body2' }>{text}</Typography>}
              </CovalentGrid>
            </div>
          </RowColumn>}
          <RowColumn expanded>
            <Typography color="black2" type={isSmall ? 'subtitle1': 'body2'}>
              {promptTextUsed}
            </Typography>
          </RowColumn>
          <RowColumn expanded>
            <TextBox
              size="medium"
              hideLabel
              label={promptTextUsed}
              value={confirmDeleteText}
              onChange={(value) => setConfirmDeleteText(value)}
              dataTest="box"
              bottomMargin={false}
            />
          </RowColumn>
        </ConfirmWrapper>
      </RowColumn>

      <LowerWrapper>
        {info && <RowColumn expanded>
          <Typography as={typeof info == 'string' ? 'p' : 'div'} type={dialogSubtitleType} color="black3">{info}</Typography>
        </RowColumn>}
        <Row align="justify" collapse>
          <Column columns={{ small: 6 }}>
            <LeftButtonWrapper>
              <Button
                expanded
                color="secondary"
                dataTest="cancel-delete"
                text={t('Common:Answers.No')}
                onClick={onClose}
                size={isSmall ? 'small' : 'medium'}
              />
            </LeftButtonWrapper>
          </Column>
          <Column columns={{ small: 6 }}>
            <RightButtonWrapper>
              <Button
                {...defaultConfirmButtonProps}
                {...confirmButtonProps}
              />
            </RightButtonWrapper>
          </Column>
        </Row>
      </LowerWrapper>
    </Dialog>
  );
};

export default withWindowSize(RequireConfirmation);

