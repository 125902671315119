import * as React from 'react';
import * as PropTypes from 'prop-types';
import { IPanelProps, IPanelState } from './types';
import { Transition } from 'react-transition-group';
import { PanelTransitionStates, PanelContainer, Clipped } from './styles';
import GridContext from '../Grid/Shared/Context';
import CustomAriaModal from '../CustomAriaModal/CustomAriaModal';
import { generateId } from 'src/modules/browsers/generateId';
import { PanelContext } from './context';
import { getFirstFocusableElementInside } from 'src/modules';
import FocusableLoadingDots from './components/FocusableLoadingDots';
import withErrorCustomHandling from './components/withErrorCustomHandling';

class Panel extends React.Component<IPanelProps, IPanelState> {
  private id;

  public static contextType = PanelContext;

  public static propTypes = {
    id: PropTypes.string,
    open: PropTypes.bool,
    title: PropTypes.string,
    enterDir: PropTypes.string,
    exitDir: PropTypes.string,
    onPanelClose: PropTypes.func,
    onPanelDidOpen: PropTypes.func,
    onPanelDidClose: PropTypes.func,
    backgroundColor: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
    sizePercentage: PropTypes.string,

    connected: PropTypes.bool,
    openPanelIds: PropTypes.array,
    mountPanelById: PropTypes.func,
    unmountPanelById: PropTypes.func,
    dataTest: PropTypes.string.isRequired
  }

  public static defaultProps = {
    onPanelDidClose: null
  };

  public constructor(props: IPanelProps) {
    super(props);
    const { connected, open, error, id } = props;

    this.state = {
      ariaHidden: false,
      scrollTop: 0,
      open: connected ? open : false,
      panelShown: false,
      error: error
    };

    this.id = id || generateId();
    this.contentRef = React.createRef();
    this.loadingRef = React.createRef();
    this.onKeyDown = this.onKeyDown.bind(this);
    this.setFocus = this.setFocus.bind(this);
  }

  public isOpen(props: IPanelProps, state: IPanelState) {
    const { open } = state;
    const { connected, openPanelIds } = props;
    return connected ? openPanelIds.indexOf(this.id) >= 0 : open;
  }

  public closePanel = () => {
    const { onPanelClose, onPanelDidClose } = this.props;

    if (onPanelDidClose) {
      this.setState({ open: false }, () => {
        onPanelDidClose && onPanelDidClose();
      });
    } else {
      onPanelClose && onPanelClose();
    }
  }

  private onKeyDown(e: KeyboardEvent) {
    const { ariaHidden, panelShown } = this.state;
    const { open } = this.props;

    if (panelShown && open && e.key === 'Escape' && !ariaHidden) {
      e.preventDefault();
      e.stopPropagation();

      this.closePanel();
    }
  }
  private contentRef: React.RefObject<HTMLDivElement>;
  private loadingRef: React.RefObject<HTMLDivElement>;

  public componentDidMount() {
    const { open, mountPanelById, connected } = this.props;

    this.handleParentPanelAriaHidden();
    document.addEventListener('keydown', this.onKeyDown);

    // If panel is connected to redux, call mountPanelById to register it
    if (connected && typeof mountPanelById !== 'undefined') {
      mountPanelById(this.id, open);
    } else if (open) {
      setTimeout(() => {
        this.setState({
          open: open
        });
      }, 100);
    }
  }

  public setFocus = () => {
    const elem = this.contentRef.current;
    const { panelShown } = this.state;

    if (panelShown) {
      if (elem && elem.offsetWidth > 0) {
        const focusElem = getFirstFocusableElementInside(elem);
        if (focusElem?.offsetWidth > 0 && focusElem?.getAttribute('data-test') !== 'loading') {
          focusElem.focus();
          return;
        }
      }
      setTimeout(() => this.setFocus(), 200);
    }
  }

  public componentWillUnmount() {
    const { unmountPanelById, connected } = this.props;

    if (connected && typeof unmountPanelById !== 'undefined') {
      unmountPanelById(this.id);
    }
    document.removeEventListener('keydown', this.onKeyDown);
  }

  public underlayClick = (e) => {
    if (e.target.getAttribute('class') === `${this.id}_underlay`) {
      this.closePanel();
    }
  };

  public componentDidUpdate(prevProps: IPanelProps, prevState: IPanelState) {
    const { open, onPanelDidOpen, onPanelClose, error, sizePercentage } = this.props;
    const { panelShown } = this.state;

    const prevOpen = this.isOpen(prevProps, prevState);
    const currOpen = this.isOpen(this.props, this.state);
    if (error && error !== prevProps.error) {
      this.setState({ open: false }, () => {
        setTimeout(() => {
          this.setState({ open: true });
        }, 800);
      });
    }
    if (!prevOpen && currOpen && typeof onPanelDidOpen === 'function') {
      onPanelDidOpen();
    }

    if (panelShown !== prevState.panelShown) {
      if(panelShown) {
        this.setFocus();
        if(sizePercentage !== '100%') {
          document.getElementsByClassName(`${this.id}_underlay`)[0]?.addEventListener('click', this.underlayClick);
        }
      } else if(sizePercentage !== '100%') {
        document.getElementsByClassName(`${this.id}_underlay`)[0]?.removeEventListener('click', this.underlayClick);
      }
    }
    if (currOpen !== prevOpen) {
      this.handleParentPanelAriaHidden();
    }
    if (prevProps.open !== open) {
      this.handleParentPanelAriaHidden();
      const showPanel = open ? prevState.panelShown : false;
      if (open) {
        setTimeout(() => {
          this.setState({
            open: open,
            panelShown: showPanel
          });
        }, 100);
      } else {
        this.setState({
          open: open,
          panelShown: showPanel
        });
      }
      !open && onPanelClose && onPanelClose();
    }
  }

  public handleParentPanelAriaHidden() {
    if (this.context) {
      const { setParentPanelAriaHidden } = this.context;
      if (setParentPanelAriaHidden) {
        const open = this.isOpen(this.props, this.state);
        // If this panel is open, its parents are hidden
        setParentPanelAriaHidden(open);
      }
    }
  }

  public handleSetPanelAriaHidden = (value: boolean) => {
    this.setState({ ariaHidden: value });
  }

  public setScrollTop = (scrollTop: number) => {
    this.setState({ scrollTop: scrollTop });
  }

  public onExit = (underlayClickExits) => {
    if (underlayClickExits) {
      this.closePanel();
    }
  };

  public render() {
    const { children, enterDir = 'up', exitDir = 'down', title, backgroundColor = 'white', dataTest, sizePercentage = '100%', error } = this.props;
    const { ariaHidden, open, scrollTop, panelShown } = this.state;

    const nested = this.context !== null;

    const transitions = PanelTransitionStates(enterDir, exitDir);

    // if entry direction = left or right, apply sizePercentage as width, 100% height
    // if entry direction = top or bottom, sizePercentage applied as height, 100% width
    let height;
    let width;

    if (enterDir == 'left' || enterDir == 'right') {
      width = sizePercentage;
    } else {
      height = sizePercentage;
    }

    // show underlay if panel is not 100%
    let underlayStyle = { background: 'none' };
    let handleOurOwnUnderlayExits = false;
    if (sizePercentage !== '100%') {
      underlayStyle = null;
      handleOurOwnUnderlayExits = true;
    }

    const panelContext = {
      id: this.id,
      open: open,
      closeParentPanel: this.closePanel,
      setParentPanelAriaHidden: this.handleSetPanelAriaHidden,
      scrollTop: scrollTop,
      setScrollTop: this.setScrollTop
    };

    return (
      <React.Fragment>
        {open && <GridContext.Provider value={{ collapse: false, nested: false }}>
          <PanelContext.Provider value={panelContext}>
            <CustomAriaModal dialogId={this.id + '_custom-aria-modal'} onExit={() => this.onExit(handleOurOwnUnderlayExits)} titleText={title} contentRef={this.contentRef}
              // we CANNOT use underlayClickExits=true, this will invalidate our 'allowClickOutside' and will prevent any floatable inside partial panels
              underlayClickExits={false} applicationNode={nested ? null : document.querySelector('#root')} underlayClass={`${this.id}_underlay`}
              scrollDisabled={!nested} underlayStyle={underlayStyle} initialFocus={() => ((this.contentRef.current || this.loadingRef.current) as HTMLElement)}>
              {(!panelShown || error) && <FocusableLoadingDots id={this.id + '_fallback'} onUnmount={() => this.setFocus()} ref={this.loadingRef} hidden={handleOurOwnUnderlayExits} />}
              <Transition key={this.id} timeout={250} in={open} appear={open} mountOnEnter unmountOnExit onEntered={() => this.setState({ panelShown: true })}>
                {
                  (state) => {
                    return (
                      <PanelContainer
                        id={this.id + '_panelContent'}
                        backgroundColor={backgroundColor}
                        ref={this.contentRef}
                        style={{ ...transitions[state], width: width, height: height }}
                        aria-hidden={ariaHidden}
                        role="document"
                        enterDir={enterDir as ('left' | 'right')}
                        data-test={`panel-${dataTest}`}>
                        <div role="main" aria-labelledby={this.id + '_panel-title'} style={{ width: '100%', height: '100%' }}>
                          <Clipped><h1 id={this.id + '_panel-title'}>{title}</h1></Clipped>
                          {!error && <React.Suspense fallback={<FocusableLoadingDots onUnmount={() => this.setFocus()} />}>
                            {children}
                          </React.Suspense>}
                        </div>
                      </PanelContainer>
                    );
                  }
                }
              </Transition>
            </CustomAriaModal>
          </PanelContext.Provider>
        </GridContext.Provider>}
      </React.Fragment>
    );
  }
}

export default withErrorCustomHandling(Panel);
