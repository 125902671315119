import React, { ClassicComponentClass, FunctionComponent } from 'react';

export interface IState {
  error: boolean | null;
}

const withErrorBoundaries = <P extends Record<string, any>>(WrappedComponent: FunctionComponent<P> | ClassicComponentClass<P>) => {
  class WithBoundaries extends React.Component<P, IState> {
    public constructor(props) {
      super(props);

      this.state = {
        error: null
      };
    }

    public componentDidCatch(error) {
      console.error(error, `ERROR from Error Boundaries: ${WrappedComponent.displayName || WrappedComponent.name} component`, WrappedComponent.toString());
      this.setState({ error: error });
    }

    public render() {
      const { error } = this.state;

      if (error) return <div>An error has occurred</div>;

      return <WrappedComponent {...this.props as P} />;
    }
  }

  return WithBoundaries;
};

export default withErrorBoundaries;
