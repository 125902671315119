import { AuthenticationResult } from '@myblueprint-spaces/redux/lib/authentication';
import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'src/services/toasts';
import ProductContext from '../Contexts/ProductContext';
import { Product } from '@myblueprint-spaces/redux/lib/api/common/globals/enums';
import useGetFullUrl from 'src/modules/hooks/useGetFullUrl';
import { failToast } from 'src/services/toasts/toastEventEmitter';

export function useHandleAuthorizationErrors(isLogin = true, redirectToLogin = false, setLoading?: (loading: boolean) => void) {
  const { productType } = useContext(ProductContext);
  const { t } = useTranslation(['Spaces.Common', 'LoginSignUp.Common']);
  const { search } = useLocation();
  const navigate = useNavigate();
  const isSpaces = productType === Product.Spaces;
  const getFullUrl = useGetFullUrl();
  const loginOrSignUp = isLogin ? 'login' : 'signup';
  const noAccessUrl = getFullUrl(isSpaces ? `/${loginOrSignUp}/no-access` : '/no-access');
  const missingRecordUrl = getFullUrl(isSpaces ? `/${loginOrSignUp}/missing-record` : '/missing-record');
  const missingEmailUrl = getFullUrl(`/${loginOrSignUp}/missing-email`);

  return useCallback((errorData) => {
    setLoading?.(false);

    if (errorData.resultCode === AuthenticationResult.RecordDoesNotExist) navigate({ pathname: missingRecordUrl, search });
    else if (errorData.resultCode === AuthenticationResult.SsoRequired) navigate({ pathname: noAccessUrl, search });
    else if (errorData.resultCode === AuthenticationResult.AccountLocked) failToast(errorData, t, t('Common:Validations.AccountLocked'));
    else if (errorData.resultCode === AuthenticationResult.EmailNotProvided && isSpaces) navigate(missingEmailUrl);
    else if (errorData.resultCode === AuthenticationResult.Fail) failToast(errorData, t, t('Common:Validations.InvalidCredentials'));
    else if (errorData.resultCode === AuthenticationResult.ExternalIdMismatch && isSpaces) {
      toast(null, { text: t('LoginSignUp.Common:CleverUserExistsToast') });
      navigate('/');
    } else {
      toast(null, { text: t('Spaces.Common:AuthorizationFailed').toString(), timeout: 5000 });
      if (redirectToLogin) navigate({ pathname: '/', search });
    }
  }, [noAccessUrl, missingRecordUrl, missingEmailUrl, search, redirectToLogin, t, navigate, setLoading, isSpaces]);
}
