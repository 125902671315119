import * as React from 'react';
import { RovingTabIndexContext } from './Provider';
import shortid from 'shortid';
import { IUseRovingProps, INewProps } from './types';
import { onKeyDown, onClick, register, unregister, calcTabIndex, changeDisabled } from './common';

export const withRovingTabindex = <OriginalProps extends { onClick?: (e?: unknown) => void },>(WrappedComponent: React.ComponentType<OriginalProps>, isGrid?: boolean) => {
  type HocProps = OriginalProps & IUseRovingProps & INewProps;

  class WithRovingTabIndexElem extends React.Component<OriginalProps & IUseRovingProps> {
    public tabIndexId: string;

    public constructor(props: OriginalProps & IUseRovingProps) {
      super(props);

      // This id is stable for the life of the component:
      this.tabIndexId = props.id || 'roving-tabindex_' + shortid.generate();
    }

    // Registering and unregistering are tied to whether the input is disabled or not.
    // Context is not in the inputs because context.dispatch is stable.
    public componentDidMount() {
      const { disabled, domElementRef, context } = this.props;

      register(context, this.tabIndexId, domElementRef, disabled);
    }

    isFocused(props: IUseRovingProps & OriginalProps) {
      const { disabled, context } = props;

      const selected = !disabled && this.tabIndexId === context.state.selectedId;
      const focused = selected && context.state.lastActionOrigin === 'keyboard';

      return focused;
    }

    public componentDidUpdate(prevProps: IUseRovingProps & OriginalProps) {
      const { domElementRef, context, disabled, isGrid } = this.props;
      if (this.isFocused(this.props) && !this.isFocused(prevProps) && domElementRef?.current) {
        (domElementRef.current as HTMLElement).focus();
      }
      if (disabled !== prevProps.disabled && isGrid) {
        changeDisabled(context, this.tabIndexId, domElementRef, disabled);
      }
    }

    public componentWillUnmount() {
      const { context } = this.props;
      unregister(context, this.tabIndexId);
    }

    public handleKeyDown = (event: KeyboardEvent): void => {
      const { tabIndexId } = this;
      const { isGrid, context } = this.props;

      onKeyDown(event, context, tabIndexId, isGrid);
    };

    public handleClick = () => {
      const { tabIndexId } = this;
      const { context, onClick: originalOnClick } = this.props;

      onClick(context, tabIndexId);
      originalOnClick?.();
    };

    public render() {
      const { tabIndexId, handleKeyDown, handleClick } = this;
      const { context, disabled, domElementRef, ...rest } = this.props;

      const childProps = {
        ...rest,
        disabled,
        ...calcTabIndex(context, tabIndexId, disabled),
        ref: domElementRef,
        onKeyDown: handleKeyDown,
        onClick: handleClick
      };

      return <WrappedComponent {...childProps as OriginalProps} />;
    }
  }

  return React.forwardRef<typeof WrappedComponent, OriginalProps>((props, ref) => {
    return (
      <RovingTabIndexContext.Consumer>
        {(context) => <WithRovingTabIndexElem {...props as HocProps} isGrid={isGrid} context={context} domElementRef={ref} />}
      </RovingTabIndexContext.Consumer>
    );
  });
};
