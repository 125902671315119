import { flexAlignMap, IColumnProps, IGridContext } from '@myblueprint-spaces/papier-core';
import * as PropTypes from 'prop-types';
import * as React from 'react';
import GridContext from '../Shared/Context';
import { IGridCommonProps } from '../Shared/types';
import { ColumnElem } from './styles';

// gridColumns is added to support the DataGrid that is 24 columns. DO NOT use this outside DataGrid
type ForwardedColumn = IColumnProps & IGridCommonProps;
export type ColumnProps = (IColumnProps & IGridCommonProps) | ForwardedColumn;

const Column = (props: ColumnProps) => (
  <GridContext.Consumer>
    {({ collapse: contextCollapse }: IGridContext) => {
      const { collapse, forwardRef, ...rest } = props as ForwardedColumn;
      return <ColumnElem {...rest} ref={forwardRef} collapse={typeof collapse !== 'undefined' ? collapse : contextCollapse} />;
    }}
  </GridContext.Consumer>
);

const columnPropType = PropTypes.oneOfType([
  PropTypes.oneOf(['expand', 'shrink']),
  PropTypes.number
]);

const columnPropSize = (propValidation: PropTypes.Requireable<unknown>) => PropTypes.shape({
  small: propValidation,
  medium: propValidation,
  large: propValidation,
  xlarge: propValidation,
  xxlarge: propValidation,
  xxxlarge: propValidation
});

Column.propTypes = {
  columns: columnPropSize(columnPropType),
  offset: columnPropSize(PropTypes.number),
  shrink: PropTypes.bool,
  collapse: PropTypes.bool,
  style: PropTypes.object,
  verticalAlign: PropTypes.oneOf(Object.keys(flexAlignMap)),
  gridColumns: PropTypes.number
};

const RefedColumn = React.forwardRef<HTMLDivElement, ForwardedColumn>((props, ref) => <Column {...props} forwardRef={ref} />);

export default RefedColumn;
