import { Grid, IRowProps, flexAlignMap, flexJustifyMap } from '@myblueprint-spaces/papier-core';
import styled from 'styled-components';

const RowElem = styled.div((props: IRowProps) => {
  const { expanded, align, verticalAlign, span, nested, collapse, wrap } = props;
  let style = `
  display: flex;
  max-width: ${expanded ? 'none' : Grid.maxWidth + 'rem'};
  ${expanded ? 'width: 100%;' : ''}
  ${span ? 'height: 100%;' : ''}
  margin-right: auto;
  margin-left: auto;
  flex-direction: row;
  flex-wrap: ${wrap ? 'wrap' : 'nowrap'};
  ${wrap ? '' : 'overflow-x: auto'};
`;

  if (align) {
    style += `justify-content: ${flexJustifyMap[align] || flexJustifyMap.left};`;
  }
  if (verticalAlign) {
    style += `align-items: ${flexAlignMap[verticalAlign] || flexAlignMap.top};`;
  }

  if (nested && !collapse) {
    style += `margin-left: ${-Grid.gutter}rem; margin-right: ${-Grid.gutter}rem`;
  }

  return style;
});

export { RowElem };
