import * as React from 'react';

export interface ObservingElements {
  id: string;
  callback: () => unknown;
}

interface IIntersectionObserverComponentState {
  elements: ObservingElements[];
}

export interface IIntersectionObserverComponentProps {
  children: React.ReactNode;
  stickyHeader?: string;
  id?: string;
}

class IntersectionObserverComponent extends React.PureComponent<IIntersectionObserverComponentProps, IIntersectionObserverComponentState> {
  private io;

  private fireEvent = (stuck, target) => {
    const e = new CustomEvent('viewport-position-change', { detail: { stuck, target } });
    document.dispatchEvent(e);
  };

  public constructor(props) {
    super(props);

    const { stickyHeader, id } = props;
    let offsetTop = 0;

    const containerId = id ? `${id}--wrapper` : '';

    if (typeof stickyHeader === 'string') {
      if (stickyHeader.indexOf('px') !== -1) {
        offsetTop = parseInt(stickyHeader.replace('px', ''));
      } else {
        offsetTop = parseInt(stickyHeader.replace('rem', '')) * parseFloat(getComputedStyle(document.documentElement).fontSize);
      }
    }

    this.io = new IntersectionObserver(
      (entries) => {
        if (entries[0]) {
          const targetInfo = entries[0].boundingClientRect;
          const rootBoundsInfo = entries[0].rootBounds;
          // Started sticking.
          if (targetInfo.top <= (rootBoundsInfo.top + offsetTop + 72)) {
            this.fireEvent(true, entries[0]);
          }

          // Stopped sticking.
          if (targetInfo.bottom > (rootBoundsInfo.top + offsetTop + 72)) {
            this.fireEvent(false, entries[0]);
          }
        }
      },
      {
        root: containerId ? document.getElementById(containerId) : undefined
      }
    );
    this.observe = this.observe.bind(this);
    this.unobserve = this.unobserve.bind(this);
    this.state = { elements: [] };
  }

  public componentDidUpdate(prevProps: Readonly<IIntersectionObserverComponentProps>): void {
    const { stickyHeader, id } = this.props;

    if (prevProps.stickyHeader !== stickyHeader) {
      this.unobserve?.(id);
      let offsetTop = 0;

      const containerId = id ? `${id}--wrapper` : '';

      if (typeof stickyHeader === 'string') {
        if (stickyHeader.indexOf('px') !== -1) {
          offsetTop = parseInt(stickyHeader.replace('px', ''));
        } else {
          offsetTop = parseInt(stickyHeader.replace('rem', '')) * parseFloat(getComputedStyle(document.documentElement).fontSize);
        }
      }

      this.io = new IntersectionObserver(
        (entries) => {
          if (entries[0]) {
            const targetInfo = entries[0].boundingClientRect;
            const rootBoundsInfo = entries[0].rootBounds;
            // Started sticking.
            if (targetInfo.top <= (rootBoundsInfo.top + offsetTop + 72)) {
              this.fireEvent(true, entries[0]);
            }

            // Stopped sticking.
            if (targetInfo.bottom > (rootBoundsInfo.top + offsetTop + 72)) {
              this.fireEvent(false, entries[0]);
            }
          }
        },
        {
          root: containerId ? document.getElementById(containerId) : undefined
        }
      );
      this.observe = this.observe.bind(this);
      this.unobserve = this.unobserve.bind(this);
      this.setState({ elements: [] });
    }
  }

  public observe(id: string, callback) {
    const { elements } = this.state;
    if (elements.find((e) => e?.id === id)) {
      return; // Do nothing already being observed
    }
    const elem = document.getElementById(id);
    if (!elem) return; // Do nothing, non-existent element

    const sentinel = document.createElement('div');
    sentinel.setAttribute('style', 'position: absolute;left: 0;right: 0;visibility: hidden;');
    sentinel.setAttribute('id', id + 'sentinel--top');

    elem.parentNode.insertBefore(sentinel, elem);
    this.io.observe(sentinel);

    document.addEventListener('viewport-position-change', callback);
    this.setState({ elements: elements.concat({ id: id, callback: callback }) });
  }

  public unobserve(id) {
    const element = document.getElementById(id + 'sentinel--top');
    if (element) {
      this.io.unobserve(element);
    }

    this.destroy(id);
  }

  private destroy(id) {
    const { elements } = this.state;

    const elem = elements.find((e) => e?.id === id);
    if (elem) {
      document.getElementById(id + 'sentinel--top')?.remove();
      document.removeEventListener('viewport-position-change', elem.callback);
      this.setState({ elements: elements.filter((e) => e?.id !== id) });
    }
  }

  public componentWillUnmount() {
    const { elements } = this.state;

    for (const elem in elements) {
      this.destroy(elem);
    }

    this.io.disconnect();
  }

  public render() {
    const { children } = this.props;
    const clonedChildren = React.Children.map(children as React.ReactElement,
      (child) => React.cloneElement(child, { observeIntersection: this.observe, unobserveIntersection: this.unobserve }));
    return (
      <React.Fragment>
        {clonedChildren}
      </React.Fragment>
    );
  }
}

const withIntersectionObserver = <P extends object>(WrappedComponent: React.ComponentType<P>) => {
  return (props: P): React.ReactElement<P> => (
    <IntersectionObserverComponent {...props}>
      <WrappedComponent {...props} />
    </IntersectionObserverComponent>
  );
};

export default withIntersectionObserver;
