import React from 'react';
import NotificationRefreshBar from './NotificationRefreshBar';
import NotificationHooks from 'src/Common/Notifications/hooks';

const NotificationRefreshBarContainer = (props : { inPanel?: boolean }) => {
  const loadNewest = NotificationHooks.useLoadNewest();

  return <NotificationRefreshBar loadNewest={loadNewest} {...props} />;
};

export default NotificationRefreshBarContainer;
