import { ButtonColors, ButtonSizes, ButtonStatus, ButtonStatusNames, IStatusButtonProps, getTextIconSizeByButtonSize, getButtonAppearance } from '@myblueprint-spaces/papier-core';
import Icon from 'src/Common/Icon';
import LoadingDots from 'src/Common/LoadingDots';
import * as PropTypes from 'prop-types';
import * as React from 'react';
import { BaseButton } from '../../styles';
import { LoadingDotsSizesType } from 'src/Common/LoadingDots/types';
import { IButtonCustomProps } from '../../types';

const StatusButton = ({ id, type = 'button', size, color, disabled, rounded, expanded, icon, status, forwardRef, dataTest, ...props }: IStatusButtonProps & IButtonCustomProps) => {
  const { iconColor, disabledIconColor } = getButtonAppearance(color);

  const { iconSize } = getTextIconSizeByButtonSize(size);

  const baseButtonProps = {
    type,
    id,
    size,
    rounded,
    expanded
  };
  let buttonProps;
  switch (status) {
    case ButtonStatus.loading:
    default:
      buttonProps = {
        ...baseButtonProps,
        color,
        loading: true,
        pointerEvents: 'none',
        ...props,
        children: <LoadingDots size={size as LoadingDotsSizesType} color={disabled ? disabledIconColor : iconColor} />
      };
      break;
    case ButtonStatus.success:
      buttonProps = {
        ...baseButtonProps,
        color: 'success',
        pointerEvents: 'none',
        ...props,
        children: <Icon type="checkmark" size={iconSize} color="white" inline />
      };
      break;
    case ButtonStatus.fail:
      buttonProps = {
        ...baseButtonProps,
        color: 'danger',
        pointerEvents: 'none',
        shake: true,
        ...props,
        children: <Icon type="close" size={iconSize} color="white" inline />
      };
      break;
  }

  return <BaseButton ref={forwardRef} data-test={dataTest} {...buttonProps} />;
};

StatusButton.propTypes = {
  color: PropTypes.oneOf(ButtonColors),
  size: PropTypes.oneOf(ButtonSizes),
  id: PropTypes.string,
  icon: PropTypes.string,
  rounded: PropTypes.bool,
  status: PropTypes.oneOf(ButtonStatusNames)
};

export default React.memo(React.forwardRef<HTMLButtonElement, IStatusButtonProps & IButtonCustomProps>((props, ref) => <StatusButton {...props} forwardRef={ref} />));
