import { useCallback, useState } from 'react';

/**
 * This should be used for all scenarios where we want a function to be in the state but it is not a case of
 * lazy initialization. https://github.com/facebook/react/issues/15209
 *
 * Basically, whenever you pass a function as the initial state, it will *execute* the function
 * and whenever you set it, it'll treat it like a functional update. So, to avoid this, use this hook.
 *
 * @param initialFunction
 */
const useFunctionState = (initialFunction) => {
  const [func, setFunc] = useState(() => initialFunction);
  const setCallbackFunc = useCallback((f) => setFunc(() => f), [setFunc]);
  return [func, setCallbackFunc];
};

export default useFunctionState;
