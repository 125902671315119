import React, { useCallback, useContext, useState } from 'react';
import ExternalProviderButton from '@myblueprint-spaces/papier-web/lib/Common/Button/components/ExternalProviderButton';
import { useTranslation } from 'react-i18next';
import { AccountType } from '@myblueprint-spaces/appstate';
import { LoginProviders, TimeZones } from '@myblueprint-spaces/redux/lib/user';
import { getLanguageFromCulture } from '@myblueprint-spaces/redux/lib/resources';
import { SsoAuthPayload } from '@myblueprint-spaces/redux/lib/authentication';
import withErrorBoundaries from '@myblueprint-spaces/papier-web/lib/modules/hocs/withErrorBoundaries';
import { IExternalProviderButtonTypes } from '@myblueprint-spaces/papier-web/lib/Common/Button/components/ExternalProviderButton/types';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import ExternalLoginBase from '../ExternalLoginBase';
import AuthLoadingContext from '../Contexts/AuthLoadingContext';
import Terms from './components/Terms';
import { useId } from '@myblueprint-spaces/papier-web/lib/modules/hooks';
import { useHandleAuthorizationErrors } from '../ExternalLoginBase/hooks';
import { getScopeTypeFromAccountType, getScopeTypeFromExternalUserType } from 'src/modules/helpers/accountTypeHelpers';

interface ExternalAuthSignUpButtonProps {
  onLogin: (e: unknown) => Promise<unknown>;
  createUser: (e: Record<string, unknown>, y?: unknown) => void;
  accountType?: AccountType;
  googleReCaptchaProps: Record<string, any>;
  validateUsedEmail?: (email: string) => boolean;
  provider: Omit<LoginProviders, LoginProviders.googleUri | LoginProviders.cleverUri>
}

const ExternalAuthSignUpButton = ({ provider, onLogin, createUser, accountType, googleReCaptchaProps, validateUsedEmail = undefined }: ExternalAuthSignUpButtonProps) => {
  const [showTerms, setShowTerms] = useState(false);
  const { t, i18n } = useTranslation(['Common', 'LoginSignUp.Common', 'Spaces.Common']);
  const { loading, setLoading } = useContext(AuthLoadingContext);
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone as TimeZones;
  const isClever = provider === LoginProviders.clever;
  const onError = useHandleAuthorizationErrors(false, false, setLoading);
  const id = useId();

  const onAuthenticated = useCallback((response) => {
    setLoading(true);
    return onLogin(response)
      .finally(() => setLoading(false));
  }, []);

  const onSignUp = useCallback((ssoAuthPayload: SsoAuthPayload) => {
    setLoading(true);
    const { externalData } = ssoAuthPayload;

    if (!validateUsedEmail || (validateUsedEmail && externalData.email && validateUsedEmail(externalData.email))) {
      const user = {
        scopeType: !isClever ? getScopeTypeFromAccountType(accountType) : getScopeTypeFromExternalUserType(externalData.userType),
        firstName: externalData.firstName,
        lastName: externalData.lastName,
        email: externalData.email,
        culture: getLanguageFromCulture(i18n.language),
        timeZone
      };

      return googleReCaptchaProps.executeRecaptcha?.(`${provider}_signup`.toLocaleLowerCase())
        .then((token) => createUser({
          user,
          externalData
        }, token))
        .then(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [validateUsedEmail, getScopeTypeFromAccountType, getLanguageFromCulture, accountType, i18n, timeZone, googleReCaptchaProps, createUser]);

  const onClose = useCallback(() => setLoading(false), []);

  return (
    <ExternalLoginBase
      provider={provider}
      onClose={onClose}
      onError={onError}
      onAuthenticated={onAuthenticated}
      id={id}
      onSignUp={onSignUp}>
      {({ initiateLogin }) => (
        <React.Fragment>
          <ExternalProviderButton
            id={`btn-${provider}-signup`.toLocaleLowerCase()}
            type={provider as keyof typeof IExternalProviderButtonTypes}
            onClick={() => setShowTerms(true)}
            text={t(`LoginSignUp.Common:${provider}`)}
            loading={loading}
            dataTest={`signup-${provider}`.toLocaleLowerCase()}
            shrink
            reducedPadding />
          <Terms
            visible={showTerms}
            initiateLogin={initiateLogin}
            onClose={() => setShowTerms(false)} />
        </React.Fragment>
      )}
    </ExternalLoginBase>
  );
};

export default withGoogleReCaptcha(withErrorBoundaries(ExternalAuthSignUpButton)) as React.FunctionComponent<Omit<ExternalAuthSignUpButtonProps, 'googleReCaptchaProps'>>;
