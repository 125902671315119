import Icon from 'src/Common/Icon';
import styled from 'styled-components';

export const EmptyStateDefaultIcon = styled(Icon).attrs(() => ({
  type: 'image',
  size: 'large',
  color: 'grey1'
}))`
display: inline-block;
margin: 0;
`;
