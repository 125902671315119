import { FontSizes, FontTypes, FontWeights } from '@myblueprint-spaces/papier-core';
import FormValidation from 'src/Common/FormValidation';
import { IconSizesType } from 'src/Common/Icon/types';
import Label from 'src/Common/Label';
import { LabelSize } from 'src/Common/Label/types';
import useId from 'src/modules/hooks/useId';
import * as PropTypes from 'prop-types';
import * as React from 'react';
import { TemporarySpacer, TextBoxContainer, TextBoxElement, TextBoxIcon, TextBoxIconButton, TextBoxIconElement, TextBoxLabelContainer } from './styles';
import { useTranslation } from 'react-i18next';
import { IconNameType } from '@myblueprint-spaces/papier-icons';
import { isIosDevice } from 'src/modules';
import { sanitizeString } from '@myblueprint-spaces/modules';
import { IconObj, ITextBoxProps, TextBoxSizes } from 'src/Atoms/types';
import { BaseTextArea } from 'src/Atoms/BasicTextArea';
import { BaseTextBox } from 'src/Atoms/BasicTextBox';

export function TextBox(props: ITextBoxProps & { forwardRef?: React.ForwardedRef<HTMLInputElement> }): React.ReactElement {
  const { id, size, onChange, onFocus, onBlur, label, hideLabel = false, labelRight, labelIcon, icon,
    validation, multiline, value = '', type = 'text', bottomMargin = true, className, dataTest, clearable, tabIndex, required = false, ...rest } = props;
  const textBoxId = useId(id);
  const [isFocussed, setIsFocussed] = React.useState(false);
  const [isHovered, setIsHovered] = React.useState(false);
  const usedIcon: IconObj = !icon ? null
    : typeof icon === 'object' ? icon as IconObj : {
      type: icon as IconNameType
    };
  const { type: iconType, position: iconPosition = 'left', color: iconColor = 'black3', onClick: onIconClick, title: iconTitle, size: iSize, ...iconRest } = (usedIcon || {});

  let valueSize: { size: number; lineHeight: number; defaultWeight: FontWeights };
  let iconSize: IconSizesType;
  let labelSize: LabelSize = 'medium';

  const { t } = useTranslation(['Common']);

  // Calculate values to use
  switch (size) {
    case 'xlarge':
      valueSize = FontSizes[FontTypes.body1];
      iconSize = iSize || 'large';
      break;
    case 'large':
      valueSize = FontSizes[FontTypes.body2];
      iconSize = iSize || 'medium';
      break;
    default:
    case 'medium':
      valueSize = FontSizes[FontTypes.body2];
      iconSize = iSize || 'medium';
      break;
    case 'small':
      valueSize = FontSizes[isIosDevice() ? FontTypes.body2 : FontTypes.subtitle1];
      iconSize = iSize || 'small';
      labelSize = 'small';
      break;
  }

  // Event Handlers
  const handleChange = React.useCallback((e: (React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>)) => onChange && onChange(sanitizeString(e.target.value)), [onChange]);
  const handleFocus = React.useCallback(() => {
    setIsFocussed(true);
    if (onFocus) {
      onFocus();
    }
  }, [setIsFocussed, onFocus]);
  const handleBlur = React.useCallback(() => {
    setIsFocussed(false);
    if (onBlur) {
      onBlur();
    }
  }, [setIsFocussed, onBlur]);
  const handleMouseOver = React.useCallback(() => {
    setIsHovered(true);
  }, [setIsHovered]);
  const handleMouseOut = React.useCallback(() => {
    setIsHovered(false);
  }, [setIsHovered]);

  // Handle validation
  const isSuccess = validation && validation.state === 'success';
  const validationElement = validation && validation.state !== null && !validation.hideIcon
    ? (
      <TextBoxIconElement multiline={multiline} size={size} key={`${id}_validationElement`} data-test={`text-${dataTest}-validation`}>
        <TextBoxIcon type={isSuccess ? 'check' : 'warning'} size={iconSize} color={isSuccess ? 'success1' : 'danger1'} />
      </TextBoxIconElement>
    )
    : null;
  const validationMessage = validation && <FormValidation {...validation} size={size === 'small' ? size : 'medium'} />;

  // Handle ordering of elements
  const commonTextBoxProps = {
    id: textBoxId,
    value,
    size,
    valueSize,
    iconSize,
    onChange: handleChange,
    isFocussed,
    onFocus: handleFocus,
    onBlur: handleBlur,
    onMouseOver: handleMouseOver,
    onMouseOut: handleMouseOut,
    tabIndex,
    type
  };
  if (hideLabel) {
    commonTextBoxProps['aria-label'] = label;
  }
  const textBoxElement = multiline
    ? <TextBoxElement key={`${id}_textBoxElement`}><BaseTextArea {...commonTextBoxProps} {...rest} data-test={`text-${dataTest}-textarea`} aria-required={required.toString()} /></TextBoxElement>
    : <TextBoxElement key={`${id}_textBoxElement`}><BaseTextBox {...commonTextBoxProps} {...rest} data-test={`text-${dataTest}`} aria-required={required.toString()} /></TextBoxElement>;
  const iconInner = <TextBoxIcon type={iconType} size={iconSize} color={iconColor} />;
  const iconElement = icon ? (
    <TextBoxIconElement key={`${id}_iconElement`} multiline={multiline} size={size} data-test={`text-${dataTest}-icon`}>
      {
        typeof onIconClick === 'function'
          ? <TextBoxIconButton onClick={onIconClick} title={iconTitle} data-test={`${dataTest}-btn`} {...iconRest}>{iconInner}</TextBoxIconButton>
          : iconInner
      }
    </TextBoxIconElement>
  ) : null;
  const handleClear = () => {
    onChange('');
  };
  const clearElement = clearable && value && value.length ? (
    <TextBoxIconElement key={`${id}_ClearIconElement`} multiline={multiline} size={size} data-test={`text-${dataTest}-clear`}>
      <TextBoxIconButton onClick={handleClear} title={t('Common:Actions.Clear')} data-test="clear">
        <TextBoxIcon type="close" size={iconSize} color="black3" />
      </TextBoxIconButton>
    </TextBoxIconElement>
  ) : null;
  const temporarySpacer = icon ? <TemporarySpacer key={`${id}_spacer`} /> : null;

  // Markup
  return (
    <div className={className}>
      {label && !hideLabel && (
        <TextBoxLabelContainer>
          <Label htmlFor={textBoxId} size={labelSize} icon={labelIcon} right={labelRight} required={required}>
            {label}
          </Label>
        </TextBoxLabelContainer>
      )}
      <TextBoxContainer {...rest} size={size} isFocussed={isFocussed} isHovered={isHovered} validation={validation} multiline={multiline} bottomMargin={bottomMargin} data-test={`text-${dataTest}-box`}>
        {
          iconPosition === 'left'
            ? [iconElement, temporarySpacer, textBoxElement, clearElement, validationElement]
            : [textBoxElement, temporarySpacer, iconElement, clearElement, validationElement]
        }
      </TextBoxContainer>
      {validationMessage}
    </div>
  );
}

TextBox.defaultProps = {
  bottomMargin: true,
  hideLabel: false,
  required: false
};

TextBox.propTypes = {
  id: PropTypes.string,
  size: PropTypes.oneOf(TextBoxSizes),
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  label: PropTypes.string,
  labelRight: PropTypes.any,
  labelIcon: PropTypes.string,
  hideLabel: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  validation: PropTypes.shape({
    state: PropTypes.oneOf(['success', 'error']),
    message: PropTypes.string
  }),
  multiline: PropTypes.bool,
  value: PropTypes.string,
  type: PropTypes.string,
  bottomMargin: PropTypes.bool,
  tabIndex: PropTypes.number,
  className: PropTypes.string,
  dataTest: PropTypes.string.isRequired,
  clearable: PropTypes.bool,
  maxLength: PropTypes.string,
  required: PropTypes.bool
};

export const MemoedTextBox = React.memo(TextBox);

const RefedTextBox = React.forwardRef<HTMLInputElement, ITextBoxProps>((props, ref) => <MemoedTextBox {...props} forwardRef={ref} />);

export default RefedTextBox;
