import React, { useRef, useState, useEffect, useCallback } from 'react';
import { IExternalLoginBaseProps } from './types';
import { Alert } from '@myblueprint-spaces/papier-web/lib/Common/Dialog';
import useId from '@myblueprint-spaces/papier-web/lib/modules/hooks/useId';
import AuthenticationHooks from 'src/modules/hooks/redux/authentication';
import SignUpHooks from 'src/modules/hooks/redux/signup';
import { useTranslation } from 'react-i18next';
import withErrorBoundaries from '@myblueprint-spaces/papier-web/lib/modules/hocs/withErrorBoundaries';
import useFunctionState from '@myblueprint-spaces/papier-web/lib/modules/hooks/useFunctionState';
import { onReceiveMessage } from './helpers';

const ExternalLogin = ({ children, onAuthenticated, onSignUp, onError, onClose, id, onPopUpClose, provider }: IExternalLoginBaseProps) => {
  const [openedWindow, setOpenedWindow] = React.useState<Window | null>(null);
  const formId = useId(id);
  const formRef = React.useRef<HTMLFormElement>(null);
  const userClosedWindow = useRef(true);
  const [popupError, setPopupError] = useState(false);
  const { t } = useTranslation(['Common', 'LoginSignUp.Common']);
  const endpoint = AuthenticationHooks.useExternalAuthenticate(provider as string);
  const saveAuthenticationData = AuthenticationHooks.useSaveAuthenticationData();
  const saveExternalUserData = SignUpHooks.useSaveExternalUserData();
  const [receiveMessageFunc, setReceiveMessageFunc] = useFunctionState(null);

  const initiateLogin = useCallback(() => {
    const newWindow = window.open('', id, 'height=640,width=480,left=0,top=0');
    const receiveMessage = onReceiveMessage(onError, saveExternalUserData, onSignUp, saveAuthenticationData, onAuthenticated, newWindow, userClosedWindow, onClose);
    setReceiveMessageFunc(receiveMessage);

    window.addEventListener('message', receiveMessage);
    if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
      setPopupError(true);
      onClose?.();
      window.removeEventListener('message', receiveMessage);
    } else {
      formRef.current?.submit();
      setOpenedWindow(newWindow);
    }
  }, [setPopupError, onClose, formRef, setOpenedWindow, id, onError, saveExternalUserData, onSignUp, saveAuthenticationData, onAuthenticated]);

  useEffect(() => {
    // Poll window to see if it has been closed. If it has clear interval and call onClose (no other way of knowing when window has been closed)
    let pollWindowClosed;
    if (onClose && openedWindow) {
      pollWindowClosed = setInterval(() => {
        if (openedWindow.closed) {
          clearInterval(pollWindowClosed);
          onClose?.(userClosedWindow.current);
          window.removeEventListener('message', receiveMessageFunc);
        }
      }, 1000);
    }

    return () => {
      window.removeEventListener('message', receiveMessageFunc);
      if (pollWindowClosed) clearInterval(pollWindowClosed);
    };
  }, [openedWindow, receiveMessageFunc, onClose]);

  const handlePopUpClose = useCallback(() => {
    setPopupError(false);
    onPopUpClose?.();
  }, [setPopupError, onPopUpClose]);

  return (
    <React.Fragment>
      <form id={formId} ref={formRef} method="post" action={endpoint as string} target={id} />
      {children({ initiateLogin: initiateLogin })}
      <Alert
        visible={popupError}
        onClose={handlePopUpClose}
        title={t('LoginSignUp.Common:PopupBlocked.Title')}
        subtext={t('LoginSignUp.Common:PopupBlocked.Subtitle').toString()}
        buttonProps={{
          text: t('Common:Ok'),
          onClick: handlePopUpClose
        }}
        type="info"
        size="small" />
    </React.Fragment>
  );
};

export default withErrorBoundaries(ExternalLogin);
