export enum IExternalProviderButtonTypes {
  Google,
  QrCode,
  GoogleClassroom,
  Clever
}

export interface IExternalProviderButtonProps {
  id?: string;
  text: string;
  onClick?: (e?: unknown) => void;
  disabled?: boolean;
  type: keyof typeof IExternalProviderButtonTypes;
  loading?: boolean;
  dataTest?: string;
  shrink?: boolean;
  reducedPadding?: boolean;
}
