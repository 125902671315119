import * as React from 'react';
import Button from '../../../Button';
import { IPanelButtonProps } from './types';

export default function PanelButton({ color = 'secondary', ...rest }: IPanelButtonProps): React.ReactElement {
  const ref = React.useRef();

  React.useLayoutEffect(() => {
    ref && ref.current && (ref.current as HTMLElement).focus();
  }, [ref, ref.current]);

  return <Button rounded color={color} ref={ref} {...rest} />;
}
