import * as React from 'react';
import useId from 'src/modules/hooks/useId';
import { TooltipTriggerContainer, InlineIcon } from './styles';
import Typography from '../Typography';
import { ITooltipTriggerProps } from './types';

const TooltipTrigger = ({ id, icon, text, forwardRef, textContainer, tabIndex, ...rest }: ITooltipTriggerProps) => {
  const tooltipId = useId(id);

  return (
    <TooltipTriggerContainer rounded={icon && !text} tabIndex={tabIndex || 0} id={tooltipId} ref={forwardRef} ellipsis={(textContainer || {}).ellipsis} {...rest}>
      {icon && <Typography as="span">
        <InlineIcon {...icon} />
      </Typography>}
      {text && <Typography {...textContainer}>
        {text}
      </Typography>}
    </TooltipTriggerContainer>
  );
};

export default React.forwardRef<HTMLDivElement, ITooltipTriggerProps>((props, ref) => <TooltipTrigger {...props} forwardRef={ref} />);
