/* global gapi, google */
import React, { createContext, useEffect, useState, useRef, RefObject, useMemo } from 'react';
import AppConfigContext from './AppConfigContext';
import { GoogleClassroomRequiredScopes } from '@myblueprint-spaces/redux/lib/integrations';
import { loadScript } from 'src/modules/helpers/loadScript';

const G_API_JS_URL = 'https://apis.google.com/js/api.js';
const G_SI_JS_URL = 'https://accounts.google.com/gsi/client';

const DISCOVERY_DOC = 'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest';
const GPICKER_AUTH_SCOPE = [
  'https://www.googleapis.com/auth/drive.file',// required to see the files
  'https://www.googleapis.com/auth/drive.readonly'// required to see preview from videos
].join(' ');

const GC_AUTH_SCOPE = [
  'profile',
  'email',
  ...GoogleClassroomRequiredScopes,
].join(' ');

interface CodeClient {
  requestCode(): void;
  error_callback: (err: Record<string, string>) => void;
}

interface TokenClient {
  requestAccessToken(): void;
  error_callback: (err: Record<string, string>) => void;
}

interface IGoogleContextProps {
  gsiGpickerClient: RefObject<TokenClient>,
  gsiGoogleClient: RefObject<CodeClient>,
  pickerInitialised: RefObject<boolean>,
  accessToken: string | null,
  codeToken: string | null,
  hasGPickerSupport: boolean,
  expireAccessToken: () => void
}

const GoogleContext = createContext<IGoogleContextProps>({} as IGoogleContextProps);

export default GoogleContext;

export const GoogleContextProvider = ({ children }: { children: React.ReactElement }) => {
  const pickerInitialised = useRef<boolean | null>(null);
  const gsiGpickerClient = useRef(null);
  const gsiGoogleClient = useRef(null);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [codeToken, setCodeToken] = useState<string | null>(null);
  const { googleClientId } = React.useContext(AppConfigContext)?.integrations ?? {};
  const [failedGapi, setFailedGapi] = useState<boolean>(false);
  const [failedGsi, setFailedGsi] = useState<boolean>(false);
  const accessTokenTimeout = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => () => clearTimeout(accessTokenTimeout.current as unknown as number), []);

  const gsiHandle = () => {
    setFailedGsi(false);
    // @ts-expect-error Google accounts not correctly typed by Google
    gsiGpickerClient.current = google.accounts.oauth2.initTokenClient({
      client_id: googleClientId,
      scope: GPICKER_AUTH_SCOPE,
      discoveryDocs: [DISCOVERY_DOC],
      prompt: 'select_account',
      callback: (tokenResponse) => {
        setAccessToken(tokenResponse.access_token);
        clearTimeout(accessTokenTimeout.current as unknown as number);
        accessTokenTimeout.current = setTimeout(() => setAccessToken(null), (tokenResponse.expires_in - 60) * 1000); // if user opens the GPicker but token expires in-between user might have issues, so let's expire the token earlier
      },
      error_callback: '' // defined at requestTime
    });

    // @ts-expect-error Google accounts not correctly typed by Google
    gsiGoogleClient.current = google.accounts.oauth2.initCodeClient({
      client_id: googleClientId,
      scope: GC_AUTH_SCOPE,
      callback: (tokenResponse) => {
        setCodeToken(tokenResponse.code);
      },
      error_callback: '' // defined at requestTime
    });
  };

  const gapiHandle = () => {
    gapi.client.init({}).then(() => {
      gapi.client.load('drive', 'v2');
    });
  };

  const gapiStart = () => {
    setFailedGapi(false);
    gapi.load('picker', () => {
      pickerInitialised.current = true;
    });
    gapi.load('client', gapiHandle);
  };

  useEffect(() => {
    loadScript('gapi-script', G_API_JS_URL, gapiStart, () => {
      console.error('GoogleDrive - Picker initialization error no Picker GAPI support');
      setFailedGapi(true);
    });
    loadScript('gsi-script', G_SI_JS_URL, gsiHandle, () => {
      console.error('GoogleDrive - Picker initialization error no Picker GSI support');
      setFailedGsi(true);
    });
  }, [googleClientId]);

  const contextValue = useMemo(() => ({
    gsiGpickerClient,
    gsiGoogleClient,
    pickerInitialised,
    accessToken,
    codeToken,
    hasGPickerSupport: !failedGapi && !failedGsi,
    expireAccessToken: () => {
      setAccessToken(null);
      setCodeToken(null);
    }
  }), [accessToken, codeToken, failedGapi, failedGsi, setAccessToken]);

  return (
    <GoogleContext.Provider value={contextValue}>
      {children}
    </GoogleContext.Provider>
  );
};
