import { Colors, Shadows } from '@myblueprint-spaces/papier-core';
import styled from 'styled-components';

// can't be Typography we need to many modifications + we need this to be a <a> and StyledComponent doesn't work with 'as'
const SkipToMain = styled.a`
  left: -999px;
  position: absolute;
  top: auto;
  width: 1px; // required so that it can be considered "validElement"
  height: 1px; // required so that it can be considered "validElement"
  overflow: hidden;
  z-index: -999;

  &:focus, &:active {
    color: ${Colors.primary1};
    background-color: ${Colors.white};
    left: auto;
    top: auto;
    width: 30%;
    height: auto;
    overflow:auto;
    margin: 0.75rem 35%;
    padding: 0.25rem;
    border-radius: 0.75rem;
    ${Shadows.primaryFloatingFocus};
    text-align: center;
    font-size: 1.2rem;
    z-index:99999;
  }
`;

export default SkipToMain;
