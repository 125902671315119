import React from 'react';
import ConnectedAvatar from './ConnectedAvatar';
import withErrorBoundaries from '@myblueprint-spaces/papier-web/lib/modules/hocs/withErrorBoundaries';
import ScopeHooks from 'src/modules/hooks/redux/scopes';
import { CommonConnectedAvatarProps } from './types';
import { HumanDisplayScope } from '@myblueprint-spaces/appstate';

const ConnectedScopeAvatar = ({ scope, size, attachmentId, ...rest }: CommonConnectedAvatarProps) => {
  const scopeModel = (ScopeHooks.useScopeModels(scope) as HumanDisplayScope) ?? null;

  if (attachmentId) {
    return (
      <ConnectedAvatar attachmentId={attachmentId} size={size} description={scopeModel?.displayName} {...rest} />
    );
  }
  return (
    <ConnectedAvatar attachment={scopeModel?.displayPicture} size={size} description={scopeModel?.displayName} {...rest} />
  );
};

export default withErrorBoundaries(ConnectedScopeAvatar);
