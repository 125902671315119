import Icon from 'src/Common/Icon';
import * as React from 'react';
import { components } from 'react-select';
import { DropdownSizesType } from '../types';

const DropdownIndicator = (size: DropdownSizesType): (props) => React.ReactElement => {
  const SizedDropdownIndicator = (props: { isFocused?: boolean, selectProps?: { menuIsOpen: boolean, validationState: string }, isDisabled?: boolean }) => {
    const { isFocused, selectProps, isDisabled } = props;
    const { menuIsOpen, validationState } = selectProps;
    let iconSize: 'small' | 'medium' | 'large';
    switch (size) {
      case 'small':
        iconSize = 'small';
        break;
      case 'xlarge':
        iconSize = 'large';
        break;
      default:
        iconSize = 'medium';
        break;
    }

    const iconColor = isFocused
      ? 'primary1'
      : validationState
        ? validationState === 'error'
          ? 'danger1'
          : 'success1'
        : isDisabled ? 'grey1'
          : 'black3';

    return (
      /* isOpen is not a prop, but it is spread to the state. We don't have control over the props accepted.
        We also cannot simply change the icon type due to an error on the library
        @ts-expect-error */
      <components.DropdownIndicator {...props} isOpen={menuIsOpen}>
        <Icon type="up-arrow" size={iconSize} color={iconColor} />
      </components.DropdownIndicator>
    );
  };

  return SizedDropdownIndicator;
};

export default DropdownIndicator;
