import PropTypes from 'prop-types';
import * as React from 'react';

export interface IWindowScrollObserverProps {
  rootRef?: React.RefObject<HTMLElement | HTMLDocument>;
}

export interface IWindowScrollObserverState {
  scrollTop: number;
  container: HTMLElement | HTMLDocument;
}

export interface WindowScrollConsumerProps {
  containerScrollTop: number;
}

// TODO: address eslint
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function withContainerScroll<OriginalProps, >(WrappedComponent: React.ComponentType<OriginalProps & WindowScrollConsumerProps & any>): React.ComponentClass {
  return class extends React.Component<IWindowScrollObserverProps, IWindowScrollObserverState> {
    public static propTypes = {
      children: PropTypes.object,
      rootRef: PropTypes.object
    }

    public constructor(props) {
      super(props);
      this.state = {
        scrollTop: 0,
        container: props.rootRef && props.rootRef.current ? props.rootRef.current // Root specified and loaded
          : props.rootRef ? null    // There should be a root, but it's not loaded yet
            : document                // Root is not defined, so use document root
      };
    }

    public componentDidUpdate() {
      const { container } = this.state;
      const { rootRef } = this.props;
      if (container == null && rootRef !== null && rootRef.current !== null) {
        rootRef.current.addEventListener('scroll', this.handleScroll, false);
        this.setState({
          container: rootRef.current
        });
      }
    }

    public componentDidMount() {
      const { container } = this.state;
      if (container) {
        container.addEventListener('scroll', this.handleScroll, false);
      }
      window.addEventListener('resize', this.handleScroll, false);
    }

    public componentWillUnmount() {
      const { container } = this.state;
      if (container) {
        container.removeEventListener('scroll', this.handleScroll);
      }
      window.removeEventListener('resize', this.handleScroll);
    }

    public render() {
      const { scrollTop, container } = this.state;

      return <WrappedComponent containerScrollTop={scrollTop} container={container} {...this.props} />;
    }

    public handleScroll = () => {
      this.setState((s) => {
        const element = (s.container as HTMLDocument)?.scrollingElement ? (s.container as HTMLDocument).scrollingElement : s.container as HTMLElement;
        return {
          scrollTop: element ? element.scrollTop : 0
        };
      });
    }
  };
}
