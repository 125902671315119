import { Breakpoints } from '@myblueprint-spaces/papier-core';
import { IResponsiveChangelingProps } from './types';
import { IWindowSizeProps } from 'src/modules/hocs/withWindowSize';

// If no values are provided for the corresponding size (+ fallback). Returns name of breakpoint size.
export default function ResponsiveChangelingFn (props: IResponsiveChangelingProps & IWindowSizeProps): unknown | string {
  const { small, medium, large, xlarge, xxlarge, xxxlarge, windowSize } = props;
  const windowWidth = windowSize.width;
  if (windowWidth < Breakpoints.medium) {
    return small || 'small';
  }
  if (windowWidth < Breakpoints.large) {
    return medium || small || 'medium';
  }
  if (windowWidth < Breakpoints.xlarge) {
    return large || medium || small || 'large';
  }
  if (windowWidth < Breakpoints.xxlarge) {
    return xlarge || large || medium || small || 'xlarge';
  }
  if (windowWidth < Breakpoints.xxxlarge) {
    return xxlarge || xlarge || large || medium || small || 'xxlarge';
  }
  return xxxlarge || xxlarge || xlarge || large || medium || small || 'xxxlarge';
}
