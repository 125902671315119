import * as React from 'react';
import * as PropTypes from 'prop-types';
import { Colors } from '@myblueprint-spaces/papier-core';
import { Row, Column } from 'src/Common/Grid';
import CommentSkeleton from '../CommentSkeleton';
import TextSkeleton from '../TextSkeleton';
import IconSkeleton from '../IconSkeleton';
import { SkeletonWrapper, SkeletonHeader, SkeletonDescriptionContainer, SkeletonDescriptionDivider, SkeletonCommentInput, SkeletonInput, SkeletonCommentList } from './styles';
import { IArtifactLoadingSkeletonProps } from './types';

export function ArtifactLoadingSkeleton({ children, hideComments = false }: IArtifactLoadingSkeletonProps): React.ReactElement {
  return (
    <SkeletonWrapper>
      <SkeletonHeader>
        <Row collapse>
          <Column shrink verticalAlign="middle">
            <IconSkeleton style={{ marginRight: '1rem' }} />
          </Column>
          <Column verticalAlign="middle">
            <TextSkeleton width="100%" marginBottom="0.25rem" />
            <TextSkeleton width="25%" />
          </Column>
          <Column shrink verticalAlign="middle">
            <IconSkeleton size="small" style={{ marginLeft: '1rem' }} />
          </Column>
        </Row>
      </SkeletonHeader>
      {children}
      <SkeletonDescriptionContainer>
        <TextSkeleton width="100%" marginBottom="1.5rem" />
        <TextSkeleton width="90%" marginBottom="0.5rem" />
        <TextSkeleton width="100%" marginBottom="0.5rem" />
        <TextSkeleton width="90%" marginBottom="0.5rem" />
        <TextSkeleton width="100%" marginBottom="1rem" />
        <TextSkeleton width="25%" marginBottom="1.25rem" color={Colors.grey1} />
        <SkeletonDescriptionDivider />
        <Row collapse>
          <Column shrink>
            <IconSkeleton style={{ marginRight: '0.5rem' }} />
          </Column>
          {/* <Column shrink>
            <IconSkeleton />
          </Column> */}
          <Column />
          <Column shrink>
            <IconSkeleton />
          </Column>
        </Row>
      </SkeletonDescriptionContainer>
      {!hideComments && <React.Fragment>
        <SkeletonCommentList>
          <CommentSkeleton />
        </SkeletonCommentList>
        <SkeletonCommentInput>
          <Row collapse>
            <Column>
              <SkeletonInput />
            </Column>
            <Column shrink>
              <IconSkeleton style={{ marginLeft: '0.5rem' }} />
            </Column>
          </Row>
        </SkeletonCommentInput>
      </React.Fragment>}
    </SkeletonWrapper>
  );
}

ArtifactLoadingSkeleton.propTypes = {
  children: PropTypes.node,
  hideComments: PropTypes.bool
};

export default React.memo(ArtifactLoadingSkeleton);
