import React, { ReactElement } from 'react';
import withWindowSize, { IWindowSize } from '@myblueprint-spaces/papier-web/lib/modules/hocs/withWindowSize';
import { Breakpoints } from '@myblueprint-spaces/papier-core';

export interface IWindowSizeContext {
  height: number | undefined;
  width: number | undefined;
  isSmall: boolean | undefined;
  isMedium: boolean | undefined;
  isLarge: boolean | undefined;
  isXLarge: boolean | undefined;
  isXXLarge: boolean | undefined;
  isXXXLarge: boolean | undefined;
  isMediumUp: boolean | undefined;
  isLargeUp: boolean | undefined;
  isXLargeUp: boolean | undefined;
  isXXLargeUp: boolean | undefined;
}

interface IWindowSizeContextProviderProps {
  children: ReactElement
  windowSize: IWindowSize;
}

interface IWindowSizeProviderProps {
  children: ReactElement
}

const WindowSizeContext = React.createContext<IWindowSizeContext>({
  height: undefined,
  width: undefined,
  isSmall: undefined,
  isMedium: undefined,
  isLarge: undefined,
  isXLarge: undefined,
  isXXLarge: undefined,
  isXXXLarge: undefined,
  isMediumUp: undefined,
  isLargeUp: undefined,
  isXLargeUp: undefined,
  isXXLargeUp: undefined
});

const WindowSizeContextProvider = ({
  children,
  windowSize: { height, width }
}: IWindowSizeContextProviderProps) => {
  const contextValue: IWindowSizeContext = React.useMemo(() => ({
    height,
    width,
    isSmall: width < Breakpoints.medium,
    isMedium: width >= Breakpoints.medium && width < Breakpoints.large,
    isLarge: width >= Breakpoints.large && width < Breakpoints.xlarge,
    isXLarge: width >= Breakpoints.xlarge && width < Breakpoints.xxlarge,
    isXXLarge: width >= Breakpoints.xxlarge && width < Breakpoints.xxxlarge,
    isXXXLarge: width >= Breakpoints.xxxlarge,
    isMediumUp: width >= Breakpoints.medium,
    isLargeUp: width >= Breakpoints.large,
    isXLargeUp: width >= Breakpoints.xlarge,
    isXXLargeUp: width >= Breakpoints.xxlarge
  }), [height, width]);

  return (
    <WindowSizeContext.Provider
      value={contextValue}>
      {children}
    </WindowSizeContext.Provider>
  );
};

const WindowSizeProvider = withWindowSize<IWindowSizeProviderProps>(WindowSizeContextProvider);

export { WindowSizeProvider };
export default WindowSizeContext;
