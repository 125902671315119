import { Colors } from '@myblueprint-spaces/papier-core';
import BlockSvg from 'src/Atoms/BlockSvg';
import FormValidation from 'src/Common/FormValidation';
import Typography from 'src/Common/Typography';
import * as React from 'react';
import { RadioButtonBox, RadioButtonContainer, RadioButtonLabel, RadioButtonControl } from './styles';
import { IRadioButtonProps, IReducedRadioButtonProps } from './types';
import withToggleable from 'src/Atoms/withToggleable/withToggleable';

function RadioButton({ id, label, labelId, size, checked, disabled, onChange, hovered, focussed, active, validation, name, dataTest, hideRadioButtonControl = false, ...rest }: IRadioButtonProps) {
  const RadioButtonSize = size || 'medium';
  const iconColor = disabled
    ? checked
      ? 'grey2'
      : 'grey4'
    : hovered
      ? checked
        ? 'primary1' : 'grey2'
      : validation
        ? checked
          ? validation.state === 'success'
            ? 'success1' : 'danger1'
          : 'white'
        : checked
          ? 'primary1' : 'white';

  let radioSize: number;
  switch (size) {
    case 'large':
      radioSize = 12;
      break;
    default:
      radioSize = 8;
      break;
  }

  return (
    <React.Fragment>
      <RadioButtonContainer data-test={`radio-container-${dataTest}`} validation={validation} disabled={disabled}>
        {!hideRadioButtonControl && <RadioButtonControl checked={checked} radioSize={RadioButtonSize} {...rest} id={id} type="radio" name={name} data-test={`radio-${dataTest}`} disabled={disabled} /> }
        <RadioButtonBox size={RadioButtonSize} checked={checked} hovered={hovered} disabled={disabled} focussed={focussed} active={active} validation={validation}>
          <BlockSvg xmlns="http://www.w3.org/2000/svg" width={radioSize} height={radioSize}>
            <circle cx={radioSize / 2} cy={radioSize / 2} r={radioSize / 2} fill={Colors[iconColor]} />
          </BlockSvg>
        </RadioButtonBox>
        {label && <RadioButtonLabel data-test={`radio-label-${dataTest}`} htmlFor={id} disabled={disabled}>
          <Typography id={labelId} type="body2" color="black3">{label}</Typography>
        </RadioButtonLabel>}
      </RadioButtonContainer>
      {validation && <FormValidation data-test={`radio-validation-${dataTest}`} {...validation} size={size === 'small' ? size : 'medium'} />}
    </React.Fragment>
  );
}

RadioButton.defaultProps = {
  bottomMargin: true
};

export default React.memo(withToggleable(RadioButton as React.FunctionComponent<IReducedRadioButtonProps>));
