import { ColorNames } from '@myblueprint-spaces/papier-core';

export const LoadingDotsSizes = ['small', 'medium', 'large'];
export type LoadingDotsSizesType = 'small' | 'medium' | 'large';

export interface ILoadingDotsProps {
  id?: string;
  color?: ColorNames;
  dotCount?: number;
  size?: LoadingDotsSizesType;
  speedModifier?: number;
  width?: number;
  onUnmount?: () => void;
  dataTest?: string;
}
