import React, { useContext, useCallback } from 'react';
import { LoginProviders } from '@myblueprint-spaces/redux/lib/user';
import { useTranslation } from 'react-i18next';
import ExternalProviderButton from '@myblueprint-spaces/papier-web/lib/Common/Button/components/ExternalProviderButton';
import withErrorBoundaries from '@myblueprint-spaces/papier-web/lib/modules/hocs/withErrorBoundaries';
import { IExternalProviderButtonTypes } from '@myblueprint-spaces/papier-web/lib/Common/Button/components/ExternalProviderButton/types';
import ExternalLoginBase from '../ExternalLoginBase';
import AuthLoadingContext from '../Contexts/AuthLoadingContext';
import { toast } from 'src/services/toasts';
import { useId } from '@myblueprint-spaces/papier-web/lib/modules/hooks';
import { useHandleAuthorizationErrors } from '../ExternalLoginBase/hooks';

const ExternalAuthLoginButton = ({ provider, onLogin }: { provider: Omit<LoginProviders, LoginProviders.googleUri | LoginProviders.cleverUri>, onLogin: (response?: unknown) => Promise<unknown> }) => {
  const { t } = useTranslation(['Common', 'LoginSignUp.Common', 'Spaces.Common']);
  const { loading, setLoading } = useContext(AuthLoadingContext);
  const id = useId();

  const onError = useHandleAuthorizationErrors(true, false, setLoading);

  const onSignUp = useCallback(() => Promise.resolve((() => {
    toast(null, { text: t('Common:AccountDoesntExist').toString(), timeout: 5000 });
    setLoading(false);
  })()), [t]);

  const onAuthenticated = useCallback((response) => {
    setLoading(true);
    onLogin(response)
      .then(() => setLoading(false));
  }, [onLogin]);

  const onClose = useCallback(() => setLoading(false), [setLoading]);

  return (
    <ExternalLoginBase
      provider={provider}
      id={id}
      onSignUp={onSignUp}
      onAuthenticated={onAuthenticated}
      onClose={onClose}
      onError={onError}>
      {({ initiateLogin }) => (
        <ExternalProviderButton
          id={`btn-${provider}-login`}
          type={provider as keyof typeof IExternalProviderButtonTypes}
          onClick={() => {
            setLoading(true);
            initiateLogin();
          }}
          loading={loading}
          text={t(`LoginSignUp.Common:${provider}`)}
          dataTest={`login-${provider}`}
          shrink
          reducedPadding
        />
      )}
    </ExternalLoginBase>
  );
};

export default withErrorBoundaries(ExternalAuthLoginButton);
