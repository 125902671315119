import React from 'react';
import { Row, Column } from 'src/Common/Grid';
import Typography from 'src/Common/Typography';
import { StyledExternalProviderButton, StyledProviderLogo, TextWrapper } from './styles';
import { useId } from 'src/modules';
import LoadingDots from 'src/Common/LoadingDots';
import GoogleLogo from './assets/logo-google.svg';
import GoogleLogoDisabled from './assets/logo-google-disabled.svg';
import { IExternalProviderButtonProps, IExternalProviderButtonTypes } from './types';
import Icon from 'src/Common/Icon';
import GoogleClassroomAsset from './assets/logo_googleClassroom.svg';
import CleverAsset from './assets/logo_Clever.svg';

const ExternalProviderButton = ({ id, text, onClick, disabled, loading, type, dataTest, shrink = false, reducedPadding }: IExternalProviderButtonProps): React.ReactElement => {
  const buttonId = useId(id);
  let icon;
  let usedDataTest: string;

  switch (IExternalProviderButtonTypes[type]) {
    case IExternalProviderButtonTypes.Google:
      icon = <StyledProviderLogo reducedPadding={reducedPadding} alt="" src={disabled ? GoogleLogoDisabled : GoogleLogo} />;
      usedDataTest = dataTest || 'google';
      break;
    case IExternalProviderButtonTypes.QrCode:
      icon = <Icon type="qr" size="medium" color={disabled ? 'grey1' : 'primary0'} style={reducedPadding ? { margin: '0.5rem 0' } : { marginLeft: '0.75rem' }} />;
      usedDataTest = dataTest || 'qrcode';
      break;
    case IExternalProviderButtonTypes.GoogleClassroom:
      icon = <StyledProviderLogo reducedPadding={reducedPadding} alt="" src={GoogleClassroomAsset} />;
      usedDataTest = dataTest || 'btn-google-classroom';
      break;
    case IExternalProviderButtonTypes.Clever:
      icon = <StyledProviderLogo reducedPadding={reducedPadding} alt="" src={CleverAsset} />;
      usedDataTest = dataTest || 'clever';
      break;
  }

  return (
    <StyledExternalProviderButton id={buttonId} onClick={onClick} disabled={disabled || loading} data-test={usedDataTest}>
      <Row align="center" verticalAlign="middle" collapse>
        <Column shrink>
          {icon}
        </Column>
        <Column shrink={shrink}>
          {
            loading
              ? <div style={{ marginLeft: '1rem' }}><LoadingDots /></div>
              : <TextWrapper reducedPadding={reducedPadding}>
                <Typography type="compact" weight="medium">{text}</Typography>
              </TextWrapper>
          }
        </Column>
      </Row>
    </StyledExternalProviderButton>
  );
};

export default ExternalProviderButton;
