function isVisible(elem) {
  return !(((elem.getAttribute('style') && elem.getAttribute('style').display) || window.getComputedStyle(elem, 'display')) === 'none');
}

function closest(elem, findNode) {
  // Traverse the DOM up with a while loop
  while (elem.nodeName.toLowerCase() != findNode) {
    // Increment the loop to the parent node
    elem = elem.parentNode;
    if (!elem) {
      return null;
    }
  }
  // At this point, the while loop has stopped and `elem` represents the element that has
  // the type you specified in the second parameter of the function `findNode`

  // Then return the matched element
  return elem;
}

export function getFirstFocusableElementInside(element: HTMLElement): HTMLElement | null {
  // Just checking buttons because for now, there's no use in other scenario
  const focussableElements = 'button:not([disabled]):not([tabindex="-1"]), a:not([tabindex="-1"]), div[tabindex]:not([tabindex="-1"])';
  const focussable = Array.prototype.filter.call(element.querySelectorAll(focussableElements),
    (el) => {
      return isVisible(el);
    });

  return focussable[0] || null;
}

export function focusFirstFocusableElementInside(element: HTMLElement): void {
  const focussable = getFirstFocusableElementInside(element);

  focussable && focussable.focus();
}

export function isFocusable(element: HTMLElement): boolean | null {
  let map, mapName, img, focusableIfVisible, fieldset;

  const nodeName = element.nodeName.toLowerCase();

  if ( nodeName === 'area' ) {
    map = element.parentNode;
    mapName = map.getAttribute('name');
    if ( !element.getAttribute('href') || !mapName || map.nodeName.toLowerCase() !== 'map' ) {
      return false;
    }
    img = document.querySelectorAll('img[usemap=\'#' + mapName + '\']');
    return img.length > 0 && isVisible(img[0]);
  }

  // checking only native HTML element since 'role' does NOT transform a non-focusable element into a focusable one;
  if ( /^(input|select|textarea|button|object)$/.test(nodeName) ) {
    focusableIfVisible = !element.getAttribute('disabled');

    if (focusableIfVisible) {
      // Form controls within a disabled fieldset are disabled.
      // However, controls within the fieldset's legend do not get disabled.
      // Since controls generally aren't placed inside legends, we skip
      // this portion of the check.
      fieldset = closest(element, 'fieldset');
      if (fieldset) {
        focusableIfVisible = !fieldset.getAttribute('disabled');
      }
    }
  } else if ( nodeName === 'a' ) {
    focusableIfVisible = element.getAttribute('href') || parseInt(element.getAttribute('tabindex')) >= 0;
  } else {
    focusableIfVisible = parseInt(element.getAttribute('tabindex')) >= 0;
  }

  return focusableIfVisible && isVisible(element);
}

// Returns true if element and only the element has focus
export function isElementFocused(e: HTMLElement): boolean {
  return e === document.activeElement && (!document.hasFocus || document.hasFocus()) && !!((e as HTMLButtonElement).type || (e as HTMLAnchorElement).href || ~e.tabIndex);
}

// Returns true if element or any of its children has focus
export function isElementIdFocused(id: string): boolean | Element {
  const element = document.getElementById(id);
  return isElementFocused(element) || document.querySelector('[id="'+id+'"]:focus-within') || (document.activeElement && document.activeElement.closest('[id="'+id+'"]')!==null);
}


