import React, { KeyboardEventHandler, MouseEventHandler, MutableRefObject } from 'react';

export type IUseRovingProps = {
  domElementRef: React.RefObject<HTMLElement>;
  disabled: boolean;
  isGrid?: boolean;
  id?: string;
  context: Context;
}

export type INewProps = {
  handleKeyDown: (_e: Event) => void;
  handleClick: (_e: Event) => void;
  tabIndex: number | undefined;
  focused: boolean;
}

export type TabStop = {
  id: string;
  domElementRef: React.RefObject<HTMLElement>;
  disabled?: boolean;
};

export type State = {
  selectedId: string | null;
  lastActionOrigin: 'mouse' | 'keyboard' | 'system' | null;
  tabStops: TabStop[];
  ignoreLoopEdges: boolean;
  allIds: string[];
  allValidIds: string[];
  overScan: number;
};

export type Context = {
  state: State;
  dispatch: React.Dispatch<Action>;
};

export type Props = {
  children: React.ReactNode | React.ReactNode[];
  ignoreLoopEdges?: boolean;
  overScan?: number;
  preventDefaultFocus?: boolean;
};

export enum ActionTypes {
  REGISTER = 'REGISTER',
  UNREGISTER = 'UNREGISTER',
  TAB_TO_PREVIOUS = 'TAB_TO_PREVIOUS',
  TAB_TO_NEXT = 'TAB_TO_NEXT',
  TAB_TO_PREVIOUS_ROW = 'TAB_TO_PREVIOUS_ROW',
  TAB_TO_NEXT_ROW = 'TAB_TO_NEXT_ROW',
  TAB_TO_FIRST = 'TAB_TO_FIRST',
  TAB_TO_LAST = 'TAB_TO_LAST',
  CLICKED = 'CLICKED',
  SELECTED = 'SELECTED',
  UPDATE = 'UPDATE',
  LAST_ACTION_ORIGIN = 'LAST_ACTION_ORIGIN',
  FOCUS = 'FOCUS'
}

export type Action =
  {
    type: ActionTypes.REGISTER;
    payload: TabStop;
  }
  | {
    type: ActionTypes.UNREGISTER;
    payload: { id: TabStop['id'] };
  }
  | {
    type: ActionTypes.TAB_TO_PREVIOUS;
    payload: { id: TabStop['id'] };
  }
  | {
    type: ActionTypes.TAB_TO_NEXT;
    payload: { id: TabStop['id'] };
  }
  | {
    type: ActionTypes.TAB_TO_PREVIOUS_ROW;
    payload: { id: TabStop['id'] };
  }
  | {
    type: ActionTypes.TAB_TO_NEXT_ROW;
    payload: { id: TabStop['id'] };
  }
  | {
    type: ActionTypes.TAB_TO_FIRST;
    payload: { id: TabStop['id'] };
  }
  | {
    type: ActionTypes.TAB_TO_LAST;
    payload: { id: TabStop['id'] };
  }
  | {
    type: ActionTypes.CLICKED;
    payload: { id: TabStop['id'] };
  }
  | {
    type: ActionTypes.SELECTED;
    payload: { id: TabStop['id'] };
  }
  | {
    type: ActionTypes.UPDATE;
    payload: TabStop;
  }
  | {
    type: ActionTypes.LAST_ACTION_ORIGIN;
    payload: 'mouse' | 'system' | 'keyboard' | null;
  }
  | {
    type: ActionTypes.FOCUS;
    payload: { id: TabStop['id'] }
  };


export type PropsReturn<T> = {
  onFocus: () => void;
  onBlur: () => void;
  onMouseOver: () => void;
  onMouseOut: () => void;
  onKeyDown: KeyboardEventHandler<T>;
  ref: MutableRefObject<T>;
  tabIndex: number;
  focusHoverEvent: boolean;
  onClick: MouseEventHandler<T>;
  isHovered: boolean;
  isFocussed: boolean;
  id?: string;
}

export type DebounceReturn = [number | null, (...args: unknown[]) => void];
