import React, { useEffect } from 'react';
import NotificationsPopover from './NotificationsPopover';
import { INotificationsPopoverContainerProps, INotificationsPopoverProps } from './types';
import NotificationHooks from '../../hooks';
import { NotificationFilterActionType, useNotificationFilter, useNotificationFilterUpdater } from '../NotificationFilterContext';

const NotificationsPopoverContainer = (props: INotificationsPopoverContainerProps) => {
  const { open } = props;
  const notificationFilter = useNotificationFilter();
  const markAllRead = NotificationHooks.useMarkAllRead();
  const hasNewCount = NotificationHooks.useHasNewCount();
  const unreadNotifications = NotificationHooks.useUnreadCount(notificationFilter);
  const hasNewWithContext = NotificationHooks.useHasNew(notificationFilter);
  const notificationFilterUpdater = useNotificationFilterUpdater();
  const loadNotifications = NotificationHooks.useLoadNotifications();

  useEffect(() => {
    notificationFilterUpdater({ type: NotificationFilterActionType.All, payload: notificationFilter });
  }, [notificationFilterUpdater, notificationFilter]);

  React.useEffect(() => {
    notificationFilter && open && loadNotifications(notificationFilter);
  }, [notificationFilter, loadNotifications, open]);

  const allProps = {
    ...props,
    hasNewCount,
    markAllRead,
    unreadNotifications,
    hasNewWithContext
  };

  return <NotificationsPopover {...allProps as INotificationsPopoverProps} />;
};

export default NotificationsPopoverContainer;
