import { Colors, primaryFloatingFocus } from '@myblueprint-spaces/papier-core';
import styled, { css } from 'styled-components';
import { ICheckboxContainerProps, CheckboxSizesType } from './types';

export const CheckboxContainer = styled.div(({ align, validation, bottomMargin, alignItems }: ICheckboxContainerProps) => {
  return `
  position: relative;
  display: flex;
  flex-direction: ${align === 'right' ? 'row-reverse' : 'row'};
  align-items: ${alignItems ? alignItems: 'center'};
  cursor: pointer;
  outline: 0;
  margin-bottom: ${bottomMargin ? validation ? '0.25rem' : '1.5rem' : 0}
`;
});

export const CheckboxBox = styled('div').attrs(({ checked, hovered, active, focussed, validation } : ICheckboxContainerProps) => {
  return {
    style: {
      ...(!checked && hovered) && { borderColor: Colors.black3 },
      ...(validation && { borderColor: validation.state === 'success' ? Colors.success1 : Colors.danger1 }),
      ...(focussed && !active) && { outline: 0, boxShadow: primaryFloatingFocus }
    }
  };
})((props: ICheckboxContainerProps) => {
  const { size, checked, disabled } = props;
  let width: number;
  let padding: number;

  switch (size) {
    case 'small':
      width = 1.0625;
      padding = 0;
      break;
    default:
    case 'medium':
      width = 1.0625;
      padding = 0;
      break;
    case 'large':
      width = 1.5;
      padding = 0.1875;
      break;
  }

  return css`
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
    background-color: ${disabled ? Colors.grey4 : Colors.white};
    border: 0.0625rem solid ${(checked && !disabled) ? Colors.primary1 : disabled ? Colors.grey2 : Colors.grey1};
    border-radius: 0.25rem;
    width: ${width}rem;
    height: ${width}rem;
    padding: ${padding}rem;
    margin: 0.125rem 0;
    transition: all 0.2s ease;

    ${!disabled ? `
      &:hover {
        background-color: ${Colors.white};
        border-color: ${Colors.black3};
      }` : ''}
  `;
});

export const CheckboxControl = styled.input(({ checkboxSize }: { checkboxSize: CheckboxSizesType }) => {
  let dimension: number;
  switch (checkboxSize) {
    case 'small':
      dimension = 1;
      break;
    default:
    case 'medium':
      dimension = 1.25;
      break;
    case 'large':
      dimension = 1.5;
      break;
  }
  return `
  margin: 0;
  opacity: 0;
  position: absolute;
  z-index: 1;
  cursor: pointer;
  width: ${dimension}rem;
  height: ${dimension}rem;
`;
});

export const CheckboxLabel = styled.label`
  padding: 0 0.5rem;
  user-select: none;
  cursor: pointer;
  width: 100%;
`;
