import * as React from 'react';
import { Action } from 'redux';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { IState } from '@myblueprint-spaces/redux';
import { SignUpActions } from '@myblueprint-spaces/redux/lib/signUp';
import { SsoAuthPayload } from '@myblueprint-spaces/redux/lib/authentication';

export default function useSaveExternalUserData() {
  const dispatch = useDispatch<ThunkDispatch<IState, unknown, Action>>();
  return React.useCallback(
    (data: SsoAuthPayload) => dispatch(SignUpActions.saveExternalUserData(data)),
    [dispatch]
  );
}
