import React, { useMemo, useState, createContext } from 'react';
import * as filestack from 'filestack-js';
import { FileUploadContextType, FileUploadContextProviderProps } from './types';

export const FileUploadContext = createContext<FileUploadContextType>({} as FileUploadContextType);

const FileUploadContextProvider = ({ children, hooks }: FileUploadContextProviderProps) => {
  const [filestackClient, setFilestackClient] = useState<filestack.Client | null>();

  const contextValue = useMemo(() => ({
    filestackClient,
    setFilestackClient,
    hooks
  }), [filestackClient, setFilestackClient, hooks]);

  return (
    <React.Fragment>
      <FileUploadContext.Provider value={contextValue}>
        {children}
      </FileUploadContext.Provider>
    </React.Fragment>
  );
};

export default FileUploadContextProvider;
