import { Colors, Breakpoints, FontSizes, FontTypes, FontWeights, IFontSpec, WebFontFamily, Shadows } from '@myblueprint-spaces/papier-core';
import { IWindowSize } from 'src/modules/hocs/withWindowSize';
import { LabelSize } from 'src/Common/Label';
import { IconSizesType } from 'src/Common/Icon';
import { TextBoxSizeType } from 'src/Atoms/types';
import { IFormValidationProps } from 'src/Common/FormValidation';
import { isIosDevice } from '.';

export const rgba2hex = (rgba) => `#${rgba.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+\.?\d*))?\)$/).slice(1)
  .map((n, i) => (i === 3 ? Math.round(parseFloat(n) * 255) : parseFloat(n)).toString(16).padStart(2, '0').replace('NaN', '')).join('')}`;

export const getCurrentBreakpoint = (windowSize: IWindowSize): string => {
  return windowSize.width < Breakpoints.medium ? 'small'
    : windowSize.width < Breakpoints.large ? 'medium'
      : windowSize.width < Breakpoints.xlarge ? 'large'
        : windowSize.width < Breakpoints.xxlarge ? 'xlarge'
          : windowSize.width < Breakpoints.xxxlarge ? 'xxlarge' : 'xxxlarge';
};

export const getTextInputCss = (props: { size: TextBoxSizeType, disabled?: boolean; readOnly?: boolean; isFocussed?: boolean; validation?: IFormValidationProps; multiline?: boolean, isHovered?: boolean}) => {
  const { size, disabled = false, isFocussed = false, validation = null, multiline = false, isHovered = false, readOnly = false } = props;
  const defaultMarginBottom = 1.5;
  let marginBottom = defaultMarginBottom;
  const textAreaToInputRatio = 2; // Default TextArea is this many inputs tall
  const inputLabelHeight = 2;
  const textAreaMarginBottom = 0.875;
  let height = 2.5;
  let topSpacing = 0.375;
  let padding = 0.75;

  switch (size) {
    case 'xlarge':
      height = 3.5;
      topSpacing = 0.75;
      padding = 1;
      break;
    case 'large':
      height = 3;
      topSpacing = 0.625;
      padding = 0.75;
      break;
    case 'medium':
      height = 2.5;
      topSpacing = 0.375;
      padding = 0.75;
      break;
    case 'small':
      height = 2;
      topSpacing = 0.375;
      padding = 0.5;
      break;
  }

  // State styles
  let backgroundColor: string = Colors.white;
  let borderColor: string = Colors.grey1;
  let boxShadow = '';

  if (disabled || (readOnly && !isFocussed)) {
    backgroundColor = Colors.grey4;
    borderColor = Colors.grey2;
  } else if (isFocussed) {
    backgroundColor = Colors.white;
    borderColor = Colors.primary1;
    boxShadow = Shadows.primaryFocus;
  }

  // Validation styles
  if (validation && validation.state === 'error') {
    borderColor = Colors.danger1;
    marginBottom = 0.25;
  } else if (validation && validation.state === 'success') {
    borderColor = Colors.success1;
    marginBottom = 0.25;
  }

  // Multiline styles
  if (multiline) {
    height = null;
  }

  if (isHovered && !disabled && !isFocussed) {
    borderColor = Colors.black3;
  }

  return { defaultMarginBottom, borderColor, boxShadow, backgroundColor, padding, topSpacing, height, marginBottom, textAreaToInputRatio, inputLabelHeight, textAreaMarginBottom };
};


export interface IValueSize {
  size: number;
  lineHeight: number;
  defaultWeight: FontWeights
}

export const getTextInputConfig = (size) => {
  let valueSize: IValueSize;
  let labelSize: LabelSize = 'medium';
  let iconSize : IconSizesType;

  // Calculate values to use
  switch (size) {
    case 'xlarge':
      valueSize = FontSizes[FontTypes.body1];
      iconSize = 'large';
      break;
    case 'large':
      valueSize = FontSizes[FontTypes.body2];
      iconSize = 'medium';
      break;
    default:
    case 'medium':
      valueSize = FontSizes[FontTypes.body2];
      iconSize = 'medium';
      break;
    case 'small':
      valueSize = FontSizes[isIosDevice() ? FontTypes.body2 : FontTypes.subtitle1];
      iconSize = 'small';
      labelSize = 'small';
      break;
  }

  return { valueSize, iconSize, labelSize };
};

// Input and Textarea fields
export const commonInputStyles = (size: TextBoxSizeType, valueSize: IFontSpec) => `
  font-family: ${WebFontFamily};
  width: 100%;
  border: 0;
  outline: 0;
  padding: 0;
  background-color: transparent;
  font-size: ${valueSize.size}rem;
  line-height: ${valueSize.lineHeight};
  font-weight: ${size === 'small' ? FontWeights.medium : FontWeights.regular}
  ::placeholder {
    color: ${Colors.black3};
    font-size: ${valueSize.size}rem;
    opacity: 1;
  }
  &:disabled::placeholder {
    color: ${Colors.grey1};
    font-size: ${valueSize.size}rem;
    opacity: 1;
  }
  &:invalid {
    box-shadow: inherit;
  }
`;
