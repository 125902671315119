import React, { useRef, useCallback } from 'react';
import { Action } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { IState } from '@myblueprint-spaces/redux';
import { AttachmentActions, AttachmentSelectors, FSProgressEvent, FilestackUploadResponse } from '@myblueprint-spaces/redux/lib/attachments';
import useMemoCompare from '@myblueprint-spaces/papier-web/lib/modules/hooks/useMemoCompare';

const useUploadAttachment = () => {
  const dispatch = useDispatch<ThunkDispatch<IState, unknown, Action>>();
  const { load } = useSecurity();

  return useCallback(async (file: string | File, name: string, mimetype: string, size: number, onProgress?: (evt: FSProgressEvent) => void, cancelSignal?: AbortSignal) => {
    await load();
    return dispatch(AttachmentActions.uploadAttachment(file, name, mimetype, size, onProgress, cancelSignal));
  },[dispatch, load]);
};

const useCreateUrlAttachment = () => {
  const dispatch = useDispatch<ThunkDispatch<IState, unknown, Action>>();
  const { load } = useSecurity();

  return React.useCallback(async (url: string, showPreview: boolean) => {
    await load();
    return dispatch(AttachmentActions.createUrlAttachment(url, showPreview));
  },[dispatch, load]);
};

const useSecurity = () => {
  const security = useMemoCompare(useSelector((state: IState) => AttachmentSelectors.getFilepickerSecurity(state)), (p, n) => JSON.stringify(p) === JSON.stringify(n));
  const dispatch = useDispatch<ThunkDispatch<IState, unknown, Action>>();
  const loadingSecurityMutex = useRef(false);

  // We cannot/should not set a timeout/interval for refreshing security in here because all of our MediaRecorders depend on this, and if we set automatic refreshes,
  // users might be in the middle of recording something and get kicked out because the security key changed.
  const loadSecurity = React.useCallback((forceReload = false) => {
    // Calculate expiration every time. "security" does not change upon expired, so it will not trigger a const recalculation on root of hook
    const expired = !security || security && security.expiration <= (Date.now() + 500 * 60);
    if (forceReload || expired) {
      const mutex = loadingSecurityMutex.current;
      loadingSecurityMutex.current = true;

      return !mutex && dispatch(AttachmentActions.loadFilepickerSecurity()).finally(() => {
        loadingSecurityMutex.current = false;
      });
    }
  }, [security, dispatch]);

  const memoizedSecurity = React.useMemo(() => {
    const expired = !security || security && security.expiration <= (Date.now() + 500 * 60);
    return { load: loadSecurity, key: expired ? undefined : security };
  }, [loadSecurity, security]);

  return memoizedSecurity;
};

const useCreateAttachment = () => {
  const dispatch = useDispatch<ThunkDispatch<IState, unknown, Action>>();

  return React.useCallback((fs: FilestackUploadResponse) => dispatch(AttachmentActions.createAttachment(fs)), [dispatch]);
};

const AttachmentHooks = {
  useUploadAttachment,
  useCreateUrlAttachment,
  useSecurity,
  useCreateAttachment
};

export default AttachmentHooks;
