import * as React from 'react';
import { Breakpoints } from '@myblueprint-spaces/papier-core';
import withWindowSize, { IWindowSizeProps } from 'src/modules/hocs/withWindowSize';

interface IResponsiveSwitchProps {
  small: React.ComponentType;
  medium?: React.ComponentType;
  large?: React.ComponentType;
  xlarge?: React.ComponentType;
}

// If no values are provided for the corresponding size (+ fallback)
function ResponsiveSwitch (props: IResponsiveSwitchProps & IWindowSizeProps) {
  const { small, medium, large, xlarge, windowSize, ...rest } = props;
  const windowWidth = windowSize.width;
  let Component;

  if (windowWidth > Breakpoints.small && small) {
    Component = small;
  }
  if (windowWidth >= Breakpoints.medium && medium) {
    Component = medium;
  }
  if (windowWidth >= Breakpoints.large && large) {
    Component = large;
  }
  if (windowWidth >= Breakpoints.xlarge && xlarge) {
    Component = xlarge;
  }
  return <Component {...rest} />;
}

export default withWindowSize(ResponsiveSwitch);
