import CleanButton from 'src/Atoms/CleanButton';
import Icon from 'src/Common/Icon';
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ColorNames } from '@myblueprint-spaces/papier-core';

const MultiValueRemove = (color: ColorNames): React.ReactNode => {
  const SizedMultiValueRemove = (props) => {
    const { t } = useTranslation();
    const { innerProps, selectProps } = props;
    return (
      <CleanButton {...innerProps} title={t('Papier.Dropdown:Remove')} disabled={selectProps.isDisabled}>
        <Icon size="small" color={color || 'white'} type="close" />
      </CleanButton>
    );
  };

  SizedMultiValueRemove.propTypes = {
    innerProps: PropTypes.object,
    selectProps: PropTypes.object
  };

  return SizedMultiValueRemove;
};

export default MultiValueRemove;
