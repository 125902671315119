import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from '../../DropdownContainer';
import RoundedDropdownValueContainer from './components/RoundedDropdownValueContainer';
import { IDropdownProps } from '../..';

export default function RoundedDropdown(props: IDropdownProps): React.ReactElement {
  const { size, components, ...rest } = props;
  const dropdownProps = {
    ...rest,
    multi: false,
    rounded: true,
    clearable: false,
    align: 'center',
    components: {
      ...components,
      ValueContainer: RoundedDropdownValueContainer(size)
    },
    size: size
  };

  return <Dropdown {...dropdownProps} />;
}

RoundedDropdown.propTypes = {
  size: PropTypes.string,
  components: PropTypes.object
};
