import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IState } from '@myblueprint-spaces/redux';
import { AttachmentActions, AttachmentSelectors, MediaType, SourceType } from '@myblueprint-spaces/redux/lib/attachments';
import { Attachment } from '@myblueprint-spaces/redux/lib/attachments/types';
import LoadingDots from '@myblueprint-spaces/papier-web/lib/Common/LoadingDots';

interface IWithAttachmentProps {
  attachmentIds?: string[];
  attachmentId?: string;
  onFullScreen: () => void;
  onRemove: () => void;
  attachment: Attachment[];
  attachments: Attachment[];
  refreshAttachment: (attachmentId: string, token: string) => Promise<string>;
}

export interface IWithAttachmentState {
  error: boolean;
}

const locks = {};

const withAttachment = <P,>(WrappedComponent: React.ComponentType<P & {error?: boolean; attachment?: Attachment[] | null}>) => {
  const mapStateToProps = (state: IState, ownProps: P & IWithAttachmentProps) => {
    const attachment = ownProps.attachment || ownProps.attachments || (ownProps.attachmentIds || [ownProps.attachmentId]).map((i) => AttachmentSelectors.getAttachmentWithId(state, i!));
    return ({
      attachment: attachment
    });
  };

  let timeout;

  const mapDispatchToProps = {
    refreshAttachment: AttachmentActions.refreshAttachment
  };

  const refreshIfExpired = (attachment: Attachment | undefined, refreshAttachment: (attachmentId: string, token: string) => Promise<string>) => {
    if (!attachment) return attachment;
    const { id, sources, refreshToken, mediaType } = attachment;
    // all attachments have an original source, all sources created/refreshed at the same time
    // there's a bug on core where for a few ms original is null
    const expiration = sources[SourceType.Original]?.expiration.getTime();

    if (!expiration){
      timeout = setTimeout(() => {
        !locks[id] && refreshIfExpired(attachment, refreshAttachment);
      }, 100);
    } else {
      if (!locks[id] && expiration <= Date.now() && refreshToken !== null) {
        locks[id] = true;
        refreshAttachment(id, refreshToken).then(() => delete locks[id]);
      } else if (mediaType === MediaType.Video || mediaType === MediaType.Audio || mediaType === MediaType.File) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          !locks[id] && refreshIfExpired(attachment, refreshAttachment);
        }, Math.abs(expiration - Date.now()));
      }
    }
  };

  const checkForRefresh = (attachment: Attachment | Attachment[], refreshAttachment: (attachmentId: string, token: string) => Promise<string>) => {
    if (Array.isArray(attachment)) {
      attachment.forEach((a) => refreshIfExpired(a, refreshAttachment));
    } else {
      refreshIfExpired(attachment, refreshAttachment);
    }
  };

  class WithAttachment extends React.Component<IWithAttachmentProps, IWithAttachmentState> {
    public constructor(props) {
      super(props);
      this.state = {
        error: false
      };
    }

    public componentDidMount() {
      const { attachment, refreshAttachment } = this.props;
      checkForRefresh(attachment, refreshAttachment);
    }

    public componentDidUpdate(prevProps) {
      const { attachment, refreshAttachment } = this.props;

      if (prevProps.attachment !== attachment) {
        checkForRefresh(attachment, refreshAttachment);
      }
    }

    public componentWillUnmount() {
      clearTimeout(timeout);
    }

    public static getDerivedStateFromProps(props) {
      const { attachment } = props;

      return {
        error: attachment === null
      };
    }

    public render() {
      const { attachment, ...rest } = this.props;
      const { error } = this.state;

      if (!attachment && !error) return <LoadingDots color="grey3" />;

      return <WrappedComponent {...rest as P & IWithAttachmentProps} error={error} attachment={attachment} />;
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(WithAttachment);
};

withAttachment.propTypes = {
  attachmentIds: PropTypes.array.isRequired,
  attachmentId: PropTypes.string.isRequired,
  attachment: PropTypes.object,
  refreshAttachment: PropTypes.func
};

export default withAttachment;
