import styled from 'styled-components';
import { Colors, Breakpoints } from '@myblueprint-spaces/papier-core';
import { IPanelHeaderRowProps, IPanelHeaderColumnInnerProps } from './types';
import Column from 'src/Common/Grid/Column';


export const PanelHeaderRow = styled('div')`
  height: 4rem;
  padding: 0.75rem;
  ${(props: IPanelHeaderRowProps) => props.bottomBorder ? `border-bottom: solid 0.0625rem ${Colors.grey2};` : ''}

  @media (max-width: ${Breakpoints.medium}em) {
    height: fit-content;
  }
`;

export const PanelHeaderColumn = styled(Column).attrs({
  collapse: true,
  verticalAlign: 'middle'
})``;

export const PanelHeaderColumnInnerLeft = styled.div`
  text-align: left;
`;

export const PanelHeaderColumnInnerRight = styled.div`
  text-align: right;

  @media (max-width: ${Breakpoints.medium}em) {
    display: flex; 
    flex-direction: row-reverse;
  }
`;

export const PanelHeaderColumnInnerMiddle = styled.div(({ textAlign } : IPanelHeaderColumnInnerProps) => `
  text-align: ${textAlign};

  @media (max-width: ${Breakpoints.medium}em) {
    margin: 0 1rem; 
    display: flex; 
    flex-direction: row;
    }
`);

export const ButtonWrapper = styled.div`
  height: 2.5rem;
  min-width: 2.5rem;
  @media (max-width: ${Breakpoints.medium}em) {
    height: fit-content;
    min-width: 2rem;
  }
`;
