import BlockSvg from 'src/Atoms/BlockSvg';
import * as PropTypes from 'prop-types';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { getEnLogo, getFrLogo, getLogoDot } from './helpers';
import { CSSProperties } from 'styled-components';

export interface ILogoProps {
  size?: 'small' | 'medium' | 'large' | 'xlarge';
  logoOnly?: boolean;
  type?: 'white' | 'primary';
  language?: string;
  style?: CSSProperties;
}

export function Logo({ size, logoOnly, type, language, ...props }: ILogoProps): React.ReactElement {
  let width: number;
  let height: number;
  const { t, i18n } = useTranslation(['Common']);
  const productName = t('Common:ProductName');
  const lang = language || i18n.language || 'en';
  const useFr = lang.startsWith('fr');

  switch (size) {
    case 'small':
      width = 134;
      height = 32;
      break;
    default:
    case 'medium':
      width = 168;
      height = 40;
      break;
    case 'large':
      width = 201;
      height = 48;
      break;
    case 'xlarge':
      width = 235;
      height = 56;
      break;
  }

  const isWhite = type === 'white';

  if (logoOnly) {
    return (
      <BlockSvg xmlns="http://www.w3.org/2000/svg" width={height} height={height} viewBox="0 0 200 200" {...props}>
        <title>{productName}</title>
        {getLogoDot(isWhite)}
      </BlockSvg>
    );
  }

  return (
    <React.Fragment>
      {useFr ? getFrLogo(isWhite, productName, width, height, props) : getEnLogo(isWhite, productName, width, height, props)}
    </React.Fragment>
  );
}

Logo.defaultProps = {
  type: 'primary'
};

Logo.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large', 'xlarge']),
  type: PropTypes.oneOf(['primary', 'white']),
  logoOnly: PropTypes.bool
};

export default React.memo(Logo);
