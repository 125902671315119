import { RowColumn } from 'src/Common/Grid';
import HeaderBlock from 'src/Common/HeaderBlock';
import * as PropTypes from 'prop-types';
import * as React from 'react';
import { EmptyStateDefaultIcon } from './styles';
import { IEmptyStateProps } from './types';

export default function EmptyState(props: IEmptyStateProps): React.ReactElement {
  const { image, title, subtitle, action } = props;
  return (
    <React.Fragment>
      <RowColumn align="center">
        <HeaderBlock image={image || <EmptyStateDefaultIcon aria-hidden="true" />} title={title} subtitle={subtitle} />
      </RowColumn>
      {action
        &&        <RowColumn align="center">
          {action}
        </RowColumn>
      }
    </React.Fragment>
  );
}

EmptyState.propTypes = {
  image: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  subtitle:  PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  action: PropTypes.node
};
