export const ToggleableSizes = ['small', 'medium', 'large'];
export type ToggleableSizesTypes = 'small' | 'medium' | 'large';
export const ToggleableAlignments = ['left', 'right'];
export type ToggleableAlignmentsType = 'left' | 'right';

export interface IToggleableProps {
  id?: string;
  size?: ToggleableSizesTypes;
  label?: string | React.ReactElement;
  checked?: boolean;
  onChange?: (state: boolean) => void;
  disabled?: boolean;
  'aria-labelledby'?: string;
  align?: ToggleableAlignmentsType;
  labelId?: string;
}
