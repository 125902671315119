import { IFontSpec } from '@myblueprint-spaces/papier-core';
import { IFormValidationProps } from 'src/Common/FormValidation/types';
import { IIconProps, IconSizesType } from 'src/Common/Icon';
import { AriaAttributes, ButtonHTMLAttributes } from 'react';

export type IconHTMLButton = Partial<Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'type' | 'form' | 'style' | 'contentEditable'>> & AriaAttributes;
export type IconObj = IIconObj & IconHTMLButton;

export interface IIconObj extends IIconProps {
  position?: AlignmentType;
  onClick?: () => void;
  dataTest?: string;
}

export const TextBoxSizes = ['small', 'medium', 'large', 'xlarge'];
export type TextBoxSizeType = 'small' | 'medium' | 'large' | 'xlarge';
export type AlignmentType = 'left' | 'right';

export interface ICommonTextBoxProps {
  id?: string;
  size?: TextBoxSizeType;
  placeholder?: string;
  value?: string;
  label?: string;
  hideLabel?: boolean;
  labelIcon?: string;
  labelRight?: React.ReactNode;
  disabled?: boolean;
  icon?: string | IconObj;
  className?: string;
  onBlur?: () => void;
  onFocus?: () => void;
  validation?: IFormValidationProps | null;
  multiline?: boolean;
  type?: string;
  bottomMargin?: boolean;
  style?: React.CSSProperties;
  readOnly?: boolean;
  dataTest?: string;
  clearable?: boolean;
  maxLength?: string;
  autoComplete?: string;
  autoFocus?: boolean;
  tabIndex?: 0 | -1 | '0' | '-1';
  name?: string;
  required?: boolean;
}

export interface ITextBoxProps extends ICommonTextBoxProps {
  onChange?: (e: string) => void;
}

// Props calculated in TextBox component and passed to BaseTextBox
export interface IBaseTextBoxProps extends ICommonTextBoxProps {
  valueSize?: IFontSpec;
  isFocussed?: boolean;
  isHovered?: boolean;
  children?: React.ReactNode;
  iconSize?: IconSizesType;
  onChange?: (event: (React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>)) => void;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
}

export type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] };
