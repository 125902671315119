
import { Colors } from '@myblueprint-spaces/papier-core';
import { IconMap } from '@myblueprint-spaces/papier-icons';
import styled, { createGlobalStyle, CSSProperties } from 'styled-components';
import { IIconProps } from './types';

import '@myblueprint-spaces/papier-icons/assets/NF_iconset.eot';
import '@myblueprint-spaces/papier-icons/assets/NF_iconset.ttf';
import '@myblueprint-spaces/papier-icons/assets/NF_iconset.woff';
import '@myblueprint-spaces/papier-icons/assets/NF_iconset.woff2';

export const IconImport = createGlobalStyle`
@font-face {
  font-family: 'NF_iconset';
  src: url('/fonts/NF_iconset.eot') format('embedded-opentype'),
    url('/fonts/NF_iconset.woff2') format('woff2'),
    url('/fonts/NF_iconset.woff') format('woff'),
    url('/fonts/NF_iconset.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
`;

export const PureIconElem = styled.i(({ size, type, inline, removeMargin }: IIconProps) => {
  let iconSize: number;
  let iconSpacing: number;
  switch (size) {
    case 'small':
      iconSize = 1;
      iconSpacing = 0.125;
      break;
    default:
    case 'medium':
      iconSize = 1.5;
      iconSpacing = 0.25;
      break;
    case 'large':
      iconSize = 2;
      iconSpacing = 0.3125;
      break;
  }

  const hexCode = (IconMap[type] || IconMap.image).toString(16);

  return `
    display: ${inline ? 'inline-block' : 'block'};
    font: normal normal normal 1em/1 'NF_iconset';
    speak: none;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: ${iconSize}rem;
    width: ${iconSize}rem;
    height: ${iconSize}rem;
    margin: ${removeMargin ? '0' : iconSpacing}rem;
    appearance: none !important; // Fixes Firefox [type="search"] changing style

    &::before {
      content: "\\${hexCode}";
    }
  `;
});

export const IconElem = styled.i.attrs(({ color, style }: IIconProps) => ({
  style: { color: Colors[color], ...style } as CSSProperties
}))(({ size, type, inline, removeMargin }: IIconProps) => {
  let iconSize: number;
  let iconSpacing: number;
  switch (size) {
    case 'small':
      iconSize = 1;
      iconSpacing = 0.125;
      break;
    default:
    case 'medium':
      iconSize = 1.5;
      iconSpacing = 0.25;
      break;
    case 'large':
      iconSize = 2;
      iconSpacing = 0.3125;
      break;
  }

  const hexCode = (IconMap[type] || IconMap.image).toString(16);

  return `
    display: ${inline ? 'inline-block' : 'block'};
    font: normal normal normal 1em/1 'NF_iconset';
    speak: none;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: ${iconSize}rem;
    width: ${iconSize}rem;
    height: ${iconSize}rem;
    margin: ${removeMargin ? '0' : iconSpacing}rem;
    appearance: none !important; // Fixes Firefox [type="search"] changing style

    &::before {
      content: "\\${hexCode}";
    }
  `;
});
