import CovalentGrid from 'src/Atoms/CovalentGrid';
import Icon from 'src/Common/Icon';
import Typography from 'src/Common/Typography';
import * as PropTypes from 'prop-types';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonTagContainer, TagContainer, TagImage, TagText } from './styles';
import { ITagProps, TagSizes } from './types';
import { getButtonAppearance, ColorNames, ButtonColorsType } from '@myblueprint-spaces/papier-core';
import { IIconProps } from '../Icon';

export function Tag(props: ITagProps & { forwardRef?: React.ForwardedRef<HTMLButtonElement | HTMLDivElement>}): React.ReactElement {
  const { size, text, onClick, onRemove, icon, color, disabled, forwardRef, dataTest, ...rest } = props;
  const { t } = useTranslation(['Papier.Tag']);

  let usedColor: ButtonColorsType = 'primary';
  let usedTextColor = typeof color === 'object' ? color.text : 'white';
  let removeIconColor = typeof color === 'object' ? color.removeIcon : 'white';
  const iconProps: IIconProps = !icon ? null
    : typeof icon === 'object' ? icon : {
      type: icon,
      color: typeof color === 'object' ? color.icon : 'white',
      size: 'small'
    };

  if (typeof color !== 'object') {
    const buttonAppearance = getButtonAppearance(color);
    usedColor = color;
    usedTextColor = removeIconColor = disabled ? buttonAppearance.disabledTextColor : buttonAppearance.textColor;
    if (icon) iconProps.color = disabled ? buttonAppearance.disabledIconColor : buttonAppearance.iconColor ;
  }

  const inner = (
    <CovalentGrid verticalAlign="middle" align="right">
      <TagText>
        {icon && (
          <TagImage>
            <Icon {...iconProps} />
          </TagImage>
        )}
        <Typography type="subtitle1" color={usedTextColor as ColorNames}>{text}</Typography>
      </TagText>
      {onRemove && <div>
        <Icon type="close" color={removeIconColor as ColorNames} size="small" />
      </div>}
    </CovalentGrid>
  );

  if (onClick || onRemove) {
    return (
      <ButtonTagContainer size={size} color={usedColor} data-test={dataTest ? `tag-${dataTest}` : 'tag'} disabled={disabled} onClick={onClick || onRemove}
        aria-label={onRemove ? `${text} (${t('Papier.Tag:ClickToRemove')})` : undefined} {...rest} ref={forwardRef as React.Ref<HTMLButtonElement>}>
        {inner}
      </ButtonTagContainer>
    );
  } else {
    return (
      <TagContainer size={size} color={usedColor} disabled={disabled} tabIndex={0} data-test={dataTest ? `tag-${dataTest}` : 'tag'} {...rest} ref={forwardRef as React.Ref<HTMLDivElement>}>
        {inner}
      </TagContainer>
    );
  }
}

Tag.propTypes = {
  size: PropTypes.oneOf(TagSizes),
  text: PropTypes.string,
  dataTest: PropTypes.string,
  onClick: PropTypes.func,
  onRemove: PropTypes.func,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  style: PropTypes.object
};

export const MemoedTag = React.memo(Tag);

const RefedTag = React.forwardRef<HTMLButtonElement | HTMLDivElement, ITagProps>((props, ref) => <MemoedTag {...props} forwardRef={ref} />);

export default RefedTag;
