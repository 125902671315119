import React, { FunctionComponent } from 'react';
import * as PropTypes from 'prop-types';
import PanelButton from '../PanelButton';
import { PanelContext } from '../../context';
import { Breakpoints } from '@myblueprint-spaces/papier-core';
import { useTranslation } from 'react-i18next';
import { withWindowSize } from 'src/modules';
import { IWindowSizeProps } from 'src/modules/hocs/withWindowSize';
import { IClosePanelButtonProps } from '../PanelButton/types';

function ClosePanelButton({ windowSize, onClick, ...rest }: IClosePanelButtonProps & IWindowSizeProps) {
  const isSmall = windowSize.width < Breakpoints.medium;
  const { t } = useTranslation('Common');

  return (
    <PanelContext.Consumer>
      {({ closeParentPanel }) => (
        <PanelButton icon="close" rounded title={t('Common:Actions.Close')} onClick={() => {
          closeParentPanel();
          onClick && onClick();
        }} color="secondary" dataTest="close-panel" size={isSmall ? 'small' : 'medium'} {...rest} />
      )}
    </PanelContext.Consumer>
  );
}

export default withWindowSize(ClosePanelButton as FunctionComponent<IClosePanelButtonProps>);

ClosePanelButton.propTypes = {
  onParentPanelClose: PropTypes.func
};
