import * as React from 'react';
import * as PropTypes from 'prop-types';
import Typography from 'src/Common/Typography';
import Button from 'src/Common/Button';
import Dialog from '../../Dialog';
import { ContentWrapper, ImageWrapper, SubtextWrapper, ButtonContainer } from '../styles';
import { IAlertProps } from './types';
import { DialogSize } from '../../types';
import { withWindowSize } from 'src/modules';
import { Breakpoints } from '@myblueprint-spaces/papier-core';

function Alert(props: IAlertProps) {
  const { title, subtext, image, buttonProps, type, windowSize, ...otherProps } = props;
  const isSmall = windowSize.width < Breakpoints.medium;
  const dialogProps = {
    title,
    size: 'small' as DialogSize,
    fullScreenOnMobile: false,
    ...otherProps
  };
  return (
    <Dialog {...dialogProps}>
      <ContentWrapper>
        {image && <ImageWrapper>{image}</ImageWrapper>}
        <Typography as="h1" type={ isSmall ? 'body2' : 'header4'} weight="demibold"
          color={type === 'error' ? 'danger1' : 'black1'}>
          {title}
        </Typography>
        {subtext && <SubtextWrapper>
          <Typography as="p" type={ isSmall ? 'subtitle1': 'body2'} color="black2">{subtext}</Typography>
        </SubtextWrapper>}
        <ButtonContainer>
          <Button expanded
            color={type === 'error' ? 'danger' : type === 'success' ? 'success' : 'primary'}
            {...buttonProps} dataTest="dialog-alert" />
        </ButtonContainer>
      </ContentWrapper>
    </Dialog>
  );
}

Alert.defaultProps = {
  type: 'info'
};

Alert.propTypes = {
  title: PropTypes.string.isRequired,
  subtext: PropTypes.string,
  image: PropTypes.element,
  buttonProps: PropTypes.object,
  type: PropTypes.oneOf(['info', 'error', 'success'])
};

export default withWindowSize(Alert as React.FunctionComponent<IAlertProps>);
