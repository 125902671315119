import { IFormValidationProps } from '../FormValidation/types';
import { SelectComponentsProps } from 'react-select/src/Select';
import { WithTranslation } from 'react-i18next';
import { ReactNode } from 'react';
import { ButtonColorsType } from '@myblueprint-spaces/papier-core';

export const DropdownSizes = ['small', 'medium', 'large', 'xlarge'];
export type DropdownSizesType = 'small' | 'medium' | 'large' | 'xlarge';

export interface IDropdownValueLabel { value: unknown; label: string }
type DropdownValue = { value: unknown; label: string | ReactNode } | unknown;

export interface DropdownProps extends SelectComponentsProps, WithTranslation {
  id?: string;
  size?: DropdownSizesType;
  creatable?: boolean;
  disabled?: boolean;
  searchable?: boolean;
  multiselect?: boolean;
  formatCreateLabel?: (value: string) => string;
  noOptionsMessage?: (value: string) => string;
  placeholder?: string;
  label?: string;
  labelIcon?: string;
  labelRight?: React.ReactNode;
  validation?: IFormValidationProps | null;
  components?: unknown[];
  bottomMargin?: boolean;
  value: DropdownValue;
  clearable?: boolean;
  options: DropdownValue[];
  onChange?: (value: unknown) => void;
  dataTest: string;
  hideLabel?: boolean;
  labelId?: string;
  tagColor?: ButtonColorsType;
}

type AccessibilityLabelProps<T = Pick<DropdownProps, 'labelId'>, U = Pick<DropdownProps, 'hideLabel'>, V = Pick<DropdownProps, 'label'>> =
  // labelId = exists => hideLabel === true && label can't exist
   { [K in keyof T]-? : T[K] } & { [P in keyof U]-?: true} & { [Q in keyof V]: never}
   // labelId doesn't exist => hideLabel === true && label Required
   // labelId doesn't exist => hideLabel === false && label Required
   | { [X in keyof T]: never } & { [W in keyof V]-?: V[W] };

export type IDropdownProps = DropdownProps & AccessibilityLabelProps;
