import { useState, useEffect, useCallback } from 'react';

export default function useSessionStorage<T>(key: string, defaultValue: T): [T, (e: T) => void] {
  const getValueOrDefault = useCallback((k: string, defaultValue: T) => {
    const storedValue = sessionStorage.getItem(k);
    if (!storedValue) {
      return defaultValue;
    }

    return JSON.parse(storedValue);
  }, []);

  const [value, setValue] = useState(getValueOrDefault(key, defaultValue));

  useEffect(() => {
    if (value) {
      sessionStorage.setItem(key, JSON.stringify(value));
    } else {
      sessionStorage.removeItem(key);
    }
  }, [key, value]);

  return [value, setValue];
}
