import { Colors, Shadows } from '@myblueprint-spaces/papier-core';
import styled from 'styled-components';
import { IToastBaseProps, IToast, IToastIcon } from './types';

export const ToastElement = styled.div<IToastBaseProps & IToast>(({ size, theme, width }: IToastBaseProps & IToast) => {
  let vPadding: number;
  let hPadding: number;
  let bgColor: Colors;
  switch (size) {
    case 'small':
      vPadding = 0.375;
      hPadding = 0.5;
      break;
    case 'large':
      vPadding = 0.875;
      hPadding = 0.75;
      break;
    case 'xlarge':
      vPadding = 1.5;
      hPadding = 1;
      break;
    case 'medium':
    default:
      vPadding = 0.625;
      hPadding = 0.625;
      break;
  }
  switch (theme) {
    case 'dark':
      bgColor = Colors.black2;
      break;
    case 'light':
    default:
      bgColor = Colors.white;
      break;
  }

  return `
    ${width ? width : 'width: 23.5rem'};
    max-width: calc(100vw - 2rem);
    padding: ${vPadding}rem ${hPadding}rem;
    margin-top: 1rem;
    border-radius: 0.25rem;
    background-color: ${bgColor};
    ${Shadows.shadow2}
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
  `;
});

export const ToastRegion = styled.div`
  position: fixed;
  ${(props: { top?: boolean }) => props.top ?  'top: 0; width: 100%' : 'right: 0; bottom: 0'};
  margin: 1rem;
  z-index: 9999;
`;

export const ToastContent = styled.div`
  width: 100%;
`;

export const ToastActionContent = styled.div`
  white-space: nowrap;
`;

export const ToastIcon = styled.div`
  ${(props: IToastIcon) => props.size !== 'xlarge' && props.canClose ? 'padding-top: 0.125rem;' : ''}
`;
