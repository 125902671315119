import React, { FunctionComponent } from 'react';
import LoadingDots from '@myblueprint-spaces/papier-web/lib/Common/LoadingDots';
import Avatar from '@myblueprint-spaces/papier-web/lib/Common/Avatar';
import { Attachment, SourceType } from '@myblueprint-spaces/redux/lib/attachments';
import withErrorBoundaries from '@myblueprint-spaces/papier-web/lib/modules/hocs/withErrorBoundaries';
import { useTranslation } from 'react-i18next';
import Profile from 'assets/Asset_profile.png';
import { ConnectedAvatarProps } from './types';
import withAttachment from 'src/modules/hocs/withAttachment';

function ConnectedAvatar({ attachment, size, description, error, scopeModel, scope, ...rest }: ConnectedAvatarProps) {
  const { t } = useTranslation(['Papier.Common']);

  if ((typeof attachment === 'undefined' || (attachment as Attachment[])?.length === 0) && !error && !!scopeModel) {
    return <LoadingDots />;
  }

  const firstAttachment = Array.isArray(attachment) ? attachment[0] : attachment;

  if (!firstAttachment || error || (scope && !scopeModel)) {
    return <Avatar size={size} avatarSource={Profile} description={t('Papier.Common:AvatarAlt').toString()} {...rest} />;
  }

  const avatarSource = firstAttachment?.sources[SourceType.Compressed] || firstAttachment.sources[SourceType.Original];

  return (
    <Avatar avatarSource={avatarSource} size={size} description={description} error={error} {...rest} />
  );
}

// do *not* remove withAttachment from here
export default withErrorBoundaries(withAttachment(ConnectedAvatar) as FunctionComponent<ConnectedAvatarProps>);
