import React, { useContext } from 'react';
import { withTranslation } from 'react-i18next';
import ComplexI18n from '@myblueprint-spaces/papier-web/lib/Common/ComplexI18n';
import LoadingDots from '@myblueprint-spaces/papier-web/lib/Common/LoadingDots';
import ExternalLink from '@myblueprint-spaces/papier-web/lib/Atoms/ExternalLink';
import AppConfigContext from 'src/Common/Contexts/AppConfigContext';

const TermsOfAgreement = () => {
  const { links } = useContext(AppConfigContext) ?? {};

  if (!links) return <LoadingDots />;

  return (
    <ComplexI18n i18nKey="LoginSignUp.Common:SignUp.Terms">
      <ExternalLink href={links.privacyPolicyUrl} underline />
      <ExternalLink href={links.termsUrl} underline />
    </ComplexI18n>
  );
};


export default withTranslation(['LoginSignUp.Common'])(TermsOfAgreement);
