import styled from 'styled-components';
import { Colors } from '@myblueprint-spaces/papier-core';
import { IPanelContainerProps } from './types';

const exitedStyle = {
  up: 'translateY(100%)',
  down: 'translateY(-100%)',
  left: 'translateX(100%)',
  right: 'translateX(-100%)',
};

const exitingStyles = {
  down: 'translateY(100%)',
  up: 'translateY(-100%)',
  left: 'translateX(-100%)',
  right: 'translateX(100%)'
};

const enterDefaultTransition = 'all 250ms ease-in-out';

const exitDefaultTransition = 'transform 200ms cubic-bezier(0, 0, 0.5, 1.5)';

interface IPanelTransitionStates {
  exited: {
    transition: string;
    transform: string;
  };
  entering: {
    transition: string;
    transform: string;
  };
  entered: {
    transition: string;
    transform: string;
  };
  exiting: {
    transition: string;
    transform: string;
  };
}

export function PanelTransitionStates(enterDir: string, exitDir: string): IPanelTransitionStates {
  return {
    // exited style depends on enter direction
    exited: {
      transition: exitDefaultTransition,
      transform: exitedStyle[enterDir]
    },
    entering: {
      transition: enterDefaultTransition,
      transform: 'translate(0, 0)'
    },
    entered: {
      transition: enterDefaultTransition,
      transform: 'translate(0, 0)'
    },
    exiting: {
      transition: exitDefaultTransition,
      transform: exitingStyles[exitDir]
    }
  };
}

export const PanelContainer = styled('div')(({ backgroundColor, enterDir }: IPanelContainerProps) => `
  background-color: ${Colors[backgroundColor] || backgroundColor || Colors.white};
  max-height: 100%;
  height: 100%;
  width: 100%;
  position: fixed;
  bottom: 0;
  ${enterDir === 'right' ? 'left: 0;' : 'right: 0;'}
`);

export const Clipped = styled.div`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`;
