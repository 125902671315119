import { Shadows, ICommonButtonProps, ITextButtonStyleProps, getButtonAppearance, Colors } from '@myblueprint-spaces/papier-core';
import styled, { css, keyframes } from 'styled-components';

interface IBaseButtonProps extends Omit<ICommonButtonProps, 'dataTest'> {
  wrap?: boolean;
}

type ITextButtonProps = (IBaseButtonProps & ITextButtonStyleProps);

const Alignment = {
  'left': 'space-between',
  'center': 'center',
  'right': 'flex-end'
};

export const shakeAnimation = keyframes`
  10%,
  90% {
      transform: translate3d(-0.125rem, 0, 0);
  }
  20%,
  80% {
      transform: translate3d(0.125rem, 0, 0);
  }
  30%,
  50%,
  70% {
      transform: translate3d(-0.25rem, 0, 0);
  }
  40%,
  60% {
      transform: translate3d(0.25rem, 0, 0);
  }
`;

const shakeCss = css`
  animation: ${shakeAnimation} 1s cubic-bezier(.36, .07, .19, .97) both;
`;

export const BaseButton = styled('button').attrs<Omit<ICommonButtonProps, 'dataTest'>>((props: Omit<ICommonButtonProps, 'dataTest'>) => {
  if (props.width && props.height) {
    return {
      style: {
        ...(!props.expanded && { width: props.width }),
        height: props.height
      }
    };
  }
})((props: IBaseButtonProps) => {
  const { size, color, rounded, expanded, shake, pointerEvents, shadow, disabled, wrap, align } = props;
  let padding: number;
  let roundedSize: number;

  const { backgroundColor, activeBackgroundColor, activeBorderColor, hoverBackgroundColor, hoverBorderColor, disabledBackgroundColor,
    disabledBorderColor, activeTextColor, activeIconColor, hoverTextColor, hoverIconColor, textColor, iconColor, disabledIconColor, disabledTextColor } = getButtonAppearance(color);

  switch (size) {
    case 'xlarge':
      padding = 0.4375;
      roundedSize = 1.75;
      break;
    case 'large':
      padding = 0.5;
      roundedSize = 2;
      break;
    default:
    case 'medium':
      padding = 0.25;
      roundedSize = 2;
      break;
    case 'small':
      padding = 0.375;
      roundedSize = 2;
      break;
  }

  const buttonPadding = padding;
  return css`
    ${shake ? shakeCss : ''};
    ${pointerEvents ? `pointer-events: ${pointerEvents};` : ''}
    display: block;
    background-color: ${backgroundColor};
    
    padding: ${buttonPadding}rem;
    border-radius: ${rounded ? roundedSize : 0.25}rem;
    ${shadow && !disabled ? Shadows.shadow1 : 'box-shadow: none;'}
    cursor: pointer;
    text-align: ${align || 'center'};
    ${wrap ? '' : 'max-width: 100%;'}
    ${expanded ? 'width: 100%;' : ''}
    border: none;
    transition: all 0.2s ease-in-out;
    outline: none;
    position: relative;
    flex-wrap: ${wrap ? 'wrap;' : 'nowrap;'};
    &:not([disabled]):hover {
      ${hoverTextColor ? `.typographyContent {
        color: ${Colors[hoverTextColor]};
      }` : ''}
      ${hoverIconColor ? `.iconContent {
        color: ${Colors[hoverIconColor]};
      }` : ''}
      background-color: ${hoverBackgroundColor};
      border-color: ${hoverBorderColor};
      ${shadow && !disabled ? Shadows.shadow2 : ''}
    }
    &[disabled], &.disabled {
      ${disabledTextColor ? `.typographyContent {
        color: ${Colors[disabledTextColor]};
      }` : ''}
      ${disabledIconColor ? `.iconContent {
        color: ${Colors[disabledIconColor]};
      }` : ''}
      background-color: ${disabledBackgroundColor};
      border-color: ${disabledBorderColor};
      cursor: not-allowed;
    }
    &:not([disabled]):focus, &.disabled:focus {
      ${Shadows.primaryFloatingFocus}
    }
    &:not([disabled]):active {
      ${activeTextColor ? `.typographyContent {
        color: ${Colors[activeTextColor]};
      }` : ''}
      ${activeIconColor ? `.iconContent {
        color: ${Colors[activeIconColor]};
      }` : ''}
      background-color: ${activeBackgroundColor};
      border-color: ${activeBorderColor};
      box-shadow: none;
    }
    * {
      &::-moz-focus-inner {
        border-style: none;
      }
    }
    &::-moz-focus-inner {
      border-style: none;
      padding: 0;
    }
    .typographyContent {
      color: ${Colors[textColor]};
    }
    .iconContent {
      color: ${Colors[iconColor]};
    }
  `;
});

export const IconTextButtonIconWrapper = styled.div(({ text, size, iconPosition, icon, align }: ITextButtonProps) => {
  let margin;
  switch (size) {
    case 'small':
      margin = '0 0.4375rem';
      break;
    default:
    case 'large':
    case 'medium':
      margin = `${(text && icon) ? '0 0.375rem' : '0.125rem 0.375rem'}`;
      break;
    case 'xlarge':
      margin = `0 ${text ? 0.5 : 0.25}rem`;
      break;
  }

  return `
    display: flex;
    justify-content: ${align ? Alignment[align] : 'center'};
    flex-direction: row;
    margin: ${margin};
    margin-${iconPosition}: 0;
    i {
      margin-${iconPosition}: 0;
    }
  `;
});

export const TextButtonWrapper = styled.div(({ size, icon, text, iconPosition, wrap, align }: ITextButtonProps) => {
  let margin: string;
  switch (size) {
    case 'xlarge':
      margin = `${icon ? '0 1.0625rem' : '0.3125rem 1.0625rem'}`;
      break;
    case 'large':
      margin = `${icon ? '0 0.75rem' : '0.125rem 1rem'}`;
      break;
    default:
    case 'medium':
      margin = `${icon ? '0rem 0.75rem' : '0.125rem 0.75rem'}`;
      break;
    case 'small':
      margin = '0 0.625rem';
      break;
  }
  return `
    margin: ${text ? margin : 0};
    ${(icon && text) ? `margin-${iconPosition}: 0;` : text ? '' : 'margin: 0;'}
    display: flex;
    justify-content: ${align ? Alignment[align] : 'center'};
    align-items: center;
    flex-direction: row;
    flex-wrap: ${wrap ? 'wrap;' : 'nowrap;'};
  `;
});

export const IconButtonWrapper = styled.div`
  /* margin: 0 auto; */
`;
