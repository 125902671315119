import styled from 'styled-components';
import { IIconContainerProps } from './types';
import Typography from '../Typography';
import { Colors } from '@myblueprint-spaces/papier-core';

export const IconContainer = styled.div((props: IIconContainerProps) => {
  const { size } = props;
  return `
  margin-right: ${size === 'small' ? 0.375 : 0.5}rem
  `;
});

export const TextContainer = styled.div`
  flex: 1;
`;

export const LabelText = styled(Typography)(({ required }: { required?: boolean }) =>
  required ? `
  &::after {
    content: " *";
    color: ${Colors.danger1};
    left: 0;
    top: 0;
  }` : ''
);
