import * as React from 'react';
import * as PropTypes from 'prop-types';
import LoadingDots from 'src/Common/LoadingDots';
import styled from 'styled-components';

export interface FocusableLoadingDotsProps {
  onUnmount?: () => void;
  id?: string;
  hidden?: boolean;
}

export const FocusableDots = styled(LoadingDots).attrs(({ onBlur, id }: { onBlur: () => void; id?: string; }) => ({
  tabIndex: 0,
  id: id,
  title: 'Loading...',
  dataTest: 'loading',
  onBlur: onBlur
}))(({ hidden }: { hidden?: boolean }) => `
  ${hidden ? `
    & circle {
      fill: transparent
    }
  ` : ''}
`);

function FocusableLoadingDots({ onUnmount, id, forwardRef, hidden = false }: FocusableLoadingDotsProps & { forwardRef: React.Ref<HTMLDivElement> }) {
  React.useLayoutEffect(() => {
    return () => {
      onUnmount && onUnmount();
    };
  }, []);

  return (
    <div ref={forwardRef}>
      <FocusableDots onBlur={onUnmount} id={id} hidden={hidden} />
    </div>
  );
}


FocusableLoadingDots.propTypes = {
  onUnmount: PropTypes.func,
  id: PropTypes.string,
  hidden: PropTypes.bool
};

export default React.forwardRef<HTMLDivElement, FocusableLoadingDotsProps>((props, ref) => <FocusableLoadingDots {...props} forwardRef={ref} />);
