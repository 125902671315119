import { ColorNames, Colors } from '@myblueprint-spaces/papier-core';
import styled from 'styled-components';

export type AvatarSizeTypes = 'xxxsmall' | 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge';

export const getAvatarSize = (size: AvatarSizeTypes): number => {
  let width;
  switch (size) {
    case 'xxxsmall':
      width = 1.125;
      break;
    case 'xsmall':
      width = 1.75;
      break;
    case 'small':
      width = 2;
      break;
    case 'medium':
      width = 2.5;
      break;
    case 'large':
      width = 3;
      break;
    case 'xlarge':
      width = 3.5;
      break;
    case 'xxlarge':
      width = 5;
      break;
  }

  return width;
};

export const AvatarWrapper = styled.div((props: { size?: AvatarSizeTypes; noMargin?: boolean, borderColor?: ColorNames }) => {
  const { size, noMargin, borderColor } = props;
  const width = getAvatarSize(size);

  return `
    object-fit: cover;
    border-radius: 50%;
    position: relative;
    ${noMargin ? '' : 'margin: 0.25rem 0.5rem 0.25rem 0;'}
    width: ${width}rem;
    height: ${width}rem;
    ${borderColor ? `border: 0.0625rem solid ${Colors[borderColor]}` : ''};
  `;
});


export const AvatarImg = styled.img((props: { disabled?: boolean; size?: string }) => {
  const { disabled, size } = props;
  return `
    width: 100%;
    height: 100%;
    border-radius: 50%;
    ${size !== 'xxxsmall' ? `
      position: relative;
      top: 50%;
      transform: translate(-50%, -50%);
      left: 50%;
    `: `
      top: 0;
      left: 0;
      position: absolute;
    `}
    object-fit: cover;
    ${disabled && `
    filter : gray;
    -webkit-filter : grayscale(100%); 
    filter: grayscale(100%);
    `
}
    `;
});
