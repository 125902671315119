import * as PropTypes from 'prop-types';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Transition, TransitionGroup } from 'react-transition-group';
import Toast from './components/Toast';
import { ToastRegion } from './styles';
import { IToast, IToastManagerState, ToastProps } from './types';

const ToastTransitionStates = {
  entering: {
    transform: 'scale(0)'
  },
  entered: {
    transform: 'scale(1)',
    transition: 'all 250ms cubic-bezier(0, 0, 0.5, 1.5)'
  },
  exiting: {
    transform: 'scale(0)',
    transition: 'all 250ms cubic-bezier(0, 0, 0.5, 1.5)'
  },
  exited: {
    transform: 'scale(0)'
  }
};

export default class ToastManager extends React.PureComponent<ToastProps, IToastManagerState> {
  public static propTypes = {
    portal: PropTypes.object
  };

  private toastRemovalEventCache: { [key: string]: () => void } = {};

  public constructor(props: ToastProps) {
    super(props);

    this.state = {
      toasts: []
    };
    this.removeToast = this.removeToast.bind(this);
  }

  public pushToast(toast: IToast) {
    if (!toast.id) {
      throw new Error('Toast requires an ID');
    }
    this.setState((s) => ({ toasts: s.toasts.concat(toast) }));
  }

  public removeToast(id: string) {
    delete this.toastRemovalEventCache[id];
    this.setState((s) => ({ toasts: s.toasts.filter((t) => t.id !== id) }));
  }

  private getToasts = (id, text, toast) => {
    return  (
      <Transition key={id} timeout={250}>
        {(state) => (
          <React.Suspense fallback={null}>
            <Toast id={id} text={text} {...toast} style={ToastTransitionStates[state]}
              removeToast={this.toastRemovalEventCache[id] || (this.toastRemovalEventCache[id] = () => this.removeToast(id))} />
          </React.Suspense>
        )}
      </Transition>
    );
  };

  public render() {
    const { portal } = this.props;
    const { toasts } = this.state;

    if (!portal) return null;

    return (
      <React.Fragment>
        { ReactDOM.createPortal(
          <ToastRegion aria-live="polite">
            <TransitionGroup component={null} appear enter exit>
              {toasts.filter((t) => !t.top).map(({ id, text, ...toast }: IToast) => (
                this.getToasts(id, text, { ...toast })
              ))}
            </TransitionGroup>
          </ToastRegion>
          , portal) }
        { ReactDOM.createPortal(
          <ToastRegion aria-live="polite" top>
            <TransitionGroup component={null} appear enter exit>
              {toasts.filter((t) => t.top).map(({ id, text, ...toast }: IToast) => (
                this.getToasts(id, text, { ...toast })
              ))}
            </TransitionGroup>
          </ToastRegion>
          , portal) }
      </React.Fragment>
    );
  }
}
