import * as React from 'react';
import Panel, { PanelHeader, ClosePanelButton } from '@myblueprint-spaces/papier-web/lib/Common/Panel';
import Typography from '@myblueprint-spaces/papier-web/lib/Common/Typography';
import Button from '@myblueprint-spaces/papier-web/lib/Common/Button';
import { Row, Column, RowColumn } from '@myblueprint-spaces/papier-web/lib/Common/Grid';
import InfoBar from '@myblueprint-spaces/papier-web/lib/Common/InfoBar';
import { useTranslation } from 'react-i18next';
import { ContentWrapper, ScrollWrapper, Wrapper } from './styles';
import NotificationsManager from './components/NotificationsManager';
import { Breakpoints } from '@myblueprint-spaces/papier-core';
import withErrorBoundaries from '@myblueprint-spaces/papier-web/lib/modules/hocs/withErrorBoundaries';
import NotificationRefreshBar from './components/NotificationsManager/components/NotificationRefreshBar';
import WindowSizeContext from '../Contexts/WindowSizeContext';
import { useNavigate } from 'react-router-dom';
import { INotificationsProps } from './types';
import { useNotificationFilter } from './components/NotificationFilterContext';

const NotificationsPanel = (props: INotificationsProps) => {
  const { markAllRead, currentScope, unreadCount, hasNewCount, hasNewWithContext, leftComponent, settingsUrl, onClose, open } = props;
  const { t } = useTranslation(['Common', 'Notifications.Common', 'Notifications.Server', 'Classes.Common', 'Spaces.Common']);
  const { width, isSmall } = React.useContext(WindowSizeContext);
  const navigate = useNavigate();
  const usedUnreadCount = unreadCount ?? 0;
  const notificationFilter = useNotificationFilter();
  const showInfoBarOtherClasses = hasNewCount > 0 && !hasNewWithContext && currentScope;
  const showFilterOrButton = !!leftComponent || usedUnreadCount > 0;

  return (
    <Panel id="account-settings" title={t('Common:Notifications')} open={open} onPanelDidClose={onClose} dataTest="notification-panel">
      <PanelHeader
        left={<ClosePanelButton />}
        bottomBorder
        middle={<Typography as="h1" type="body2" color="black1">{t('Common:Notifications')}</Typography>}
        right={settingsUrl ? <Button color="secondary" icon="gear" title={t('Spaces.Common:Settings')} rounded size={isSmall ? 'small' : 'medium'}
          onClick={() => navigate(settingsUrl)} dataTest="notification-settings" /> : null} />
      <ContentWrapper>
        <ScrollWrapper align="center" span collapse expanded hasInfoBar={hasNewCount > 0}>
          <Column>
            {/* show InfoBar in other classes */}
            {showInfoBarOtherClasses && <Row align="center" expanded>
              <Column columns={{ small: 12, medium: 11, large: 6 }} collapse={Breakpoints.medium < width}>
                <div style={{ marginBottom: isSmall ? '1rem' : '1.5rem' }}>
                  <InfoBar text={t('Notifications.Common:HasNew')} type="info" size={ isSmall ? 'small' : 'medium'} />
                </div>
              </Column>
            </Row>}
            {hasNewWithContext && <NotificationRefreshBar inPanel />}
            {showFilterOrButton && <Row align="center" expanded>
              <Column columns={{ small: 12, medium: 11, large: 6 }}>
                <Wrapper>
                  <Row align="justify" verticalAlign="middle" span>
                    <Column columns={{ small: 4, medium: 4, large: 3 }} collapse={Breakpoints.medium < width}>
                      {leftComponent}
                    </Column>
                    {usedUnreadCount > 0 && <Column shrink collapse={Breakpoints.medium < width}>
                      <Button color="secondary" text={t('Notifications.Common:MarkAllRead')} expanded size="small"
                        onClick={() => markAllRead(notificationFilter)} dataTest="notif-mark-all-read" />
                    </Column>}
                  </Row>
                </Wrapper>
              </Column>
            </Row>}
            <RowColumn style={{ height: `calc(calc(100% - ${showFilterOrButton ? '5rem' : '0px'}) - ${showInfoBarOtherClasses ? '5rem' : '0px'})` }} collapse span expanded>
              <NotificationsManager />
            </RowColumn>
          </Column>
        </ScrollWrapper>
      </ContentWrapper>
    </Panel>
  );
};

export default withErrorBoundaries(NotificationsPanel);
