import * as React from 'react';
import * as PropTypes from 'prop-types';
import { IIconSkeletonProps } from './types';
import { IconSkeletonDiv } from './styles';

export function IconSkeleton({ size, ...rest }: IIconSkeletonProps): React.ReactElement {
  return <IconSkeletonDiv {...rest} diameter={size === 'small' ? '1rem' : '2rem'} />;
}

IconSkeleton.propTypes = {
  size: PropTypes.string,
  style: PropTypes.object
};

export default React.memo(IconSkeleton);
