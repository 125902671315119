import React from 'react';
import PropTypes from 'prop-types';
import Typography from 'src/Common/Typography';
import { FontTypeNames } from '@myblueprint-spaces/papier-core';

const MarkdownHeader = ({ level, children }: { level: number, children: React.ReactNode }): React.ReactElement => {
  const typeMap = ['header1', 'header2', 'header3', 'header4', 'subheader', 'body1'];

  return <Typography type={typeMap[level] as FontTypeNames}>{children}</Typography>;
};

MarkdownHeader.propTypes = {
  level: PropTypes.number,
  children: PropTypes.any
};

export default MarkdownHeader;
