import React, { useContext } from 'react';
import Icon from '@myblueprint-spaces/papier-web/lib/Common/Icon';
import CovalentGrid from '@myblueprint-spaces/papier-web/lib/Atoms/CovalentGrid';
import Typography from '@myblueprint-spaces/papier-web/lib/Common/Typography';
import { BannerWrapper } from './styles';
import { useTranslation } from 'react-i18next';
import UserHooks from 'src/modules/hooks/redux/user';
import WindowSizeContext from 'src/Common/Contexts/WindowSizeContext';

export default function ImpersonationBanner() {
  const { impersonated } = UserHooks.useUser() || {};
  const { isSmall } = useContext(WindowSizeContext);
  const { t } = useTranslation(['Common']);

  return impersonated ? <BannerWrapper>
    <CovalentGrid align="center" verticalAlign="middle">
      <Icon type="warning" color="white" size="small" />
      <Typography color="white" type={isSmall ? 'subtitle1' : 'compact'}>
        {t('Common:Impersonation')}
      </Typography>
    </CovalentGrid>
  </BannerWrapper> : null;
}
