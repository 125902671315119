import React from 'react';
import PropTypes from 'prop-types';
import { IPanelContentProps } from './types';
import { StyledPanelContent } from './styles';
import { PanelContext } from '../../context';

const PanelContent = ({ children, forwardRef, ...rest }) => {
  return (
    <PanelContext.Consumer>
      {({ setScrollTop }) =>
        (
          <StyledPanelContent ref={forwardRef} onScroll={(e) => setScrollTop(e.currentTarget.scrollTop)} {...rest}>
            {children}
          </StyledPanelContent>
        )
      }
    </PanelContext.Consumer>
  );
};

export default React.forwardRef<HTMLDivElement, IPanelContentProps>((props, ref) =>
  <PanelContent {...props} forwardRef={ref} />
);

PanelContent.propTypes = {
  children: PropTypes.any,
  forwardRef: PropTypes.object,
  style: PropTypes.object
};
