/* eslint max-len: 0 */
import { Colors } from '@myblueprint-spaces/papier-core';
import * as PropTypes from 'prop-types';
import * as React from 'react';

import { ILoadingDotsProps, LoadingDotsSizes } from './types';
import { generateId } from 'src/modules/browsers/generateId';
import { WithTranslation } from 'react-i18next';

class LoadingDots extends React.Component<ILoadingDotsProps> {
  public static propTypes = {
    color: PropTypes.string,
    dotCount: PropTypes.number,
    size: PropTypes.oneOf(LoadingDotsSizes),
    speedModifier: PropTypes.number,
    width: PropTypes.number,
    onUnmount: PropTypes.func
  };

  public static defaultProps = {
    color: 'grey4',
    dotCount: 3,
    size: 'medium',
    speedModifier: 1
  };

  private id: string;
  private componentRef: React.RefObject<HTMLDivElement>;
  private dots: React.RefObject<SVGCircleElement>[];
  private height: number;
  private dotWidth: number;
  private animationFrame: number;

  public constructor(props: ILoadingDotsProps & WithTranslation) {
    super(props);

    this.id = props.id || generateId();
    this.componentRef = React.createRef();

    this.setup();
  }

  private setup() {
    const { dotCount, size } = this.props;
    this.dots = Array(dotCount).fill({}).map(() => React.createRef());

    switch (size) {
      case 'small':
        this.height = 12;
        this.dotWidth = 2;
        break;
      default:
      case 'medium':
        this.height = 16;
        this.dotWidth = 3;
        break;
      case 'large':
        this.height = 22;
        this.dotWidth = 4;
        break;
    }
  }

  public componentDidMount(): void {
    this.animationFrame = window.requestAnimationFrame((f) => this.animate(f));
  }

  public componentDidUpdate(): void {
    this.setup();
  }

  public componentWillUnmount(): void {
    const { onUnmount } = this.props as ILoadingDotsProps & WithTranslation;
    window.cancelAnimationFrame(this.animationFrame);
    onUnmount?.();
  }

  public render(): React.ReactElement {
    const { color, dotCount, t, tReady, width, speedModifier, dataTest, ...rest } = this.props as ILoadingDotsProps & WithTranslation;
    const padding = (2 * this.dotWidth);
    const actualWidth = width || this.height * 2;
    const innerWidth = actualWidth - (2 * padding);
    const id = this.id;

    return (
      <div role="alert" ref={this.componentRef} aria-live="assertive" data-test={dataTest} {...rest}>
        {/* negative left margin to account for the padding in the first element that makes the entire animation not centered */}
        <svg xmlns="http://www.w3.org/2000/svg" width={actualWidth} height={this.height} viewBox={`0 0 ${actualWidth} ${this.height}`}
          role="img" aria-labelledby={`loadingTitle${id}`} style={{ marginLeft: `-${padding / 2}px` }}>
          {/* Since this is used inside the Suspense component, if translations are not ready, we need to hardCode it */}
          <title id={`loadingTitle${id}`}>{tReady ? t('Common:Actions.Loading') : 'Loading...'}</title>
          { this.dots.map((d, i) => (
            <circle ref={d} fill={Colors[color]} cx={((innerWidth / (dotCount - 1)) * i) + padding}
              cy={this.height / 2} r={this.dotWidth} key={i} transform="translate(0 0)" />
          )) }
        </svg>
      </div>
    );
  }

  private animate(time: number) {
    const { speedModifier } = this.props;

    // Reduce high-resolution time value by 250, multiply by Pi for simplified frequency manipulation
    const theta = (time / 250) * Math.PI * speedModifier;
    // Sinusoids return values from [-1, 1]
    // ---------------------
    // |       ^
    // |    o  | (full height - diameter of the circle) / 2
    // |       v
    // ---------------------
    const amplitude = (this.height - (2 * this.dotWidth)) / 2;

    for (let i = 0; i < this.dots.length; i++) {
      // Render each dot out-of-phase of each other by Pi/N to space them out
      const phase = (Math.PI / this.dots.length * i);

      const translation = Math.sin(theta + phase) * amplitude;

      this.dots[i].current && this.dots[i].current.setAttribute('transform', 'translate(0 ' + translation + ')');
    }

    if (this.componentRef.current) {
      this.animationFrame = window.requestAnimationFrame((t) => this.animate(t));
    }
  }
}

export default LoadingDots;
