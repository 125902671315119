import styled from 'styled-components';

const FlexContainer = styled.div(({ handleOverflow }: { handleOverflow? : boolean }) => `
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 0;
  ${handleOverflow ? 'overflow-x: hidden; overflow-y: auto;' : ''}
`);

export default FlexContainer;
