import styled from 'styled-components';
import { IFlexibleForm } from './types';

export const StyledForm  =styled.form<IFlexibleForm>(({ flex }) => {
  return flex
    ? `
      flex: 1;
      display: flex;
      flex-direction: column;
    `
    :'';
});

export const DefaultFormButtonContainer = styled.div`
  padding-top: 0.5rem;
`;
