import * as PropTypes from 'prop-types';
import * as React from 'react';
import AriaModal, { AriaModalProps, RequiredAriaTypes } from '@myblueprint-spaces/react-aria-modal';
import FocusContext from 'src/Common/FocusContext';

export type ModalProps = AriaModalProps & RequiredAriaTypes;

export interface ICustomAriaModalProps {
  children: React.ReactNode | React.ReactNode[];
  contentRef: React.Ref<HTMLDivElement>;
  renderTo?: string;
  initialFocus?: HTMLElement | SVGElement | string | false | (() => HTMLElement | SVGElement | false);
}

// Here goes the parent element's id
const whitelistForClickOutside = ['[id*=ToastManager]', '#__filestack-picker'];

export function CustomAriaModal(props: ICustomAriaModalProps & ModalProps): React.ReactElement {
  const { children, contentRef, renderTo, onExit, initialFocus, dialogId, ...rest } = props;
  const newRef = React.useRef(contentRef);
  const ref = ((contentRef || newRef) as React.RefObject<HTMLDivElement>);

  const allowOutsideClick = (event) => {
    return whitelistForClickOutside.some((id) => {
      if (event.target?.parentElement?.closest(id)!==null) {
        return true;
      }
    });
  };

  const focusTrapOptions = {
    allowOutsideClick: allowOutsideClick,
    onDeactivate: onExit,
    escapeDeactivates: true,
    initialFocus,
    fallbackFocus: ref.current || document.getElementById(dialogId) || undefined,
    delayInitialFocus: true
  };

  if(!renderTo) {
    return (
      <AriaModal {...rest as ModalProps} dialogId={dialogId} onExit={onExit} focusTrapOptions={focusTrapOptions}>
        <FocusContext.Provider value={{ actualFocusTrappedElement: ref }}>
          { children }
        </FocusContext.Provider>
      </AriaModal>
    );
  }

  const Modal = AriaModal.renderTo(renderTo);

  return (
    <Modal {...rest as ModalProps} dialogId={dialogId} onExit={onExit} focusTrapOptions={focusTrapOptions}>
      <FocusContext.Provider value={{ actualFocusTrappedElement: ref }}>
        { children }
      </FocusContext.Provider>
    </Modal>
  );
}

CustomAriaModal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]),
  contentRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  renderTo: PropTypes.string
};

export default CustomAriaModal;

