// https://betterprogramming.pub/loading-third-party-scripts-dynamically-in-reactjs-458c41a7013d
export const loadScript = (elementId: string, scriptSrc: string, callback?: () => void, errorCallback?: () => void): void => {
  const existingScript = document.getElementById(elementId);

  if (!existingScript) {
    const script = document.createElement('script');
    script.src = scriptSrc;
    script.id = elementId;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    script.nonce = window.nonce;
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
    script.onerror = () => {
      if (errorCallback) errorCallback();
    };
  }
  if (existingScript && callback) callback();
};
