import useId from 'src/modules/hooks/useId';
import * as PropTypes from 'prop-types';
import * as React from 'react';
import { IToggleableProps, ToggleableSizes } from './types';

const withToggleable = <P extends object>(WrappedComponent: React.ComponentType<P>) => (props: IToggleableProps & P): React.ReactElement => {
  const { id, size, checked, onChange, disabled, 'aria-labelledby': ariaLabelledby, labelId, ...rest } = props;
  const toggleableSize = size || 'medium';
  const [hovered, setHoverState] = React.useState(false);
  const [focussed, setFocusState] = React.useState(false);
  const [active, setActiveState] = React.useState(false);
  const toggleId = useId(id);
  const onClick = React.useCallback(() => !disabled && onChange && onChange(!checked), [onChange, checked, disabled]);
  const mouseEnter = React.useCallback(() => !disabled && setHoverState(true), [setHoverState, disabled]);
  const mouseLeave = React.useCallback(() => !disabled && setHoverState(false), [setHoverState, disabled]);
  const mouseDown = React.useCallback(() => !disabled && setActiveState(true), [setActiveState, disabled]);
  const mouseUp = React.useCallback(() => !disabled && setActiveState(false), [setActiveState, disabled]);
  const focus = React.useCallback(() => setFocusState(true), [setFocusState]);
  const blur = React.useCallback(() => setFocusState(false), [setFocusState]);
  const usedLabelId = labelId ?? `${toggleId}_label`;

  return (
    <WrappedComponent onClick={onClick} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} id={toggleId}
      onMouseDown={mouseDown} onMouseUp={mouseUp} onFocus={focus} onBlur={blur} focussed={focussed}
      size={toggleableSize} checked={checked} hovered={hovered} active={active} disabled={disabled} labelId={usedLabelId} {...rest as P}
      aria-labelledby={ariaLabelledby ?? usedLabelId} aria-disabled={disabled} />
  );
};

withToggleable.propTypes = {
  'id': PropTypes.string,
  'size': PropTypes.oneOf(ToggleableSizes),
  'checked': PropTypes.bool,
  'onChange': PropTypes.func,
  'disabled': PropTypes.bool,
  'aria-labelledby': PropTypes.arrayOf(PropTypes.string)
};

export default withToggleable;
