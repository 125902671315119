import React, { useCallback } from 'react';
import { Row, Column } from '@myblueprint-spaces/papier-web/lib/Common/Grid';
import Typography from '@myblueprint-spaces/papier-web/lib/Common/Typography';
import Icon from '@myblueprint-spaces/papier-web/lib/Common/Icon';
import CleanButton from '@myblueprint-spaces/papier-web/lib/Atoms/CleanButton';
import CovalentGrid from '@myblueprint-spaces/papier-web/lib/Atoms/CovalentGrid';
import { Colors } from '@myblueprint-spaces/papier-core';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import withErrorBoundaries from '@myblueprint-spaces/papier-web/lib/modules/hocs/withErrorBoundaries';
import { useNotificationFilter } from '../../../NotificationFilterContext';
import { NotificationFeedFilter } from '@myblueprint-spaces/redux/lib/notifications';

export const Wrapper = styled(CleanButton)(({ inPanel } : { inPanel?: boolean }) => `
  background-color: ${Colors.primary2};
  width: 100%;
  ${inPanel ? `margin-top: -1.5rem;
    padding: 0.875rem 0;` : `
    padding: 0.75rem 0;
  `}
`);

const NotificationRefreshBar = ({ loadNewest, inPanel }: { loadNewest: (filter: NotificationFeedFilter | null) => void, inPanel?: boolean }) => {
  const { t } = useTranslation(['Notifications.Common']);
  const notificationFilter = useNotificationFilter();

  const loadNewestItems = useCallback(() => loadNewest(notificationFilter), [notificationFilter, loadNewest]);

  return (
    <Wrapper onClick={loadNewestItems} inPanel={inPanel} data-test="workspace">
      <Row align="center" expanded verticalAlign="middle">
        <Column shrink>
          <CovalentGrid verticalAlign="middle">
            <Icon type="refresh" color="primary1" size="medium" />
            <Typography color="primary1" type="subtitle1">{t('Notifications.Common:NewNotifications')}</Typography>
          </CovalentGrid>
        </Column>
      </Row>
    </Wrapper>
  );
};

export default withErrorBoundaries(NotificationRefreshBar);
