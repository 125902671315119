import * as React from 'react';
import warning from 'warning';
import { State, Action, Props, ActionTypes, Context } from './types';
import { getValidIds, onMouseMove } from './common';

// const DOCUMENT_POSITION_PRECEDING = 2;

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case ActionTypes.UPDATE:
    case ActionTypes.REGISTER: {
      const newTabStop = action.payload;
      if (state.tabStops.length === 0) {
        return {
          ...state,
          selectedId: newTabStop.id,
          tabStops: [newTabStop],
          allIds: [newTabStop.id],
          allValidIds: [newTabStop.id]
        };
      }
      const index = state.tabStops.findIndex(
        (tabStop) => tabStop.id === newTabStop.id
      );

      if (index >= 0) {
        const newState = state;
        newState.tabStops[index].disabled = newTabStop.disabled;
        newState.tabStops[index].domElementRef = newTabStop.domElementRef;
        const { tabStops, overScan } = newState;
        if (action.type === ActionTypes.UPDATE) {
          if (newState.selectedId === newTabStop.id && newTabStop.disabled) {
            let newIndex = index;
            let reverse = false;
            do {
              // we go till the end trying to find something not disabled, if we can't find it, we go backwards until 0
              if (reverse) {
                newIndex--;
              } else {
                if (tabStops[newIndex + 1]) {
                  newIndex++;
                } else {
                  newIndex = index - 1;
                  reverse = true;
                }
              }
            } while (tabStops[newIndex]?.disabled && newIndex >= 0);

            return {
              ...newState,
              lastActionOrigin: 'system',
              selectedId: tabStops[newIndex]?.id ?? tabStops[index]?.id,
              allValidIds: getValidIds(overScan, newState.tabStops)
            };
          } else {
            return {
              ...newState,
              lastActionOrigin: 'system',
              allValidIds: getValidIds(overScan, newState.tabStops)
            };
          }
        }
        if (!state.ignoreLoopEdges) warning(false, `${newTabStop.id} tab stop already registered`);
        return {
          ...newState,
          lastActionOrigin: 'system',
          allValidIds: getValidIds(overScan, newState.tabStops)
        };
      }
      const { tabStops, overScan } = state;
      const newTabStops = [
        ...tabStops,
        newTabStop
      ];

      return {
        ...state,
        tabStops: newTabStops,
        allIds: newTabStops.map(({ id }) => id),
        allValidIds: getValidIds(overScan, newTabStops)
      };
    }
    case ActionTypes.UNREGISTER: {
      const id = action.payload.id;
      let index = 0;
      const { selectedId, tabStops, ignoreLoopEdges, overScan } = state;

      let newSelectedId = selectedId;
      if (selectedId === id && tabStops.length > 0) {
        index = tabStops.findIndex(
          (tabStop) => tabStop.id === id
        );
        let newIndex = index;
        let reverse = false;
        do {
          // we go till the end trying to find something not disabled, if we can't find it, we go backwards until 0
          if (reverse) {
            newIndex--;
          } else {
            if (tabStops[newIndex + 1]) {
              newIndex++;
            } else {
              newIndex = index - 1;
              reverse = true;
            }
          }
        } while (tabStops[newIndex]?.disabled && newIndex >= 0);
        newSelectedId = tabStops[newIndex]?.id ?? tabStops[index]?.id;
      }
      const newTabStops = ignoreLoopEdges ? tabStops.map((tabStop) => tabStop.id !== id ? tabStop : { ...tabStop, disabled: true }) : tabStops.filter((tabStop) => tabStop.id !== id);
      if (newTabStops.length === tabStops.length && !ignoreLoopEdges) {
        warning(false, `${id} tab stop already unregistered`);
        return state;
      }
      return {
        ...state,
        lastActionOrigin: 'system',
        selectedId: newTabStops.length === 0 ? null : newSelectedId,
        tabStops: newTabStops,
        allIds: newTabStops.map(({ id }) => id),
        allValidIds: getValidIds(overScan, newTabStops)
      };
    }
    case ActionTypes.TAB_TO_PREVIOUS:
    case ActionTypes.TAB_TO_NEXT: {
      const id = action.payload.id;
      const index = state.tabStops.findIndex((tabStop) => tabStop.id === id);
      if (index === -1) {
        warning(false, `${id} tab stop not registered`);
        return state;
      }
      let newIndex = 0;
      let cpIndex = index;
      do {
        newIndex = action.type === ActionTypes.TAB_TO_PREVIOUS
          ? cpIndex <= 0
            ? state.ignoreLoopEdges ? index : state.tabStops.length - 1
            : cpIndex - 1
          : cpIndex >= state.tabStops.length - 1
            ? state.ignoreLoopEdges ? index : 0
            : cpIndex + 1;
        cpIndex = newIndex;
      } while (state.tabStops[newIndex].disabled);

      return {
        ...state,
        lastActionOrigin: 'keyboard',
        selectedId: state.tabStops[newIndex].id
      };
    }
    case ActionTypes.TAB_TO_PREVIOUS_ROW:
    case ActionTypes.TAB_TO_NEXT_ROW: {
      const id = action.payload.id;
      let indexOverall = state.tabStops.findIndex((tabStop) => tabStop.id === id);
      let indexInRow = Array.prototype.indexOf.call(state.tabStops[indexOverall].domElementRef.current?.parentNode?.childNodes, state.tabStops[indexOverall].domElementRef.current);
      let stepToMove = state.tabStops[indexOverall].domElementRef.current?.parentNode?.childNodes.length || 0;

      if (indexOverall === -1) {
        warning(false, `${id} tab stop not registered`);
        return state;
      }
      let newIndex = 0;
      do {
        newIndex = action.type === ActionTypes.TAB_TO_PREVIOUS_ROW
          // if first row
          ? indexOverall - stepToMove < 0
            ? (state.tabStops.length) - (stepToMove - indexInRow)
            : indexOverall - stepToMove
          // if last row
          : indexOverall + stepToMove > state.tabStops.length - 1
            ? indexInRow
            : indexOverall + stepToMove;
        indexOverall = newIndex;
        indexInRow = Array.prototype.indexOf.call(state.tabStops[indexOverall].domElementRef.current?.parentNode?.childNodes, state.tabStops[indexOverall].domElementRef.current);
        stepToMove = state.tabStops[indexOverall].domElementRef.current?.parentNode?.childNodes.length || 0;
      } while (state.tabStops[newIndex].disabled);

      return {
        ...state,
        lastActionOrigin: 'keyboard',
        selectedId: state.tabStops[newIndex].id
      };
    }
    case ActionTypes.TAB_TO_FIRST:
    case ActionTypes.TAB_TO_LAST: {
      const id = action.payload.id;
      const index = state.tabStops.findIndex((tabStop) => tabStop.id === id);
      if (index === -1) {
        warning(false, `${id} tab stop not registered`);
        return state;
      }
      let newIndex = index;
      // I need to confirm this behaviour, if I don't have a real "first" and "last" should this behave with the "current first", no, right?
      if (!state.ignoreLoopEdges) {
        newIndex
          = action.type === ActionTypes.TAB_TO_FIRST
            ? 0
            : state.tabStops.length - 1;

        if (state.tabStops[newIndex].disabled) {
          do {
            newIndex = state.ignoreLoopEdges ? index : action.type === ActionTypes.TAB_TO_FIRST
              ? newIndex + 1
              : newIndex - 1;
          } while (state.tabStops[newIndex].disabled);
        }
      }

      return {
        ...state,
        lastActionOrigin: 'keyboard',
        selectedId: state.tabStops[newIndex].id
      };
    }
    case ActionTypes.SELECTED: {
      return {
        ...state,
        lastActionOrigin: 'keyboard',
        selectedId: action.payload.id
      };
    }
    case ActionTypes.CLICKED: {
      return {
        ...state,
        lastActionOrigin: 'mouse',
        selectedId: action.payload.id
      };
    }
    case ActionTypes.LAST_ACTION_ORIGIN: {
      return {
        ...state,
        lastActionOrigin: action.payload
      };
    }
    case ActionTypes.FOCUS: {
      const { id } = action.payload;
      if (state.selectedId === id) return state;

      return {
        ...state,
        lastActionOrigin: 'system',
        selectedId: id
      };
    }
    default:
      return state;
  }
}

export const RovingTabIndexContext = React.createContext<Context>({
  state: {
    selectedId: null,
    lastActionOrigin: 'system',
    tabStops: [],
    ignoreLoopEdges: false,
    allIds: [],
    allValidIds: [],
    overScan: 0
  }
  ,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatch: () => { }
});

// ignoreLoopEdges
//   - boolean; If true, clicking previous arrow on first element will not go to last. (Same behaviour for end of list). This is useful on virtualization cases where there's not really a "first" and "last" rendered
//   - Default: FALSE
const Provider = ({ children, ignoreLoopEdges = false, overScan = 0, preventDefaultFocus = false }: Props) => {
  const [state, dispatch] = React.useReducer(reducer, {
    selectedId: null,
    lastActionOrigin: 'system',
    tabStops: [],
    ignoreLoopEdges: ignoreLoopEdges,
    allIds: [],
    allValidIds: [],
    overScan
  });

  const context = React.useMemo<Context>(
    () => ({
      state,
      dispatch
    }),
    [state, dispatch]
  );
  const firstRun = React.useRef(true);

  const handleMouseMove = React.useCallback(() => onMouseMove(context), [context]);

  React.useLayoutEffect(() => {
    // lastAction only considers everything keyboard + click, so if user changes from keyboard to moving mouse we don't have a way to catch this and need a manual check
    // since this will directly affect the current context, we need one listener per context instance!
    document.addEventListener('mousemove', handleMouseMove);

    return () => document.removeEventListener('mousemove', handleMouseMove);
  }, [handleMouseMove]);


  React.useLayoutEffect(() => {
    const { tabStops, selectedId } = context?.state || {};
    if (firstRun.current && tabStops.length > 0) {
      if ((!selectedId || tabStops.find(({ id }) => id === selectedId)?.disabled) && !preventDefaultFocus) {
        context.dispatch({
          type: ActionTypes.FOCUS,
          payload: { id: tabStops.find(({ disabled }) => !disabled)!.id }
        });
      }
      context.dispatch({ type: ActionTypes.LAST_ACTION_ORIGIN, payload: null });
      firstRun.current = false;
    }
  }, [context]);

  return (
    <RovingTabIndexContext.Provider value={context}>
      {children}
    </RovingTabIndexContext.Provider>
  );
};

export default Provider;
