import * as React from 'react';
import { Column, Row, RowColumn } from '@myblueprint-spaces/papier-web/lib/Common/Grid';
import { IconSkeleton, TextSkeleton } from '@myblueprint-spaces/papier-web/lib/Common/LoadingSkeleton';
import { Wrapper } from './styles';
import WindowSizeContext from '../../../Contexts/WindowSizeContext';

const NotificationsSkeleton = (props: {
  small: boolean;
}) => {
  const { small } = props;
  const { isSmall } = React.useContext(WindowSizeContext);

  return (
    <RowColumn align="center" data-test="notifications-skeleton">
      { (new Array(5)).fill({}).map((_n, i) => (
        <Wrapper small={small || isSmall } key={i} style={{ marginTop: `${3.125 * i}rem` }}>
          <RowColumn align="left" verticalAlign="top" expanded collapse>
            <Row verticalAlign="top">
              <Column shrink>
                <IconSkeleton style={{ marginTop: '0.5rem' }} />
              </Column>
              <Column collapse={false}>
                <RowColumn>
                  <TextSkeleton width="40%" style={{ marginTop: '0.5rem', marginBottom: '0.625rem' }} />
                </RowColumn>
                <RowColumn>
                  <TextSkeleton width="18%" style={{ marginBottom: '0.625rem' }} />
                </RowColumn>
              </Column>
            </Row>
          </RowColumn>
        </Wrapper>
      ))}
    </RowColumn>
  );
};

export default React.memo(NotificationsSkeleton);
