import styled from 'styled-components';

const BlockSvg = styled.svg`
  display: block;

  * {
    transition: all 0.2s ease;
  }
`;

export default BlockSvg;
