import { FlexContainer } from 'src/Atoms';
import styled from 'styled-components';


export const LoadingGifWrapper = styled(FlexContainer)`
  justify-content:center;
  align-items:center;
  width:100%;
`;

export const StyledImage =  styled.img`
  height: 8rem;
  width : 8rem;
`;
