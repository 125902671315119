import * as React from 'react';
import { RovingTabIndexContext } from './Provider';
import shortid from 'shortid';
import { onKeyDown, onClick, register, unregister, calcTabIndex, changeDisabled, debounce } from './common';
import { ActionTypes, Context, DebounceReturn, State } from './types';

type ReturnType = [
  number,
  boolean,
  (e: KeyboardEvent) => void,
  () => void
];

// domElementRef:
//   - a React DOM element ref of the DOM element that is the focus target
//   - this must be the same DOM element for the lifecycle of the component
// disabled:
//   - can be updated as appropriate to reflect the current enabled or disabled
//     state of the component
// id:
//   - an optional ID that is the unique ID for the component
//   - if provided then it must be a non-empty string
//   - if not provided then one will be autogenerated
// isGrid:
//   - if true, side arrows go to next item in line and side/down arrows move through lines
// enterClicks/spaceClicks:
//   - boolean; If true, will trigger the click action when enter and/or space is pressed.
//   - Default: FALSE
// The returned callbacks handleKeyDown and handleClick are stable.
export default function useRovingTabIndex(
  domElementRef: React.RefObject<HTMLElement>,
  disabled: boolean,
  isGrid?: boolean,
  id?: string,
  enterClicks?: boolean,
  spaceClicks?: boolean
): ReturnType {
  // This id is stable for the life of the component:
  const tabIndexId = React.useRef(id || ('roving-tabindex_' + shortid.generate()));
  const context = React.useContext(RovingTabIndexContext);
  const [tabIndex, setTabIndex] = React.useState(-1);
  const [focused, setFocused] = React.useState(false);
  const calcTI = (context: Context, disabled: boolean) => {
    const { tabIndex: tI, focused: f } = calcTabIndex(context, tabIndexId.current, disabled);
    setFocused(f);
    setTabIndex(tI);
  };
  let loopCount = 0;
  const prevAllValidIds = React.useRef(context.state.allValidIds ?? []);

  React.useLayoutEffect(() => {
    tabIndexId.current = id || ('roving-tabindex_' + shortid.generate());
  }, [id]);

  const focusFirstEnabled = ({ selectedId, allValidIds, allIds, overScan }: Pick<State, 'selectedId' | 'allValidIds' | 'allIds' | 'overScan'>) => {
    window.clearTimeout(debHover.current?.[0] as number);
    loopCount = 0;
    let usedId;
    // current selectedId is still fine & on screen;
    if (allValidIds.find((id: string) => id === selectedId) && document.getElementById(selectedId!)) {
      usedId = selectedId!;
    } else {
      // Use first allValidIds IF it exists on the DOM (allValid Ids only include non-disabled & respects overScan)
      if (document.getElementById(allValidIds[0])) {
        usedId = allValidIds[0];
      } else {
        const filtered = allIds.filter((id) => {
          const elem = document.getElementById(id);

          return elem && elem.getAttribute('disabled') !== 'true';
        });
        if (!overScan || allIds.length <= overScan + 1) {
          usedId = filtered[0];
        } else {
          // Use first id controlled by us that is on Screen without a disabled attribute
          usedId = filtered[overScan + 1];
        }
      }
    }
    context.dispatch({
      type: ActionTypes.FOCUS,
      payload: { id: usedId }
    });
  };

  const handleScrollFocus = ({ allValidIds, selectedId, ...rest }: Pick<State, 'allValidIds' | 'selectedId' | 'allIds' | 'overScan'>, debHover: React.RefObject<DebounceReturn>, elseCallback: () => void) => {
    // If the system triggered the last event, it was most likely a scroll
    // Find the current hovered element and focus on it without scrolling
    // to deal with issues in virtualized lists
    loopCount++;
    const elems = document.querySelectorAll(':hover');
    const reversed = Array.from(elems).reverse();
    const hovered = reversed.find((e) => e.attributes['tabindex'] !== undefined) as HTMLElement;
    // if hovering an element controlled by RRTI
    if (hovered && allValidIds.includes(hovered.id)) {
      window.clearTimeout(debHover?.current?.[0] as number);
      loopCount = 0;
      context.dispatch({ type: ActionTypes.FOCUS, payload: { id: hovered.id } });
    } else {
      let found = false;

      found = !!reversed.find((comp) => {
        const childHovered = comp.querySelector('[tabindex]');
        // Hovering the _parent_ of an element controlled by RRTI
        if (childHovered && allValidIds.includes(childHovered.id)) {
          window.clearTimeout(debHover?.current?.[0] as number);
          loopCount = 0;
          context.dispatch({ type: ActionTypes.FOCUS, payload: { id: childHovered.id } });
          return true;
        }
        return false;
      });

      if (!found) {
        if (loopCount < 5) {
          elseCallback();
        } else {
          focusFirstEnabled({ selectedId, allValidIds, ...rest });
        }
      }
    }
  };

  const calcTIScrollHandle = (context: Context, disabled: boolean) => {
    const { state } = context;
    const { selectedId, allValidIds, lastActionOrigin, ignoreLoopEdges, allIds, overScan } = state;

    if (lastActionOrigin === 'system' && ignoreLoopEdges) {
      if (!selectedId || !allValidIds.find((id) => id === selectedId) || !document.getElementById(selectedId)) {
        handleScrollFocus(state, debHover, () => {
          debHover.current?.[1]?.(allValidIds, selectedId, allIds, overScan);
        });
      }
    }
    calcTI(context, disabled);
  };

  const deb = React.useRef<DebounceReturn>(debounce(calcTIScrollHandle, 300));
  const debHover = React.useRef<DebounceReturn>(debounce((allValidIds: string[], selectedId: string, allIds: string[], overScan: number) => {
    if (!selectedId || !allValidIds.find((id) => id === selectedId) || !document.getElementById(selectedId)) {
      handleScrollFocus({ selectedId, allValidIds, allIds, overScan }, debHover, () => {
        focusFirstEnabled({ selectedId, allValidIds, allIds, overScan });
      });
    }
  }, 300));

  // Registering and unregistering are tied to whether the input is disabled or not.
  // Context is not in the inputs because context.dispatch is stable.
  React.useLayoutEffect(() => {
    register(context, tabIndexId.current, domElementRef, disabled);

    return () => {
      unregister(context, tabIndexId.current);
      window.clearTimeout(debHover.current?.[0] as number);
    };
  }, []);

  const getTabIndex = React.useCallback(
    context.state.lastActionOrigin !== 'keyboard' && context.state.ignoreLoopEdges ? deb.current[1] : calcTI, [context.state.lastActionOrigin]
  );

  React.useLayoutEffect(() => {
    getTabIndex(context, disabled);
    // this dependency MUST be here, but I am not using it here... I *can't* make a condition without having both previousSlectedId && previous disabled
  }, [context.state.selectedId, disabled]);

  React.useLayoutEffect(() => {
    const { ignoreLoopEdges, allValidIds } = context.state;
    if (ignoreLoopEdges && prevAllValidIds.current.join('') !== allValidIds.join('') && allValidIds.length > 0) {
      prevAllValidIds.current = allValidIds;
      calcTIScrollHandle(context, disabled);
    }
  }, [context.state.allValidIds]);

  React.useLayoutEffect(() => {
    changeDisabled(context, tabIndexId.current, domElementRef, disabled);
  }, [domElementRef, disabled, tabIndexId.current]);

  const handleKeyDown = React.useCallback((event: KeyboardEvent) => {
    onKeyDown(event, context, tabIndexId.current, isGrid, enterClicks, spaceClicks);
  }, [context, isGrid, enterClicks, spaceClicks]);

  const handleClick = React.useCallback(() => {
    onClick(context, tabIndexId.current);
  }, [context]);

  return [tabIndex, focused, handleKeyDown, handleClick];
}
