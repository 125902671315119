import React, { createContext, useCallback } from 'react';
import withErrorBoundaries from '@myblueprint-spaces/papier-web/lib/modules/hocs/withErrorBoundaries';
import useSessionStorage from 'src/modules/hooks/useSessionStorage';
import { useSearchParams } from 'react-router-dom';

interface IAuthenticationProps {
  saveToken: (value: string | null) => void;
  storedToken: string | null;
  clearToken: () => void;
}

export const AuthenticationContext = createContext<IAuthenticationProps>({} as IAuthenticationProps);

const AuthenticationContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [searchParams] = useSearchParams();
  const sessionToken = searchParams.get('sessionToken');
  const [token, setToken] = useSessionStorage<string | null>('auth.refresh', sessionToken ?? null);

  const clearToken = useCallback(() => setToken(null), [setToken]);

  return (
    <AuthenticationContext.Provider value={{ saveToken: setToken, storedToken: token, clearToken }}>
      {children}
    </AuthenticationContext.Provider>
  );
};

export default withErrorBoundaries(AuthenticationContextProvider);
