import React, { ReactNode } from 'react';

const AuthLoadingContext = React.createContext<{ loading?: boolean; setLoading?: (loading: boolean) => void }>({});

export const AuthLoadingContextProvider = ({ children }: { children: ReactNode }) => {
  const [loading, setLoading] = React.useState(false);

  return (
    <AuthLoadingContext.Provider
      value={{
        loading,
        setLoading
      }}>
      {children}
    </AuthLoadingContext.Provider>
  );
};

export default AuthLoadingContext;
