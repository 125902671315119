import styled from 'styled-components';
import { IButtonWrapperProps } from './types';

export const ContentWrapper = styled.div`
  padding: 0 2.5rem 0.5rem;
  text-align: center;
`;

export const SubtextWrapper = styled.div`
  margin-top: 0.5rem;
`;

export const ImageWrapper = styled.div`
  margin: 0 auto 2rem;
  width: 10rem;
  height: 10rem;
`;

export const ButtonContainer = styled.div `
  margin: 2rem 0;
`;

export const ButtonWrapper = styled.div((props: IButtonWrapperProps) => {
  const { position } = props;
  return `
    margin: 0 ${position === 'left' ? '0.5rem' : '0'} 0 ${position === 'right' ? '0.5rem' : '0'};
  `;
});
