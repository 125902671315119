import styled from 'styled-components';
import { Colors } from '@myblueprint-spaces/papier-core';
import CleanButton from '@myblueprint-spaces/papier-web/lib/Atoms/CleanButton';

export const ButtonTextWrapper = styled.div`
  margin-left: 0.5rem;
`;

export const StyledIcon = styled.img`
  display: block;
  width: 2rem;
  height: 2rem;
`;

export const StyledRegionPopoverItem = styled(CleanButton)`
  display: block;
  width: 100%;
  border-radius: 0;
  padding: 0.375rem 0.625rem;

  &:hover {
    background-color: ${Colors.grey3};
  }
`;
