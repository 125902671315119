import * as React from 'react';
import { Row, Column } from 'src/Common/Grid';
import TextSkeleton from '../TextSkeleton';
import { SkeletonComment, SkeletonCommentBody, StyledIconSkeleton, SkeletonCommentName } from './styles';
import { ICommentSkeletonProps } from './types';

export function CommentSkeleton({ alignRight }: ICommentSkeletonProps): React.ReactElement {
  const iconColumn = (
    <Column shrink key="icon-column">
      <StyledIconSkeleton alignRight={alignRight} />
    </Column>
  );

  const bodyColumn = (
    <Column key="body-column">
      <SkeletonCommentName alignRight={alignRight}>
        <TextSkeleton width="28%" style={{ marginTop: '0.5rem', marginBottom: '0.625rem' }} />
      </SkeletonCommentName>
      <SkeletonComment>
        <SkeletonCommentBody>
          <TextSkeleton comment width="85%" />
          <TextSkeleton comment width="80%" />
          <TextSkeleton comment width="85%" />
          <TextSkeleton comment width="80%" />
          <TextSkeleton comment width="50%" />
        </SkeletonCommentBody>
      </SkeletonComment>
    </Column>
  );

  const contextColumn = (
    <Column shrink key="context-column">
      <StyledIconSkeleton size="small" style={{ marginTop: '0.5rem' }} />
    </Column>
  );

  return (
    <SkeletonComment>
      <Row collapse>
        {
          alignRight ? [contextColumn, bodyColumn, iconColumn] : [iconColumn, bodyColumn, contextColumn]
        }
      </Row>
    </SkeletonComment>
  );
}

export default React.memo(CommentSkeleton);
