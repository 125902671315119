import React, { useContext, createContext, useReducer, useEffect } from 'react';
import { NotificationFeedFilter } from '@myblueprint-spaces/redux/lib/notifications';

export enum NotificationFilterActionType {
  All = 'all',
  AllClassroomsAndPortfoliosUpdate = 'allClassroomsAndAllPortfolios',
  ClassroomIdFilter = 'classroomIdFilter',
  AllClassroomsUpdate = 'allClassroomsUpdate',
  AllPortfoliosUpdate = 'allPortfoliosUpdate',
  None = 'none'
}

export type NotificationFilterAction =
  | {
    type: NotificationFilterActionType.All;
    payload: NotificationFeedFilter;
  }
  | {
    type: NotificationFilterActionType.AllClassroomsAndPortfoliosUpdate;
  }
  | {
    type: NotificationFilterActionType.ClassroomIdFilter;
    payload: {
      classroomId: string
    };
  }
  | {
    type: NotificationFilterActionType.AllPortfoliosUpdate;
  }
  | {
    type: NotificationFilterActionType.AllClassroomsUpdate;
  }
  | {
    type: NotificationFilterActionType.None;
  };

const NotificationFilterStateContext = createContext<NotificationFeedFilter | undefined>(undefined);
const NotificationFilterDispatchContext = createContext<React.Dispatch<NotificationFilterAction> | undefined>(undefined);

function NotificationFilterReducer(_: NotificationFeedFilter, action: NotificationFilterAction): NotificationFeedFilter {
  switch (action.type) {
    case NotificationFilterActionType.All:
    case NotificationFilterActionType.ClassroomIdFilter: {
      return action.payload;
    }
    case NotificationFilterActionType.AllPortfoliosUpdate: {
      return { allPortfolios: true };
    }
    case NotificationFilterActionType.AllClassroomsUpdate: {
      return { allClassrooms: true };
    }
    case NotificationFilterActionType.AllClassroomsAndPortfoliosUpdate: {
      return { allClassrooms: true, allPortfolios: true };
    }
    case NotificationFilterActionType.None: {
      return {};
    }
    default: {
      throw new Error(`Unhandled NotificationFilterContext update with Action: ${action}`);
    }
  }
}

function NotificationFilterProvider({ children, initialValue }: { children: React.ReactNode, initialValue?: NotificationFeedFilter }) {
  const [state, dispatch] = useReducer(NotificationFilterReducer, initialValue);
  const notificationFilterDispatch = useContext(NotificationFilterDispatchContext);

  useEffect(() => {
    if (Object.values(initialValue ?? {}).length > 0) {
      notificationFilterDispatch?.({
        type: NotificationFilterActionType.All,
        payload: initialValue
      });
    }
  }, [initialValue, notificationFilterDispatch]);

  return (
    <NotificationFilterStateContext.Provider value={state}>
      <NotificationFilterDispatchContext.Provider value={dispatch}>
        {children}
      </NotificationFilterDispatchContext.Provider>
    </NotificationFilterStateContext.Provider>
  );
}

function useNotificationFilter(): NotificationFeedFilter {
  return useContext(NotificationFilterStateContext) as NotificationFeedFilter;
}

function useNotificationFilterUpdater(): React.Dispatch<NotificationFilterAction> {
  const notificationFilterDispatch = useContext(NotificationFilterDispatchContext);
  if (notificationFilterDispatch === undefined) {
    throw new Error('useNotificationFilterUpdater must be used within a NotificationFilterProvider');
  }
  return notificationFilterDispatch;
}

const withNotificationFilterProvider = (WrappedComponent, initialValue = null) => (props) => (
  <NotificationFilterProvider initialValue={initialValue}>
    <WrappedComponent {...props} />
  </NotificationFilterProvider>
);

export { NotificationFilterProvider, useNotificationFilter, useNotificationFilterUpdater, NotificationFilterStateContext,
  NotificationFilterDispatchContext, withNotificationFilterProvider };
