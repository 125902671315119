import { Colors, primaryFloatingFocus } from '@myblueprint-spaces/papier-core';
import styled from 'styled-components';
import { IRadioButtonContainerProps, RadioButtonBoxProps, RadioSizesType } from './types';

export const RadioButtonContainer = styled.div(({ align, validation, bottomMargin, disabled }: IRadioButtonContainerProps) => {
  return `
  position: relative;
  display: flex;
  flex-direction: ${align === 'right' ? 'row-reverse' : 'row'};
  align-items: center;
  outline: 0;
  margin-bottom: ${bottomMargin ? validation ? '0.25rem' : '1.5rem' : 0};
  cursor: ${disabled ? 'not-allowed' : 'pointer'};
`;
});

export const RadioButtonBox = styled('div').attrs(({ checked, hovered, focussed, active, validation }: RadioButtonBoxProps) => ({
  style: {
    ...(!checked && hovered) && { borderColor: Colors.black3 },
    ...(validation && { borderColor: validation.state === 'success' ? Colors.success1 : Colors.danger1 }),
    ...(focussed && !active) && { outline: 0, boxShadow: primaryFloatingFocus }
  }
}))((props: RadioButtonBoxProps) => {
  const { size, checked, disabled } = props;
  let width: number;
  let padding: number;

  switch (size) {
    case 'small':
      width = 0.5;
      padding = 0.1875;
      break;
    default:
    case 'medium':
      width = 0.5;
      padding = 0.1875;
      break;
    case 'large':
      width = 0.75;
      padding = 0.3125;
      break;
  }

  return `
  ${disabled ? 'pointer-events: none;' : ''}
  background-color: ${disabled ? Colors.grey4 : Colors.white};
  border: 0.0625rem solid ${(checked && !disabled) ? Colors.primary1 : Colors.grey1};
  border-radius: 100%;
  width: ${width + ((padding + 0.0625) * 2)}rem;
  height: ${width + ((padding + 0.0625) * 2)}rem;
  padding: ${padding}rem;
  margin: 0.25rem 0;
  transition: all 0.2s ease;
`;
});

export const RadioButtonControl = styled.input(({ radioSize, disabled }: { radioSize: RadioSizesType, disabled?: boolean }) => {
  let dimension: number;
  switch (radioSize) {
    case 'small':
      dimension = 1;
      break;
    default:
    case 'medium':
      dimension = 1;
      break;
    case 'large':
      dimension = 1.5;
      break;
  }
  return `
  margin: 0;
  opacity: 0;
  position: absolute;
  z-index: 1;
  ${disabled ? 'pointer-events: none;' : ''}
  width: ${dimension}rem;
  height: ${dimension}rem;
`;
});

export const RadioButtonLabel = styled.label(({ disabled }: { disabled?: boolean }) => `
  padding: 0 0.5rem;
  user-select: none;
  ${disabled ? 'pointer-events: none;' : ''}
  width: 100%;
`);
