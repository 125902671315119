import { Breakpoints } from '@myblueprint-spaces/papier-core';
import TooltipTrigger from 'src/Common/TooltipTrigger';
import styled from 'styled-components';
import { IWrappedTooltipTypographyProps } from './types';

export const StyledTooltipTrigger = styled(TooltipTrigger)(({ clamp, mediumUpOnly = true }: IWrappedTooltipTypographyProps) => {
  return  `
    @media (min-width: ${mediumUpOnly ? Breakpoints.medium : 0}em) {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: ${clamp ?? 2};
      -webkit-box-orient: vertical;
      overflow-wrap: break-word;
      hyphens: auto;
      -webkit-hyphens: auto
    }
  `;
});
