import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from '@myblueprint-spaces/modules';
import { Colors } from '@myblueprint-spaces/papier-core';
import LinkButton from '@myblueprint-spaces/papier-web/lib/Atoms/LinkButton';
import { Row, Column, RowColumn } from '@myblueprint-spaces/papier-web/lib/Common/Grid';
import useId from '@myblueprint-spaces/papier-web/lib/modules/hooks/useId';
import Typography from '@myblueprint-spaces/papier-web/lib/Common/Typography';
import Button from '@myblueprint-spaces/papier-web/lib/Common/Button';
import CovalentGrid from '@myblueprint-spaces/papier-web/lib/Atoms/CovalentGrid';
import Popover from '@myblueprint-spaces/papier-web/lib/Common/Popover';
import LoadingDots from '@myblueprint-spaces/papier-web/lib/Common/LoadingDots';
import Pill from '@myblueprint-spaces/papier-web/lib/Common/Pill';
import { BottomInfo, Wrapper, NotificationsTopWrapper } from './styles';
import withErrorBoundaries from '@myblueprint-spaces/papier-web/lib/modules/hocs/withErrorBoundaries';
import { INotificationsPopoverProps } from './types';
import { useNavigate } from 'react-router-dom';
import NotificationRefreshBar from '../NotificationsManager/components/NotificationRefreshBar';
import NotificationsManager from '../NotificationsManager';
import { useNotificationFilter } from '../NotificationFilterContext';
import NotificationsConfigContext from '../NotificationsConfigContext';

const NotificationsPopover = ({ open, triggerRef, markAllRead, currentScope, hasNewCount, unreadNotifications, hasNewWithContext, urls }: INotificationsPopoverProps) => {
  const notificationsId = useId();
  const { t } = useTranslation(['Common', 'Notifications.Common', 'Notifications.Server', 'Account.Teacher', 'Spaces.Common']);
  const { settings: settingsUrl, page: notificationsUrl, filteredPage: filteredPageUrl } = urls ?? {};
  const navigate = useNavigate();
  const notificationFilter = useNotificationFilter();
  const { closePopover: onClose } = useContext(NotificationsConfigContext);

  const handleClick = (url: string) => {
    onClose();
    navigate(url);
  };

  return (
    <Popover id={notificationsId} triggerPosition="bottom-left" menuPosition="top-right" triggerRef={triggerRef} open={open} onClose={onClose}
      hostAriaLabel={t('Common:Notifications')}>
      <Wrapper showRefresh={!!hasNewWithContext} viewAll={!!(hasNewCount && currentScope)} aria-labelledby={`${notificationsId}_notificationsTitle`} role="menuitem">
        <React.Suspense fallback={<LoadingDots />}>
          <NotificationsTopWrapper>
            <RowColumn expanded span>
              <Row align="center" verticalAlign="middle" expanded span>
                <Column collapse={false}>
                  <Typography id={`${notificationsId}_notificationsTitle`} type="subtitle1" color="black1" as="h1" weight="medium">{t('Common:Notifications')}</Typography>
                </Column>
                <Column shrink collapse={false}>
                  <CovalentGrid verticalAlign="middle">
                    {(!!unreadNotifications && unreadNotifications > 0) && <Button color="secondary" text={t('Notifications.Common:MarkAllRead')} expanded rounded
                      onClick={() => markAllRead(notificationFilter)} size="small" dataTest="notif-mark-all-read" />}
                    {settingsUrl && <Button color="secondary" icon="gear" title={t('Spaces.Common:Settings')} rounded
                      onClick={() => handleClick(settingsUrl)} dataTest="notification-popover-settings" />}
                  </CovalentGrid>
                </Column>
              </Row>
            </RowColumn>
          </NotificationsTopWrapper>
          {hasNewWithContext && <NotificationRefreshBar />}
          <RowColumn align="center" span style={{ background: Colors.grey4, margin: '0 0.0625rem' }}>
            <NotificationsManager small />
          </RowColumn>
          <BottomInfo align="center">
            <Column>
              <Button color="primary" text={currentScope && (hasNewCount ?? 0) > 0 ? format(t('Notifications.Common:ViewClassSpecific'), currentScope.displayName) : t('Notifications.Common:ViewAll')} expanded
                onClick={() => handleClick(currentScope ? filteredPageUrl : notificationsUrl)} size="small" dataTest="notification-view-all" wrap={false} />
            </Column>
          </BottomInfo>
          {currentScope && (hasNewCount ?? 0) > 0 && <BottomInfo verticalAlign="middle">
            <Column>
              <LinkButton onClick={() => handleClick(notificationsUrl)} dataTest="notification-view-all-classes">
                <Typography type="subtitle1" color="primary1">{t('Notifications.Common:ViewAllClasses')}</Typography>
              </LinkButton>
            </Column>
            <Column shrink>
              <Pill color="danger" text={hasNewCount.toString()} typographyProps={{ type: 'subtitle1' }} style={{ padding: '0 0.75rem' }} />
            </Column>
          </BottomInfo>}
        </React.Suspense>
      </Wrapper>
    </Popover>
  );
};

export default withErrorBoundaries(NotificationsPopover);
