import styled from 'styled-components';
import { Icon } from '../Icon/Icon';
import { Shadows, Colors } from '@myblueprint-spaces/papier-core';

export const TooltipTriggerContainer = styled.div(({ rounded, ellipsis }: { rounded?: boolean; ellipsis?: boolean }) => `
  display: inline-flex;
  margin: 0;
  
  ${ellipsis ? `
   overflow: hidden;
   white-space: nowrap;
  ` : ''}

  ${rounded ? `
    &:focus {
      outline: none;
      border-radius: 50%; 
      border: 0.0625rem solid ${Colors.primary1};
      margin: -0.0625rem;
      ${Shadows.primaryFocus};
    }
    & > span {
      display: inline-flex;
    }
  ` : ''}
`);

export const InlineIcon = styled(Icon)`
  display: inline-flex;
  vertical-align: baseline;
`;
