import React from 'react';
import NotificationsManager from './NotificationsManager';
import NotificationsSkeleton from './NotificationsSkeleton';
import NotificationHooks from '../../hooks';
import { useNotificationFilter } from '../NotificationFilterContext';
import { NotificationsManagerContainerProps } from './types';

const NotificationsManagerContainer = ({ small, ...rest }: NotificationsManagerContainerProps) => {
  const notificationFilter = useNotificationFilter();
  const toggleRead = NotificationHooks.useToggleRead();
  const loadMore = NotificationHooks.useLoadMoreNotifications();

  const notificationList = NotificationHooks.useNotifications(notificationFilter);
  const isAtEnd = NotificationHooks.useIsAtEnd(notificationFilter);

  if (typeof notificationList === 'undefined') return <NotificationsSkeleton small={!!small} />;

  const allProps = {
    ...rest,
    small,
    toggleRead,
    notificationList,
    loadMore,
    isAtEnd
  };

  return <NotificationsManager {...allProps} />;
};

export default NotificationsManagerContainer;
