import * as React from 'react';
import { ControlProps } from 'react-select/src';

const Control = (props: ControlProps): React.ReactElement => {
  const {
    children,
    cx,
    getStyles,
    className,
    isDisabled,
    isFocused,
    innerRef,
    innerProps,
    menuIsOpen,
    selectProps
  } = props;

  const additionalProps = {};
  Object.keys(selectProps).filter((key) => key.startsWith('data')).forEach((key) => {
    additionalProps[key] = selectProps[key];
  });

  return (
    <div
      ref={innerRef}
      style={getStyles('control', props)}
      {...additionalProps}
      className={cx(
        {
          control: true,
          'control--is-disabled': isDisabled,
          'control--is-focused': isFocused,
          'control--menu-is-open': menuIsOpen,
        },
        className
      )}
      {...innerProps}
    >
      {children}
    </div>
  );
};

export default Control;
