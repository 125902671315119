import { RefObject, useCallback, useContext, useRef, useState, useEffect, KeyboardEventHandler, MouseEventHandler } from 'react';
import { RovingTabIndexContext } from './Provider';
import { ActionTypes, PropsReturn, State } from './types';
import useFocusEffect from './useFocusEffect';
import useRovingTabIndex from './useRovingTabindex';

// isSelected:
//   - initialState
// disabled:
//   - can be updated as appropriate to reflect the current enabled or disabled
//     state of the component
// enterClicks/spaceClicks:
//   - boolean; If true, will trigger the click action when enter and/or space is pressed.
//   - Default: FALSE
// customKeyDownHandler:
//   - custom function for keyDown
// actionOnClick:
//   - custom function for click
// isGrid:
//   - if true, side arrows go to next item in line and side/down arrows move through lines
// id:
//   - an optional ID that is the unique ID for the component
//   - if provided then it must be a non-empty string
//   - if not provided then one will be autogenerated
//   - IMPORTANT: whenever passing `ignoreLoopEdges`, id is MANDATORY! not only that but you MUST ensure that ids are ALWAYS the same so you cannot use `useId` except if passing a FIXED prop
// The returned callbacks handleKeyDown and handleClick are stable.
export type AcceptableTypes = HTMLDivElement | HTMLButtonElement | HTMLAnchorElement | HTMLHRElement | HTMLLIElement | HTMLTableCellElement | HTMLElement;
export default function useRovingTabIndexProps<T extends AcceptableTypes>({
  isSelected = false,
  ref = null,
  disabled = false,
  enterClicks,
  spaceClicks,
  customKeyDownHandler,
  actionOnClick,
  isGrid = undefined,
  id = undefined
}: {
  ref?: RefObject<T> | null;
  isSelected?: boolean;
  disabled: boolean;
  enterClicks?: boolean;
  spaceClicks?: boolean;
  customKeyDownHandler?: (e: KeyboardEvent) => void;
  actionOnClick?: (e?: Event) => void;
  isGrid?: boolean | undefined;
  id?: string | undefined;
}): PropsReturn<T> {
  const newRowRef = useRef<T>(ref as unknown as T);
  const rowRef = ref || newRowRef;

  const [tabIndex, focused, handleKeyDown, handleClick] = useRovingTabIndex(
    rowRef, disabled, isGrid, id, enterClicks, spaceClicks
  );
  const { state, dispatch } = useContext(RovingTabIndexContext);
  const { lastActionOrigin } = state || {} as State;

  const [isFocussed, setIsFocussed] = useState(isSelected);
  const [focusHoverEvent, setFocusHoverEvent] = useState(isSelected);
  const prevIsFocussed = useRef(false);
  const [isHovered, setIsHovered] = useState(false);
  useFocusEffect(focused, rowRef);

  useEffect(() => {
    if (lastActionOrigin !== 'mouse' && prevIsFocussed.current !== isFocussed) {
      dispatch({
        type: ActionTypes.LAST_ACTION_ORIGIN,
        payload: 'keyboard'
      });
      prevIsFocussed.current = isFocussed;
    }
  }, [isFocussed]);

  useEffect(() => {
    const newFocusHoverEvent = !disabled && (!lastActionOrigin || lastActionOrigin === 'system' ? (isFocussed || isHovered)
      : lastActionOrigin === 'mouse' ? isHovered
        : isFocussed);
    setFocusHoverEvent(newFocusHoverEvent);
  }, [lastActionOrigin, isHovered, isFocussed, disabled]);

  const handleFocus = useCallback(() => {
    setIsFocussed(true);
  }, [setIsFocussed]);

  const handleBlur = useCallback(() => {
    setIsFocussed(false);
  }, [setIsFocussed]);

  const handleMouseOver = useCallback(() => {
    setIsHovered(true);
  }, [setIsHovered]);

  const handleMouseOut = useCallback(() => {
    setIsHovered(false);
  }, [setIsHovered]);

  const keyDownHandle = (e: KeyboardEvent) => {
    customKeyDownHandler?.(e);
    handleKeyDown(e);
  };

  const memoizedOnClick = useCallback((e) => {
    actionOnClick?.(e);
  }, [actionOnClick]);

  const clickHandle = (e?: Event) => {
    handleClick();
    memoizedOnClick(e);
  };

  const itemProps = {
    onFocus: handleFocus,
    onBlur: handleBlur,
    onMouseOver: handleMouseOver,
    onMouseOut: handleMouseOut,
    onKeyDown: keyDownHandle as unknown as KeyboardEventHandler<T>,
    ref: rowRef,
    tabIndex: tabIndex,
    focusHoverEvent,
    onClick: clickHandle as unknown as MouseEventHandler<T>,
    isHovered,
    isFocussed,
    disabled
  } as PropsReturn<T>;

  if (id) {
    itemProps.id = id;
  }

  return itemProps;
}
