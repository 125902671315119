import * as React from 'react';
import * as PropTypes from 'prop-types';
import { Row, Column } from 'src/Common/Grid';
import Typography from 'src/Common/Typography';
import Button from 'src/Common/Button';
import { DialogSize } from '../../types';
import Dialog from '../../Dialog';
import { ContentWrapper, ImageWrapper, SubtextWrapper, ButtonContainer, ButtonWrapper } from '../styles';
import { IConfirmProps } from './types';
import { withWindowSize } from 'src/modules';
import { Breakpoints } from '@myblueprint-spaces/papier-core';

const Confirm = (props: IConfirmProps) => {
  const { title, subtext, image, type: type = 'info', okButtonProps, cancelButtonProps, onClose, children, windowSize, ...otherProps } = props;
  const dialogProps = {
    title,
    size: 'small' as DialogSize,
    onClose,
    ...otherProps
  };
  const isSmall = windowSize.width < Breakpoints.medium;
  return (
    <Dialog {...dialogProps}>
      <ContentWrapper>
        {image && <ImageWrapper>{image}</ImageWrapper>}
        <Typography as="h1" type={ isSmall ? 'body2' : 'header4'} weight="demibold"
          color={type === 'error' ? 'danger1' : 'black1'}>
          {title}
        </Typography>
        {subtext && <SubtextWrapper>
          <Typography as="p" type={ isSmall ? 'subtitle1': 'body2'} color="black2">{subtext}</Typography>
        </SubtextWrapper>}
        <ButtonContainer>
          <Row collapse>
            <Column>
              <ButtonWrapper position="left">
                <Button color="secondary" expanded onClick={onClose} size={ isSmall ? 'small': 'medium'} {...cancelButtonProps} dataTest="dialog-cancel" />
              </ButtonWrapper>
            </Column>
            <Column>
              <ButtonWrapper position="right">
                <Button expanded size={ isSmall ? 'small': 'medium'}
                  color={type === 'error' ? 'danger' : type === 'success' ? 'success' : 'primary'} dataTest="dialog-okay"
                  {...okButtonProps} />
              </ButtonWrapper>
            </Column>
          </Row>
        </ButtonContainer>
      </ContentWrapper>
    </Dialog>
  );
};

Confirm.defaultProps = {
  type: 'info'
};

Confirm.propTypes = {
  title: PropTypes.string.isRequired,
  subtext: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  image: PropTypes.element,
  okButtonProps: PropTypes.object,
  cancelButtonProps: PropTypes.object,
  onClose: PropTypes.func,
  type: PropTypes.oneOf(['info', 'error', 'success'])
};

export default withWindowSize(Confirm as React.FunctionComponent<IConfirmProps>);
