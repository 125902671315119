import styled from 'styled-components';
import { Breakpoints, Colors } from '@myblueprint-spaces/papier-core';

export const RightButtonWrapper = styled.div`
  margin: 1.5rem 0 0 0.5rem;
`;

export const LeftButtonWrapper = styled.div`
  margin: 1.5rem 0.5rem 0 0;
`;


export const ButtonContainer = styled.div `
  margin: 2rem 0;
`;

export const UpperWrapper = styled.div`
  padding: 0 2.5rem 0;
  text-align: center;
`;

export const ConfirmWrapper = styled.div`
  width: 100%;
  background-color: ${Colors.grey4};
  padding: 1.5rem 2.5rem;
  margin: 1rem 0;
  @media (max-width: ${Breakpoints.medium}em) {
    padding: 1rem 2.5rem;
  }
`;

export const LowerWrapper = styled.div`
  padding: 0 2.5rem 2.5rem;
  text-align: center;
`;
