import React from 'react';
import PropTypes from 'prop-types';
import Typography from 'src/Common/Typography';
import { FontWeightNames, FontWeights } from '@myblueprint-spaces/papier-core';
import { CSSProperties } from 'styled-components';

const MarkdownStrong = ({ children, boldWeight = 'medium' }: { children: React.ReactNode, boldWeight: FontWeightNames }): React.ReactElement => {
  return <Typography weight={boldWeight} as="strong" style={{ weight: `${FontWeights[boldWeight]} !important` } as CSSProperties}>{children}</Typography>;
};

MarkdownStrong.propTypes = {
  children: PropTypes.any,
  boldWeight: (props, propName, componentName) => {
    const propValue = props[propName];
    if (!propValue) {
      return;
    } else if (!FontWeights[propValue]) {
      return new Error(`Invalid prop '${propName}' supplied to '${componentName}'. Expected a valid weight.`);
    }
  }
};

export default MarkdownStrong;
