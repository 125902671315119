import { FontTypeNames } from '@myblueprint-spaces/papier-core';
import Typography from 'src/Common/Typography';
import * as React from 'react';
import TooltipBox from './components/TooltipBox';
import { ITooltipProps } from './types';
import useId from 'src/modules/hooks/useId';
import { isFocusable } from 'src/modules/browsers/focusManager';

const Tooltip = ({ children, dark = true, size, trigger, triggerId, onShow, onHide, ...otherProps }: ITooltipProps): React.ReactElement => {
  const [visible, setVisible] = React.useState(false);
  const id = useId();

  const hideTooltip = React.useCallback((e) => {
    const removeId = e?.detail?.removeId;

    if (!e || !removeId || e && removeId === id) {
      setVisible(false);
      if (onHide) onHide();
    }
  }, [onHide, id]);

  const showTooltip = React.useCallback(() => {
    setVisible(true);

    if (onShow) onShow();
  }, [onShow]);

  React.useEffect(() => {
    const triggerElem = trigger && trigger.current ? trigger.current : document.getElementById(triggerId);

    if (triggerElem) {
      // TODO: only show on development
      if (!isFocusable(triggerElem)) {
        // eslint-disable-next-line no-console
        console.error('In order to use a tooltip, the trigger component MUST be a focusable element and/or have tabindex >= 0 explicity declared');
      }

      triggerElem.addEventListener('mouseenter', showTooltip);
      triggerElem.addEventListener('focus', showTooltip);
      triggerElem.addEventListener('mouseleave', hideTooltip);
      triggerElem.addEventListener('blur', hideTooltip);
    }

    return () => {
      triggerElem?.removeEventListener('focus', showTooltip);
      triggerElem?.removeEventListener('blur', hideTooltip);
      triggerElem?.removeEventListener('mouseenter', showTooltip);
      triggerElem?.removeEventListener('mouseleave', hideTooltip);
    };
  }, [trigger, triggerId, showTooltip, hideTooltip]);

  React.useEffect(() => {
    const triggerElem = trigger && trigger.current ? trigger.current : document.getElementById(triggerId);

    if (visible) {
      triggerElem?.setAttribute('aria-controls', id);
      triggerElem?.setAttribute('aria-describedby', id);
    } else {
      triggerElem?.removeAttribute('aria-controls');
      triggerElem?.removeAttribute('aria-describedby');
    }
  }, [trigger, triggerId, visible]);

  let textSize: FontTypeNames;
  switch (size) {
    case 'small':
      textSize = 'subtitle1';
      break;
    default:
    case 'medium':
    case 'large':
      textSize = 'body2';
      break;
    case 'xlarge':
      textSize = 'body1';
      break;
  }

  return (
    <TooltipBox
      id={id}
      trigger={trigger}
      triggerId={triggerId}
      open={visible}
      size={size}
      dark={dark}
      onForcedClose={hideTooltip}
      role="tooltip"
      triggerPosition="bottom-middle"
      menuPosition="top-middle"
      offsetY={8} {...otherProps}>
      <Typography type={textSize} color={dark ? 'white' : 'black1'}>
        {children}
      </Typography>
    </TooltipBox>
  );
};

export default Tooltip;
