import { Grid, Breakpoints, BreakpointNames, flexAlignMap, IColumnProps } from '@myblueprint-spaces/papier-core';
import styled from 'styled-components';

const valuePrecision = 3;

const ColumnElem = styled.div((props: IColumnProps & { gridColumns?: number }) => {
  const { offset, columns, shrink, verticalAlign, collapse, gridColumns = Grid.columns } = props;
  // Base CSS for columns with gutters
  let baseColumnString = `
    margin: 0;
    padding-right: ${collapse ? 0 : Grid.gutter + 'rem'};
    padding-left: ${collapse ? 0 : Grid.gutter + 'rem'};
    min-width: 0;
    box-sizing: border-box;

    &:before {
      box-sizing: border-box;
    }
    &:after {
      box-sizing: border-box;
    }
  `;

  if (verticalAlign) {
    baseColumnString += `align-self: ${flexAlignMap[verticalAlign]};`;
  }

  if (offset) {
    Object.keys(offset).map((col: BreakpointNames) => {
      const columnValue = offset[col];
      const size = ((100 / gridColumns) * columnValue).toFixed(valuePrecision);

      const offsetVal = `margin-left: ${size}%;`;

      if (col === 'small') {
        baseColumnString += offsetVal;
      } else {
        baseColumnString += `
          @media print, screen and (min-width: ${Breakpoints[col]}em) {
            ${offsetVal}
          }
        `;
      }
    });
  }

  if (columns) {
    // box-flex 0 causes it to follow a fixed width
    let responsiveColumn = baseColumnString;
    let hasHidden = false;
    Object.keys(columns).map((col: BreakpointNames) => {
      hasHidden = false;
      const columnValue = columns[col];
      let size = '';
      // Numeric column value = occupy that many columns
      if (typeof columnValue === 'number') {
        if (columnValue === 0) {
          size = 'display: none;';
          hasHidden = true;
        } else {
          // Calculate the width in % and truncate the numeric digits
          const width = ((100 / gridColumns) * columnValue).toFixed(valuePrecision);
          size = `
            -webkit-box-flex: 0;
            -webkit-flex: 0 0 ${width}%;
                -ms-flex: 0 0 ${width}%;
                    flex: 0 0 ${width}%;
            max-width: ${width}%;
          `;
        }
      } else if (columnValue === 'expand') {
        size = `
          -webkit-box-flex: 1;
          -webkit-flex: 1 1 0px;
              -ms-flex: 1 1 0px;
                  flex: 1 1 0px;
        `;
      } else if (columnValue === 'shrink') {
        size = `
          -webkit-box-flex: 0;
          -webkit-flex: 0 0 auto;
              -ms-flex: 0 0 auto;
                  flex: 0 0 auto;
          max-width: 100%;
        `;
      }

      if (col === 'small') {
        responsiveColumn += size;
      } else {
        responsiveColumn += `
          @media print, screen and (min-width: ${Breakpoints[col]}em) {
            ${size}
            ${hasHidden ? '' : 'display: block;'}
          }
        `;
      }
    });

    return responsiveColumn;
  } else if (shrink) {
    return `
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 auto;
          -ms-flex: 0 0 auto;
              flex: 0 0 auto;
      max-width: 100%;
      ${baseColumnString}
    `;
  }

  // Automatic flex to distribute remaining space
  return `
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 0px;
        -ms-flex: 1 1 0px;
            flex: 1 1 0px;
    ${baseColumnString}
  `;
});

export { ColumnElem };
