import * as React from 'react';
import { IPortalProps, IPortalRefChild } from '@myblueprint-spaces/papier-web/lib/modules/hocs/withPortal';
import { IIconProps } from '@myblueprint-spaces/papier-web/lib/Common/Icon';
export const ToastSizes = ['small', 'medium', 'large', 'xlarge'];
export type ToastSizesType = 'small' | 'medium' | 'large' | 'xlarge';

export interface IToastBaseProps {
  size?: ToastSizesType;
  theme?: 'light' | 'dark';
}

export interface IToastElementProps extends IToastBaseProps {
  id?: string;
}

export interface IToast extends IToastElementProps {
  text?: string;
  subtext?: string;
  icon?: IIconProps;
  timeout?: number;
  onAction?: (id: string | undefined, closeToast: () => void) => void;
  action?: string;
  width?: string;
  onUnmount?: () => void;
  top?: boolean;
  canClose?: boolean;
  size?: ToastSizesType;
}

export interface IToastProps extends IToast {
  removeToast: () => void;
  style: React.CSSProperties;
}

export interface IToastManagerState {
  toasts: IToast[];
}
export interface IToastIcon {
  id?: string;
  size?: ToastSizesType;
  canClose?: boolean;
}

export type ToastProps = IToast & IPortalProps & IPortalRefChild;

export type ToastManagerProps = Partial<Omit<ToastProps, 'id'>>;
