import { ButtonColorsType, ColorNames } from '@myblueprint-spaces/papier-core';
import React from 'react';
import { CSSProperties } from 'styled-components';
import { IIconProps } from '../Icon';

export const TagSizes = ['small', 'regular'];
export type TagSizesType = 'small' | 'regular';

export interface ITagStyleProps {
  size?: TagSizesType;
  rounded?: boolean;
  color?: ButtonColorsType;
  style?: CSSProperties;
  disabled?: boolean;
}

export interface ITagBaseProps extends Omit<ITagStyleProps, 'color'> {
  ref?: React.Ref<HTMLButtonElement | HTMLDivElement>;
  color?: ButtonColorsType | {
    text: ColorNames;
    removeIcon: ColorNames;
    icon?: ColorNames;
  };
  text: string;
  icon?: string | IIconProps;
  onRemove?: (e?: unknown) => void;
  onClick?: (e?: unknown) => void;
  dataTest?: string;
}

// Tags should work as a single element. Even when the tag has a close to remove we should not have a senario where there's click _and_ a remove
type ClickProps<T = Pick<ITagBaseProps, 'onRemove'>, V = Pick<ITagBaseProps, 'onClick'>> =
  // onRemove = exists => onClick can't exist
  { [K in keyof T]-? : T[K] } & { [Q in keyof V]: never}
  // onClick = exists => onRemove can't exist
  | { [X in keyof V]-? : V[X] } & { [W in keyof T]: never}
  | { [A in keyof V]: never } & { [B in keyof T]: never};

export type ITagProps = ITagBaseProps & ClickProps;
