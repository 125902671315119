import { flexAlignMap, flexJustifyMap, IGridContext, IRowProps } from '@myblueprint-spaces/papier-core';
import * as PropTypes from 'prop-types';
import * as React from 'react';
import GridContext from '../Shared/Context';
import { IGridCommonProps } from '../Shared/types';
import { RowElem } from './styles';

type ForwardedRow = IRowProps & IGridCommonProps;
export type RowProps = (IRowProps & IGridCommonProps) | ForwardedRow;

const Row = (props: RowProps) => {
  return (
    <GridContext.Consumer>
      {({ collapse, nested }: IGridContext) => {
        const { collapse: propCollapse, wrap: propWrap, forwardRef } = props as ForwardedRow;
        const c = typeof propCollapse !== 'undefined' ? propCollapse : collapse;
        const wrap = typeof propWrap !== 'undefined' ? propWrap : true;
        return (
          <GridContext.Provider value={{ collapse: c, nested: true }}>
            <RowElem {...props} ref={forwardRef} wrap={wrap} collapse={c} nested={nested} />
          </GridContext.Provider>
        );
      }}
    </GridContext.Consumer>
  );
};
Row.defaultProps = {
  wrap: true
};

Row.propTypes = {
  expanded: PropTypes.bool,
  collapse: PropTypes.bool,
  wrap: PropTypes.bool,
  span: PropTypes.bool,
  align: PropTypes.oneOf(Object.keys(flexJustifyMap)),
  verticalAlign: PropTypes.oneOf(Object.keys(flexAlignMap))
};

const RefedRow = React.forwardRef<HTMLDivElement, ForwardedRow>((props, ref) => <Row {...props} forwardRef={ref} />);

export default RefedRow;
