import * as PropTypes from 'prop-types';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { generateId } from '..';
import withPortal, { IPortalProps } from './withPortal';

export type Positions = 'top-left' | 'top-middle' | 'top-right'
  | 'middle-left' | 'middle-middle' | 'middle-right'
  | 'bottom-left' | 'bottom-middle' | 'bottom-right';

interface IRelativePosition {
  top: number;
  left: number;
}

export interface IRelativePositionProps {
  open: boolean;
  trigger?: React.RefObject<HTMLElement>;
  menuPosition: Positions;
  triggerPosition: Positions;
  offsetX?: number;
  offsetY?: number;
  triggerId?: string;
  ignoreFitCheck?: boolean;
  style?: React.CSSProperties;
  onDisplay?: (e: boolean) => void;
}

interface IRelativePositionState {
  top: number;
  left: number;
  offsetActual: IRelativePosition;
  menuHeight?: number;
  display: boolean;
}

interface IRelativePositionComponentProps {
  style: IRelativePosition;
  offsetActual: IRelativePosition;
  ref: React.RefObject<HTMLElement>;
}

// TODO: address eslint
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const withRelativePosition = (id?: string, removeOthers: string = null) => <OriginalProps extends object>(WrappedComponent: React.ComponentType<OriginalProps & IRelativePositionComponentProps & any>):
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  React.ForwardRefExoticComponent<Pick<any, string | number | symbol> & React.RefAttributes<React.ComponentType<any>>> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  type HocProps = OriginalProps & IRelativePositionProps & IPortalProps & any;
  class RelativePositionHOC extends React.Component<HocProps, IRelativePositionState> {
    public static propTypes = {
      open: PropTypes.bool,
      trigger: PropTypes.shape({ current: PropTypes.element }),
      menuPosition: PropTypes.string,
      triggerPosition: PropTypes.string,
      triggerId: PropTypes.string,
      ignoreFitCheck: PropTypes.bool,
      onDisplay: PropTypes.func
    };
    public static defaultProps = {
      menuPosition: 'top-left',
      triggerPosition: 'top-left'
    };

    private componentRef: React.RefObject<HTMLElement> = React.createRef();
    private componentId: string;

    public constructor(props: HocProps) {
      super(props);
      const { id } = props;

      this.state = {
        left: 0,
        top: 0,
        offsetActual: {
          top: 0,
          left: 0
        },
        menuHeight: null,
        display: false
      };

      this.componentId = id || generateId();
      this.onWindowChange = this.onWindowChange.bind(this);
    }

    public componentDidMount() {
      const { open } = this.props;
      document.addEventListener('resize', this.onWindowChange);
      document.addEventListener('orientationchange', this.onWindowChange);
      if (open) {
        this.positionUpdate();
      }
    }

    public componentWillUnmount() {
      document.removeEventListener('resize', this.onWindowChange);
      document.removeEventListener('orientationchange', this.onWindowChange);
    }

    public componentDidUpdate(prevProps, prevState) {
      const { open, menuPosition, triggerPosition, onDisplay, rootNode } = this.props;
      const { display } = this.state;

      if (!prevProps.open && open) {
        this.positionUpdate();
      }
      if (prevProps.open && !open) {
        this.setState({ display: false });
      } else if (!prevProps.open && open) {
        this.setState({ display: true }, () => {
          if (removeOthers && rootNode) {
            const rootId = rootNode.getAttribute('id');
            const newPortal = rootNode.querySelector(`[id=${rootId}${id}]`);

            if (newPortal) {
              const visibleChildren = Array.from(newPortal.childNodes).filter((c: HTMLElement) => c.style?.visibility === 'visible');
              visibleChildren.forEach((vC: HTMLElement) => {
                if (vC?.id !== this.componentId) {
                  const e = new CustomEvent(removeOthers, { detail: { removeId: vC?.id } });
                  document.dispatchEvent(e);
                }
              });
            }
          }
        });
      } else if (open && (prevProps.menuPosition !== menuPosition || prevProps.triggerPosition !== triggerPosition)) {
        this.positionUpdate();
      }
      if (display && !prevState.display) {
        onDisplay && onDisplay(display);
      }
    }

    public getPosition() {
      const { trigger, triggerId, menuPosition, triggerPosition, offsetX = 0, offsetY = 0, ignoreFitCheck, rootNode } = this.props;
      const triggerElement = trigger && trigger.current ? trigger.current : document.getElementById(triggerId);
      const { top: oldTop, left: oldLeft } = this.state;
      const triggerRelativePosition: { top: number; bottom: number; left: number; right: number } = { top: oldTop, bottom: 0, left: oldLeft, right: 0 };

      const rootNodeRect = rootNode.getBoundingClientRect();
      const scrollY = window.scrollY;

      if (this.componentRef.current && triggerElement) {
        const menuRect = this.componentRef.current.getBoundingClientRect();
        const triggerRect = triggerElement.getBoundingClientRect();

        triggerRelativePosition.top = triggerRect.top - rootNodeRect.top;
        triggerRelativePosition.bottom = triggerRect.bottom - rootNodeRect.top;
        triggerRelativePosition.left = triggerRect.left - rootNodeRect.left;
        triggerRelativePosition.right = triggerRect.right - rootNodeRect.left;

        let [menuVertical, menuHorizontal] = menuPosition.split('-');
        let [triggerVertical, triggerHorizontal] = triggerPosition.split('-');

        let top: number;
        let left: number;
        let menuStartingPoint;

        /* *********

          Menu Position will align menu's left/right with the trigger's provided left/right.
          tigger-right menu-left
          trigger-top menu-bottom
                    __________
          _________|__menu____|
          | trigger|
        */
        menuStartingPoint = triggerVertical == 'top' ? triggerRelativePosition.top : triggerVertical == 'bottom'
          ? triggerRelativePosition.bottom : triggerRelativePosition.top + (triggerRect.height / 2);

        if (!ignoreFitCheck) {
          if (menuVertical == 'top'
            && menuStartingPoint + offsetY + menuRect.height >= rootNode.clientHeight + scrollY) {
            menuVertical = 'bottom';
            triggerVertical = 'top';
          } else if (menuVertical == 'bottom'
            && menuStartingPoint - offsetY - menuRect.height < scrollY) {
            menuVertical = 'top';
            triggerVertical = 'bottom';
          }
        }

        menuStartingPoint = triggerHorizontal == 'left' ? triggerRelativePosition.left : triggerHorizontal == 'right'
          ? triggerRelativePosition.right : triggerRelativePosition.left + (triggerRect.width / 2);

        if (!ignoreFitCheck) {
          if (menuHorizontal == 'left'
            && menuStartingPoint + offsetX + menuRect.width >= rootNode.clientWidth) {
            menuHorizontal = 'right';
            triggerHorizontal = 'left';
          } else if (menuHorizontal == 'right'
            && menuStartingPoint - offsetX - menuRect.width < scrollY) {
            menuHorizontal = 'left';
            triggerHorizontal = 'right';
          }
        }

        switch (triggerVertical) {
          default:
          case 'top':
            top = triggerRelativePosition.top - offsetY;
            break;
          case 'middle':
            top = triggerRelativePosition.top + (triggerRect.height / 2);
            break;
          case 'bottom':
            top = triggerRelativePosition.bottom + offsetY;
            break;
        }
        switch (triggerHorizontal) {
          default:
          case 'left':
            left = triggerRelativePosition.left - offsetX;
            break;
          case 'middle':
            left = triggerRelativePosition.left + (triggerRect.width / 2);
            break;
          case 'right':
            left = triggerRelativePosition.right + offsetX;
            break;
        }
        switch (menuVertical) {
          default:
          case 'top':
            // no changes top = top;
            break;
          case 'middle':
            top = top - (menuRect.height / 2);
            break;
          case 'bottom':
            top = top - menuRect.height;
            break;
        }
        switch (menuHorizontal) {
          default:
          case 'left':
            // no changes left = left;
            break;
          case 'middle':
            left = left - (menuRect.width / 2);
            break;
          case 'right':
            left = left - menuRect.width;
            break;
        }
        top = Math.max(top, 0);
        left = Math.max(left, 0);
        const actualVerticalCenter = top + menuRect.height;
        const windowMaxHeight = rootNode.clientHeight + scrollY;
        const resultantTop = Math.min(top, actualVerticalCenter, windowMaxHeight);
        const actualHorizontalCenter = left + menuRect.width;
        const windowMaxWidth = rootNode.clientWidth - menuRect.width;
        const resultantLeft = Math.min(left, actualHorizontalCenter, windowMaxWidth);

        return {
          top: resultantTop,
          left: resultantLeft,
          offsetActual: {
            top: actualVerticalCenter - resultantTop,
            middle: actualHorizontalCenter - resultantLeft - menuRect.width + (menuRect.width / 2),
            left: actualHorizontalCenter - resultantLeft
          },
          menuHeight: menuRect.height,
          display: true
        };
      }
    }

    public positionUpdate = () => {
      // guarantee that menu will have dimensions > 0
      window.setTimeout(() => this.positionUpdate(), 200);
      const position = this.getPosition();
      if (position) {
        this.setState(position);
      }
    }

    public render() {
      const { portal, open, menuPosition, triggerPosition, style, ...rest } = this.props;
      const { top, left, offsetActual, display } = this.state;
      const styling = { top, left, visibility: display ? 'visible' : 'hidden', ...(style || {}) };

      return open && ReactDOM.createPortal(<WrappedComponent style={styling} offsetActual={offsetActual} ref={this.componentRef} open={open} {...rest} id={this.componentId} />, portal);
    }

    private onWindowChange() {
      window.requestAnimationFrame(this.positionUpdate);
    }
  }

  return withPortal(id)(RelativePositionHOC as React.ComponentClass<HocProps>);
};

export default withRelativePosition;
