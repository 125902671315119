import CleanButton from 'src/Atoms/CleanButton';
import CovalentGrid from 'src/Atoms/CovalentGrid';
import LinkButton from 'src/Atoms/LinkButton';
import * as PropTypes from 'prop-types';
import * as React from 'react';
import Icon from '../Icon';
import Typography from '../Typography';
import { IconContainer, InfoBarContainer, SubtextContainer, TextContainer } from './styles';
import { IActionProps, IInfoBarProps, InfoBarBorderColors } from './types';
import { IconSizesType } from '../Icon/types';
import { FontTypeNames } from '@myblueprint-spaces/papier-core';

const BorderAndIconColor: { [key: string]: InfoBarBorderColors } = {
  info: 'primary1',
  success: 'success1',
  error: 'danger1',
  note: 'grey1'
};

const IconType = {
  info: 'info',
  success: 'check',
  error: 'warning',
  note: 'info'
};

const IconSize: { [key: string]: IconSizesType } = {
  xsmall: 'small',
  small: 'small',
  medium: 'medium',
  large: 'medium',
  xlarge: 'large'
};

const FontType: { [key: string]: FontTypeNames } = {
  xsmall: 'subtitle1',
  small: 'subtitle1',
  medium: 'body2',
  large: 'body2',
  xlarge: 'body1'
};

const InfoBar = (props: IInfoBarProps): React.ReactElement => {
  const {
    type = 'info',
    size = 'medium',
    text,
    subtext,
    onClose,
    action,
    dataTest = 'content',
    iconAlignment = 'middle'
  } = props;

  const { onClick, dataTest: actionDataTest = 'infobar-action', text: actionText } = (action ?? {} as IActionProps);

  return (
    <InfoBarContainer color={BorderAndIconColor[type]} size={size} data-test={`infobar-${dataTest}`}>
      <CovalentGrid collapse verticalAlign={iconAlignment}>
        <IconContainer size={size}>
          <Icon size={IconSize[size]} color={BorderAndIconColor[type]} type={IconType[type]} removeMargin />
        </IconContainer>
        <CovalentGrid align="right" verticalAlign="middle">
          <TextContainer>
            <Typography
              as="p"
              type={FontType[size]}
              color="black1">
              {text}
            </Typography>
          </TextContainer>
          {
            onClose
              ? <CleanButton onClick={onClose}>
                <Icon type="close" size={IconSize[size]} color="black1" />
              </CleanButton>
              : action
                ? <LinkButton type={FontType[size]} color="primary1" weight="medium" onClick={onClick} dataTest={actionDataTest}>{actionText}</LinkButton>
                : null
          }
        </CovalentGrid>
      </CovalentGrid>
      {
        subtext
            && <SubtextContainer size={size}>
              <Typography
                as="p"
                type={['xsmall', 'small'].includes(size)
                  ? 'subtitle1'
                  : 'body2'}
                color="black3">
                {subtext}
              </Typography>
            </SubtextContainer>
      }
    </InfoBarContainer>
  );
};

InfoBar.propTypes = {
  type: PropTypes.oneOf(['info', 'error', 'success', 'note']),
  size: PropTypes.oneOf(['small', 'medium', 'large', 'xlarge']),
  text: PropTypes.string.isRequired,
  subtext: PropTypes.string,
  onClose: PropTypes.func,
  action: PropTypes.shape({
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
  })
};

export default InfoBar;
