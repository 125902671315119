import * as PropTypes from 'prop-types';
import * as React from 'react';
import { BaseButton } from '../../styles';
import { ButtonColors, ButtonSizes, IIconButtonProps, getTextIconSizeByButtonSize } from '@myblueprint-spaces/papier-core';
import { IButtonCustomProps } from '../../types';
import { PureIconElem } from 'src/Common/Icon/styles';

const IconButton = ({ id, icon, size, color, disabled, rounded, type = 'button', expanded, forwardRef, hover, dataTest, ...props }: IIconButtonProps & IButtonCustomProps) => {
  const { iconSize } = getTextIconSizeByButtonSize(size);

  return (
    <BaseButton ref={forwardRef} type={type} id={id} size={size} color={color} disabled={disabled} rounded={rounded} hover={hover} data-test={dataTest} {...props}>
      <PureIconElem className="iconContent" type={icon} size={iconSize} />
    </BaseButton>
  );
};

IconButton.propTypes = {
  color: PropTypes.oneOf(ButtonColors),
  size: PropTypes.oneOf(ButtonSizes),
  id: PropTypes.string,
  icon: PropTypes.string,
  disabled: PropTypes.bool,
  rounded: PropTypes.bool,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  dataTest: PropTypes.string
};

export default React.memo(React.forwardRef<HTMLButtonElement, IIconButtonProps & IButtonCustomProps>((props, ref) => <IconButton forwardRef={ref} {...props} />));
