import CleanButton from 'src/Atoms/CleanButton';
import Icon from 'src/Common/Icon';
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DropdownSizesType } from '../types';

const ClearIndicator = (size: DropdownSizesType): void | React.ReactNode => {
  const SizedClearIndicator = ({ innerProps, selectProps, isFocused, clearValue }) => {
    const { t } = useTranslation();
    let iconSize: 'small' | 'medium' | 'large';
    switch (size) {
      case 'small':
        iconSize = 'small';
        break;
      case 'xlarge':
        iconSize = 'large';
        break;
      default:
        iconSize = 'medium';
        break;
    }

    const keyDown = React.useCallback((e) => {
      if (selectProps.isDisabled) {
        return;
      }

      if (e.keyCode === 32 || e.keyCode === 13) { // Space or Enter
        clearValue();
      }
    }, [clearValue, selectProps.isDisabled]);

    return (
      <CleanButton {...innerProps} title={t('Papier.Dropdown:Clear')} onKeyDown={keyDown}>
        <Icon type="close" size={iconSize} color={isFocused ? 'primary1' : 'black3'} />
      </CleanButton>
    );
  };

  SizedClearIndicator.propTypes = {
    innerProps: PropTypes.object,
    selectProps: PropTypes.object,
    isFocused: PropTypes.bool,
    clearValue: PropTypes.func
  };

  return SizedClearIndicator;
};

export default ClearIndicator;
