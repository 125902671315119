import styled from 'styled-components';
import { Colors, shadow1, shadow2 } from '@myblueprint-spaces/papier-core';
import CleanButton from '@myblueprint-spaces/papier-web/lib/Atoms/CleanButton';

export const StyledRegionButton = styled(CleanButton)(({ iconOnly, rounded, expanded } : { iconOnly?: boolean, rounded ?: boolean, expanded?: boolean }) => `
  background-color: ${Colors.white};
  padding: ${iconOnly ? '0.25rem' : '0.25rem 1rem'};
  border-radius: ${rounded ? '2' : '0.25'}rem;
  box-shadow: ${shadow1};
  cursor: pointer;
  text-align: center;
  border: none;
  transition: all 0.2s ease-in-out;
  outline: none;
  position: relative;
  flex-wrap: wrap;
  ${expanded ? 'width: 100%;' : ''}

  &:hover {
    border-color: ${Colors.white};
    box-shadow: ${shadow2};
  }

  &:active {
    background-color: ${Colors.grey2};
    border-color: #${Colors.grey2};
    box-shadow: none;
  }
`);

export const ButtonTextWrapper = styled.div`
  margin-left: 0.5rem;
`;

export const StyledIcon = styled.img`
  display: block;
  width: 2rem;
  height: 2rem;
`;
