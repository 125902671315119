import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@myblueprint-spaces/papier-web/lib/Common/Typography';
import { Row, Column } from '@myblueprint-spaces/papier-web/lib/Common/Grid';
import { StyledRegionButton, ButtonTextWrapper, StyledIcon } from './styles';
import { getRegionAsset } from '../helper';
import { RegionButtonProps } from './types';

const RegionButton = ({ regionConfig, iconOnly = false, forwardRef, rounded = false, ...rest }: RegionButtonProps & { forwardRef: React.ForwardedRef<HTMLButtonElement>}) => {
  const { nameKey, key } = regionConfig;
  const { t } = useTranslation(['Common', 'Spaces.Common']);

  return (
    <StyledRegionButton
      ref={forwardRef}
      iconOnly={iconOnly}
      rounded={rounded}
      {...rest}>
      <Row
        align="center"
        verticalAlign="middle"
        collapse>
        <Column shrink>
          <StyledIcon
            aria-hidden="true"
            src={getRegionAsset(key)} />
        </Column>
        {!iconOnly
          && <Column shrink>
            <ButtonTextWrapper>
              <Typography
                type="body2"
                color="black1"
                weight="medium">
                {t(`Spaces.Common:${nameKey}`)}
              </Typography>
            </ButtonTextWrapper>
          </Column>}
      </Row>
    </StyledRegionButton>
  );
};

export default React.forwardRef((props: RegionButtonProps, ref: React.ForwardedRef<HTMLButtonElement>) => <RegionButton {...props} forwardRef={ref} />);
