import { ButtonColors, ButtonSizes, ITextButtonProps, getTextIconSizeByButtonSize } from '@myblueprint-spaces/papier-core';
import { PureTypography } from 'src/Common/Typography/styles';
import { PureIconElem } from 'src/Common/Icon/styles';
import * as PropTypes from 'prop-types';
import * as React from 'react';
import { BaseButton, IconTextButtonIconWrapper, TextButtonWrapper } from '../../styles';
import { IButtonCustomProps } from '../../types';

const TextButton = ({ id, text, type = 'button', size, color, disabled, rounded, expanded, icon, forwardRef, iconPosition='left', hover, dataTest, wrap, align, ...props }: ITextButtonProps & IButtonCustomProps) => {
  const { textSize, iconSize } = getTextIconSizeByButtonSize(size);

  const iconElem = (
    <IconTextButtonIconWrapper text={text} size={size} iconPosition={iconPosition} icon={icon} align={align}>
      <PureIconElem className="iconContent" type={icon} size={iconSize} />
    </IconTextButtonIconWrapper>
  );

  return (
    <BaseButton ref={forwardRef} type={type} id={id} {...props} size={size} color={color} disabled={disabled} rounded={rounded} expanded={expanded} hover={hover} data-test={dataTest} wrap={wrap}>
      <TextButtonWrapper size={size} icon={icon} text={text} wrap={wrap} align={align}>
        {icon && iconPosition=='left' && iconElem}
        <PureTypography className="typographyContent" type={textSize} weight="medium" ellipsis={!wrap}>{text}</PureTypography>
        {icon && iconPosition=='right' && iconElem}
      </TextButtonWrapper>
    </BaseButton>
  );
};

TextButton.defaultProps = {
  wrap: true
};

TextButton.propTypes = {
  color: PropTypes.oneOf(ButtonColors),
  size: PropTypes.oneOf(ButtonSizes),
  id: PropTypes.string,
  icon: PropTypes.string,
  disabled: PropTypes.bool,
  rounded: PropTypes.bool,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  wrap: PropTypes.bool
};

export default React.memo(React.forwardRef<HTMLButtonElement, ITextButtonProps & IButtonCustomProps>((props, ref) => <TextButton {...props} forwardRef={ref} />));
