import * as React from 'react';
import { StyledTooltipTrigger } from './styles';
import Tooltip from '../Tooltip';
import { IWrappedTooltipTypographyProps } from './types';

const WrappedTooltipTypography = (props: IWrappedTooltipTypographyProps): React.ReactElement => {
  const { tooltipProps = {}, text } = props;
  const typographyRef = React.useRef();
  return (
    <React.Fragment>
      <StyledTooltipTrigger ref={typographyRef} {...props} />
      <Tooltip trigger={typographyRef} {...tooltipProps}>{text}</Tooltip>
    </React.Fragment>
  );
};

export default WrappedTooltipTypography;
