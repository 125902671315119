import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Typography from '@myblueprint-spaces/papier-web/lib/Common/Typography';
import { Row, Column } from '@myblueprint-spaces/papier-web/lib/Common/Grid';
import { StyledRegionPopoverItem, StyledIcon, ButtonTextWrapper } from './styles';
import { getRegionAsset } from '../helper';


const RegionPopoverItem = ({ regionConfig, onClick }) => {
  const { nameKey, key } = regionConfig;
  const { t } = useTranslation(['Spaces.Common']);

  return (
    <StyledRegionPopoverItem role="menuitem" onClick={() => onClick(regionConfig)}>
      <Row
        verticalAlign="middle"
        collapse>
        <Column shrink>
          <StyledIcon
            alt={t(`Spaces.Common:${nameKey}`).toString()}
            src={getRegionAsset(key)} />
        </Column>
        <Column shrink>
          <ButtonTextWrapper>
            <Typography
              type="body2"
              color="black1"
              weight="medium">
              {t(`Spaces.Common:${nameKey}`)}
            </Typography>
          </ButtonTextWrapper>
        </Column>
      </Row>
    </StyledRegionPopoverItem>
  );
};

RegionPopoverItem.propTypes = {
  regionConfig: PropTypes.shape({
    nameKey: PropTypes.string.isRequired,
    key: PropTypes.oneOf(['us', 'ca', 'intl']).isRequired
  }).isRequired,
  onClick: PropTypes.func.isRequired
};

export default RegionPopoverItem;
