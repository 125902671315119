export enum Region {
  UnitedStates,
  Canada,
  International
}

export interface RegionConfig {
  id: Region;
  key: string;
  nameKey: string;
  host: string;
  order: number;
}

// @ts-expect-error Set by executing environment
const RegionConfigs = Object.fromEntries(window.RegionConfigs.map((c) => [c.id, c]));

export const getAllRegionConfigs = () => Object.values(RegionConfigs);

// eslint-disable-next-line no-restricted-properties
export const getHostRegion = () => getAllRegionConfigs().find((rConfig) => rConfig.host === window.location.host.toLowerCase());

export const getRegionByKey = (regionKey) => getAllRegionConfigs().find((regionConfig) => regionConfig.key === regionKey);

// eslint-disable-next-line no-restricted-properties
export const getCurrentRegion = () => localStorage.getItem('chosen-region') || getHostRegion()?.id || Region.UnitedStates;

export const getRegionConfig = (region) => RegionConfigs[region] || RegionConfigs[Region.UnitedStates];
