export const onReceiveMessage = (onError, saveExternalUserData, onSignUp, saveAuthenticationData, onAuthenticated, openedWindow?, userClosedWindow?, onClose?) => {
  return (event) => {
    const { data } = event ?? {};
    const { sender, success, message, payload } = data ?? {};

    if (data && sender === 'myBlueprint') {
      try {
        if (typeof success !== 'boolean') {
          onError?.('errNoEventData', event);
          return Promise.reject();
        } else if (message === 'ErrUserNotExists') {
          saveExternalUserData(payload);
          onSignUp?.(payload);
        } else if (!success) {
          onError?.(data);
        } else {
          const { expiry, ...rest } = data;
          saveAuthenticationData({ ...rest, expiry: typeof expiry === 'string' ? new Date(expiry) : expiry });
          onAuthenticated?.();
        }
        return Promise.resolve();
      } catch (e) {
        onError?.(e);
      } finally {
        if (userClosedWindow) userClosedWindow.current = false;
        openedWindow?.close();
        onClose?.();
      }
    }
  };
};
