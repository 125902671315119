import React, { useContext } from 'react';
import { Confirm } from '@myblueprint-spaces/papier-web/lib/Common/Dialog';
import { useTranslation } from 'react-i18next';
import withErrorBoundaries from '@myblueprint-spaces/papier-web/lib/modules/hocs/withErrorBoundaries';
import AuthLoadingContext from 'src/Common/Contexts/AuthLoadingContext';
import TermsOfAgreement from 'src/Common/TermsOfAgreement';

const Terms = ({ visible, onClose, initiateLogin }: { visible: boolean, onClose: () => void, initiateLogin: () => void }) => {
  const { setLoading } = useContext(AuthLoadingContext);
  const { t } = useTranslation(['Common', 'LoginSignUp.Common']);

  return (
    <Confirm
      visible={visible}
      size="medium"
      title={t('LoginSignUp.Common:GoogleTerms.Title')}
      subtext={<TermsOfAgreement />}
      onClose={onClose}
      okButtonProps={{
        text: t('LoginSignUp.Common:GoogleTerms.Agree'),
        onClick: () => {
          onClose();
          setLoading(true);
          initiateLogin();
        }
      }}
      cancelButtonProps={{
        text: t('Common:Answers.No')
      }} />
  );
};

export default withErrorBoundaries(Terms);
