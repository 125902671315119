import * as React from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function ComplexI18n({ i18nKey, children, i18nParams }: { i18nKey: string, children: React.ReactNode | React.ReactNode[], i18nParams?: Record<string, unknown> }): React.ReactElement {
  const [set, key] = i18nKey.split(':');
  const { t } = useTranslation(set);
  const translateString = i18nParams ? t(key, i18nParams) : t(key);
  const magicRegex = /<\/?[0-9]*>/;
  const parts = translateString.split(magicRegex);
  const replacements = React.Children.toArray(children);
  return (
    <React.Fragment>
      {parts.map((p, i) => {
        if (i % 2 == 0) {
          return p;
        }
        const elem = replacements[(i-1)/2];
        return React.cloneElement(elem as React.ReactElement<Record<string, unknown>, string | React.JSXElementConstructor<Record<string, unknown>>>, { children: p });
      })}
    </React.Fragment>
  );
}

ComplexI18n.propTypes = {
  i18nKey: PropTypes.string,
  children: PropTypes.any,
  i18nParams: PropTypes.object
};

export default ComplexI18n;
