import { getButtonAppearance, Shadows } from '@myblueprint-spaces/papier-core';
import CleanButton from 'src/Atoms/CleanButton';
import styled from 'styled-components';
import { ITagStyleProps } from './types';

const TagStyles = ({ size, rounded, color, disabled }: ITagStyleProps) => {
  const btnAppearance = getButtonAppearance(color);

  const backgroundColor = disabled ? btnAppearance.disabledBackgroundColor : btnAppearance.backgroundColor;
  return `
    outline: 0;
    display: inline-block;
    background-color: ${backgroundColor};
    border-radius: ${rounded ? size === 'small' ? '0.75rem' : '1rem' : '0.25rem'};
    padding: ${rounded ? size === 'small' ? '0.125rem 0.75rem' : '0.375rem 0.75rem' : size === 'small' ? '0.125rem 0.5rem' : '0.375rem 0.5rem'};

    :focus {
      ${Shadows.primaryFloatingFocus};
    }
  `;
};

export const TagImage = styled.div`
  margin-right: 0.125rem;
`;

export const TagContainer = styled.div(TagStyles);

export const ButtonTagContainer = styled(CleanButton)(TagStyles);

export const TagText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
