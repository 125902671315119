import React, { useCallback, useEffect, useContext } from 'react';
import LoadingGif from '@myblueprint-spaces/papier-web/lib/Common/LoadingGif';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AuthenticationActions } from '@myblueprint-spaces/redux/lib/authentication';
import { ThunkDispatch } from 'redux-thunk';
import { IState } from '@myblueprint-spaces/redux';
import { Action } from 'redux';
import AppConfigContext from '../Contexts/AppConfigContext';
import { Product } from '@myblueprint-spaces/redux/lib/api/common/globals/enums';
import ProductContext from '../Contexts/ProductContext';

const Impersonate = () => {
  const { productType } = useContext(ProductContext);
  const { search } = useLocation();
  const urlSearch = new URLSearchParams(search);
  const { token, returnUrl } = Object.fromEntries(urlSearch.entries());
  const { appLinks: { spacesUrl, adminUrl } } = useContext(AppConfigContext);
  const appUrl = productType === Product.Spaces ? spacesUrl : adminUrl;
  const fallBackAccountUrl = productType === Product.Spaces ? '/teacher' : '/admin';
  const navigate = useNavigate();

  const dispatch = useDispatch<ThunkDispatch<IState, unknown, Action>>();
  const impersonate = useCallback(
    (impersonationToken: string) => dispatch(AuthenticationActions.impersonate(impersonationToken)),
    [dispatch]
  );
  useEffect(() => {
    if (!token || Array.isArray(token)) {
      navigate('/', { replace: true });
      return;
    }

    const ret = new URL(Array.isArray(returnUrl) ? returnUrl[0] : returnUrl ?? fallBackAccountUrl, appUrl);
    impersonate(token).then(() => {
      navigate(ret.pathname, { replace: true });
    });
  }, [navigate]);

  return <LoadingGif />;
};

export default Impersonate;
