import * as React from 'react';
import * as PropTypes from 'prop-types';
import { ResponsiveChangelingFn } from 'src/Atoms/ResponsiveChangeling';
import IconButton from 'src/Common/Button/components/IconButton';
import { IResponsiveIconButtonProps } from './types';
import { withWindowSize, IWindowSizeProps } from 'src/modules';
import { IIconButtonProps, ButtonSizeType } from '@myblueprint-spaces/papier-core';
import { IButtonCustomProps } from '../../types';


type ResponsiveIconButtonProps = IResponsiveIconButtonProps & IWindowSizeProps & IButtonCustomProps;
export const ResponsiveIconButton = ({ windowSize, forwardRef, dataTest, ...props }: ResponsiveIconButtonProps): React.ReactElement => {
  const buttonSize = ResponsiveChangelingFn({
    small: 'small',
    medium: 'medium',
    windowSize: windowSize
  }) as ButtonSizeType;
  return <IconButton ref={forwardRef} size={buttonSize} rounded={true} {...props} dataTest={dataTest} />;
};

ResponsiveIconButton.propTypes = {
  windowSize: PropTypes.object
};

export const WrappedResponsiveIconButton = withWindowSize(ResponsiveIconButton as React.ComponentType<ResponsiveIconButtonProps>);

export default React.forwardRef<HTMLButtonElement, IIconButtonProps & IButtonCustomProps>((props, ref) => <WrappedResponsiveIconButton {...props} forwardRef={ref} />);
