
import * as PropTypes from 'prop-types';
import * as React from 'react';

import { ColorNames, FontTypeNames } from '@myblueprint-spaces/papier-core';
import CleanButton from '@myblueprint-spaces/papier-web/lib/Atoms/CleanButton';
import CovalentGrid from '@myblueprint-spaces/papier-web/lib/Atoms/CovalentGrid';
import Typography from '@myblueprint-spaces/papier-web/lib/Common/Typography';
import LinkButton from '@myblueprint-spaces/papier-web/lib/Atoms/LinkButton';
import Icon, { IconSizesType } from '@myblueprint-spaces/papier-web/lib/Common/Icon';
import useTimeout from '@myblueprint-spaces/papier-web/lib/modules/hooks/useTimeout';
import { isElementIdFocused } from '@myblueprint-spaces/papier-web/lib/modules/browsers/focusManager';
import { useTranslation } from 'react-i18next';
import FocusTrap from '@myblueprint-spaces/focus-trap-react';

import { ToastActionContent, ToastElement, ToastContent, ToastIcon } from '../styles';
import { IToastProps } from '../types';

export function Toast({ id, size = 'large', text, subtext, theme = 'light', removeToast: originalRemoveToast, timeout = 5000, icon, action, onAction, canClose, onUnmount, ...rest }: IToastProps) {
  let typographyType: FontTypeNames;
  let iconSize: IconSizesType;
  const [returnFocus, setReturnFocus] = React.useState<Element | null>(null);

  const removeToast = React.useCallback(() => {
    originalRemoveToast();
    onUnmount?.();
  }, [originalRemoveToast, onUnmount]);

  useTimeout(() => {
    // Ids used here might start with digits, querySelector cannot find element with ids starting with digits. https://stackoverflow.com/a/51396346/3326192
    // if mouse is not hovering toast, there are focus but on the element itself not on children, toast can be removed
    if (!isElementIdFocused(id) || (onAction !== null && !document.querySelector('[id="' + id + '"]:hover')
      && document.querySelector('[id="' + id + '"]:focus'))) {
      removeToast();
    } else if (id) {
      const elem = document.getElementById(id);
      if (elem) {
        elem.onmouseleave = () => {
          removeToast();
        };
      }
    }
  }, timeout);
  const { t } = useTranslation();

  switch (size) {
    case 'small':
      typographyType = 'subtitle1';
      iconSize = 'small';
      break;
    case 'xlarge':
      typographyType = 'body1';
      iconSize = 'large';
      break;
    case 'medium':
    case 'large':
    default:
      typographyType = 'body2';
      iconSize = 'medium';
      break;
  }

  const handleClick = (evt) => {
    // keyboard click
    if (evt.screenX === 0 && evt.screenY === 0) {
      onAction && (returnFocus as HTMLElement)?.focus();
    }
    removeToast();
  };

  const closeButton = (canClose || onAction) && (
    <ToastIcon size={size}>
      <CleanButton onClick={handleClick} title={onAction === null ? '' : t('Common:Actions.Close').toString()} aria-hidden={onAction === null ? 'true' : 'false'} data-test="action-close">
        <Icon type="close" size={iconSize} color={theme === 'light' ? 'black1' : 'white'} />
      </CleanButton>
    </ToastIcon>
  );

  const iconElem = icon && (
    <ToastIcon size={size}>
      <Icon {...icon} size={iconSize} />
    </ToastIcon>
  );

  const linkButtonAction = React.useCallback(() => onAction?.(id, removeToast), [id, onAction, removeToast]);
  let colorConfig = { text: 'black1', button: 'primary1' } as { text: ColorNames, button: ColorNames };

  if (theme !== 'light') {
    colorConfig = { text: 'grey2', button: 'white' };
  }

  const toastElem = (
    <ToastElement size={size} id={id} theme={theme} role={onAction ? 'alert' : 'presentation'} {...rest} tabIndex={0} data-test="toast">
      <CovalentGrid align="right" verticalAlign="middle">
        <CovalentGrid verticalAlign="middle">
          {iconElem}
          <ToastContent data-test="toast-content">
            <Typography type={typographyType} color={colorConfig.text}>
              {text}
            </Typography>
            {subtext
              && <Typography type="subtitle1" color={colorConfig.text}>
                {subtext}
              </Typography>}
          </ToastContent>
        </CovalentGrid>
        <CovalentGrid verticalAlign="middle">
          <ToastActionContent>
            {action && <LinkButton type={typographyType} color={colorConfig.button} weight="medium" onClick={linkButtonAction} dataTest="toast-action">{action}</LinkButton>}
          </ToastActionContent>
          {canClose && closeButton}
        </CovalentGrid>
      </CovalentGrid>
    </ToastElement>
  );


  return onAction ? <FocusTrap focusTrapOptions={{ initialFocus: '#' + id, allowOutsideClick: () => true, returnFocusOnDeactivate: false, onActivate: () => setReturnFocus(document.activeElement) }}>{toastElem}</FocusTrap> : toastElem;
}

Toast.propTypes = {
  id: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  text: PropTypes.string,
  theme: PropTypes.oneOf(['light', 'dark']),
  canClose: function (props, propName, componentName) {
    if (props.onAction && props[propName] === false) {
      return new Error(`canClose will be ignored when using onAction on ${componentName}.`);
    } else if (props[propName] && typeof props[propName] !== 'boolean') {
      return new Error(`canClose should be boolean on ${componentName}.`);
    }
  },
  onAction: function (props, propName, componentName) {
    if (props.action && !props[propName]) {
      return new Error(`onAction is required when using action prop on ${componentName}.`);
    } else if (props[propName] && typeof props[propName] !== 'function') {
      return new Error(`onAction should be a function on ${componentName}.`);
    }
  },
  action: function (props, propName, componentName) {
    if (props.onAction && !props[propName]) {
      return new Error(`Action is required when using onAction prop on ${componentName}.`);
    } else if (props[propName] && typeof props[propName] !== 'string') {
      return new Error(`Action should be a string on ${componentName}.`);
    }
  },
  timeout: PropTypes.number,
  icon: PropTypes.object
};

Toast.defaultProps = {
  canClose: true
};

export default React.memo(Toast as React.FunctionComponent<IToastProps>);
