import { ColorNames } from '@myblueprint-spaces/papier-core';
import { CSSProperties } from 'styled-components';

export interface IIconProps {
  size?: IconSizesType;
  color?: ColorNames;
  type: string;
  inline?: boolean;
  removeMargin?: boolean;
  style?: CSSProperties;
  id?: string;
  className?: string;
  tabIndex?: number;
}

export const IconSizes = ['small', 'medium', 'large'];
export type IconSizesType = 'small' | 'medium' | 'large';
