import withToggleable from 'src/Atoms/withToggleable';
import FormValidation from 'src/Common/FormValidation';
import Icon from 'src/Common/Icon';
import Typography from 'src/Common/Typography';
import * as React from 'react';
import { CheckboxBox, CheckboxContainer, CheckboxLabel, CheckboxControl } from './styles';
import { ICheckboxProps, IReducedCheckboxProps } from './types';

// onChange is removed because it is handled by the toggeables HOC
function Checkbox(props: ICheckboxProps) {
  const { id, label, labelId, labelColor = 'black3', size, checked, disabled, onChange, hovered, active, focussed, validation, dataTest, alignItems, forwardRef, hideCheckBoxControl = false, ...rest } = props;
  const checkboxSize = size || 'medium';
  const labelSize = size === 'small' ? 'subtitle1' : 'body2';
  const labelClr = disabled ? 'grey1' : labelColor;

  const iconColor = disabled
    ? checked
      ? 'grey2'
      : 'grey4'
    : hovered
      ? checked
        ? 'primary1' : 'grey2'
      : validation
        ? checked
          ? validation.state === 'success'
            ? 'success1' : 'danger1'
          : 'white'
        : checked
          ? 'primary1' : 'white';


  return (
    <React.Fragment>
      <CheckboxContainer data-test={`check-container-${dataTest}`} validation={validation} alignItems={alignItems}>
        {!hideCheckBoxControl && <CheckboxControl checked={checked} checkboxSize={checkboxSize} disabled={disabled} id={id} type="checkbox" data-test={`check-${dataTest}`} ref={forwardRef} {...rest} />}
        <CheckboxBox size={checkboxSize} checked={checked} hovered={hovered} active={active} disabled={disabled} focussed={focussed} validation={validation}>
          <Icon type="checkmark" size="small" color={iconColor} removeMargin />
        </CheckboxBox>
        {label && (
          <CheckboxLabel data-test={`check-label-${dataTest}`} htmlFor={id}>
            <Typography id={labelId} type={labelSize} color={labelClr}>{label}</Typography>
          </CheckboxLabel>
        )}
      </CheckboxContainer>
      {validation && <FormValidation data-test={`check-validation-${dataTest}`} {...validation} size={size === 'small' ? size : 'medium'} />}
    </React.Fragment>
  );
}

Checkbox.defaultProps = {
  bottomMargin: true
};

const MemoedCheckbox = React.memo(withToggleable<ICheckboxProps>(Checkbox as React.FunctionComponent<IReducedCheckboxProps>));

export default React.forwardRef<HTMLInputElement, IReducedCheckboxProps>((props, ref) => <MemoedCheckbox {...props} forwardRef={ref as React.RefObject<HTMLInputElement>} />);
