import { Shadows, Colors, ITypographyProps, ColorNames } from '@myblueprint-spaces/papier-core';
import { WebTypography } from 'src/Common/Typography/styles';
import * as React from 'react';
import styled from 'styled-components';

type ILinkProps = { underline?: boolean } & ITypographyProps & Partial<Omit<React.HTMLProps<HTMLAnchorElement>, 'as' | 'ref'>>;

export const StyledLink = styled(WebTypography)(({ underline, disabled }: { underline?: boolean, disabled?: boolean }) => `
  text-decoration: ${underline ? 'underline' : 'none'};
  border-radius: 0.25rem;
  transition: box-shadow 0.2s ease-in-out;
  position: relative;
  color: ${disabled ? Colors.black3 : Colors.primary1};
  &:focus:not(:active) {
    ${Shadows.primaryFloatingFocus}
  }

  &:visited {
    color: ${disabled ? Colors.black3 : Colors.primary1};
  }

  &::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }
`);

function ExternalLink({ forwardRef, ...rest }: ILinkProps & { forwardRef?: React.ForwardedRef<HTMLAnchorElement> }): React.ReactElement {
  const mergedProps = {
    color: 'primary1' as ColorNames,
    ref: forwardRef,
    ...rest
  };
  return (
    <StyledLink {...mergedProps} target="_blank" rel="noopener" as="a" />
  );
}

export const MemoedRefedExternalLink = React.memo(ExternalLink);

const RefedExternalLink = React.forwardRef<HTMLAnchorElement, ILinkProps>((props, ref) => <MemoedRefedExternalLink {...props} forwardRef={ref} />);

export default RefedExternalLink;

