import React, { createContext, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SkipToMain from '@myblueprint-spaces/papier-web/lib/Atoms/SkipToMain';

export interface IMainContentContext {
  keyboardMode: boolean;
  setContentId: React.Dispatch<React.SetStateAction<string | null>>;
}

const MainContentContext = createContext<IMainContentContext>({} as IMainContentContext);

const MainContentContextProvider = ({ children }: { children: React.ReactChild | React.ReactChildren }) => {
  const [contentId, setContentId] = useState(null);
  const [keyboardMode, setKeyboardMode] = useState(false);
  const { t } = useTranslation('Common');

  const handleMouseClick = (e) => {
    // clicks triggered by code should not invalidate keyboardMode
    setKeyboardMode((km) => km && !e.isTrusted);
  };

  const handleKeyDown = (e) => {
    const tabKey = 9;
    if (e.keyCode === tabKey) {
      setKeyboardMode(true);
    }
  };

  useLayoutEffect(() => {
    window.addEventListener('click', handleMouseClick);
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('click', handleMouseClick);
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const value = React.useMemo(() => ({ setContentId, keyboardMode }), [setContentId, keyboardMode]);

  return (
    <MainContentContext.Provider value={value}>
      {contentId && <div role="region" aria-labelledby="skipToMain">
        <SkipToMain id="skipToMain" href={`#${contentId}`}>{t('Common:SkipToMainContent')}</SkipToMain>
      </div>}
      {children}
    </MainContentContext.Provider>
  );
};

export { MainContentContextProvider };
export default MainContentContext;
