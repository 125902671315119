import { ColorNames } from '@myblueprint-spaces/papier-core';

export const ProgressBarSizes = ['small', 'medium', 'large', 'xlarge'];
export type ProgressBarSizesType = 'small' | 'medium' | 'large' | 'xlarge';

export interface IProgressBarProps {
  id?: string;
  value: number;
  max: number;
  size?: ProgressBarSizesType;
  color?: ColorNames;
}
