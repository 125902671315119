import * as React from 'react';

type ITimeoutCallback = () => void;

export default function useTimeout(callback: ITimeoutCallback, delay: number): void {
  const savedCallback: React.MutableRefObject<ITimeoutCallback> = React.useRef(null);

  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  React.useEffect(() => {
    const tick = () => savedCallback.current();

    if (typeof delay === 'number' && delay !== null) {
      const id = setTimeout(tick, delay);
      return () => clearTimeout(id);
    }
  }, [delay]);
}
