import { ITypographyProps, ValidationFontType } from '@myblueprint-spaces/papier-core';
import Typography from 'src/Common/Typography';
import * as PropTypes from 'prop-types';
import * as React from 'react';
import { FormValidationContainer } from './styles';

export interface IFormValidationProps {
  message: string | null,
  state: string,
  size: string
}

export function FormValidation({ message, state, size, ...rest }: IFormValidationProps & ITypographyProps): React.ReactElement {
  const isSuccess = state === 'success';
  return message
    ? (
      <FormValidationContainer>
        <Typography type={ValidationFontType[size]} color={isSuccess ? 'success1' : 'danger1'} {...rest}>{message}</Typography>
      </FormValidationContainer>
    )
    : null;
}

FormValidation.propTypes = {
  size: PropTypes.oneOf(['small', 'medium']),
  message: PropTypes.any,
  state: PropTypes.oneOf(['success', 'error'])
};

export default React.memo(FormValidation);
