import { useSelector } from 'react-redux';
import { IState } from '@myblueprint-spaces/redux';
import { AuthenticationSelectors } from '@myblueprint-spaces/redux/lib/authentication';

export default function useExternalAuthenticate(provider?: string | null) {
  return useSelector((state: IState) => {
    if (!provider) return undefined;
    return AuthenticationSelectors.getExternalAuthenticate(state, provider);
  });
}
