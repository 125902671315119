import React, { createContext, useCallback, useContext, useEffect, useMemo } from 'react';
import { Notification, NotificationDisplayData, NotificationFeedFilter, NotificationType } from '@myblueprint-spaces/redux/lib/notifications';
import { NotificationFilterAction, NotificationFilterActionType, useNotificationFilterUpdater, withNotificationFilterProvider } from './NotificationFilterContext';
import ProductContext from 'src/Common/Contexts/ProductContext';
import { Product } from '@myblueprint-spaces/redux/lib/api/common/globals/enums';
import useMemoCompare from '@myblueprint-spaces/papier-web/lib/modules/hooks/useMemoCompare';

export interface INotificationsConfigContext {
  getItemAdditionalInfo?: (item: Notification & { displayData?: NotificationDisplayData }) => React.ReactNode;
  getNotificationInteraction?: (location, item) => { url: string; action?: () => void } | null;
  getSpecialAvatar?: (type: NotificationType) => { url: string; mimetype?: string } | null;
  changeFilter: (filter: NotificationFilterAction) => void;
  initialFilter?: NotificationFeedFilter;
  closePopover?: (type?: NotificationType) => void;
}

const NotificationsConfigContext = createContext<INotificationsConfigContext>({} as INotificationsConfigContext);

const NotificationsConfigContextProvider = withNotificationFilterProvider((props: { children: React.ReactNode } & Omit<INotificationsConfigContext, 'changeFilter'>) => {
  const { children, initialFilter, getNotificationInteraction, getItemAdditionalInfo, getSpecialAvatar, closePopover } = props;
  const { productType } = useContext(ProductContext);
  // Spaces should always have a filter. No filter means it is not yet properly loaded, so we default to null to avoid all calls to redux.
  const usedFilter = useMemoCompare(
    initialFilter ?? (productType === Product.Admin ? { allClassrooms: true, allPortfolios: true } : productType === Product.Spaces ? null : {})
    , (p, n) => JSON.stringify(p) === JSON.stringify(n));
  const notificationFilterUpdater = useNotificationFilterUpdater();

  const changeFilter = useCallback((filter: NotificationFilterAction) => {
    notificationFilterUpdater(filter);
  }, [notificationFilterUpdater]);

  useEffect(() => {
    usedFilter && notificationFilterUpdater({ type: NotificationFilterActionType.All, payload: usedFilter });
  }, [usedFilter, notificationFilterUpdater]);

  const value = useMemo(() => ({
    changeFilter,
    getNotificationInteraction,
    getItemAdditionalInfo,
    getSpecialAvatar,
    closePopover
  }), [changeFilter, getNotificationInteraction, getItemAdditionalInfo, getSpecialAvatar, closePopover]);

  return (
    <NotificationsConfigContext.Provider value={value}>
      {children}
    </NotificationsConfigContext.Provider>
  );
});

export default NotificationsConfigContext;
export { NotificationsConfigContextProvider };
