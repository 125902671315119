import styled from 'styled-components';
import { Colors } from '@myblueprint-spaces/papier-core';
import { IIconSkeletonDivProps } from './types';
import React from 'react';

export const IconSkeletonDiv: React.FunctionComponent<IIconSkeletonDivProps> = styled('div').attrs(({ diameter }: IIconSkeletonDivProps) => ({
  style: {
    width: diameter,
    height: diameter
  }
}))`
  border-radius: 50%;
  background-color: ${Colors.grey2};
`;
