import React from 'react';

export interface IState {
  error: boolean;
  count: number;
}

const withErrorCustomHandling = <P, >(WrappedComponent: React.ComponentType<P>) => {
  return class WithBoundaries extends React.Component<P, IState> {
    public constructor(props) {
      super(props);

      this.state = {
        error: null,
        count: 0
      };
    }

    public componentDidCatch(error) {
      this.setState(({ count }) => ({ error: error, count: count + 1 }));
    }

    public render() {
      const { error, count } = this.state;

      if(error && count > 3) {
        console.error(error, 'ERROR: Panel component tried to render 3x and crashed', this.props);
        return <div>Something Went Wrong. Please Refresh page.</div>;
      }

      return <WrappedComponent {...this.props} error={error} />;
    }
  };
};

export default withErrorCustomHandling;
