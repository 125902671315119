import * as React from 'react';
import { IIntersectionObserverComponentProps, ObservingElements } from './withIntersectionObserver';

interface IIntersectionObserverComponentState {
  elements: ObservingElements[];
  previousY: number;
}

export interface IEventParamCallback {
  detail: {
    stuck: boolean;
    target: HTMLElement;
  };
}

class IntersectionObserverScrollDirectionComponent extends React.PureComponent<IIntersectionObserverComponentProps, IIntersectionObserverComponentState> {
  private io;

  private fireEvent = (stuck, target) => {
    const { elements } = this.state;
    const e = new CustomEvent('viewport-position-change' + elements?.[0]?.id, { detail: { stuck, target } });
    document.dispatchEvent(e);
  };

  public constructor(props) {
    super(props);

    this.io = new IntersectionObserver(
      (entries) => {
        const { previousY } = this.state;
        const currentY = entries[0].boundingClientRect.y;
        if (previousY === currentY) return;
        const contentScrollDirection = previousY < currentY ? 'down' : 'up';
        const sentinelId = contentScrollDirection === 'down' ? 'sentinel--top' : 'sentinel--bottom';
        // down : up
        const currentEntry = entries.find((e) => e.target?.id.indexOf(sentinelId) > -1);
        const rootBoundsInfo = currentEntry?.rootBounds;
        if (currentEntry && (currentEntry.isIntersecting || currentEntry.intersectionRatio < 0.25)) {
          this.fireEvent(true, currentEntry.target);
        } else {
          // isIntersecting has issues on Edge, and doesn't work 100% of the times.
          // The solution below, alone, also doesn't work 100% of times because the method might trigger before the <= / >= is true.
          if (contentScrollDirection === 'up') {
            if (currentEntry && rootBoundsInfo && currentEntry.boundingClientRect.bottom <= rootBoundsInfo.top) {
              this.fireEvent(true, currentEntry.target);
            }
          } else {
            if (currentEntry && rootBoundsInfo && currentEntry.boundingClientRect.bottom >= rootBoundsInfo.bottom) {
              this.fireEvent(true, currentEntry.target);
            }
          }
        }

        this.setState({ previousY: currentY });
      },
      {
      }
    );
    this.observe = this.observe.bind(this);
    this.unobserve = this.unobserve.bind(this);
    this.state = { elements: [], previousY: 0 };
  }

  public observe(id: string, callback) {
    const { elements } = this.state;
    if (elements.find((e) => e?.id === id)) {
      return; // Do nothing already being observed
    }
    const elem = document.getElementById(id);
    if (!elem) return; // Do nothing, non-existent element

    const sentinelTop = document.createElement('div');
    sentinelTop.setAttribute('style', 'position: absolute;left: 0;right: 0;visibility: hidden;');
    sentinelTop.setAttribute('id', id + 'sentinel--top');

    const sentinelBottom = document.createElement('div');
    sentinelBottom.setAttribute('style', 'position: absolute;left: 0;right: 0;visibility: hidden;');
    sentinelBottom.setAttribute('id', id + 'sentinel--bottom');

    elem.parentNode.insertBefore(sentinelTop, elem);
    this.io.observe(sentinelTop);

    // If there's no next next sibling, it will still position it after the elem
    elem.parentNode.insertBefore(sentinelBottom, elem.nextSibling);
    this.io.observe(sentinelBottom);

    document.addEventListener('viewport-position-change' + id, callback);
    this.setState({ elements: elements.concat({ id: id, callback: callback }) });
  }

  public unobserve(id) {
    const element = document.getElementById(id + 'sentinel--top');
    if (element) {
      this.io.unobserve(element);
    }

    this.destroy(id);
  }

  private destroy(id) {
    const { elements } = this.state;

    const elem = elements.find((e) => e?.id === id);
    if (elem) {
      document.removeEventListener('viewport-position-change' + id, elem.callback);
      this.setState({ elements: elements.filter((e) => e?.id !== id) });
    }
  }

  public componentWillUnmount() {
    const { elements } = this.state;

    for (const elem in elements) {
      this.destroy(elem);
    }

    this.io.disconnect();
  }

  public render() {
    const { children } = this.props;
    const clonedChildren = React.Children.map(children as React.ReactElement,
      (child) => React.cloneElement(child, { observeIntersection: this.observe, unobserveIntersection: this.unobserve }));
    return (
      <React.Fragment>
        {clonedChildren}
      </React.Fragment>
    );
  }
}


const withIntersectionObserverScrollDirection = <P extends object>(WrappedComponent: React.ComponentType<P>) => {
  return (props: P): React.ReactElement => (
    <IntersectionObserverScrollDirectionComponent>
      <WrappedComponent {...props} />
    </IntersectionObserverScrollDirectionComponent>
  );
};

export default withIntersectionObserverScrollDirection;
