import React, { useState, Fragment, useRef, useContext, useCallback, useLayoutEffect } from 'react';
import Popover from '@myblueprint-spaces/papier-web/lib/Common/Popover';
import Dialog from '@myblueprint-spaces/papier-web/lib/Common/Dialog';
import Button from '@myblueprint-spaces/papier-web/lib/Common/Button';
import Typography from '@myblueprint-spaces/papier-web/lib/Common/Typography';
import { Row, RowColumn, Column } from '@myblueprint-spaces/papier-web/lib/Common/Grid';
import { getRegionConfig, getAllRegionConfigs, getHostRegion, getRegionByKey, getCurrentRegion, RegionConfig, Region } from './helper';
import { DialogContentWrapper, RegionSection, StyledPopoverElem } from './styles';
import RegionButton from './components/RegionButton';
import RegionPopoverItem from './components/RegionPopoverItem';
import { RovingTabIndexProvider } from '@myblueprint-spaces/react-roving-tabindex';
import PopoverItem from '@myblueprint-spaces/papier-web/lib/Common/Popover/components';
import withErrorBoundaries from '@myblueprint-spaces/papier-web/lib/modules/hocs/withErrorBoundaries';
import { useTranslation } from 'react-i18next';
import RegionDialogItem from './components/RegionDialogItem';
import { Location, useLocation, useNavigate } from 'react-router-dom';
import WindowSizeContext from '../Contexts/WindowSizeContext';

const RegionSelector = () => {
  const { isSmall, isLargeUp } = useContext(WindowSizeContext);
  const currentRegion = parseInt(getCurrentRegion() as string);
  const currentRegionConfig = getRegionConfig(currentRegion);
  const regionConfigs = getAllRegionConfigs().sort((a, b) => a.order - b.order);
  const { search, pathname } = useLocation() as Location;
  // eslint-disable-next-line no-restricted-properties
  const { host } = window.location;
  const { t } = useTranslation(['Common', 'Account.Common', 'Spaces']);
  const defaultRegion = getRegionConfig(Region.UnitedStates);

  const popoverTriggerRef = useRef(null);
  const [open, setOpen] = useState(false);

  const [showRegionSelectorDialog, setShowRegionSelectorDialog] = useState(false);
  const [chosenRegion, setChosenRegion] = useState<RegionConfig>(defaultRegion);

  const navigate = useNavigate();
  const isAccountSelection = pathname.includes('account-select');

  const handleRegionSelect = (popoverChosenRegion: RegionConfig) => {
    localStorage.setItem('chosen-region', popoverChosenRegion!.id.toString());
    if (popoverChosenRegion.host === host) {
      setOpen(false);
    } else {
      const searchParams = new URLSearchParams(search);
      searchParams.set('chosenRegion', popoverChosenRegion.key);
      const { href: regionUrl } = new URL(`${pathname}?${searchParams}`,`https://${popoverChosenRegion.host}`);
      // eslint-disable-next-line no-restricted-properties
      window.location.href = regionUrl;
    }
  };

  const handleInitialRegionSelect = useCallback(() => {
    setShowRegionSelectorDialog(false);
    const searchParams = new URLSearchParams(search);
    searchParams.set('chosenRegion', chosenRegion!.key);
    localStorage.setItem('chosen-region', chosenRegion!.id.toString());

    if (host !== chosenRegion?.host) {
      const { href: regionUrl } = new URL(`${pathname}?${searchParams}`,`https://${chosenRegion!.host}`);
      // eslint-disable-next-line no-restricted-properties
      window.location.href = regionUrl;
    }
  }, [chosenRegion, pathname, search, host]);

  useLayoutEffect(() => {
    if (!isAccountSelection) {
      const urlParams = new URLSearchParams(search);
      const regionKey = urlParams.get('region');
      const chosenRegionKey = urlParams.get('chosenRegion');
      // we will be setting _all_ accounts fresh, so remove the previous value
      localStorage.removeItem('region');

      // chosenRegion is from user's choice, so we don't need to ask again
      if (chosenRegionKey || regionKey) {
        urlParams.delete(chosenRegionKey ? 'chosenRegion' : 'region');
        navigate({
          search: urlParams.toString(),
        }, { replace: true });
        if (chosenRegionKey) {
          localStorage.setItem('chosen-region', getRegionByKey(chosenRegionKey).id.toString());
        } else if (!localStorage.getItem('chosen-region')) {
          // User is only taken here if the chosenRegionKey doesnt exist, there is no chosen region in the local storage, and region param
          // was passed via search params as they still need to select a region
          setShowRegionSelectorDialog(true);
        }
      } else {
        const savedRegion = localStorage.getItem('chosen-region');
        if (savedRegion === null) {
          setShowRegionSelectorDialog(true);
        } else {
          const currentHostRegion = getHostRegion();
          const regionConfig = getRegionConfig(savedRegion as unknown as Region);
          // International and US have same host but differnt IDs
          if (currentHostRegion?.host !== regionConfig?.host) {
            const regionConfig = getRegionConfig(savedRegion);
            const searchParams = new URLSearchParams(search);
            const doNotRedirect = urlParams.has('doNotRedirect');
            searchParams.set('chosenRegion', regionConfig.key);
            const { href: regionUrl } = new URL(`${pathname}?${searchParams}`, `https://${regionConfig.host}`);
            // eslint-disable-next-line no-restricted-properties
            if (!doNotRedirect) window.location.href = regionUrl;
          }
        }
      }
    }
  }, [currentRegion]);

  return (
    <Fragment>
      {currentRegionConfig !== undefined && <RegionButton
        ref={popoverTriggerRef}
        iconOnly={!isLargeUp}
        regionConfig={{ ...currentRegionConfig }}
        onClick={() => setOpen(true)}
        dataTest="region-selector__popover-trigger"
        rounded />}
      <Popover
        id="region-popover"
        hostAriaLabel={t('Account.Common:RegionSelection.Title')}
        onClose={() => setOpen(!open)}
        open={open}
        triggerRef={popoverTriggerRef}
        triggerPosition="bottom-right"
        menuPosition="top-right">
        <RovingTabIndexProvider>
          <StyledPopoverElem>
            {regionConfigs.map((regionConfig) => (
              <PopoverItem key={regionConfig.key} type="custom" handleDeactivate={() => setOpen(false)}>
                <RegionPopoverItem
                  regionConfig={regionConfig}
                  onClick={handleRegionSelect} />
              </PopoverItem>
            ))}
          </StyledPopoverElem>
        </RovingTabIndexProvider>
      </Popover>
      {showRegionSelectorDialog && <Dialog
        title={t('Account.Common:RegionSelection.Title')}
        fullScreenOnMobile={false}
        visible={showRegionSelectorDialog}
        size={isSmall ? 'small' : 'medium'}
        overlayClickExits={false}
        closeButton={false}>
        <DialogContentWrapper>
          <RowColumn align="center">
            <Typography type={isSmall ? 'body2' : 'header4'} weight="demibold" as="h1">{t('Account.Common:RegionSelectionDialog.Title')}</Typography>
          </RowColumn>
          <RowColumn align="center">
            <Typography type={isSmall ? 'subtitle1' : 'body2'} color="black3" style={{ textAlign: 'center', marginTop: '0.5rem' }}>{t('Account.Common:RegionSelectionDialog.Subtitle')}</Typography>
          </RowColumn>
          <RegionSection>
            <Row align="center" collapse>
              {regionConfigs.map((regionConfig, index) => (
                <Column key={regionConfig.id} columns={{ small: 12, medium: 'expand' }} collapse={isSmall || index !== 1}>
                  <RegionDialogItem
                    selected={chosenRegion !== undefined && chosenRegion.id === regionConfig.id}
                    regionConfig={regionConfig}
                    onChange={setChosenRegion} />
                </Column>
              ))}
            </Row>
          </RegionSection>
          <Button text={t('Spaces:Create.ConfirmDialog.Confirm')} dataTest="confirm" color="primary" size={isSmall ? 'small' : 'medium'} expanded
            onClick={handleInitialRegionSelect} disabled={!chosenRegion} style={isSmall ? { marginTop: '-0.5rem' } : undefined} />
        </DialogContentWrapper>
      </Dialog>}
    </Fragment>
  );
};

export default withErrorBoundaries(RegionSelector);
