import withRelativePosition from 'src/modules/hocs/withRelativePosition';
import * as PropTypes from 'prop-types';
import * as React from 'react';
import { TooltipContainer, TooltipElem } from '../styles';
import { ITooltipBoxProps } from '../types';
import { getScrollParent } from 'src/modules/browsers';

class TooltipBox extends React.Component<ITooltipBoxProps> {
  public static propTypes = {
    children: PropTypes.any,
    dark: PropTypes.bool,
    style: PropTypes.object,
    open: PropTypes.bool,
    id: PropTypes.string,
    onForcedClose: PropTypes.func
  };
  public trigger: React.RefObject<HTMLDivElement> = React.createRef();
  private scrollParent: Element;

  public getBoundingClientRect() {
    return this.trigger.current.getBoundingClientRect();
  }

  public constructor(props) {
    super(props);

    const { trigger, triggerId, onForcedClose, open } = this.props;

    const triggerElem = trigger && trigger.current ? trigger.current : document.getElementById(triggerId);
    this.scrollParent = getScrollParent(triggerElem);
    if (open) {
      this.scrollParent?.addEventListener('scroll', onForcedClose);
      document.addEventListener('remove-open-tooltip', onForcedClose);
    }
  }

  public componentDidUpdate({ open: prevOpen }: Readonly<ITooltipBoxProps>): void {
    const { open, onForcedClose } = this.props;

    if (open && prevOpen !== open) {
      this.scrollParent?.addEventListener('scroll', onForcedClose);
      document.addEventListener('remove-open-tooltip', onForcedClose);
    }
  }

  public componentWillUnmount(): void {
    const { onForcedClose } = this.props;
    document.removeEventListener('remove-open-tooltip', onForcedClose);
    this.scrollParent?.removeEventListener('scroll', onForcedClose);
  }

  public render() {
    const { children, dark, style, open, id, onForcedClose, ...rest } = this.props;

    return (
      <TooltipContainer style={style} id={id}>
        <TooltipElem {...rest} dark={dark} ref={this.trigger} aria-hidden={!open}>
          {children}
        </TooltipElem>
      </TooltipContainer>
    );
  }
}

export default withRelativePosition('Tooltips', 'remove-open-tooltip')(TooltipBox as React.ComponentType<ITooltipBoxProps>);
