
import styled from 'styled-components';
import { Colors } from '@myblueprint-spaces/papier-core';

export const SkeletonWrapper = styled.div`
  background-color: ${Colors.white};
  max-width: 100%;
  padding: 1rem 0;
`;

export const SkeletonHeader = styled.div`
  margin-bottom: 1rem;
  padding: 0 1rem;
`;

export const SkeletonDescriptionContainer = styled.div`
  padding: 1.5rem 1rem 1rem 1rem;
  border-bottom: 0.0625rem solid ${Colors.grey3};
`;

export const SkeletonDescriptionDivider = styled.div`
  height: 0.0625rem;
  background-color: ${Colors.grey3};
  margin-bottom: 1rem;
  padding: 1rem;
`;

export const SkeletonCommentInput = styled.div`
  padding: 1rem;
`;

export const SkeletonInput = styled.div`
  background-color: ${Colors.grey4};
  border: 0.0625rem solid ${Colors.grey2};
  border-radius: 0.25rem;
  height: 2rem;
  width: 100%;
`;

export const SkeletonCommentList = styled.div`
  padding: 1rem;
`;
