import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingGifWrapper, StyledImage } from './styles';
import LoadingGifAsset from 'src/Assets/Loading_Gif_3.gif';

const LoadingGif = (): React.ReactElement => {
  const { t, ready: tReady } = useTranslation(['Common'], { useSuspense: false });

  return (
    <LoadingGifWrapper>
      <StyledImage src={LoadingGifAsset} alt={tReady ? t('Common:Actions.Loading') : 'Loading...'} />
    </LoadingGifWrapper>
  );
};

export default LoadingGif;
