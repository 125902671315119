import React, { useContext, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import RadioButton from '@myblueprint-spaces/papier-web/lib/Common/RadioButton';
import useId from '@myblueprint-spaces/papier-web/lib/modules/hooks/useId';
import Typography from '@myblueprint-spaces/papier-web/lib/Common/Typography';
import { Row, Column } from '@myblueprint-spaces/papier-web/lib/Common/Grid';
import { StyledRegionDialogItem, StyledIcon, ButtonTextWrapper } from './styles';
import { getRegionAsset } from '../helper';
import { IRegionDialogItemProps } from './types';
import withErrorBoundaries from '@myblueprint-spaces/papier-web/lib/modules/hocs/withErrorBoundaries';
import WindowSizeContext from 'src/Common/Contexts/WindowSizeContext';
import Clipped from '@myblueprint-spaces/papier-web/lib/Atoms/Clipped';

const RegionDialogItem = ({ selected, regionConfig, onChange }: IRegionDialogItemProps) => {
  const { nameKey, key } = regionConfig;
  const { t } = useTranslation(['Spaces.Common']);
  const { isSmall } = useContext(WindowSizeContext);
  const radioBtnId = useId();

  useLayoutEffect(() => {
    selected && document.getElementById(radioBtnId)?.focus();
  }, [selected]);

  return (
    <StyledRegionDialogItem selected={!!selected}>
      <Clipped>
        <RadioButton onChange={() => onChange(regionConfig)} checked={selected} name="region" dataTest="region" id={radioBtnId} label="" aria-hidden="true" />
      </Clipped>
      {/* we cannot use Label because this is not _exactly_ a label... */}
      <label htmlFor={radioBtnId}>
        <Row collapse>
          <Column columns={{ small: 1, medium: 12 }}>
            <StyledIcon
              aria-hidden="true"
              src={getRegionAsset(key)} />
          </Column>
          <Column verticalAlign="middle" columns={{ small: 11, medium: 12 }}>
            <ButtonTextWrapper>
              <Typography
                type={isSmall ? 'subtitle1' : 'body2'}
                color={selected ? 'primary1' : 'black1'}
                weight="medium">
                {t(`Spaces.Common:${nameKey}`)}
              </Typography>
            </ButtonTextWrapper>
          </Column>
        </Row>
      </label>
    </StyledRegionDialogItem>
  );
};

export default withErrorBoundaries(RegionDialogItem);
