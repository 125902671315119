import styled from 'styled-components';
import { PopoverElem } from '@myblueprint-spaces/papier-web/lib/Common/Popover/styles';
import { Breakpoints } from '@myblueprint-spaces/papier-core';

export const StyledPopoverElem = styled(PopoverElem)`
  width: 11.5rem;
  margin-top: 0.5rem;
`;

export const DialogContentWrapper = styled.div`
  margin: 2.5rem;

  @media (max-width: ${Breakpoints.medium}em) {
      margin: 1.5rem;
  }
`;

export const RegionSection = styled.div`
  display: inline-block;
  margin: 2rem 0; 

  @media (max-width: ${Breakpoints.medium}em) {
    margin: 1.5rem 0;
  }
`;
