import CovalentGrid from 'src/Atoms/CovalentGrid';
import { TagContainer } from 'src/Common/Tag/styles';
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { DropdownSizesType } from '../types';
import { ButtonColorsType } from '@myblueprint-spaces/papier-core';

const MultiValueContainer = (size: DropdownSizesType, color: ButtonColorsType): React.ReactNode => {
  const SizedMultiValueContainer = (props) => {
    const { children, innerProps, ...rest } = props;
    let tagSize: 'small' | 'regular';
    switch (size) {
      case 'small':
        tagSize = 'small';
        break;
      default:
        tagSize = 'regular';
        break;
    }
    return (
      <TagContainer as="div" size={tagSize} {...rest} {...innerProps} color={color}>
        <CovalentGrid align="right" verticalAlign="middle">
          {children}
        </CovalentGrid>
      </TagContainer>
    );
  };

  SizedMultiValueContainer.propTypes = {
    children: PropTypes.node,
    innerProps: PropTypes.object
  };

  return SizedMultiValueContainer;
};

export default MultiValueContainer;
