import { EventSubscription } from 'fbemitter';
import Emitter from '../eventEmitter';
import { IConfirmProps } from './components/ConfirmationManager';

export const confirmEvent = 'Confirmations.confirm';

export function subscribeConfirm(callback: (toastProps) => unknown) {
  return Emitter.addListener(confirmEvent, callback);
}

export function unsubscribeConfirm(token: EventSubscription) {
  token.remove();
}

export function confirm(options: IConfirmProps) {
  Emitter.emit(confirmEvent, options);
}
