import * as React from 'react';

import FocusContext from 'src/Common/FocusContext';
import { generateId } from '../browsers/generateId';

export interface IPortalProps {
  portal: HTMLElement;
  portalId: string;
}

interface IPortalRefProp {
  forwardedRef: React.Ref<unknown>;
  rootNode: HTMLElement;
}

export interface IPortalRefChild {
  ref: React.Ref<unknown>;
}

const withPortal = (id: string = null) => <OriginalProps extends Record<string, unknown>>(WrappedComponent: React.ComponentType<OriginalProps & IPortalProps & IPortalRefChild>):
  React.ForwardRefExoticComponent<React.PropsWithoutRef<OriginalProps> & React.RefAttributes<React.ComponentType<OriginalProps & IPortalProps & IPortalRefChild>>> => {
  type HocProps = OriginalProps & IPortalProps & IPortalRefChild;

  const getPortal = (rootNode, rootId, portalId) => rootNode.querySelector(`[id=${rootId}${portalId}]`) || Object.assign(document.createElement('div'), { id: rootId + portalId, role: 'region' });

  function PortalHoc(props: OriginalProps & IPortalProps & IPortalRefProp) {
    const { rootNode, forwardedRef, ...rest } = props;

    const portalId = id || generateId();
    const rootId = React.useRef(rootNode?.getAttribute('id') ?? null);
    const [portal, setPortal] = React.useState(rootNode ? getPortal(rootNode, rootId.current, portalId) : null);

    React.useEffect(() => {
      if (rootNode) {
        if (!rootNode.getAttribute('id')) {
          rootNode.setAttribute('id', generateId());
        }
        rootId.current = rootNode.getAttribute('id');
        const newPortal = getPortal(rootNode, rootId.current, portalId);
        // reuse portal if id already exists
        setPortal(newPortal);
        !document.getElementById(rootId.current + portalId) && rootNode.appendChild(newPortal);
      }
    }, [rootNode, portalId, rootId.current]);

    const childProps = {
      ...rest,
      rootNode: rootNode,
      ref: forwardedRef,
      portal,
      portalId: portalId
    };

    return <WrappedComponent {...childProps as HocProps} />;
  }

  return React.forwardRef<typeof WrappedComponent, OriginalProps>((props, ref) => {
    const rootNode = document.getElementById('root');
    if (rootNode) {
      return (
        <FocusContext.Consumer>
          {(context) => <PortalHoc {...props as HocProps} forwardedRef={ref} rootNode={context.actualFocusTrappedElement.current || rootNode} />}
        </FocusContext.Consumer>
      );
    }
    return null;
  });
};

export default withPortal;
