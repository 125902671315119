import { Colors } from '@myblueprint-spaces/papier-core';
import CleanButton from 'src/Atoms/CleanButton';
import Icon from 'src/Common/Icon';
import styled from 'styled-components';
import { getTextInputCss } from 'src/modules';
import { IBaseTextBoxProps, WithRequired } from 'src/Atoms/types';

// Main container
export const TextBoxContainer = styled.div((props: WithRequired<IBaseTextBoxProps, 'size'> ) => {
  const { multiline, bottomMargin } = props;

  // Get sizing
  const { borderColor, boxShadow, backgroundColor, padding, height, marginBottom } = getTextInputCss(props);

  return `
    display: flex;
    flex-direction: row;
    align-items: ${multiline ? 'flex-start' : 'center'};
    height: ${height ? height + 'rem' : 'auto'};
    background: ${backgroundColor};
    border-radius: 0.25rem;
    border: 0.0625rem solid ${borderColor};
    padding-left: ${padding + 'rem'};
    padding-right: ${padding + 'rem'};
    color: ${Colors.black1};
    margin-bottom: ${bottomMargin ? marginBottom + 'rem' : 0};
    transition: box-shadow 0.2s ease, border-color 0.2s ease;
    outline: 0;
    ${boxShadow}
  `;
});

export const TextBoxLabelContainer = styled.div`
  margin-bottom: 0.25rem;
`;

// Layout elements inside the container
export const TextBoxElement = styled.div`
  line-height: 0;
  flex: 1 1 0px;
  align-self: center;
`;

export const TextBoxIconElement = styled.div.attrs(() => ({
  shrink: true,
}))((props: WithRequired<IBaseTextBoxProps, 'size'>) => {
  const { multiline } = props;
  const { topSpacing } = getTextInputCss(props);

  return `
    padding-top: ${multiline ? topSpacing : 0}rem;
    line-height: 0;
    display: flex;
    align-items: center;
  `;
});

export const TemporarySpacer = styled.div`width: 0.5rem;`;

// Icon
export const TextBoxIcon = styled(Icon)``;

export const TextBoxIconButton = styled(CleanButton)``;
