import { format, shallowEquals } from '@myblueprint-spaces/modules';
import FormValidation from 'src/Common/FormValidation';
import Label from 'src/Common/Label';
import * as PropTypes from 'prop-types';
import * as React from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import ClearIndicator from './components/ClearIndicator';
import DropdownIndicator from './components/DropdownIndicator';
import MultiValueContainer from './components/MultiValueContainer';
import MultiValueLabel from './components/MultiValueLabel';
import MultiValueRemove from './components/MultiValueRemove';
import Control from './components/Control';
import { DropdownLabelContainer, dropdownStyles } from './styles';
import { IDropdownProps, IDropdownValueLabel } from './types';
import { SelectComponentsProps } from 'react-select/src/Select';
import { WithTranslation } from 'react-i18next';
import { generateId } from 'src/modules/browsers/generateId';
import { Clipped } from '../Panel/styles';
import { getButtonAppearance } from '@myblueprint-spaces/papier-core';

export default class Dropdown extends React.Component<IDropdownProps & WithTranslation> {
  public static defaultProps = {
    className: '',
    valid: null,
    size: 'medium',
    multiselect: false,
    clearable: false,
    searchable: false,
    bottomMargin: true,
    tagColor: 'primary',
    required: false
  };

  public static propTypes = {
    options: PropTypes.array,
    valid: PropTypes.bool,
    validationText: PropTypes.string,
    creatable: PropTypes.bool,
    size: PropTypes.string,
    multiselect: PropTypes.bool,
    clearable: PropTypes.bool,
    searchable: PropTypes.bool,
    label(props: IDropdownProps, propName: string, componentName: string): Error {
      if (props[propName] && typeof props[propName] !== 'string') {
        return new Error(`Invalid prop '${propName}' supplied to '${componentName}'. Expected type 'string'.`);
      } else if (props[propName] && !props.id) {
        return new Error(`Invalid prop '${propName}' supplied to '${componentName}'. '${componentName}' requires an 'id' with a 'label'.`);
      }
    },
    labelRight: PropTypes.node,
    labelIcon: PropTypes.string,
    id: PropTypes.string,
    disabled: PropTypes.bool,
    value: PropTypes.any,
    defaultValue: PropTypes.any,
    formatCreateLabel: PropTypes.func,
    noOptionsMessage: PropTypes.func,
    placeholder: PropTypes.string,
    t: PropTypes.func.isRequired,
    bottomMargin: PropTypes.bool,
    dataTest: PropTypes.string.isRequired,
    labelId: PropTypes.string,
    hideLabel: PropTypes.bool,
    tagColor: PropTypes.string,
    required: PropTypes.bool
  };

  private id;
  private selectInst: React.RefObject<Select> = React.createRef();

  public constructor(props: IDropdownProps & WithTranslation) {
    super(props);

    const { id } = props;

    this.id = id || generateId();
  }

  public focus = (): void => {
    this.selectInst.current.focus();
  }

  public blur = (): void => {
    this.selectInst.current.blur();
  }

  public render(): React.ReactNode {
    const { creatable, size, formatCreateLabel, noOptionsMessage, placeholder, t, bottomMargin, id, value, options, tagColor,
      label, labelIcon, labelRight, components, searchable, multiselect, disabled, validation, clearable, onChange, dataTest, hideLabel = false, labelId, required, ...props } = this.props;

    // the majority of times, papier-web will take care of DropdownLabel, for other scenarios we require the id for accessibility purposes
    const usedLabelId = labelId || generateId();

    const selectedValue = typeof value !== 'undefined'
      ? Array.isArray(value)
        ? options.filter((o: IDropdownValueLabel) => typeof o.value === 'object' ? value.some((v) => shallowEquals(o.value as Record<string, unknown>, v)) : value.includes((o as IDropdownValueLabel).value))
        : options.filter((o: IDropdownValueLabel) => o.value === value)
      : null;

    const { textColor, iconColor } = getButtonAppearance(tagColor);

    const componentProps: SelectComponentsProps = {
      'styles': dropdownStyles(size),
      'inputId': this.id,
      'id': this.id && (this.id + '_ddlElem'),
      'data-test': `dropdown-${dataTest}`,
      'ref': this.selectInst,
      'menuId': `${this.id}-_menu_id`,
      'menuPlacement': 'auto',
      'aria-labelledby': usedLabelId,
      'aria-autocomplete': 'list',
      'components': {
        ...components,
        DropdownIndicator: DropdownIndicator(size),
        ClearIndicator: ClearIndicator(size),
        MultiValueContainer: MultiValueContainer(size, tagColor),
        MultiValueLabel: MultiValueLabel(textColor),
        MultiValueRemove: MultiValueRemove(iconColor),
        Control: Control
      },
      'formatCreateLabel': formatCreateLabel || ((v: string) => format(t('Papier.Dropdown:CreateLabel'), v)),
      'noOptionsMessage': noOptionsMessage || (() => t('Papier.Dropdown:NoOptions')),
      'placeholder': placeholder || t('Papier.Dropdown:Select'),
      ...props,
      'isDisabled': disabled,
      'isMulti': multiselect,
      'isSearchable': searchable,
      'isClearable': clearable,
      'validationState': validation && validation.state,
      'bottomMargin': bottomMargin,
      'value': selectedValue,
      'options': options,
      'required' : required,
      'onChange': (e) => {
        if (e === null) {
          onChange(null);
        } else if (Array.isArray(e)) {
          onChange(e.map((o) => o.value));
        } else {
          onChange(e.value);
        }
      }
    };

    let selectElement;

    if (creatable) {
      selectElement = <CreatableSelect {...componentProps} />;
    } else {
      selectElement = <Select {...componentProps} />;
    }

    return (
      <React.Fragment>
        {hideLabel && label ? <Clipped id={usedLabelId}>{label}</Clipped>
          : label && (
            <DropdownLabelContainer>
              <Label htmlFor={this.id} size={size === 'small' ? size : 'medium'} icon={labelIcon} right={labelRight} id={usedLabelId} required={required}>
                {label}
              </Label>
            </DropdownLabelContainer>
          )}
        {selectElement}
        {validation && <FormValidation {...validation} size={size === 'small' ? size : 'medium'} />}
      </React.Fragment>
    );
  }
}
