import React, { useCallback } from 'react';
import InfiniteLoader from 'react-window-infinite-loader';
import { VariableSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import NotificationItem from './components/NotificationItem';
import EmptyState from '@myblueprint-spaces/papier-web/lib/Common/EmptyState';
import LoadingDots from '@myblueprint-spaces/papier-web/lib/Common/LoadingDots';
import ImageBlock from '@myblueprint-spaces/papier-web/lib/Atoms/ImageBlock';
import { FullHeightScrollableColumn, Row } from '@myblueprint-spaces/papier-web/lib/Common/Grid';
import { useTranslation } from 'react-i18next';
import { NotificationFeed, EmptyStateWrapper } from './styles';
import withErrorBoundaries from '@myblueprint-spaces/papier-web/lib/modules/hocs/withErrorBoundaries';
import WindowSizeContext from '../../../Contexts/WindowSizeContext';
import EmptyNotifications from 'assets/Asset_empty_notifications.png';
import { NotificationsManagerProps } from './types';
import { useNotificationFilter } from '../NotificationFilterContext';

const NotificationsManager = ({ notificationList, isAtEnd, small = false, toggleRead, loadMore }: NotificationsManagerProps) => {
  const { isSmall } = React.useContext(WindowSizeContext);
  const notificationFilter = useNotificationFilter();
  const NOTIFICATION_SIZE = 56;
  const NOTIFICATION_AMOUNT_FOR_END_ASSET = isSmall ? 6 : 20;
  const listRef = React.useRef<{ resetAfterIndex: (index: number, shouldForceUpdate: boolean) => void }>();
  const { t } = useTranslation('Notifications.Common');

  const isNotificationLoaded = (index) => index < notificationList.length;
  const itemCount = isAtEnd ? notificationList.length : notificationList.length + 1;
  const showEndAsset = isAtEnd && notificationList.length >= NOTIFICATION_AMOUNT_FOR_END_ASSET;

  const getNotificationSize = React.useCallback((index) => {
    if (notificationList && notificationList.length > 0){
      const next = notificationList[index+1];
      const curr = notificationList[index];

      if (typeof next !== 'undefined' && typeof curr !== 'undefined') {
        if (next.lastUpdated.getFullYear() !== curr.lastUpdated.getFullYear()
          || (notificationList[0].lastUpdated.getFullYear() === curr.lastUpdated.getFullYear()
          && next.lastUpdated.getMonth() !== curr.lastUpdated.getMonth())) {
          return NOTIFICATION_SIZE*2;
        }
      }
      return NOTIFICATION_SIZE;
    }
  }, [notificationList]);

  React.useLayoutEffect(() => {
    listRef.current && listRef.current.resetAfterIndex(0, true);
  }, [notificationList.length]);

  const itemData = React.useMemo(() => ({
    items: notificationList,
    showEndAsset: isAtEnd && showEndAsset,
    small,
    height: NOTIFICATION_SIZE,
    toggleRead
  }), [notificationList, isAtEnd, small, showEndAsset, toggleRead]);

  const loadMoreItems = useCallback(() => loadMore(notificationFilter), [notificationFilter, loadMore]);

  return (
    <React.Suspense fallback={<LoadingDots />}>
      { notificationList.length == 0 && <Row collapse span expanded align="center">
        <FullHeightScrollableColumn>
          <EmptyStateWrapper small>
            <EmptyState
              image={<ImageBlock src={EmptyNotifications} aria-hidden="true" alt="" />}
              title={{ type: `${small ? 'body2' : 'header4'}`, children: t('Notifications.Common:EmptyState.Title') }}
              subtitle={{ type: `${small ? 'compact' : 'body2'}`, children: t('Notifications.Common:EmptyState.Subtitle') }} />
          </EmptyStateWrapper>
        </FullHeightScrollableColumn>
      </Row>}
      { notificationList.length > 0
        && <NotificationFeed collapse span expanded small={small}>
          <InfiniteLoader
            isItemLoaded={isNotificationLoaded}
            itemCount={itemCount}
            loadMoreItems={loadMoreItems}>
            {({ onItemsRendered, ref }) => (
              <AutoSizer ref={ref}>
                {({ height, width }) => (
                  <VariableSizeList
                    height={height}
                    itemCount={itemCount}
                    itemSize={getNotificationSize}
                    onItemsRendered={onItemsRendered}
                    itemData={itemData}
                    ref={listRef}
                    width={width}
                    style={{ overflowX: 'hidden', overflowY: 'auto' }}
                  >
                    {NotificationItem}
                  </VariableSizeList>
                )}
              </AutoSizer>
            ) }
          </InfiniteLoader>
        </NotificationFeed>}
    </React.Suspense>
  );
};

export default withErrorBoundaries(NotificationsManager);
