import useId from 'src/modules/hooks/useId';
import * as PropTypes from 'prop-types';
import * as React from 'react';
import { ButtonStatus, ButtonStatusNames, IIconButtonProps, ITextButtonProps, IButtonClick } from '@myblueprint-spaces/papier-core';
import { IButtonCustomProps } from './types';
import TextButton from './components/TextButton';
import ResponsiveIconButton from './components/ResponsiveIconButton';
import IconButton from './components/IconButton';
import StatusButton from './components/StatusButton';

export type ButtonProps = ((Omit<IIconButtonProps, 'title'> & { title?: string } | ITextButtonProps) & IButtonCustomProps)
export function Button(props: ButtonProps): React.ReactElement {
  const { id, onClick, status, async, showResult, forwardRef, dataTest, ...rest } = props;

  const createdRef = React.useRef();
  const buttonRef = ((forwardRef || createdRef) as React.RefObject<HTMLButtonElement>);
  const buttonId = useId(id);
  const [internalStatus, setInternalStatus] = React.useState(status || ButtonStatus.initial);
  const [width, setWidth] = React.useState(null);
  const [height, setHeight] = React.useState(null);
  const [hover, setHover] = React.useState(false);
  const timeout = React.useRef(null);

  const setNewStatus = (newStatus) => {
    const { width: w = 0, height: h = 0 } = buttonRef.current && buttonRef.current.getBoundingClientRect() || {};
    w > 0 && setWidth(w);
    h > 0 && setHeight(h);
    setInternalStatus(newStatus);
  };

  // only used by async
  const updateStatus = (newStatus) => {
    if (showResult) {
      setNewStatus(newStatus);
    } else {
      setNewStatus(ButtonStatus.initial);
    }
    timeout.current = setTimeout(() => {
      setNewStatus(ButtonStatus.initial);
    }, 3000);
  };

  React.useEffect(() => {
    status && setNewStatus(status);
  }, [status]);

  React.useEffect(() => {
    return () => clearTimeout(timeout.current);
  }, []);

  // only used by async
  const handleOnClick = React.useCallback((e): IButtonClick => {
    if (async && onClick) {
      setNewStatus(ButtonStatus.loading);
      // Wrapping Promise ensures that it will work with and without onClick promises.
      return Promise.resolve(onClick()).then((value) => {
        updateStatus(ButtonStatus.success);
        return value;
      }, (reason) => {
        console.error(reason);
        updateStatus(ButtonStatus.fail);
        return reason;
      });
    } else if (onClick) {
      onClick(e);
    }
  }, [onClick, async]);

  const handleMouseEnter = React.useCallback(() => {
    setHover(true);
  }, []);

  const handleMouseLeave = React.useCallback(() => {
    setHover(false);
  }, []);

  const hoverProps = {
    hover,
    onMouseEnter: handleMouseEnter,
    onMouseLeave: handleMouseLeave
  };

  if (!internalStatus || internalStatus === ButtonStatus.initial) {
    if (Object.prototype.hasOwnProperty.call(props, 'text')) {
      return (
        <TextButton ref={buttonRef} id={buttonId} {...rest as ITextButtonProps} onClick={handleOnClick} {...hoverProps} dataTest={`btn-${dataTest}`} />
      );
    } else {
      const iconButtonProps = rest as IIconButtonProps;
      if (iconButtonProps.responsive) {
        return (
          <ResponsiveIconButton ref={buttonRef} id={buttonId} {...iconButtonProps} onClick={handleOnClick} {...hoverProps} dataTest={`btn-${dataTest}`} />
        );
      } else {
        return (
          <IconButton ref={buttonRef} id={buttonId} {...iconButtonProps} onClick={handleOnClick} {...hoverProps} dataTest={`btn-${dataTest}`} />
        );
      }
    }
  } else {
    return <StatusButton ref={buttonRef} id={buttonId} {...rest as IIconButtonProps} width={width} height={height} status={internalStatus} dataTest={`btn-${dataTest}`} {...hoverProps} />;
  }
}

Button.propTypes = {
  type: PropTypes.oneOf(['button', 'reset', 'submit']),
  align: PropTypes.oneOf(['left', 'center', 'right']),
  text: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string,
  status: PropTypes.oneOf(ButtonStatusNames),
  showResult: PropTypes.bool,
  async: PropTypes.bool,
  onClick: PropTypes.func,
  responsive: PropTypes.bool,
  validation(props: ButtonProps) {
    if (props.status && props.async) {
      return new Error(
        'You can only use one of [status, async].'
      );
    }
    if (props.status && props.showResult) {
      return new Error(
        'You can only use one of [status, showResult].'
      );
    }
    if (props.showResult && !props.async) {
      return new Error(
        'To have showResult you need async as true.'
      );
    }
    if (props.async && !props.onClick) {
      return new Error(
        'To have async you need onClick.'
      );
    }
  },
  dataTest: PropTypes.string.isRequired,
  wrap: PropTypes.bool
};

export const MemoedButton = React.memo(Button);

const RefedButton = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => <MemoedButton {...props} forwardRef={ref} />);

export default RefedButton;
