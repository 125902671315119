import { useCallback, useContext } from 'react';
import AppConfigContext from '../../Common/Contexts/AppConfigContext';
import { Product } from '@myblueprint-spaces/redux/lib/api/common/globals/enums';
import ProductContext from 'src/Common/Contexts/ProductContext';

export default function useGetFullUrl() {
  const { appLinks: { spacesUrl, adminUrl } } = useContext(AppConfigContext);
  const { productType } = useContext(ProductContext);
  const appUrl = productType === Product.Spaces ? spacesUrl : adminUrl;

  return useCallback(
    (url: string, isHref?: boolean ) => {
      if ( isHref ) {
        const { href } = new URL(url, appUrl);
        return href;
      } else {
        const { pathname, search } = new URL(url, appUrl);
        return pathname + search;
      }
    },
    [appUrl]
  );
}
