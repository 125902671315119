import { EventSubscription } from 'fbemitter';
import Emitter from '../eventEmitter';
import { TFunction } from 'i18next';
import { IToast } from 'src/Common/ToastManager/types';

export const toastEvent = 'Toasts.toast';


export function subscribeToasts(callback: (toastProps) => void) {
  return Emitter.addListener(toastEvent, callback);
}

export function unsubscribeToasts(token: EventSubscription) {
  token.remove();
}
export const successToast = (t: TFunction, message?: string, extraProps?: Record<string, unknown>) => {
  toast(null, {
    canClose: true,
    ...extraProps,
    // cannot be .toString() bc jest breaks, we KNOW for sure based on how we configured our i18next, that even if key/set does not exists, it will return a string
    text: message || t('Common:SuccessMessage') as string
  });
};

export const failToast = (e: { response?: { data?: Record<string, unknown> }}, t: TFunction, message?: string, extraProps?: Record<string, unknown>) => {
  toast(e, {
    canClose: true,
    ...extraProps,
    // cannot be .toString() bc jest breaks, we KNOW for sure based on how we configured our i18next, that even if key/set does not exists, it will return a string
    text: message || t('Common:FailMessage') as string
  });
};

/*
  For any sucess message, pass null to e. For _any_ fail toast error provided by catch should be used.
  This is not an optional param to force developers to pass the error when existing
*/
export function toast(e: { response?: { data?: Record<string, unknown> }} | null, options: Partial<Omit<IToast, 'id'>> & { id?: string }) {
  const traceId = e?.response?.data?.traceId;

  if (traceId) {
    options.subtext = options.subtext ? `${options.subtext} - TraceId: ${traceId}` : `TraceId: ${traceId}`;
  }
  Emitter.emit(toastEvent, options);
}
