import { useState, useEffect } from 'react';

const useDebounceValue = (value, delay = 1000) => {
  const [debouncedValue, setDebouncedValue] = useState(null);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay);

    return () => clearTimeout(timer);
  }, [value, delay]);

  return debouncedValue;
};

export default useDebounceValue;
