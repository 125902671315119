import { NotificationDisplayData } from '@myblueprint-spaces/redux/lib/notifications';
import { TFunction } from 'i18next';
import { Grade } from '@myblueprint-spaces/redux/lib/students';
import { getMultiGradeString } from '@myblueprint-spaces/appstate';
import { formatDateShort } from '@myblueprint-spaces/modules';

export const processDisplayData = (displayData: NotificationDisplayData, t: TFunction, language: string) => {
  const displayDataEntries = Object.entries(displayData);
  const processedDisplayData = displayDataEntries.map((e) => {
    if (e[0].endsWith('Resx')) {
      return [e[0].replace('Resx', ''), t(e[1] as string)];
    } else if (e[0].startsWith('grades')) {
      return [e[0], e[1] === Grade.None ? t('Common:AllX', { feature: t('Common:Grades') }) : getMultiGradeString(e[1] as Grade, t, true, true)];
    } else if (e[0].startsWith('dueDate')) {
      const date = new Date(e[1] as string);
      return [e[0], formatDateShort(date, language)];
    } else {
      return e;
    }
  });

  return processedDisplayData;
};
