import * as React from 'react';
import DropdownIndicator from '../../../components/DropdownIndicator';
import { components, IndicatorContainerProps, OptionTypeBase } from 'react-select';
import styled from 'styled-components';
import { DropdownSizesType } from '../../../types';

const HiddenElement = styled.div`
visibility: hidden;
`;

const RoundedDropdownValueContainer = (size: DropdownSizesType) => (props: IndicatorContainerProps<OptionTypeBase, boolean>): React.ReactElement => {
  const Indicator = DropdownIndicator(size);

  return (
    <React.Fragment>
      <components.IndicatorsContainer {...props}>
        <HiddenElement>
          <Indicator {...props} />
        </HiddenElement>
      </components.IndicatorsContainer>
      <components.ValueContainer {...props} />
    </React.Fragment>
  );
};

export default RoundedDropdownValueContainer;
