import React, { FunctionComponent, useContext, useState } from 'react';
import { Row, Column, RowColumn } from '@myblueprint-spaces/papier-web/lib/Common/Grid';
import EmptyState from '@myblueprint-spaces/papier-web/lib/Common/EmptyState';
import ImageBlock from '@myblueprint-spaces/papier-web/lib/Atoms/ImageBlock';
import Typography from '@myblueprint-spaces/papier-web/lib/Common/Typography';
import Markdown from '@myblueprint-spaces/papier-web/lib/Common/Markdown';
import Avatar from '@myblueprint-spaces/papier-web/lib/Common/Avatar';
import { getAvatarSize } from '@myblueprint-spaces/papier-web/lib/Common/Avatar/styles';
import CleanButton from '@myblueprint-spaces/papier-web/lib/Atoms/CleanButton';
import Tooltip from '@myblueprint-spaces/papier-web/lib/Common/Tooltip';
import Icon from '@myblueprint-spaces/papier-web/lib/Common/Icon';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getFormattedMonthDate } from '@myblueprint-spaces/modules';
import { Wrapper, MarkdownWrapper, Dot } from '../../styles';
import withErrorBoundaries from '@myblueprint-spaces/papier-web/lib/modules/hocs/withErrorBoundaries';
import { NotificationItemProps } from './types';
import Profile from 'assets/Asset_profile.png';
import EmptyNotifications from 'assets/Asset_empty_notifications.png';
import { processDisplayData } from './helper';
import { TOptionsBase } from 'i18next';
import { Scope } from '@myblueprint-spaces/redux/lib/scopes';
import WindowSizeContext from 'src/Common/Contexts/WindowSizeContext';
import { ConnectedScopeAvatar } from 'src/Common/ConnectedAvatar';
import NotificationsConfigContext from '../../../NotificationsConfigContext';
import LoadingDots from '@myblueprint-spaces/papier-web/lib/Common/LoadingDots';

const NotificationItem = ({ data, index, style }: NotificationItemProps) => {
  const { items, showEndAsset, small, height, toggleRead } = data;
  const item = items[index];
  const windowSize = useContext(WindowSizeContext);
  const { isSmall } = windowSize;
  let divider: React.ReactNode | null = null;
  const { t, i18n } = useTranslation(['Spaces.Common', 'Notifications.Common', 'Papier.Common', 'Common']);
  const notificationRef = React.useRef(null);
  const location = useLocation();
  const [notificationHover, setNotificationHover] = useState(false);
  const { getItemAdditionalInfo, getNotificationInteraction, getSpecialAvatar, closePopover } = useContext(NotificationsConfigContext);
  const additionalInfo = getItemAdditionalInfo?.(item) ?? null;
  const [loading, setLoading] = useState(false);
  const avatarSize = getAvatarSize(small ? 'small' : 'medium');

  const { url: interactionUrl, action: interactionAction } = getNotificationInteraction?.(location, item) ?? {};

  if (!item) return null;

  const { displayData, read, avatarScope, lastUpdated, id: notificationId, resource, type } = item;
  const specialAvatar = getSpecialAvatar?.(type);

  const endAssetTopHeight = style.top + style.height;

  if (items[index - 1] && items[index - 1].lastUpdated.getFullYear() !== lastUpdated.getFullYear()) {
    divider = (
      <Wrapper noBackground style={{ top: style.top - height, height: height }} small={small}>
        <Typography type="body2" color="black1" as="h2" dataTest="last-updated-year">{lastUpdated.getFullYear()}</Typography>
      </Wrapper>
    );
  }

  if (items[index - 1] && items[0].lastUpdated.getFullYear() === lastUpdated.getFullYear()
    && items[index - 1].lastUpdated.getMonth() !== lastUpdated.getMonth()) {
    divider = (
      <Wrapper noBackground style={{ top: style.top - height, height: height }} small={small}>
        <Typography type="body2" color="black1" as="h2" dataTest="last-updated-month-year">{`${getFormattedMonthDate(lastUpdated, i18n.language)} ${lastUpdated.getFullYear()}`}</Typography>
      </Wrapper>
    );
  }
  const handleClick = (action) => {
    setLoading(true);
    typeof action === 'function' && action?.();
    closePopover?.(type);
    !read && toggleRead(notificationId, true);
    setLoading(false);
  };

  let translatedDisplayData: TOptionsBase | null = null;
  let markdown: Markdown | null = null;

  if (displayData) {
    const processedDisplayData = processDisplayData(displayData, t, i18n.language);
    translatedDisplayData = Object.fromEntries(processedDisplayData);

    markdown = <Markdown source={t(resource?.set + ':' + resource?.key, translatedDisplayData as TOptionsBase)} boldWeight={read ? 'medium' : 'demibold'} allowedTypes={['text', 'strong', 'paragraph']} />;
  }

  return (
    <Row align="center">
      {divider}
      <Wrapper style={{ top: style.top, height }} small={small || isSmall}>
        {displayData && <Row align="left" verticalAlign="top" expanded span>
          <Column shrink>
            {loading ? <div style={{ width: avatarSize, height: avatarSize }}>
              <LoadingDots />
            </div> : <Dot showDot={!read}>
              {!specialAvatar ? <ConnectedScopeAvatar scope={avatarScope as Scope} size={small ? 'small' : 'medium'} noMargin /> : <Avatar avatarSource={specialAvatar} size={small ? 'small' : 'medium'} noMargin />}
            </Dot>}
          </Column>
          <Column collapse={false} verticalAlign="middle">
            <RowColumn>
              <Link to={interactionUrl} onClick={() => handleClick(interactionAction)} data-test="notification-content">
                <MarkdownWrapper read={!!read}>
                  <React.Fragment>
                    <div ref={notificationRef} tabIndex={0} onMouseEnter={() => setNotificationHover(true)} onMouseOver={() => setNotificationHover(true)}
                      onMouseLeave={() => setNotificationHover(false)} onMouseOut={() => setNotificationHover(false)}>
                      <Typography
                        type={(small || isSmall) ? 'subtitle1' : 'compact'}
                        weight="medium"
                        color={notificationHover ? 'primary1' : 'black1'}
                        as="p">
                        {markdown}
                      </Typography>
                    </div>
                    <Tooltip trigger={notificationRef} size="small" dark style={{ zIndex: '3001' }}>
                      {markdown}
                    </Tooltip>
                  </React.Fragment>
                </MarkdownWrapper>
              </Link>
            </RowColumn>
            {!!additionalInfo && <RowColumn>
              {additionalInfo}
            </RowColumn>}
          </Column>
          <Column shrink verticalAlign="middle">
            <CleanButton onClick={() => toggleRead(notificationId, !read)} data-test="mark-read-unread" title={t(read ? 'Notifications.Common:MarkUnread' : 'Notifications.Common:MarkRead')}>
              <Icon size="small" type="notification-read" color={!read ? 'primary1' : 'grey1'} />
            </CleanButton>
          </Column>
        </Row>}
        {!displayData && <CleanButton onClick={handleClick} data-test="notification-content">
          <Row align="left" verticalAlign="top" expanded span>
            <Column shrink>
              <Avatar size={small ? 'small' : 'medium'} avatarSource={Profile}
                description={t('Papier.Common:AvatarAlt').toString()} noMargin />
            </Column>
            <Column collapse={false} verticalAlign="middle">
              <RowColumn>
                <MarkdownWrapper>
                  <Typography type={small ? 'subtitle1' : 'compact'} weight="medium" color="black2" as="span">
                    {t('Notifications.Common:MissingNotification.Title')}
                  </Typography>
                </MarkdownWrapper>
              </RowColumn>
              <RowColumn>
                <Typography type={small ? 'subtitle2' : 'subtitle1'} color="black3" as="span" italic>
                  {t('Notifications.Common:MissingNotification.Subtitle')}
                </Typography>
              </RowColumn>
            </Column>
          </Row>
        </CleanButton>}

      </Wrapper>
      {!small && items.length - 1 === index && <Row align="center">
        <Column columns={{ small: 12, medium: 12, large: 8 }}>
          <Wrapper noBackground style={{ ...style, top: endAssetTopHeight, paddingTop: '1rem', marginBottom: (small || isSmall) ? '16rem' : '9rem', border: 0, height: 'auto' }}>
            { showEndAsset ? <EmptyState
              image={<ImageBlock src={EmptyNotifications} alt="" aria-hidden="true" />}
              title={t('Spaces.Common:EndOf.Title').toString()}
              subtitle={t('Notifications.Common:EndOfFeed.Subtitle').toString()} />
              : ''}
          </Wrapper>
        </Column>
      </Row>}
    </Row>
  );
};

export default withErrorBoundaries(NotificationItem as FunctionComponent);
