import styled, { AnyStyledComponent, StyledComponentInnerAttrs } from 'styled-components';
import IconSkeleton from '../IconSkeleton';
import { Colors } from '@myblueprint-spaces/papier-core';
import { ICommentSkeletonProps } from './types';

export const SkeletonComment = styled.div`
  margin-top: 0.5rem;
  border-radius: 0.25rem;
  overflow: hidden;
`;

export const SkeletonCommentBody = styled.div`
  padding: 0.5rem;
  background-color: ${Colors.grey4};
`;

export const StyledIconSkeleton = styled(IconSkeleton).attrs(({ alignRight, style, ...rest }: ICommentSkeletonProps): StyledComponentInnerAttrs<AnyStyledComponent> => ({
  ...rest,
  style: {
    ...style,
    marginRight: alignRight ? '' : '0.5rem',
    marginLeft: alignRight ? '0.5rem' : ''
  }
}))``;

export const SkeletonCommentName = styled.div(({ alignRight }: ICommentSkeletonProps) => `
  text-align: ${alignRight ? 'right' : 'left'};
  div { display: inline-block; }
`);
