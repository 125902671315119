import styled from 'styled-components';
import { Breakpoints, Colors } from '@myblueprint-spaces/papier-core';

export const BannerWrapper = styled.div`
  padding: 0.875rem 0;
  background-color: ${Colors.secondary1};
  color: ${Colors.white};
  text-align: center;

  @media (max-width: ${Breakpoints.medium}em) {
    padding: 1rem 0;
  }
`;
