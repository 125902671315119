import styled from 'styled-components';
import { Colors } from '@myblueprint-spaces/papier-core';
import { Row } from '@myblueprint-spaces/papier-web/lib/Common/Grid';
import { PopoverElem } from '@myblueprint-spaces/papier-web/lib/Common/Popover';

export const Wrapper = styled(PopoverElem)(({ showRefresh, viewAll }: { showRefresh: boolean, viewAll: boolean }) => `
  padding-bottom: calc(7rem ${showRefresh ? '+ 3.5rem' : ''} ${viewAll ? '+ 3rem' : ''});
  width: 32rem;
  height: 32rem;
  background: ${Colors.white}
`);

export const BottomInfo = styled(Row)`
  padding: 0.75rem;
  border-top: 0.0625rem solid ${Colors.grey3}
`;

export const NotificationsTopWrapper = styled.div`
  display: flex;
  align-items: center;
  min-height: 3.25rem;
  padding-bottom: 0.75rem;
  border-bottom: 0.0625rem solid ${Colors.grey3};
`;
