import Button from 'src/Common/Button';
import * as PropTypes from 'prop-types';
import * as React from 'react';
import { DefaultFormButtonContainer, StyledForm } from './styles';
import { ButtonStatus } from '@myblueprint-spaces/papier-core';
import { IFormProps } from './types';
import { ButtonProps } from '../Button/Button';

interface IHandleSubmitProps {
  onValidate: () => void,
  setStatus: React.Dispatch<unknown>,
  onSubmit: () => void,
  showResult: boolean,
  onSuccess: (result: unknown) => void,
  onFail: (error: unknown) => void,
  showResultForValidation: boolean
}

export const FormContext = React.createContext(null);

export const handleSubmit = ({ onValidate, setStatus, onSubmit, showResult, onSuccess, onFail, showResultForValidation }: IHandleSubmitProps) => (e: { preventDefault: () => void }): void => {
  e.preventDefault();
  if (!onValidate || onValidate()) {
    setStatus('loading');
    Promise.resolve(onSubmit()).then((result) => {
      setStatus(showResult ? 'success' : 'initial');
      if (showResult) {
        setTimeout(() => {
          setStatus('initial');
        }, 3000);
      }
      if (onSuccess) {
        onSuccess(result);
      }
    }, (error) => {
      setStatus(showResult ? 'fail' : 'initial');
      if (showResult) {
        setTimeout(() => {
          setStatus('initial');
        }, 3000);
      }
      if (onFail) {
        onFail(error);
      }
    });
  } else if (showResultForValidation) {
    setStatus('fail');
    setTimeout(() => {
      setStatus('initial');
    }, 3000);
  }
};

export function Form({ onValidate, onSubmit, showResult, children, button, onSuccess, onFail, flex, showResultForValidation, ...rest }: IFormProps): React.ReactElement {
  const [status, setStatus] = React.useState(null);

  React.useLayoutEffect(() => {
    setStatus(ButtonStatus.initial);
  }, []);

  const onFormSubmit = React.useCallback(handleSubmit({ onValidate, setStatus, onSubmit, showResult, onSuccess, onFail, showResultForValidation }), [onValidate, setStatus, onSubmit, showResult, onSuccess, onFail, showResultForValidation]);

  const defaultButtonProps = {
    size: 'medium',
    expanded: true,
    type: 'submit'
  } as ButtonProps;

  return (
    <FormContext.Provider value={status}>
      <StyledForm onSubmit={onFormSubmit} flex={flex} {...rest}>
        {children}
        {button && <FormContext.Consumer>
          {(consumerStatus) => (
            <DefaultFormButtonContainer>
              <Button {...defaultButtonProps} {...button} status={consumerStatus} />
            </DefaultFormButtonContainer>
          )}
        </FormContext.Consumer>}
      </StyledForm>
    </FormContext.Provider>
  );
}

Form.propTypes = {
  onValidate: PropTypes.func,
  onSubmit: PropTypes.func,
  onSuccess: PropTypes.func,
  onFail: PropTypes.func,
  showResult: PropTypes.bool,
  showResultForValidation: PropTypes.bool,
  button: PropTypes.shape({
    id: PropTypes.string,
    text: PropTypes.string.isRequired,
    size: PropTypes.string,
    expanded: PropTypes.bool,
    disabled: PropTypes.bool
  }),
  children: PropTypes.node,
  flex: PropTypes.bool
};

export default React.memo(Form);
