import { Colors, primaryFocus, shadow2, FontSizes, FontWeights } from '@myblueprint-spaces/papier-core';
// @ts-expect-error Module '"../../../node_modules/@types/react-select/src/styles"' has no exported member 'styleFn' - outdated @types lib
import { styleFn, Styles } from 'react-select/src/styles';
import styled from 'styled-components';
import { DropdownSizesType } from './types';

export const DropdownLabelContainer = styled.div`
  margin-bottom: 0.25rem;
`;

const defaultBorderRadius = '0.25rem';

const controlStyles: styleFn = (_, state) => ({
  'display': 'flex',
  'flexWrap': 'wrap',
  'alignItems': 'center',
  'justifyContent': 'space-between',
  'transition': ['box-shadow', 'background-color', 'border-color'].map((s) => `${s} 0.2s ease-in-out`).join(','),
  'borderWidth': '0.0625rem',
  'borderStyle': 'solid',
  'borderColor': state.isDisabled
    ? Colors.grey2
    : state.selectProps.validationState === 'error'
      ? Colors.danger1
      : state.selectProps.validationState === 'success'
        ? Colors.success1
        : state.isFocused
          ? Colors.primary1
          : Colors.grey1,
  'cursor': 'pointer',
  'backgroundColor': state.isDisabled ? Colors.grey4 : Colors.white,
  'boxShadow': state.isFocused ? primaryFocus : 'none',
  'marginBottom': state.selectProps.bottomMargin ? state.selectProps.validationState ? '0.25rem' : '1.5rem' : 0,
  '&:hover': {
    borderColor: state.isFocused ? Colors.primary1 : Colors.black3
  }
});

const inputStyles: styleFn = (_, state) => ({
  padding: 0,
  color: state.isDisabled ? Colors.black3 : Colors.black1,
  lineHeight: 1
});

const placeholderStyles: styleFn = () => ({
  margin: 0,
  color: Colors.black3,
  position: 'absolute'
});

const valueContainerStyles: styleFn = (_, state) => ({
  display: 'flex',
  flexWrap: 'wrap',
  flex: 1,
  alignItems: 'center',
  position: 'relative',
  overflow: 'hidden',
  lineHeight: 1,
  fontSize: 1,
  color: state.isDisabled ? Colors.black3 : Colors.black3,
  justifyContent: state.selectProps.rounded ? 'center' : 'flex-start'
});

const dropdownIndicatorStyles: styleFn = (_, state) => ({
  padding: 0,
  marginLeft: '0.25rem',
  display: 'flex',
  transition: 'transform 0.2s ease-in-out',
  transform: state.isOpen ? 'rotate(0);' : 'rotate(180deg);'
});

const indicatorSeparatorStyles: styleFn = () => ({
  display: 'none'
});

const indicatorsContainerStyles: styleFn = () => ({
  display: 'flex',
  alignItems: 'center'
});

const clearIndicatorStyles: styleFn = () => ({
  padding: 0,
  marginLeft: '0.25rem',
  display: 'flex'
});

const singleValueStyles: styleFn = () => ({
  margin: 0,
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden'
});

const menuStyles: styleFn = () => ({
  padding: '0.5rem 0',
  width: '100%',
  borderRadius: '0.25rem',
  boxShadow: shadow2,
  backgroundColor: Colors.white,
  marginBottom: '0.5rem',
  marginTop: '0.5rem',
  zIndex: 1
});

const menuListStyles: styleFn = () => ({
  padding: 0,
  maxHeight: 300,
  overflowY: 'auto'
});

const optionStyles: styleFn = (_, state) => {
  return {
    cursor: state.isDisabled ? 'not-allowed' : 'pointer',
    color: state.isDisabled ? Colors.black3 : state.isSelected ? Colors.primary1 : state.isFocused ? Colors.black1 : Colors.black3,
    transition: 'all 0.2s ease-in-out',
    backgroundColor: state.isDisabled ? Colors.grey4 : state.isFocused ? Colors.grey4 : Colors.white
  };
};

// @ts-expect-error Generic type 'Styles<OptionType, IsMulti>' requires 2 type argument(s) - outdated @types lib
export function dropdownStyles(size: DropdownSizesType): Styles {
  switch (size) {
    case 'small':
      return {
        control: (provided, state) => {
          return {
            ...controlStyles(provided, state),
            padding: state.selectProps.rounded
              ? state.isMulti && state.hasValue
                ? '0.0625rem 0.3125rem'
                : '0.3125rem 0.625rem'
              : state.isMulti && state.hasValue
                ? '0.0625rem 0.3125rem 0.0625rem 0.0625rem'
                : '0.3125rem',
            marginBottom: state.selectProps.bottomMargin ? state.selectProps.validationState ? '0.25rem' : '1.5rem' : 0,
            borderRadius: state.selectProps.rounded ? '1.25rem' : defaultBorderRadius
          };
        },
        input: (provided, state) => ({
          ...inputStyles(provided, state),
          fontSize: `${FontSizes.subtitle1.size}rem`,
          lineHeight: FontSizes.subtitle1.lineHeight,
          fontWeight: FontWeights.medium
        }),
        placeholder: (provided, state) => ({
          ...placeholderStyles(provided, state),
          fontSize: `${FontSizes.subtitle1.size}rem`,
          lineHeight: FontSizes.subtitle1.lineHeight,
          fontWeight: FontWeights.medium,
          height: '1.25rem',
          padding: state.isMulti ? '0 0.25rem' : 0
        }),
        valueContainer: (provided, state) => ({
          ...valueContainerStyles(provided, state),
          minHeight: '1.25rem',
          padding: state.isMulti ? 0 : '0 0.25rem'
        }),
        dropdownIndicator: dropdownIndicatorStyles,
        clearIndicator: clearIndicatorStyles,
        indicatorSeparator: indicatorSeparatorStyles,
        indicatorsContainer: indicatorsContainerStyles,
        singleValue: (provided, state) => ({
          ...singleValueStyles(provided, state),
          fontSize: `${FontSizes.subtitle1.size}rem`,
          lineHeight: FontSizes.subtitle1.lineHeight,
          fontWeight: FontWeights.medium,
        }),
        menu: (provided, state) => ({
          ...provided,
          ...menuStyles(provided, state)
        }),
        menuList: (provided, state) => ({
          ...menuListStyles(provided, state)
        }),
        option: (provided, state) => ({
          ...optionStyles(provided, state),
          padding: '0.375rem 0.75rem',
          fontSize: `${FontSizes.subtitle1.size}rem`,
          lineHeight: FontSizes.subtitle1.lineHeight,
          fontWeight: FontWeights.medium,
        }),
        multiValue: () => ({
          margin: 2
        }),
        multiValueRemove: () => ({})
      };
    default:
    case 'medium':
      return {
        control: (provided, state) => ({
          ...controlStyles(provided, state),
          padding: state.selectProps.rounded
            ? state.isMulti && state.hasValue
              ? '0.0625rem 0.3125rem'
              : '0.3125rem 0.625rem'
            : state.isMulti && state.hasValue
              ? '0.0625rem 0.3125rem 0.0625rem 0.0625rem'
              : '0.1875rem 0.3125rem',
          borderRadius: state.selectProps.rounded ? '1.5rem' : defaultBorderRadius
        }),
        input: (provided, state) => ({
          ...inputStyles(provided, state),
          fontSize: `${FontSizes.body2.size}rem`,
          lineHeight: FontSizes.body2.lineHeight,
          fontWeight: FontWeights.regular
        }),
        placeholder: (provided, state) => ({
          ...placeholderStyles(provided, state),
          fontSize: `${FontSizes.body2.size}rem`,
          lineHeight: FontSizes.body2.lineHeight,
          fontWeight: FontWeights.regular,
          height: 28,
          padding: state.isMulti ? '0 8px' : 0
        }),
        valueContainer: (provided, state) => ({
          ...valueContainerStyles(provided, state),
          minHeight: 28,
          padding: state.isMulti ? 0 : '0 8px'
        }),
        dropdownIndicator: dropdownIndicatorStyles,
        clearIndicator: clearIndicatorStyles,
        indicatorSeparator: indicatorSeparatorStyles,
        indicatorsContainer: indicatorsContainerStyles,
        singleValue: (provided, state) => ({
          ...singleValueStyles(provided, state),
          fontSize: `${FontSizes.body2.size}rem`,
          lineHeight: FontSizes.body2.lineHeight,
          fontWeight: FontWeights.regular,
        }),
        menu: (provided, state) => ({
          ...provided,
          ...menuStyles(provided, state)
        }),
        menuList: (provided, state) => ({
          ...menuListStyles(provided, state)
        }),
        option: (provided, state) => ({
          ...optionStyles(provided, state),
          padding: '0.375rem 1rem',
          fontSize: `${FontSizes.body2.size}rem`,
          lineHeight: FontSizes.body2.lineHeight,
          fontWeight: FontWeights.regular,
        }),
        multiValue: () => ({
          margin: '0.125rem'
        }),
        multiValueRemove: () => ({})
      };
    case 'large':
      return {
        control: (provided, state) => {
          return {
            ...controlStyles(provided, state),
            padding: state.selectProps.rounded
              ? state.isMulti && state.hasValue
                ? '0.3125rem 0.5625rem'
                : '0.5625rem 1.125rem'
              : state.isMulti && state.hasValue
                ? '0.3125rem 0.5625rem 0.3125rem 0.3125rem'
                : '0.4375rem 0.5625rem',
            borderRadius: state.selectProps.rounded ? '2.5rem' : defaultBorderRadius
          };
        },
        input: (provided, state) => ({
          ...inputStyles(provided, state),
          fontSize: `${FontSizes.body2.size}rem`,
          lineHeight: FontSizes.body2.lineHeight,
          fontWeight: FontWeights.regular
        }),
        placeholder: (provided, state) => ({
          ...placeholderStyles(provided, state),
          fontSize: `${FontSizes.body2.size}rem`,
          lineHeight: FontSizes.body2.lineHeight,
          fontWeight: FontWeights.regular,
          height: '1.75rem',
          padding: state.isMulti ? '0 0.125rem' : 0
        }),
        valueContainer: (provided, state) => ({
          ...valueContainerStyles(provided, state),
          minHeight: '1.75rem',
          padding: state.isMulti ? 0 : '0 0.125rem'
        }),
        dropdownIndicator: dropdownIndicatorStyles,
        clearIndicator: clearIndicatorStyles,
        indicatorSeparator: indicatorSeparatorStyles,
        indicatorsContainer: indicatorsContainerStyles,
        singleValue: (provided, state) => ({
          ...singleValueStyles(provided, state),
          fontSize: `${FontSizes.body2.size}rem`,
          lineHeight: FontSizes.body2.lineHeight,
          fontWeight: FontWeights.regular,
        }),
        menu: (provided, state) => ({
          ...provided,
          ...menuStyles(provided, state)
        }),
        menuList: (provided, state) => ({
          ...menuListStyles(provided, state)
        }),
        option: (provided, state) => ({
          ...optionStyles(provided, state),
          padding: '0.625rem 1rem',
          fontSize: `${FontSizes.body2.size}rem`,
          lineHeight: FontSizes.body2.lineHeight,
          fontWeight: FontWeights.regular,
        }),
        multiValue: () => ({
          margin: '0.125rem'
        }),
        multiValueRemove: () => ({})
      };
    case 'xlarge':
      return {
        control: (provided, state) => {
          return {
            ...controlStyles(provided, state),
            padding: state.selectProps.rounded
              ? state.isMulti && state.hasValue
                ? '0.5625rem 0.6875rem'
                : '0.6875rem 1.375rem'
              : state.isMulti && state.hasValue
                ? '0.5625rem 0.6875rem 0.5625rem 0.5625rem'
                : '0.375rem 0.6875rem',
            borderRadius: state.selectProps.rounded ? '3rem' : defaultBorderRadius
          };
        },
        input: (provided, state) => ({
          ...inputStyles(provided, state),
          fontSize: `${FontSizes.body1.size}rem`,
          lineHeight: FontSizes.body1.lineHeight,
          fontWeight: FontWeights.regular
        }),
        placeholder: (provided, state) => ({
          ...placeholderStyles(provided, state),
          fontSize: `${FontSizes.body1.size}rem`,
          lineHeight: FontSizes.body1.lineHeight,
          fontWeight: FontWeights.regular,
          padding: state.isMulti ? '0 0.125rem' : 0
        }),
        valueContainer: (provided, state) => ({
          ...valueContainerStyles(provided, state),
          minHeight: '2rem',
          padding: state.isMulti ? 0 : '0 0.125rem'
        }),
        dropdownIndicator: dropdownIndicatorStyles,
        clearIndicator: clearIndicatorStyles,
        indicatorSeparator: indicatorSeparatorStyles,
        indicatorsContainer: indicatorsContainerStyles,
        singleValue: (provided, state) => ({
          ...singleValueStyles(provided, state),
          fontSize: `${FontSizes.body1.size}rem`,
          lineHeight: FontSizes.body1.lineHeight,
          fontWeight: FontWeights.regular
        }),
        menu: (provided, state) => ({
          ...provided,
          ...menuStyles(provided, state)
        }),
        menuList: (provided, state) => ({
          ...menuListStyles(provided, state)
        }),
        option: (provided, state) => ({
          ...optionStyles(provided, state),
          padding: '0.75rem 1.25rem',
          fontSize: `${FontSizes.body1.size}rem`,
          lineHeight: FontSizes.body1.lineHeight,
          fontWeight: FontWeights.regular
        }),
        multiValue: () => ({
          margin: '0.125rem'
        }),
        multiValueRemove: () => ({})
      };
  }
}
