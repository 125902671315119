import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { IState } from '@myblueprint-spaces/redux';
import { GlobalActions, GlobalSelectors } from '@myblueprint-spaces/redux/lib/globals';
import { useTranslation } from 'react-i18next';
import * as Fallback from '@myblueprint-spaces/spaces-resx';
import { AppType } from '@myblueprint-spaces/redux/lib/api/common/globals/enums';

export default function useConfig() {
  const dispatch = useDispatch<ThunkDispatch<IState, unknown, Action>>();
  const { i18n: { language: culture } } = useTranslation();

  React.useEffect(() => {
    dispatch(GlobalActions.setAppType(AppType.Web));
  }, []);

  return useSelector((state: IState) => {
    const langSupported = Object.prototype.hasOwnProperty.call(Fallback.i18nextFallback, culture);
    const usedLang = langSupported ? culture : Fallback.i18nextFallback['default'][0];

    return GlobalSelectors.getConfig(state, usedLang);
  });
}
