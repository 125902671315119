import * as PropTypes from 'prop-types';
import * as React from 'react';
import { StyledProgress } from './styles';
import { IProgressBarProps, ProgressBarSizes } from './types';

export function ProgressBar(props: IProgressBarProps): React.ReactElement {
  return <StyledProgress {...props} />;
}

ProgressBar.propTypes = {
  id: PropTypes.string,
  value: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  size: PropTypes.oneOf(ProgressBarSizes),
  color: PropTypes.string
};

export default React.memo(ProgressBar);
