import { useRovingTabIndexProps } from '@myblueprint-spaces/react-roving-tabindex';
import { Column, Row } from 'src/Common/Grid';
import Pill from 'src/Common/Pill';
import { AllPopoverItemPropsType, PopoverItemPropsType } from 'src/Common/Popover/types';
import * as PropTypes from 'prop-types';
import * as React from 'react';
import { MenuItemElem, MenuItemButton, PopoverIcon, PopoverTypography, PopoverDivider, CustomLink, CustomCheckbox } from '../styles';
import { IconNames, IconNameType } from '@myblueprint-spaces/papier-icons';
import { useId } from 'src/modules';
import { isExternal } from '@myblueprint-spaces/papier-core';
import { IconSizesType } from 'src/Common/Icon';

export function PopoverItem(props: PopoverItemPropsType): React.ReactElement | React.ReactElement[] {
  const { id, title, onClick: paramOnClick, textColor, icon, checkbox, type, handleDeactivate, startsFocused, linkTo, pill, keepOpen = false, children, ...rest } = props as AllPopoverItemPropsType;
  const usedId = useId(id);

  const usedIcon = !icon ? null
    : typeof icon === 'object' ? icon : {
      type: icon,
      position: 'right',
      size: 'large'
    };

  const popoverItemRef = React.useRef<HTMLDivElement & HTMLButtonElement & HTMLAnchorElement & HTMLHRElement>(null);

  const clickHandle = (e) => {
    paramOnClick(e);
    !keepOpen && handleDeactivate && type !== 'checkbox' && handleDeactivate(e, true);
  };

  const keyDownHandle = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      if (type === 'link') {
        document.getElementById(usedId + '--link').click();
      } else if (type === 'checkbox') {
        document.getElementById(usedId + '--checkbox').click();
      } else {
        clickHandle(e);
      }
    }
    if (e.key === 'Tab' || e.key === 'Escape') {
      handleDeactivate(e, true);
    }
  };

  const { focusHoverEvent, isHovered, isFocussed, onClick, ref, ...rrtiProps } = useRovingTabIndexProps<HTMLDivElement & HTMLButtonElement & HTMLAnchorElement & HTMLHRElement>({
    ref: popoverItemRef,
    disabled: type === 'divider',
    spaceClicks: true,
    enterClicks: true,
    customKeyDownHandler: keyDownHandle,
    actionOnClick: clickHandle
  });

  const itemProps = {
    ...rrtiProps,
    className: 'item'
  };

  // props that *need* to overide whatever prop is being defined
  const commonProps = {
    ref,
    tabIndex: rrtiProps.tabIndex,
    focusHoverEvent: focusHoverEvent,
    id: usedId
  };
  const { type: iconType, position = 'right', size = 'large' } = (usedIcon || {});

  switch (type) {
    case 'divider':
      // Do NOT remove prop. Roving TabIndex needs to control it
      return <PopoverDivider onKeyDown={keyDownHandle} {...commonProps} role="separator" aria-hidden="true" />;
    case 'link':
      return (
        <CustomLink href={linkTo} target={isExternal(linkTo) ? '_blank' : ''} rel={isExternal(linkTo) ? 'noopener' : ''} {...itemProps} {...commonProps} role="menuitem">
          <MenuItemElem id={usedId + '--link'}>
            <Row>
              <Column>
                <PopoverTypography type="body2" weight="medium" focusHoverEvent={focusHoverEvent && textColor !== 'danger1'} color={textColor}>{title}</PopoverTypography>
              </Column>
            </Row>
          </MenuItemElem>
        </CustomLink>
      );
    default:
    case 'menuItem':
      return (
        <MenuItemButton {...itemProps} {...commonProps} onClick={onClick} {...rest} role="menuitem" data-test="popover-menu-item">
          <Row verticalAlign="middle" collapse={false}>
            {usedIcon && position === 'left' && <Column shrink>
              <PopoverIcon position={position} focusHoverEvent={focusHoverEvent} type={iconType as IconNameType} size={size as IconSizesType} color="grey1" removeMargin />
            </Column>
            }
            <Column collapse={usedIcon ? position === 'left' : undefined}>
              <PopoverTypography type="body2" weight="medium" focusHoverEvent={focusHoverEvent && textColor !== 'danger1'} color={textColor}>{title}</PopoverTypography>
            </Column>
            {pill && <Column shrink verticalAlign="middle">
              <Pill {...pill} />
            </Column>
            }
            {usedIcon && position === 'right' && <Column shrink>
              <PopoverIcon position={position} focusHoverEvent={focusHoverEvent} type={iconType} size={size as IconSizesType} color="grey1" removeMargin />
            </Column>
            }
          </Row>
        </MenuItemButton>
      );
    case 'checkbox':
      return (
        <MenuItemElem {...itemProps} {...commonProps} role="menuitemcheckbox" onClick={onClick}>
          <Row>
            <Column>
              <CustomCheckbox id={usedId + '--checkbox'} tabIndex={-1} label={title} labelId={checkbox.labelId} size="medium" align={checkbox.align || 'right'}
                checked={checkbox.checked} onChange={onClick as unknown as () => void} bottomMargin={false} dataTest="popover-checkbox" hideCheckBoxControl={checkbox.hideCheckBoxControl} />
            </Column>
          </Row>
        </MenuItemElem>
      );
    case 'custom':
      return (
        React.Children.toArray(children).map((c: React.ReactElement) => {
          // Original props are cloned, but because we are redefining some default props here, we need to mix them in order to make both working
          const mixedItemProps = {};
          const cProps = c.props || {};
          Object.keys(itemProps).forEach((k) => {
            if (typeof itemProps[k] === 'string') {
              mixedItemProps[k] = cProps[k] ? itemProps[k].concat(' ' + cProps[k]) : itemProps[k];
            } else if (typeof itemProps[k] === 'function') {
              mixedItemProps[k] = (e) => {
                itemProps[k](e);
                (cProps[k] && cProps[k](e));
              };
            }
          });
          return React.cloneElement(c as React.ReactElement, { ...mixedItemProps, ...commonProps });
        })
      );
  }
}

PopoverItem.defaultProps = {
  textColor: 'black3'
};

PopoverItem.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.oneOf(['menuItem', 'checkbox', 'link', 'divider', 'custom']).isRequired,
  onClick: function (props, propName) {
    if (props.type === 'menuItem' && !props[propName]) {
      return new Error(`'${propName}' is required when type=='menuItem'.`);
    }
    if (props.type === 'link' && props[propName]) {
      return new Error(`When provided type is link, the url should be passed with 'linkTo' not '${propName}'.`);
    }
  },
  linkTo: function (props, propName) {
    if (props.type === 'link' && !props[propName]) {
      return new Error(`'${propName}' is required when type=='link'.`);
    }
  },
  textColor: PropTypes.string,
  checkbox: function (props, propName) {
    if (props.type === 'checkbox' && !props[propName]) {
      return new Error(`'${propName}' is required when a type 'checkbox' is provided.`);
    }
    if (props.type !== 'checkbox' && props[propName]) {
      return new Error(`You can't pass '${propName}' if type provided is not checkbox.`);
    }
    if (props[propName] && (!Object.keys(props[propName]).includes('checked') || !Object.keys(props[propName]).includes('labelId'))) {
      return new Error(`Incorrect format provided to '${propName}'.`);
    }
  },
  pill: function (props, propName) {
    if (props.type !== 'menuItem' && props[propName]) {
      return new Error(`You can't pass '${propName}' if type provided is not checkbox.`);
    }
    if (props[propName] && (!Object.keys(props[propName]).includes('value'))) {
      return new Error(`Incorrect format provided to '${propName}'.`);
    }
  },
  icon: PropTypes.oneOf(IconNames),
  startsFocused: PropTypes.bool,
  keepOpen: PropTypes.bool
};

export default React.memo(PopoverItem as React.FunctionComponent<PopoverItemPropsType>);
