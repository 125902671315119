import { Colors } from '@myblueprint-spaces/papier-core';
import * as React from 'react';
import { BlockSvg } from 'src/Atoms';
import { CSSProperties } from 'styled-components';

/* eslint-disable max-len */
/* tslint:disable max-line-length */
const logoPrimary = '#0092ff';
const logoSecondary = '#22224c';

export const getEnLogo = (isWhite: boolean, productName: string, width: string | number, height: string | number, props: { style?: CSSProperties }): React.ReactElement => (
  <BlockSvg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 850.5 200" {...props}>
    <title>{productName}</title>
    <path fill={isWhite ? Colors.white : logoSecondary} d="M257.6,117.55c0,0,7.85,7.65,18.05,7.65c5.51,0,10.51-2.86,10.51-8.77c0-12.96-34.17-10.71-34.17-32.95
      c0-12.04,10.4-21.01,24.28-21.01c14.28,0,21.62,7.75,21.62,7.75l-5.71,10.71c0,0-6.94-6.32-16.02-6.32
      c-6.12,0-10.91,3.57-10.91,8.67c0,12.85,34.07,9.69,34.07,32.85c0,11.53-8.77,21.22-23.87,21.22c-16.12,0-24.99-9.89-24.99-9.89
      L257.6,117.55z" />
    <path fill={isWhite ? Colors.white : logoSecondary} d="M310.43,84.09h11.83v3.77c0,1.94-0.2,3.57-0.2,3.57h0.2c0,0,4.49-8.57,16.73-8.57
      c14.08,0,23.05,11.12,23.05,27.24c0,16.53-10.1,27.24-23.77,27.24c-10.3,0-14.89-7.14-14.89-7.14h-0.2c0,0,0.2,1.84,0.2,4.49
      v21.83h-12.95V84.09z M335.93,126.42c7.14,0,13.06-5.81,13.06-16.12c0-9.89-5.3-16.32-12.96-16.32c-6.73,0-13.06,4.9-13.06,16.42
      C322.98,118.47,327.36,126.42,335.93,126.42z" />
    <path fill={isWhite ? Colors.white : logoSecondary} d="M399.17,103.47h1.73v-0.71c0-6.83-4.28-9.18-10-9.18c-7.45,0-14.28,5-14.28,5l-4.9-9.28
      c0,0,8.06-6.43,20.4-6.43c13.67,0,21.62,7.55,21.62,20.71v32.54h-11.93v-4.28c0-2.04,0.2-3.67,0.2-3.67h-0.2
      c0.1,0-3.98,9.18-15.81,9.18c-9.28,0-17.65-5.81-17.65-16.22C368.37,104.59,390.71,103.47,399.17,103.47z M389.28,127.24
      c6.83,0,11.73-7.34,11.73-13.67v-1.22h-2.24c-6.63,0-17.34,0.92-17.34,8.16C381.42,123.98,383.97,127.24,389.28,127.24z" />
    <path fill={isWhite ? Colors.white : logoSecondary} d="M451.7,82.87c13.57,0,19.99,7.75,19.99,7.75l-5.71,8.87c0,0-5.41-5.61-13.57-5.61
      c-9.59,0-15.81,7.24-15.81,16.12c0,8.77,6.32,16.32,16.22,16.32c8.77,0,15.3-6.63,15.3-6.63l5,9.18c0,0-7.45,8.47-21.42,8.47
      c-17.24,0-28.25-12.14-28.25-27.24C423.45,95.31,434.46,82.87,451.7,82.87z" />
    <path fill={isWhite ? Colors.white : logoSecondary} d="M505.56,82.87c14.99,0,23.05,10.92,23.05,24.69c0,1.53-0.31,5-0.31,5H492.4
      c0.82,9.08,7.85,13.77,15.61,13.77c8.36,0,14.99-5.71,14.99-5.71l5,9.28c0,0-7.96,7.45-20.91,7.45
      c-17.24,0-27.95-12.44-27.95-27.24C479.14,94.09,490.05,82.87,505.56,82.87z M515.55,103.68c-0.2-7.04-4.59-10.81-10.3-10.81
      c-6.63,0-11.22,4.08-12.44,10.81H515.55z" />
    <path fill={isWhite ? Colors.white : logoSecondary} d="M540.85,120.61c0,0,5.71,5.71,14.18,5.71c3.67,0,6.83-1.63,6.83-4.69c0-7.75-25.3-6.73-25.3-23.46
      c0-9.69,8.67-15.3,19.89-15.3c11.73,0,17.44,5.92,17.44,5.92l-4.79,9.59c0,0-4.79-4.49-12.75-4.49c-3.37,0-6.63,1.43-6.63,4.69
      c0,7.34,25.3,6.43,25.3,23.16c0,8.67-7.55,15.61-19.89,15.61c-12.96,0-20.2-7.65-20.2-7.65L540.85,120.61z" />
    <path fill={isWhite ? Colors.white : logoPrimary} d="M587.46,63.69h43.35v11.32h-30.19v18.97H625v11.32h-24.38v19.48h31.83v11.32h-44.98V63.69z" />
    <path fill={isWhite ? Colors.white : logoPrimary} d="M646.11,63.69h24.68c22.34,0,36.93,13.26,36.93,36.11c0,22.85-14.59,36.31-36.93,36.31h-24.68V63.69z
      M670.08,124.79c14.69,0,24.07-8.67,24.07-24.99c0-16.12-9.59-24.79-24.07-24.79h-10.81v49.78H670.08z" />
    <path fill={isWhite ? Colors.white : logoPrimary} d="M720.47,63.69h13.16v46.41c0,9.69,6.32,15.2,16.01,15.2s16.12-5.51,16.12-15.3V63.69h13.16v46.41
      c0,16.22-11.73,27.24-29.17,27.24c-17.54,0-29.28-11.02-29.28-27.24V63.69z" />
    {getLogoDot(isWhite)}
  </BlockSvg>
);

export const getFrLogo = (isWhite: boolean, productName: string, width: string | number, height: string | number, props: { style?: CSSProperties }): React.ReactElement => (
  <BlockSvg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 850.5 200" {...props}>
    <title>{productName}</title>
    <path fill={isWhite ? Colors.white : logoSecondary} d="M256.57,63.91h43.35v11.32h-30.19v18.97h24.38v11.32h-24.38v19.48h31.83v11.32h-44.98V63.91z" />
    <path fill={isWhite ? Colors.white : logoSecondary} d="M314.71,120.83c0,0,5.71,5.71,14.18,5.71c3.67,0,6.83-1.63,6.83-4.69c0-7.75-25.3-6.73-25.3-23.46
      c0-9.69,8.67-15.3,19.89-15.3c11.73,0,17.44,5.92,17.44,5.92l-4.79,9.59c0,0-4.79-4.49-12.75-4.49c-3.37,0-6.63,1.43-6.63,4.69
      c0,7.34,25.3,6.43,25.3,23.16c0,8.67-7.55,15.61-19.89,15.61c-12.96,0-20.2-7.65-20.2-7.65L314.71,120.83z" />
    <path fill={isWhite ? Colors.white : logoSecondary} d="M359.28,84.32h11.83v3.77c0,1.94-0.2,3.57-0.2,3.57h0.2c0,0,4.49-8.57,16.73-8.57
      c14.08,0,23.05,11.12,23.05,27.24c0,16.53-10.1,27.24-23.77,27.24c-10.3,0-14.89-7.14-14.89-7.14h-0.2c0,0,0.2,1.84,0.2,4.49
      v21.83h-12.95V84.32z M384.78,126.65c7.14,0,13.06-5.81,13.06-16.12c0-9.89-5.3-16.32-12.96-16.32c-6.73,0-13.06,4.9-13.06,16.42
      C371.83,118.69,376.21,126.65,384.78,126.65z" />
    <path fill={isWhite ? Colors.white : logoSecondary} d="M448.02,103.7h1.73v-0.71c0-6.83-4.28-9.18-10-9.18c-7.45,0-14.28,5-14.28,5l-4.9-9.28
      c0,0,8.06-6.43,20.4-6.43c13.67,0,21.62,7.55,21.62,20.71v32.54h-11.93v-4.28c0-2.04,0.2-3.67,0.2-3.67h-0.2
      c0.1,0-3.98,9.18-15.81,9.18c-9.28,0-17.65-5.81-17.65-16.22C417.22,104.82,439.56,103.7,448.02,103.7z M438.13,127.46
      c6.83,0,11.73-7.34,11.73-13.67v-1.22h-2.24c-6.63,0-17.34,0.92-17.34,8.16C430.27,124.2,432.82,127.46,438.13,127.46z" />
    <path fill={isWhite ? Colors.white : logoSecondary} d="M500.55,83.09c13.57,0,19.99,7.75,19.99,7.75l-5.71,8.87c0,0-5.41-5.61-13.57-5.61
      c-9.59,0-15.81,7.24-15.81,16.12c0,8.77,6.33,16.32,16.22,16.32c8.77,0,15.3-6.63,15.3-6.63l5,9.18c0,0-7.45,8.47-21.42,8.47
      c-17.24,0-28.25-12.14-28.25-27.24C472.3,95.54,483.31,83.09,500.55,83.09z" />
    <path fill={isWhite ? Colors.white : logoSecondary} d="M554.41,83.09c14.99,0,23.05,10.92,23.05,24.69c0,1.53-0.31,5-0.31,5h-35.91
      c0.82,9.08,7.85,13.77,15.61,13.77c8.36,0,14.99-5.71,14.99-5.71l5,9.28c0,0-7.96,7.45-20.91,7.45
      c-17.24,0-27.95-12.44-27.95-27.24C527.99,94.31,538.9,83.09,554.41,83.09z M564.4,103.9c-0.2-7.04-4.59-10.81-10.3-10.81
      c-6.63,0-11.22,4.08-12.44,10.81H564.4z" />
    <path fill={isWhite ? Colors.white : logoSecondary} d="M589.7,120.83c0,0,5.71,5.71,14.18,5.71c3.67,0,6.83-1.63,6.83-4.69c0-7.75-25.3-6.73-25.3-23.46
      c0-9.69,8.67-15.3,19.89-15.3c11.73,0,17.44,5.92,17.44,5.92l-4.79,9.59c0,0-4.79-4.49-12.75-4.49c-3.37,0-6.63,1.43-6.63,4.69
      c0,7.34,25.3,6.43,25.3,23.16c0,8.67-7.55,15.61-19.89,15.61c-12.96,0-20.2-7.65-20.2-7.65L589.7,120.83z" />
    <path fill={isWhite ? Colors.white : logoPrimary} d="M636.31,63.91h43.35v11.32h-30.19v18.97h24.38v11.32h-24.38v19.48h31.83v11.32h-44.98V63.91z M661.2,45.25
				h13.36l-10.71,13.87h-10.4L661.2,45.25z" />
    <path fill={isWhite ? Colors.white : logoPrimary} d="M694.94,63.91h24.68c22.34,0,36.93,13.26,36.93,36.11c0,22.85-14.59,36.31-36.93,36.31h-24.68V63.91z
			 M718.91,125.01c14.69,0,24.07-8.67,24.07-24.99c0-16.12-9.59-24.79-24.07-24.79H708.1v49.78H718.91z" />
    <path fill={isWhite ? Colors.white : logoPrimary} d="M769.3,63.91h13.16v46.41c0,9.69,6.32,15.2,16.01,15.2s16.12-5.51,16.12-15.3V63.91h13.16v46.41
			c0,16.22-11.73,27.24-29.17,27.24c-17.54,0-29.28-11.02-29.28-27.24V63.91z" />
    {getLogoDot(isWhite)}
  </BlockSvg>
);

export const getLogoDot = (isWhite: boolean): React.ReactElement => (
  <React.Fragment>
    <circle fill={isWhite ? Colors.white : logoPrimary} cx="100" cy="100" r="100" />
    <path fill={isWhite ? logoPrimary : Colors.white} d="M74.9,59.3H63.1c-2.7,0-4.8,2.2-4.8,4.9v10.4c0,2.7,2.2,4.8,4.8,4.8h11.8c2.7,0,4.8-2.2,4.8-4.8V64.1
      C79.8,61.4,77.6,59.3,74.9,59.3z" />
    <path fill={isWhite ? logoSecondary : Colors.white} d="M74.9,94.6H63.1c-2.7,0-4.8,2.2-4.8,4.8v36.4c0,2.7,2.2,4.9,4.8,4.9h11.8c2.7,0,4.8-2.2,4.8-4.9V99.4
      C79.8,96.8,77.6,94.6,74.9,94.6z" />
    <path fill={isWhite ? logoPrimary : Colors.white} d="M136.4,59.3h-36.2c-2.9,0-5.3,2.4-5.3,5.3v35.6c0,2.9,2.4,5.3,5.3,5.3h36.2c2.9,0,5.3-2.4,5.3-5.3V64.6
      C141.7,61.6,139.4,59.3,136.4,59.3z" />
    <path fill={isWhite ? logoSecondary : Colors.white} d="M136.4,120.7h-36.2c-2.9,0-5.3,2.4-5.3,5.3v9.5c0,2.9,2.4,5.3,5.3,5.3h36.2c2.9,0,5.3-2.4,5.3-5.3V126
      C141.7,123,139.4,120.7,136.4,120.7z" />
  </React.Fragment>
);
