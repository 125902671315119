import { ActionTypes, Context, DebounceReturn, TabStop } from './types';

export const debounce = (callback: (...args: unknown[]) => void, wait: number): DebounceReturn => {
  let timeoutId: number | null = null;
  return [timeoutId, (...args: unknown[]) => {
    window.clearTimeout(timeoutId as number);
    timeoutId = window.setTimeout(() => {
      callback(...args);
    }, wait);
  }];
};

export const onKeyDown = (event: KeyboardEvent, context: Context, tabIndexId: string, isGrid?: boolean, enterClicks = false, spaceClicks = false): void => {
  // when it is not a grid, both arrows should move focus backwards
  if (event.key === 'ArrowLeft' || (!isGrid && event.key === 'ArrowUp')) {
    context.dispatch({
      type: ActionTypes.TAB_TO_PREVIOUS,
      payload: { id: tabIndexId }
    });
    event.preventDefault();
    // when it is not a grid, both arrows should move focus forward
  } else if (event.key === 'ArrowRight' || (!isGrid && event.key === 'ArrowDown')) {
    context.dispatch({
      type: ActionTypes.TAB_TO_NEXT,
      payload: { id: tabIndexId }
    });
    event.preventDefault();
    // in a grid, should move focus to previous row
  } else if (event.key === 'ArrowUp') {
    context.dispatch({
      type: ActionTypes.TAB_TO_PREVIOUS_ROW,
      payload: { id: tabIndexId }
    });
    event.preventDefault();
    // in a grid, should move focus to next row
  } else if (event.key === 'ArrowDown') {
    context.dispatch({
      type: ActionTypes.TAB_TO_NEXT_ROW,
      payload: { id: tabIndexId }
    });
    event.preventDefault();
    // should move focus to initial element
  } else if (event.key === 'Home') {
    context.dispatch({
      type: ActionTypes.TAB_TO_FIRST,
      payload: { id: tabIndexId }
    });
    event.preventDefault();
    // should move focus to last element
  } else if (event.key === 'End') {
    context.dispatch({
      type: ActionTypes.TAB_TO_LAST,
      payload: { id: tabIndexId }
    });
    event.preventDefault();
  } else if (enterClicks && event.key === 'Enter' || spaceClicks && event.key === ' ') {
    context.dispatch({
      type: ActionTypes.SELECTED,
      payload: { id: tabIndexId }
    });
    event.preventDefault();
  } else if (event.key === 'Tab') {
    context.dispatch({
      type: ActionTypes.LAST_ACTION_ORIGIN,
      payload: null
    });
  }
};

export const onClick = (context: Context, tabIndexId: string): void => {
  context.dispatch({
    type: ActionTypes.CLICKED,
    payload: { id: tabIndexId }
  });
};

export const onMouseMove = (context: Context): void => {
  const { lastActionOrigin } = context?.state || {};
  lastActionOrigin && lastActionOrigin === 'keyboard' && context.dispatch({
    type: ActionTypes.LAST_ACTION_ORIGIN,
    payload: 'mouse'
  });
};

export const register = (context: Context, tabIndexId: string, domElementRef: React.RefObject<HTMLElement>, disabled: boolean): void => {
  context.dispatch({
    type: ActionTypes.REGISTER,
    payload: { id: tabIndexId, domElementRef, disabled: disabled ?? false }
  });
};

export const changeDisabled = (context: Context, tabIndexId: string, domElementRef: React.RefObject<HTMLElement>, disabled: boolean): void => {
  context.dispatch({
    type: ActionTypes.UPDATE,
    payload: { id: tabIndexId, domElementRef, disabled }
  });
};

export const unregister = (context: Context, tabIndexId: string): void => {
  context.dispatch({
    type: ActionTypes.UNREGISTER,
    payload: { id: tabIndexId }
  });
};

export const getValidIds = (overScan: number, newTabStops: TabStop[]) => {
  return newTabStops.filter((tS, i) => !tS.disabled && (!overScan || newTabStops.length <= overScan || (i < (newTabStops.length - overScan) && i > overScan + 1))).map(({ id }) => id);
};

export const calcTabIndex = (context: Context, tabIndexId: string, disabled?: boolean): { tabIndex: number; focused: boolean } => {
  const { selectedId, ignoreLoopEdges, lastActionOrigin } = context?.state || {};
  const selected = !disabled && tabIndexId === selectedId;
  const tabIndex = selected ? 0 : -1;
  const invalidLastOrigin = ignoreLoopEdges ? 'mouse' : 'system';
  const focused = selected && !!lastActionOrigin && lastActionOrigin !== invalidLastOrigin;

  return {
    tabIndex: tabIndex,
    focused: focused
  };
};
