import { Colors, FontSizes, FontWeights, WebFontFamily, ITypographyProps } from '@myblueprint-spaces/papier-core';
import styled, { CSSProperties } from 'styled-components';

export const PureTypography = styled.p((props: ITypographyProps) => {
  const { type, weight, italic, textTransform, ellipsis, clamp } = props;

  let fontStyles = '';
  let fontWeight = '';
  let clampStyle = '';
  const sanitizedType = FontSizes[type] || FontSizes.body1;
  if (type) {
    const { size, lineHeight } = sanitizedType;
    const weightStyle = sanitizedType.defaultWeight;
    fontStyles = `
    font-size: ${size}rem;
    line-height: ${lineHeight};`;
    fontWeight = `font-weight: ${weightStyle};`;
  }
  if (weight) {
    const weightStyle = weight ? (FontWeights[weight] || FontWeights.regular) : sanitizedType.defaultWeight;
    fontWeight = `font-weight: ${weightStyle};`;
  }
  if(clamp) {
    clampStyle = `
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: ${clamp ?? 2};
      -webkit-box-orient: vertical;
      overflow-wrap: break-word;
      hyphens: auto;
      -webkit-hyphens: auto;
    `;
  }
  const fontStyle = italic ? 'font-style: italic;' : '';
  const textTransformStyle = textTransform ? `text-transform: ${textTransform};` : '';
  const ellipsisStyle = ellipsis ? `text-overflow: ellipsis;
  overflow: hidden; white-space: nowrap;` : '';

  return `
  font-family: ${WebFontFamily};
  ${fontStyles}
  ${fontWeight}
  margin: 0;
  ${fontStyle}
  ${textTransformStyle}
  ${ellipsisStyle}
  ${clampStyle}
  `;
});

export const WebTypography = styled(PureTypography).attrs<ITypographyProps & {style?: Partial<Omit<CSSProperties, 'color'>>}>(({ color }) => {
  return color
    ? {
      style: {
        color: Colors[color] || Colors.black1,
      },
    }
    : {};
})(() => '');
