import styled, { StyledComponent } from 'styled-components';
import { commonInputStyles, isIosDevice } from 'src/modules';
import { IBaseTextBoxProps } from './types';

export const BaseTextBox: StyledComponent<'input', IBaseTextBoxProps, Record<string, unknown>> = styled('input')((props: IBaseTextBoxProps) => {
  const { size, valueSize } = props;

  let height: number;

  switch (size) {
    case 'xlarge':
      height = 2;
      break;
    default:
    case 'large':
    case 'medium':
      height = 1.75;
      break;
    case 'small':
      isIosDevice() ? height = 1.75 : height = 1.25;
      break;
  }

  return `
    ${commonInputStyles(size, valueSize)}
    height: ${height}rem;
  `;
});
