import styled from 'styled-components';
import { Colors, shadow1 } from '@myblueprint-spaces/papier-core';

export const StyledExternalProviderButton = styled.button
  .attrs((props: { id: string, onClick: () => void, disabled?: boolean; dataTest?: string }) => ({ type: 'button', 'data-test': `btn-${props.dataTest || props['data-test']}` }))`
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
  display: block;
  width: 100%;
  appearance: none;
  border-radius: 0.25rem;
  box-shadow: ${({ disabled }) => disabled ? 'none' : shadow1};
  background-color: ${({ disabled }) => disabled ? 'rgba(0, 0, 0, 0.08)' : Colors.white};
  font-size: 0.875rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.22px;
  color: rgba(0, 0, 0, ${({ disabled }) => disabled ? '0.4' : '0.54'});
  transition: box-shadow 0.2s ease-in-out;

  &::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }

  &:focus:not(:active) {
    box-shadow: 0 0 0 3px rgba(66, 133, 244, 0.3);
  }

  &:active {
    background-color: #eeeeee;
  }
`;

export const StyledProviderLogo = styled.img(({ reducedPadding }: { reducedPadding?: boolean }) => `
  display: block;
  padding: ${reducedPadding ? '0.5rem 0' : '0.75rem 0 0.75rem 0.75rem'};
`);

export const TextWrapper = styled.div(({ reducedPadding }: { reducedPadding?: boolean }) => `
  padding: ${reducedPadding ? '0 0 0 0.5rem' : '0.75rem'};
  text-align: center;
`);
