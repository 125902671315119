import { useDispatch, useSelector } from 'react-redux';
import { IState } from '@myblueprint-spaces/redux';
import { NotificationsSelectors, NotificationsActions, getEntityFromType, NotificationType, NotificationFeedFilter } from '@myblueprint-spaces/redux/lib/notifications';
import { useCallback } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

// Selectors

function useHasNewCount() {
  return useSelector((state: IState) =>  {
    return NotificationsSelectors.hasNewCount(state);
  });
}

function useIsAtEnd(filter: NotificationFeedFilter | null) {
  return useSelector((state: IState) => filter === null ?  null : NotificationsSelectors.isAtEnd(state, filter));
}

function useNotifications(filter: NotificationFeedFilter | null) {
  return useSelector((state: IState) =>  {
    if (filter === null) return null;
    const notificationList = NotificationsSelectors.getNotifications(state, filter);

    if (notificationList) {
      return notificationList.map((n) => ({
        ...n,
        displayData: NotificationsSelectors.getNotificationDisplayData(state, n?.id as string, getEntityFromType(n?.type as NotificationType))
      }));
    }
  });
}

function useNotificationWithId(notificationId: string) {
  return useSelector((state: IState) =>  {
    const notification = NotificationsSelectors.getWithId(state, notificationId);

    if (notification) {
      return {
        ...notification,
        displayData: NotificationsSelectors.getNotificationDisplayData(state, notification.id, getEntityFromType(notification.type))
      };
    }
  });
}

function useUnreadCount(filter: NotificationFeedFilter | null) {
  return useSelector((state: IState) => filter === null ? null : NotificationsSelectors.getUnreadCount(state, filter));
}

function useHasNew(filter: NotificationFeedFilter | null) {
  return useSelector((state: IState) => filter === null ? null : NotificationsSelectors.hasNew(state, filter));
}

// Actions
function useLoadMoreNotifications() {
  const dispatch = useDispatch<ThunkDispatch<IState, unknown, Action>>();

  return useCallback((filter: NotificationFeedFilter | null) => filter === null ?  Promise.resolve() : dispatch(NotificationsActions.loadMore(filter))
    , [dispatch]);
}

function useLoadNewest() {
  const dispatch = useDispatch<ThunkDispatch<IState, unknown, Action>>();

  return useCallback((filter: NotificationFeedFilter | null) => filter === null ?  Promise.resolve() : dispatch(NotificationsActions.loadNewest(filter))
    , [dispatch]);
}

function useLoadNotificationData() {
  const dispatch = useDispatch<ThunkDispatch<IState, unknown, Action>>();
  return useCallback(
    (notificationId: string) => {
      return dispatch(NotificationsActions.loadNotificationData(notificationId));
    },
    [dispatch]
  );
}

function useLoadNotifications() {
  const dispatch = useDispatch<ThunkDispatch<IState, unknown, Action>>();

  return useCallback((filter: NotificationFeedFilter | null) => filter === null ?  Promise.resolve() : dispatch(NotificationsActions.loadNotifications(filter))
    , [dispatch]);
}

function useMarkAllRead() {
  const dispatch = useDispatch<ThunkDispatch<IState, unknown, Action>>();

  return useCallback((filter: NotificationFeedFilter | null) => filter === null ?  Promise.resolve() : dispatch(NotificationsActions.markAllRead(filter))
    , [dispatch]);
}

function useToggleRead() {
  const dispatch = useDispatch<ThunkDispatch<IState, unknown, Action>>();
  return useCallback(
    (notificationId: string, markRead: boolean) => {
      return dispatch(NotificationsActions.toggleRead(notificationId, markRead));
    },
    [dispatch]
  );
}

const NotificationHooks = {
  useUnreadCount,
  useHasNewCount,
  useHasNew,
  useMarkAllRead,
  useNotifications,
  useToggleRead,
  useLoadNotifications,
  useLoadMoreNotifications,
  useIsAtEnd,
  useLoadNewest,
  useNotificationWithId,
  useLoadNotificationData
};

export default NotificationHooks;
