import styled from 'styled-components';
import { Colors, Breakpoints } from '@myblueprint-spaces/papier-core';
import { Column, RowColumn } from '@myblueprint-spaces/papier-web/lib/Common/Grid';

export const Wrapper = styled(Column)(({ noBackground, small }: { noBackground?: boolean; small?: boolean }) => `
  width: ${small ? '100%' : '50%'};
  position: absolute;
  display: flex;
  align-items: center;
  padding: 0.25rem 1rem;
  text-overflow: ellipsis;
  background: ${(noBackground || Colors.white)};
  ${noBackground ? `border-bottom: 0.0625rem solid ${Colors.grey3}; padding-top: 0.5rem;` : ''}
  margin: 0 auto;

  ${!noBackground ? `&:hover {
    background: ${Colors.primary2};
  }` : ''}

  & * {
    text-decoration: none;
  }

  @media (max-width: ${Breakpoints.large}em) {
    width: ${small ? '100%' : '91.667%'};
  }

  @media (max-width: ${Breakpoints.medium}em) {
    width: ${small ? '100%' : 'calc(100% - (2 * 0.75rem))'};
  }
`);

export const NotificationFeed = styled(RowColumn)(({ small }: { small?: boolean }) => `
  ${!small && `
  margin-top: 1rem;
  height: 100%;
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  @media (min-width: ${Breakpoints.medium}em) {
    margin-left: 0.3125rem; // adjusting difference caused by scrollbar
  }`}
`);

export const EmptyStateWrapper = styled.div(({ small }: { small?: boolean }) => `
  margin: ${small ? '0' : '2rem'} auto 0 auto;
  ${small ? 'padding: 0 1rem;' : ''}
  max-width: 24rem;
  text-align: center;
`);

export const MarkdownWrapper = styled.div(({ read }: { read?: boolean }) => `
    white-space: pre-wrap;      /* CSS3 */   
    white-space: -moz-pre-wrap; /* Firefox */    
    white-space: -pre-wrap;     /* Opera <7 */   
    white-space: -o-pre-wrap;   /* Opera 7 */    
    word-wrap: break-word;      /* IE */

    & p {
      display: -webkit-box;
      max-width: 100%;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 1rem;
      ${read ? '' : 'font-weight: 700'};
    }
`);

export const Dot = styled.div(({ showDot }: { showDot?: boolean }) => `
position: relative;
margin: 0.25rem 0;
${showDot ? `
  &:before {
    content: '';
    width: 0.375rem;
    height: 0.375rem;
    position: absolute;
    left: -0.6875rem;
    top: calc(50% - 0.1875rem);
    background: ${Colors.primary1};
    border-radius: 50%;
    z-index: 99;
  }
`: ''}
`);
