import styled from 'styled-components';
import { Breakpoints, Colors } from '@myblueprint-spaces/papier-core';
import { Row } from '@myblueprint-spaces/papier-web/lib/Common/Grid';

export const ContentWrapper = styled.div`
  background: ${Colors.grey4};
  flex: 1 1 auto;
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
  height: 100%;
  overflow: hidden;
  @media (max-width: ${Breakpoints.medium}em) {
    padding-top: 1rem;
  }
`;

export const ScrollWrapper = styled(Row)`
  // 100% - top margin - infoBar if needed - topbuttons - initial margin
  height: calc(100% - 1.5rem ${(props: { hasInfoBar?: boolean }) => props.hasInfoBar ? '- 4.5rem' : ''} - 2.5rem - 1rem);

  @media (max-width: ${Breakpoints.medium}em) {
    height: calc(100% - 1rem ${(props: { hasInfoBar?: boolean }) => props.hasInfoBar ? '- 4rem' : ''} - 2.5rem - 1rem);
  }
`;

export const Wrapper = styled.div`
  padding-bottom: 1.5rem;
  @media (max-width: ${Breakpoints.medium}em) {
    padding-bottom: 1rem;
  }
`;
