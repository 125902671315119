import React, { FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import { Row, Column } from 'src/Common/Grid';
import { PanelHeaderRow, ButtonWrapper, PanelHeaderColumnInnerLeft, PanelHeaderColumnInnerMiddle, PanelHeaderColumnInnerRight  } from './styles';
import { IPanelHeaderProps } from './types';
import { IWindowSize, withWindowSize } from 'src/modules';
import { Breakpoints } from '@myblueprint-spaces/papier-core';
import WrappedTooltipTypography from 'src/Common/WrappedTooltipTypography';

function PanelHeader({ left, middle, right, bottomBorder, windowSize, ...rest }: IPanelHeaderProps & { windowSize: IWindowSize; }) {
  const isSmall = windowSize.width < Breakpoints.medium;

  return (
    <PanelHeaderRow bottomBorder={bottomBorder} {...rest}>
      <Row collapse expanded align="justify">
        <Column shrink verticalAlign="middle">
          <PanelHeaderColumnInnerLeft>
            <ButtonWrapper>
              {left}
            </ButtonWrapper>
          </PanelHeaderColumnInnerLeft>
        </Column>
        <Column verticalAlign="middle">
          <PanelHeaderColumnInnerMiddle textAlign={isSmall ? 'left' : 'center'}>
            {
              typeof middle === 'string'
                ? <WrappedTooltipTypography clamp={1} text={middle} textContainer={{ type: isSmall ? 'subtitle1' : 'body2', ellipsis: true }} />
                : middle
            }
          </PanelHeaderColumnInnerMiddle>
        </Column>
        <Column verticalAlign="middle" shrink>
          <PanelHeaderColumnInnerRight>
            <ButtonWrapper>
              {right}
            </ButtonWrapper>
          </PanelHeaderColumnInnerRight>
        </Column>
      </Row>
    </PanelHeaderRow>
  );
}

export default withWindowSize(PanelHeader as FunctionComponent<IPanelHeaderProps>);

PanelHeader.propTypes = {
  left: PropTypes.node,
  middle: PropTypes.node,
  right: PropTypes.node,
  bottomBorder: PropTypes.bool,
  onParentPanelClose: PropTypes.func
};
