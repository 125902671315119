import { nanoid } from 'nanoid';
import * as React from 'react';
import { generateId } from '../browsers/generateId';

export default function useId(staticId?: string | null | undefined): string {
  const usedStaticId = staticId ? staticId.startsWith('mbp-') ? staticId : `mbp-${staticId}` : null;
  // Ensuring uniqueness by checking if id is already used, if not, simply pass the string down; If yes, make it unique while still keeping the struture for the id
  const [id] = React.useState(usedStaticId ? (!document.getElementById(usedStaticId) ? usedStaticId : `${usedStaticId}-${nanoid()}`) : generateId());

  return id;
}
