import { ITypographyProps } from '@myblueprint-spaces/papier-core';
import Typography from 'src/Common/Typography';
import * as PropTypes from 'prop-types';
import * as React from 'react';
import { HeaderBlockContainer, HeaderBlockDefaultIcon, HeaderBlockImageContainer, HeaderBlockTitle } from './styles';
import { IHeaderBlockProps } from './types';

export default function HeaderBlock(props: IHeaderBlockProps): React.ReactElement {
  const { image, title, subtitle, style } = props;

  const defaultTitleProps: ITypographyProps = {
    type: 'header4',
    as: 'p',
    weight: 'demibold'
  };
  const defaultSubtitleProps: ITypographyProps = {
    type: 'compact',
    color: 'black2'
  };
  const titleProps = typeof title === 'string'
    ? {
      children: title,
      ...defaultTitleProps
    }
    : {
      ...defaultTitleProps,
      ...(title as ITypographyProps)
    };
  const subtitleProps = typeof subtitle === 'string'
    ? {
      children: subtitle,
      ...defaultSubtitleProps
    }
    : {
      ...defaultSubtitleProps,
      ...(subtitle as ITypographyProps)
    };

  return (
    <HeaderBlockContainer style={{ ...style }}>
      {typeof image !== 'undefined' && <HeaderBlockImageContainer>
        {image || <HeaderBlockDefaultIcon />}
      </HeaderBlockImageContainer>}
      <HeaderBlockTitle>
        <Typography {...titleProps} />
      </HeaderBlockTitle>
      {subtitle && <Typography {...subtitleProps} />}
    </HeaderBlockContainer>
  );
}

HeaderBlock.propTypes = {
  image: PropTypes.node,
  style: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};
