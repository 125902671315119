import { IState } from '@myblueprint-spaces/redux';
import { Scope, ScopeSelectors } from '@myblueprint-spaces/redux/lib/scopes';
import { SpacesSelectors } from '@myblueprint-spaces/redux/lib/spaces';
import { useSelector } from 'react-redux';

export default function useScopeModels(simpleScope?: Scope | Scope[] | null, checkSpaceId?: string) {
  return useSelector((state: IState) => {
    if (!simpleScope) return undefined;

    if (Array.isArray(simpleScope)) {
      if (checkSpaceId) {
        return simpleScope.map((SS) => ({
          isStillInSpace: SpacesSelectors.isInSpace(state, checkSpaceId, SS),
          ...ScopeSelectors.getScopeModel(state, SS.scopeType, SS.ownerId),
        }));
      } else {
        return simpleScope.map((SS) => ({
          ...ScopeSelectors.getScopeModel(state, SS.scopeType, SS.ownerId),
        }));
      }
    } else {
      return ScopeSelectors.getScopeModel(state, simpleScope.scopeType, simpleScope.ownerId);
    }
  });
}
