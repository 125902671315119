
// https://jsfiddle.net/oriadam/ncb4n882/

interface UserAgentInfo {
  ua: string;
  browser: string;
  os: string;
  touch: boolean;
  mobile: string | number;
  tablet: boolean;
  iOS: boolean;
  ie: boolean;
  safari: boolean;
  android: boolean;
  mac: boolean;
  desktop: boolean;
}

export function getUserAgentInfo(): UserAgentInfo {
  const ua = navigator.userAgent;
  const browser = /Edge\/\d+/.test(ua) ? 'ed'
    : /MSIE 9/.test(ua) ? 'ie9'
      : /MSIE 10/.test(ua) ? 'ie10'
        : /MSIE 11/.test(ua) ? 'ie11'
          : /MSIE\s\d/.test(ua) ? 'ie?' // ie8 or below
            : /rv:11/.test(ua) ? 'ie11'
              : /Trident\//.test(ua) ? 'ie11'
                : /Firefox\W\d/.test(ua) ? 'ff'
                  : /Chrom(e|ium)\W\d|CriOS\W\d/.test(ua) ? 'gc'
                    : /\bSafari\W\d/.test(ua) ? 'sa'
                      : /\bOpera\W\d/.test(ua) ? 'op'
                        : /\bOPR\W\d/i.test(ua) ? 'op'
                          // @ts-expect-error MSPointerEvent doesn't exist on typescript anymore because it is deprecated, but in this case it makes sense
                          : typeof MSPointerEvent !== 'undefined' ? 'ie?' : '';

  const os = /Windows NT 10/.test(ua) ? 'win10'
    : /Windows NT 6\.0/.test(ua) ? 'winvista'
      : /Windows NT 6\.1/.test(ua) ? 'win7'
        : /Windows NT 6\.\d/.test(ua) ? 'win8'
          : /Windows NT 5\.1/.test(ua) ? 'winxp'
            : /Windows NT [1-5]\./.test(ua) ? 'winnt'
              : /Mac/.test(ua) ? 'mac'
                : /Linux/.test(ua) ? 'linux'
                  : /X11/.test(ua) ? 'nix'
                    : '';

  const touch = 'ontouchstart' in document.documentElement;
  const mobile = /IEMobile|Windows Phone|Lumia/i.test(ua) ? 'w'
    : /iPhone|iP[oa]d/.test(ua) ? 'i'
      : /Android/.test(ua) ? 'a'
        : /BlackBerry|PlayBook|BB10/.test(ua) ? 'b'
          : /Mobile Safari/.test(ua) ? 's'
            : /webOS|Mobile|Tablet|Opera Mini|\bCrMo\/|Opera Mobi/i.test(ua) ? 1
              : 0; // not mobile

  const tablet = /Tablet|iPad/i.test(ua);

  return {
    ua,
    browser,
    os,
    touch,
    mobile,
    tablet,
    iOS: mobile === 'i',
    ie: browser === 'ie9' || browser === 'ie10' || browser === 'ie11' || browser === 'ie?',
    safari: browser === 'sa',
    android: mobile === 'a',
    mac: os === 'mac',
    desktop: !mobile && !tablet
  };
}

export function isIosDevice(): boolean {
  return getUserAgentInfo().iOS;
}

export function isMacOsDevice(): boolean {
  return getUserAgentInfo().mac;
}

export function isSafari(): boolean {
  return getUserAgentInfo().safari;
}

export function iOSVersion(): number | boolean {
  if (Object.prototype.hasOwnProperty.call(window, 'MSStream')) {
    // There is some iOS in Windows Phone...
    // https://msdn.microsoft.com/en-us/library/hh869301(v=vs.85).aspx
    return false;
  }
  if (navigator.userAgent.match('CriOS')) {
    return 10; // Return iOS 10 for iOS Chrome because WKWebView doesn't support WebRTC https://forums.developer.apple.com/thread/88052
  }
  const match = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
  let version;

  if (match !== undefined && match !== null) {
    version = [
      parseInt(match[1], 10),
      parseInt(match[2], 10),
      parseInt(match[3] || '0', 10)
    ];
    return parseFloat(version.join('.'));
  }

  return false;
}

export function getIEVersion(): number | boolean {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE ');
  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
  }
  const trident = ua.indexOf('Trident/');
  if (trident > 0) {
    // IE 11 => return version number
    const rv = ua.indexOf('rv:');
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
  }
  const edge = ua.indexOf('Edge/');
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
  }

  // other browser
  return false;
}

/*
  Safari has a lot of changes from one version to the other, and lately there are changes we cannot address with media queries.
*/
export function getSafariVersion() {
  const ua = navigator.userAgent;
  const regex = new RegExp(/Version\/([\d.]*).*Safari/gm);
  const matches = regex?.exec(ua);

  // version
  return matches?.[1] ?? null;
}
