import { TagText } from 'src/Common/Tag/styles';
import Typography from 'src/Common/Typography';
import * as React from 'react';
import { ColorNames } from '@myblueprint-spaces/papier-core';

const MultiValueLabel = (color: ColorNames): React.ReactNode => (props) => {
  return (
    <TagText>
      <Typography type="subtitle1" color={color || 'white'} {...props} />
    </TagText>
  );
};

export default MultiValueLabel;
