import * as React from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Transition } from 'react-transition-group';
import Button from 'src/Common/Button';
import withDocumentRoot from 'src/modules/hocs/withDocumentRoot';
import withWindowSize, { IWindowSizeProps } from 'src/modules/hocs/withWindowSize';
import { TopButtonWrapper, StyledAriaModal } from './styles';
import { IDialogProps } from './types';
import { Row, Column } from '../Grid';
import { Breakpoints } from '@myblueprint-spaces/papier-core';
import { useId } from 'src/modules/hooks';
import { getFirstFocusableElementInside } from 'src/modules/browsers';
import FocusableLoadingDots from '../Panel/components/FocusableLoadingDots';
import withErrorCustomHandling from '../Panel/components/withErrorCustomHandling';

function Dialog({ title, visible: visible = false, children, size: size = 'medium', error,
  onClose, windowSize, closeButton: closeButton = true, overlayClickExits: overlayClickExits = true,
  documentRoot, fullScreenOnMobile, rightComponent, initialFocus, centerComponent = null, closeButtonSize = null }: IDialogProps & { error?: boolean }) {
  const { t } = useTranslation(['Common']);
  const useCenterComponent = centerComponent && (windowSize.width >= Breakpoints.medium || fullScreenOnMobile);
  const closeButtonId = useId();
  const loadingDotsId = useId();
  const dialogId = useId();
  const [open, setOpen] = React.useState(false);
  const [dialogShown, setDialogShown] = React.useState(false);

  React.useLayoutEffect(() => {
    if(visible) {
      setTimeout(() => setOpen(true), 80);
    } else {
      setOpen(false);
    }
  }, [visible]);
  const contentRef = React.useRef(null);
  const loadingRef = React.useRef(null);

  const modalProps = {
    dialogId,
    onExit: onClose,
    titleText: title,
    contentRef,
    underlayClickExits: overlayClickExits,
    applicationNode: documentRoot,
    size,
    windowSize,
    fullScreenOnMobile: fullScreenOnMobile,
    verticallyCenter: true,
    initialFocus: initialFocus
      || (contentRef.current ? getFirstFocusableElementInside(contentRef.current) : undefined)
      || (closeButton && document.getElementById(closeButtonId))
      || document.getElementById(loadingDotsId)
      || undefined
    // TODO: once Dialog gets its type, renderTo live-region-error should only be used for error dialogs / all other should use live-region-message
    // renderTo: '#live-region-error'
  };

  const setFocus = () => {
    const elem = contentRef.current;

    if (dialogShown) {
      if (elem && elem.offsetWidth > 0) {
        const focusElem = getFirstFocusableElementInside(elem);
        if (focusElem?.offsetWidth > 0 && focusElem?.getAttribute('data-test') !== 'loading') {
          focusElem.focus();
          return;
        }
      }
      setTimeout(() => this.setFocus(), 200);
    }
  };

  React.useLayoutEffect(() => {
    if(dialogShown) {
      setTimeout(() => setFocus(), 20);
    }
  }, [dialogShown]);

  return (
    <React.Fragment>
      <Transition timeout={300} in={open} mountOnEnter unmountOnExit onEntered={() => setDialogShown(true)}>
        {
          (state) => (
            <StyledAriaModal state={state} {...modalProps}>
              {(!dialogShown || error) && <FocusableLoadingDots id={dialogId + '_fallback'} onUnmount={() => setFocus()} ref={loadingRef} hidden />}
              <div ref={contentRef} role="document">
                <React.Suspense fallback={<FocusableLoadingDots onUnmount={() => setFocus()} />}>
                  {(closeButton || rightComponent || centerComponent) && (
                    <Row align="justify" expanded collapse verticalAlign="middle">
                      <Column shrink>
                        {closeButton && <TopButtonWrapper>
                          <Button id={closeButtonId} title={t('Common:Actions.Close')} onClick={onClose} size={closeButtonSize || (size === 'small' ? 'small' : 'medium')} rounded icon="close" color="secondary" dataTest="dialog-close" />
                        </TopButtonWrapper>}
                      </Column>
                      {useCenterComponent && <Column>
                        {centerComponent}
                      </Column>}
                      <Column shrink>
                        {rightComponent && <TopButtonWrapper>
                          {rightComponent}
                        </TopButtonWrapper>}
                      </Column>
                    </Row>
                  )}
                  <React.Suspense fallback={<FocusableLoadingDots onUnmount={() => setFocus()} />}>
                    {children}
                  </React.Suspense>
                </React.Suspense>
              </div>
            </StyledAriaModal>
          )
        }
      </Transition>
    </React.Fragment>
  );
}

Dialog.defaultProps = {
  visible: false,
  size: 'medium',
  overlayClickExits: true,
  closeButton: true,
  fullScreenOnMobile: true
};

Dialog.propTypes = {
  title: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  children: PropTypes.any.isRequired,
  onClose: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  windowSize: PropTypes.object,
  closeButton: PropTypes.bool,
  rightComponent: PropTypes.any,
  overlayClickExits: PropTypes.bool,
  documentRoot: PropTypes.object,
  fullScreenOnMobile: PropTypes.bool,
  initialFocus: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  centerComponent: PropTypes.any,
  closeButtonSize: PropTypes.oneOf(['small', 'medium', 'large']),
  dataTest: PropTypes.string
};

export default withDocumentRoot<IDialogProps & IWindowSizeProps>(withWindowSize<IDialogProps & IWindowSizeProps>(withErrorCustomHandling(Dialog as React.FunctionComponent<IDialogProps & IWindowSizeProps>))) as React.FunctionComponent<IDialogProps>;
