import * as PropTypes from 'prop-types';
import * as React from 'react';

import { Colors, ColorNames, Transparencies } from '@myblueprint-spaces/papier-core';

import Typography from 'src/Common/Typography';
import { PillElem } from './styles';
import { IPillProps, ColorsType } from './types';
import { screenFill } from '@myblueprint-spaces/modules';
import { Icon } from '../Icon/Icon';
import { CovalentGrid } from 'src/Atoms';

const getAppearance = (color: ColorsType): {
  backgroundColor: Colors | Transparencies;
  textColor: ColorNames;
  disabledTextColor: ColorNames;
  disabledBackgroundColor: string;
} => {
  switch (color) {
    default:
    case 'primary':
      return {
        backgroundColor: Colors.primary1,
        textColor: 'white',
        disabledTextColor: 'white',
        disabledBackgroundColor: screenFill(Colors.primary1, Colors.grey1),
      };

    case 'secondary':
      return {
        backgroundColor: Colors.grey3,
        textColor: 'black2',
        disabledTextColor: 'grey1',
        disabledBackgroundColor: screenFill(Colors.grey3, Colors.grey1),
      };

    case 'success':
      return {
        backgroundColor: Colors.success1,
        textColor: 'white',
        disabledTextColor: 'white',
        disabledBackgroundColor: screenFill(Colors.success1, Colors.grey1),
      };

    case 'danger':
      return {
        backgroundColor: Colors.danger1,
        textColor: 'white',
        disabledTextColor: 'white',
        disabledBackgroundColor: screenFill(Colors.danger1, Colors.grey1),
      };

    case 'transparentDanger':
      return {
        backgroundColor: Transparencies.danger,
        textColor: 'danger1',
        disabledTextColor: 'white',
        disabledBackgroundColor: screenFill(Colors.danger1, Colors.grey1),
      };
  }
};

export function Pill(props: IPillProps & { forwardRef?: React.ForwardedRef<HTMLParagraphElement>; }): React.ReactElement {
  const { text, disabled, color, typographyProps, icon, onClick, forwardRef, tabIndex, ...rest } = props;

  const { backgroundColor, textColor, disabledTextColor, disabledBackgroundColor } = getAppearance(color || 'secondary');

  const elem = React.useRef<HTMLParagraphElement>();
  const usedRef = forwardRef || elem;

  let accessibilityProps = {};

  const handleKeyDown = React.useCallback((e) => {
    if (onClick && !disabled && (e.key === 'Enter' || e.key === ' ') && (usedRef as React.MutableRefObject<HTMLParagraphElement>).current) {
      e.stopPropagation();
      e.preventDefault();
      onClick(e);
      return false;
    }
  }, [onClick, disabled]);

  if(onClick && !disabled) {
    accessibilityProps = {
      role: 'button',
      tabIndex: tabIndex || 0,
      onKeyDown: handleKeyDown
    };
  }

  return (
    <PillElem ref={usedRef} textColor={disabled? disabledTextColor : textColor } color={disabled? disabledBackgroundColor : backgroundColor } onClick={onClick} {...rest} {...accessibilityProps}>
      <CovalentGrid verticalAlign="middle" align="center">
        {icon && <Icon color={disabled? disabledTextColor : textColor} {...icon} />}
        <Typography color={disabled? disabledTextColor : textColor} as="span" type="compact" { ...typographyProps }>{text}</Typography>
      </CovalentGrid>
    </PillElem>
  );
}

Pill.propTypes = {
  typographyProps: PropTypes.object,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  color: PropTypes.oneOf(['danger', 'primary', 'success', 'secondary', 'transparentDanger']).isRequired,
  icon: PropTypes.object
};

export const MemoedPill = React.memo(Pill);

const RefedPill = React.forwardRef<HTMLParagraphElement, IPillProps>((props, ref) => <MemoedPill {...props} forwardRef={ref} />);

export default RefedPill;
