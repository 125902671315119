import { flexAlignMap, flexJustifyMap, IGridContext, IRowProps } from '@myblueprint-spaces/papier-core';
import * as PropTypes from 'prop-types';
import * as React from 'react';
import GridContext from '../Shared/Context';
import { RowColumnElem } from './styles';
import { IGridCommonProps } from '../Shared/types';

type ForwardedRowColumn = IRowProps & IGridCommonProps;
export type RowColumnProps = (IRowProps & IGridCommonProps) | ForwardedRowColumn;

const RowColumn = (props: RowColumnProps) => {
  return (
    <GridContext.Consumer>
      {({ collapse, nested }: IGridContext) => {
        const { collapse: propCollapse, forwardRef } = props as ForwardedRowColumn;
        const c = typeof propCollapse !== 'undefined' ? propCollapse : collapse;
        return (
          <GridContext.Provider value={{ collapse: c, nested: true }}>
            <RowColumnElem {...props} nested={nested} ref={forwardRef} />
          </GridContext.Provider>
        );
      }}
    </GridContext.Consumer>
  );
};
RowColumn.defaultProps = {
  collapse: true
};

RowColumn.propTypes = {
  expanded: PropTypes.bool,
  span: PropTypes.bool,
  collapse: PropTypes.bool,
  align: PropTypes.oneOf(Object.keys(flexJustifyMap)),
  verticalAlign: PropTypes.oneOf(Object.keys(flexAlignMap))
};

const RefedRowColumn = React.forwardRef<HTMLDivElement, ForwardedRowColumn>((props, ref) => <RowColumn {...props} forwardRef={ref} />);

export default RefedRowColumn;
