import { createGlobalStyle } from 'styled-components';
import { Breakpoints, baseFontSize, Colors } from '@myblueprint-spaces/papier-core';


export const UtilityClasses = createGlobalStyle`
  body {
    // Scrollbar

    // Firefox
    &, & * {
      scrollbar-color: ${Colors.grey2} transparent;
      scrollbar-width: thin;  
    }    
    *:hover {
      scrollbar-color: ${Colors.grey1} transparent;
      scrollbar-width: thin;  
    }
    
    // Webkit browsers
    ::-webkit-scrollbar, & *::-webkit-scrollbar {
      width: 0.375rem;
      height: 0.375rem;
    }
    ::-webkit-scrollbar:horizontal, & *::-webkit-scrollbar:horizontal {
      height: 0.5rem;
    }
    ::-webkit-scrollbar:horizontal:hover, & *::-webkit-scrollbar:horizontal:hover {
        color: ${Colors.grey1};
        background: ${Colors.grey3};
    }
    ::-webkit-scrollbar-track, ::-webkit-scrollbar-track-piece, ::-webkit-scrollbar-corner, ::-webkit-scrollbar-button,
    *::-webkit-scrollbar-track, *::-webkit-scrollbar-track-piece, *::-webkit-scrollbar-corner, *::-webkit-scrollbar-button {
      background: transparent;
    }
    ::-webkit-scrollbar-button, *::-webkit-scrollbar-button {
      display: none;
    }
    ::-webkit-scrollbar-thumb, *::-webkit-scrollbar-thumb {
      border-radius: 0.1875rem;
      background-color: ${Colors.grey2};
    }
    *:hover::-webkit-scrollbar-thumb, ::-webkit-scrollbar-thumb:hover, *::-webkit-scrollbar-thumb:hover {
      background: ${Colors.grey1}; 
    }

    /*
      Grid Helpers
      show-for-medium: Medium; Large; XLarge
      show-for-large: Large; XLarge
    */
    .show-for-small-only {
      display: none;
    }
    .hide-for-small-only {
      display: flex;
    }
    @media (max-width: ${Breakpoints.medium-1/baseFontSize}em) {
      .show-for-small-only {
        display: flex;
      }
      .hide-for-small-only {
        display: none;
      }
    }
    .show-for-medium-only {
      display: none;
    }
    .hide-for-medium-only {
      display: flex;
    }
    @media (min-width: ${Breakpoints.medium}em) and (max-width: ${Breakpoints.large-1/baseFontSize}em) {
      .show-for-medium-only {
        display: flex;
      }
      .hide-for-medium-only {
        display: none;
      }
    }
    .show-for-medium {
      display: none;
    }
    .hide-for-medium {
      display: flex;
    }
    @media (min-width: ${Breakpoints.medium}em) {
      .show-for-medium {
        display: flex;
      }
      .hide-for-medium {
        display: none;
      }
    }
    .show-for-large-only {
      display: none;
    }
    .hide-for-large-only {
      display: flex;
    }
    @media (min-width: ${Breakpoints.large}em) and (max-width: ${Breakpoints.xlarge-1/baseFontSize}em) {
      .show-for-large-only {
        display: flex;
      }
      .hide-for-large-only {
        display: none;
      }
    }
    .show-for-large {
      display: none;
    }
    .hide-for-large {
      display: flex;
    }
    @media (min-width: ${Breakpoints.large}em) {
      .show-for-large {
        display: flex;
      }
      .hide-for-large {
        display: none;
      }
    }
    .show-for-xlarge-only {
      display: none;
    }
    .hide-for-xlarge-only {
      display: flex;
    }
    @media (min-width: ${Breakpoints.xlarge}em) {
      .show-for-xlarge-only {
        display: flex;
      }
      .hide-for-xlarge-only {
        display: none;
      }
    }
  }
`;
