import * as React from 'react';
import { CSSProperties } from 'styled-components';

export const TooltipSizes = ['small', 'medium', 'large', 'xlarge'];
export type TooltipSizesType = 'small' | 'medium' | 'large' | 'xlarge';

export interface ITooltipProps {
  dark?: boolean;
  size?: TooltipSizesType;
  children?: React.ReactNode;
  trigger?: React.RefObject<HTMLElement>;
  triggerId?: string;
  onShow?: () => void;
  style?: CSSProperties;
  onHide?: () => void;
}

export interface ITooltipBoxProps extends ITooltipProps {
  style: CSSProperties;
  open: boolean;
  children?: React.ReactNode;
  // id *must* be passed for withRelativePosition to work correctly
  id: string;
  onForcedClose: () => void;
}

export interface ITooltipState {
  open: boolean;
}
