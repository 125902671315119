import { RovingTabIndexProvider } from '@myblueprint-spaces/react-roving-tabindex';
import * as PropTypes from 'prop-types';
import * as React from 'react';
import PopoverItem from './components/PopoverItem';
import { PopoverContainer, PopoverElem, Pointer } from './styles';
import { IPopover, IPopoverRequired, IPopoverConfig, IPopoverConfigButton, IPopoverConfigLink } from './types';
import { isFocusable, focusFirstFocusableElementInside } from 'src/modules/browsers/focusManager';
import { IconNames } from '@myblueprint-spaces/papier-icons';
import { useId } from 'src/modules';
import FocusTrap from 'focus-trap-react';

export function Popover(props: IPopover & IPopoverRequired): React.ReactElement {
  const { config, open, onClose, triggerPosition, menuPosition, id, triggerRef, hostAriaLabel, children, initialFocus, showCenteredPointer = false, ...rest } = props;
  const usedId = useId(id);
  const triggerElem = triggerRef.current;
  const originalOnClick = triggerElem && triggerElem.getAttribute('onclick');
  const popoverChildren = children;
  const [isVisible, setIsVisible] = React.useState(false);
  const [triggerId, setTriggerId] = React.useState(`${usedId}--trigger`);

  function handleDeactivate(e, focus = false) {
    e?.stopPropagation();
    if (typeof e === 'undefined' || (!!triggerElem && !(event.target as Element).closest('#' + triggerElem.getAttribute('id')))) {
      // Required due to depedency chain. React Hooks guarantee it will always be initialized
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      document.removeEventListener('click', checkClickOutside);
      triggerElem.onclick = () => originalOnClick;
      open && onClose();
      focus && triggerElem.focus();
    }
  }

  function checkClickOutside(e) {
    const inside = (e.target.closest('#' + usedId));
    if (!inside) {
      handleDeactivate(e);
    }
  }

  React.useLayoutEffect(() => {
    if (!isVisible || !open) {
      triggerElem && (triggerElem.onclick = () => originalOnClick);
      document.removeEventListener('click', checkClickOutside);
    } else if (open) {
      if (triggerElem) {
        triggerElem.onclick = () => {
          return;
        };
      }
      // Roving tabIndex will take care of keyboard focus leaving Popover.
      document.removeEventListener('click', checkClickOutside);
      config && document.addEventListener('click', checkClickOutside);
    }
  }, [open, isVisible, checkClickOutside, triggerElem]);

  React.useEffect(() => {
    if (triggerElem) {
      triggerElem.setAttribute('aria-expanded', open.toString());
      triggerElem.setAttribute('aria-haspopup', 'menu');
      triggerElem.setAttribute('aria-controls', open ? usedId : null);
      triggerElem.setAttribute('aria-label', hostAriaLabel);
      !triggerElem.getAttribute('id') && triggerElem.setAttribute('id', `${usedId}--trigger`);
      setTriggerId(triggerElem.getAttribute('id') || `${usedId}--trigger`);

      if (open) {
        triggerElem.setAttribute('aria-controls', usedId);
      } else {
        setIsVisible(false);
        triggerElem.removeAttribute('aria-controls');
      }

      // TODO: only show on development
      if (!isFocusable(triggerElem)) {
        // eslint-disable-next-line no-console
        console.error('In order to use a popover, the trigger component MUST be a focusable element and/or have tabindex >= 0 explicity declared');
      }
    }
  }, [open, triggerElem]);

  React.useEffect(() => {
    if (isVisible) {
      const parentElem = document.getElementById(usedId);
      if (parentElem) {
        const focusOnOpenElement = parentElem.getElementsByClassName('item')[0];

        if (focusOnOpenElement) {
          (focusOnOpenElement as HTMLElement).focus();
        } else {
          focusFirstFocusableElementInside(parentElem);
        }
      }
      document.addEventListener('remove-open-popover', handleDeactivate);
    }

    return () => {
      document.removeEventListener('remove-open-popover', handleDeactivate);
    };
  }, [isVisible]);

  const allowOutsideClick = (event) => {
    const isClickOnTrigger = (event.target as Element).closest('#' + triggerElem.getAttribute('id'));
    if (!isClickOnTrigger) {
      handleDeactivate(event);
    }
    return !!isClickOnTrigger;
  };

  return (
    <PopoverContainer open={open} triggerPosition={triggerPosition} menuPosition={menuPosition} trigger={triggerRef} onDisplay={() => setIsVisible(true)} role="menu" aria-labelledby={triggerId} hasPointer={showCenteredPointer}>
      {showCenteredPointer && <Pointer />}
      {config && <RovingTabIndexProvider>
        <PopoverElem id={usedId} role="presentation" {...rest}>
          {config.map((rest: IPopoverConfig & IPopoverConfigButton & IPopoverConfigLink, index: number) => {
            // if there's one of the menu items with type link, then, the html should be updated to use <button>
            return <PopoverItem key={index} {...rest} handleDeactivate={handleDeactivate} startsFocused={index === 0} />;
          })}
        </PopoverElem>
      </RovingTabIndexProvider>}
      {(!config && popoverChildren) && <FocusTrap
        focusTrapOptions={{
          onDeactivate: () => handleDeactivate(undefined),
          allowOutsideClick: allowOutsideClick,
          escapeDeactivates: true,
          fallbackFocus: `#${usedId}`,
          initialFocus: initialFocus as HTMLElement | string | { (): HTMLElement }
        }}>
        <div id={usedId} tabIndex={-1} role="group">
          {popoverChildren}
        </div>
      </FocusTrap>}
    </PopoverContainer>
  );
}

Popover.defaultProps = {
  triggerPosition: 'middle-middle',
  menuPosition: 'top-right'
};

Popover.propTypes = {
  id: PropTypes.string,
  hostAriaLabel: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  triggerRef: PropTypes.object,
  config: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    keepOpen: PropTypes.bool,
    type: PropTypes.oneOf(['menuItem', 'checkbox', 'link', 'divider', 'custom']).isRequired,
    onClick: function (props: IPopoverConfig, propName) {
      if (props.type === 'menuItem' && !props[propName]) {
        return new Error(`'${propName}' is required when type=='menuItem'.`);
      }
      if (props.type === 'link' && props[propName]) {
        return new Error(`When provided type is link, the url should be passed with 'linkTo' not '${propName}'.`);
      }
    },
    linkTo: function (props: IPopoverConfig, propName) {
      if (props.type === 'link' && !props[propName]) {
        return new Error(`'${propName}' is required when type=='link'.`);
      }
    },
    textColor: PropTypes.string,
    checkbox: function (props: IPopoverConfig, propName) {
      if (props.type === 'checkbox' && !props[propName]) {
        return new Error(`'${propName}' is required when a type 'checkbox' is provided.`);
      }
      if (props.type !== 'checkbox' && props[propName]) {
        return new Error(`You can't pass '${propName}' if type provided is not checkbox.`);
      }
      if (props[propName] && (!Object.keys(props[propName]).includes('checked') || !Object.keys(props[propName]).includes('labelId'))) {
        return new Error(`Incorrect format provided to '${propName}'.`);
      }
    },
    icon: PropTypes.oneOf(IconNames),
    startsFocused: PropTypes.bool
  })),
  triggerPosition: PropTypes.string,
  menuPosition: PropTypes.string,
  initialFocus: PropTypes.string,
  showCenteredPointer: PropTypes.bool
};

export default React.memo(Popover);
