import React from 'react';
import AttachmentHooks from './AttachmentHooks';
import FileUploadContextProvider from './FileUploadContextProvider';
import { FileUploadContextProviderContainerProps } from './types';

const FileUploadContextContainer = (props: FileUploadContextProviderContainerProps) => {
  const uploadAttachment = AttachmentHooks.useUploadAttachment();
  const createUrlAttachment = AttachmentHooks.useCreateUrlAttachment();
  const security = AttachmentHooks.useSecurity();
  const createAttachment = AttachmentHooks.useCreateAttachment();

  const hooks = {
    uploadAttachment,
    createUrlAttachment,
    security,
    createAttachment
  };

  return <FileUploadContextProvider {...props} hooks={hooks} />;
};

export default FileUploadContextContainer;
