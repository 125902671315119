import styled from 'styled-components';
import { Breakpoints } from '@myblueprint-spaces/papier-core';

export const StyledPanelContent = styled.div`
  overflow-y: auto;
  height: calc(100% - 4rem);

  @media (max-width: ${Breakpoints.medium}em) {
    height: calc(100% - 3.5rem);
  }
`;
