/* globals MediaStreamConstraints */
// eslint-disable-next-line spaced-comment
/// <reference types="webrtc" />
// eslint-disable-next-line spaced-comment
/// <reference types="dom-mediacapture-record" />

import { RecorderMediaTypes } from '@myblueprint-spaces/papier-core';
import { getUserAgentInfo, isIosDevice, isMacOsDevice, isSafari } from '../browsers';

// Needs to be used ONLY before applying polyfill. COndition will always be true after polyfill
export const supportMediaRecorder = (): boolean => {
  if (!window.MediaRecorder) {
    return false;
  }

  // All functions used are available? Safari mainly. If MediaRecorder is available, basic functions are available according to release notes.
  try {
    window.MediaRecorder.isTypeSupported('audio/webm');
  } catch {
    return false;
  }

  return true;
};

export const getBlobExtension = (blob: Blob): string => {
  return blob.type.split('/')[1].split(';')[0];
};

export const validateUserMedia = (): boolean => {
  return !!(navigator.getUserMedia || navigator.webkitGetUserMedia
    || navigator.mozGetUserMedia || navigator.mediaDevices.getUserMedia);
};

export const createBlob = (mimeType: string, dataChunks: Blob[]): { url: string; name: string; type: string; blob: Blob } => {
  const blob: Blob = new Blob(dataChunks, { type: mimeType });
  return {
    url: window.URL.createObjectURL(blob),
    name: (new Date()).getTime() + '.' + getBlobExtension(blob),
    type: mimeType,
    blob: blob
  };
};

export const createFile = (blob: Blob & { name: string }): File => {
  const file = new File([blob], blob.name, { type: blob.type, lastModified: Date.now() });
  return file;
};

export const getRecorderSupportedAudioType = (nativeMediaRecorderSupport: boolean): string => {
  // on IOS, CHROME behaves like SAFARI! So, Chrome MUST record audio as mp4!
  if (nativeMediaRecorderSupport && (isIosDevice() || isSafari())) {
    return 'audio/mp4';
  }
  // Polyfill only records .wav files
  if (!nativeMediaRecorderSupport) {
    return 'audio/wav';
  }
  // Chrome supports webm for audio recording. Firefox accepts ogg.
  if (getUserAgentInfo().browser === 'ff') {
    return 'audio/ogg';
  } else {
    return 'audio/webm';
  }
};

export const delay = (t: number): Promise<void> => {
  return new Promise(function (resolve) {
    setTimeout(resolve, t);
  });
};

export const getSupportedVideoMimeType = (): string => {
  let mimeType;
  const rootMime = 'video';
  const webm = rootMime + '/webm';
  const vp8 = webm + ';codecs=vp8';
  const h264 = webm + ';codecs=h264';
  const daala = webm + ';codecs=daala';
  const opus = webm + ';codecs=opus';

  if (getUserAgentInfo().browser === 'ff' || isMacOsDevice() || isIosDevice()) {
    mimeType = webm;
  } else {
    // Prefer VP8 encoding, falling back to h264, daala, opus and base-webm before defaulting to mp4
    if (MediaRecorder.isTypeSupported(vp8)) {
      mimeType = vp8;
    } else if (MediaRecorder.isTypeSupported(h264)) {
      mimeType = h264;
    } else if (MediaRecorder.isTypeSupported(daala)) {
      mimeType = daala;
    } else if (MediaRecorder.isTypeSupported(opus)) {
      mimeType = opus;
    } else if (MediaRecorder.isTypeSupported(webm)) {
      mimeType = webm;
    } else {
      mimeType = rootMime + '/mpeg';
    }
  }

  return mimeType;
};

export const getNextDeviceIndex = (deviceIndex: number, devices: MediaDeviceInfo[]): number => {
  return (deviceIndex + 1) % devices.length;
};

const getStream = async (devices: MediaDeviceInfo[], deviceId: string, mediaType: RecorderMediaTypes, nativeMediaRecorderSupport: boolean, initialDeviceId: string,
  currentStream: MediaStream): Promise<{ stream: MediaStream, usedDeviceIndex: number }> => {
  const options: MediaStreamConstraints = {
    video: true,
    audio: true
  };

  const usedDeviceId = deviceId || devices[0].deviceId;

  if (initialDeviceId === null) initialDeviceId = usedDeviceId;
  const idealResolution = {
    height: { ideal: 2160 },
    width: { ideal: 4096 }
  };

  // defaults
  options.audio = {
    deviceId: usedDeviceId
  };

  // if Firefox
  if (getUserAgentInfo().browser === 'ff') {
    await getPermissions(mediaType, usedDeviceId);
    options.video = {
      deviceId: usedDeviceId,
      frameRate: { ideal: 30 },
      height: { ideal: 720 }, // drop down to 720p (FF workaround)
      width: { ideal: 1280 } // drop down to 1280p (FF workaround)
    };
  } else {
    options.video = {
      deviceId: usedDeviceId,
      frameRate: 30,
      ...idealResolution
    };
  }

  // When recording audio if we allow the video to be used. Chrome will encode the webm container in
  // the vp9 codec. Firefox can't decode this and requires vorpis. Disabling the video portion on the
  // audio recorder will force chrome to encode in vorpis. Firefox will play this back. (Webm containers)
  if (mediaType === RecorderMediaTypes.Audio) {
    options.video = false;
    // audio default
  } else if (mediaType === RecorderMediaTypes.Image) {
    options.audio = false;
    // video default
  } else {
    // video & audio default
  }

  let stream;
  const timeout = (prom, time, exception) => {
    let timer;
    return Promise.race([
      prom,
      new Promise((_r, rej) => timer = setTimeout(rej, time, exception))
    ]).finally(() => clearTimeout(timer));
  };
  const timeoutError = Symbol();

  try {
    if (nativeMediaRecorderSupport) {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        stream = await timeout(navigator.mediaDevices.getUserMedia(options), 50000, timeoutError);
      } else if (navigator.getUserMedia) {
        stream = await timeout(new Promise((resolve, reject) => navigator.getUserMedia(options, resolve, reject)), 50000, timeoutError);
      } else if (navigator.webkitGetUserMedia) {
        stream = await timeout(new Promise((resolve, reject) => navigator.webkitGetUserMedia(options, resolve, reject)), 50000, timeoutError);
      } else if (navigator.mozGetUserMedia) {
        stream = await timeout(new Promise((resolve, reject) => navigator.mozGetUserMedia(options, resolve, reject)), 50000, timeoutError);
      }
    } else {
      if (mediaType === RecorderMediaTypes.Audio) {
        options.audio = true;
      } else {
        options.video = {
          deviceId: usedDeviceId,
          frameRate: 30
        };
      }
      stream = timeout(await navigator.mediaDevices.getUserMedia(options), 25000, timeoutError);
    }
    return { stream, usedDeviceIndex: devices.findIndex((d) => d.deviceId === usedDeviceId) };
  } catch (e) {
    if (e === timeoutError) {
      console.error('Camera Timeout - User took too long to give permissions and/or camera failed in getting permission');
    } else {
      console.error('Camera - Error with chosen Camera - can be ignored if other cameras worked', e);
    }
    // If one camera/device is throwing an error, try next one
    // Ex. Galaxy s20+ throws error only on Back Camera2
    const currentIndex = devices.findIndex((d) => d.deviceId === usedDeviceId);
    const newIndex = getNextDeviceIndex(currentIndex, devices);
    // if an entire loop of devices is already made, stop recursion and throw error
    if (initialDeviceId === devices[newIndex].deviceId) throw new Error('Camera - Looped through entire list of devices');
    return getStream(devices, devices[newIndex].deviceId, mediaType, nativeMediaRecorderSupport, initialDeviceId, currentStream);
  }
};

const getPermissions = async (mediaType: RecorderMediaTypes, deviceId: string): Promise<void> => {
  const testStream = await navigator.mediaDevices.getUserMedia({ video: mediaType !== RecorderMediaTypes.Audio ? { deviceId, frameRate: 10 } : false, audio: true });
  testStream?.getAudioTracks()?.forEach((track) => {
    track.stop();
  });
  testStream?.getVideoTracks()?.forEach((track) => {
    track.stop();
  });
  return await delay(50);
};

export const dataURItoBlob = (dataurl) => {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = window.atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while(n--){
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type:mime });
};

const stopAllStreams = async (currentStream: MediaStream): Promise<void> => {
  currentStream?.getAudioTracks()?.forEach((track) => {
    track.stop();
  });
  currentStream?.getVideoTracks()?.forEach((track) => {
    track.stop();
  });
  return delay(50);
};

/**
 * Gets either a list of audio or video inputs
 * @returns list of media devices
 */
export const getUserMedia = async (mediaType: RecorderMediaTypes, deviceId: string = null, nativeMediaRecorderSupport = false, currentStream: MediaStream = null)
  : Promise<{ stream: MediaStream, devices: MediaDeviceInfo[], usedDeviceIndex: number }> => {
  if (!validateUserMedia()) {
    throw new Error('Camera - Invalid User Media');
  }
  let devices = [];
  if (isIosDevice?.() || isMacOsDevice?.()) {
    try {
      await getPermissions(mediaType, deviceId);
    } catch (e) {
      throw new Error('Camera - Mac/IOs issue with camera permissions');
    }
  }

  if (navigator.mediaDevices.enumerateDevices) {
    const allDevices = await navigator.mediaDevices.enumerateDevices();
    devices = allDevices.filter((d) => d.kind === (mediaType === RecorderMediaTypes.Audio ? 'audioinput' : 'videoinput'));
    if (devices.length === 0) {
      throw new Error('Camera - No devices found');
    } else {
      const uaInfo = getUserAgentInfo();
      if (uaInfo.browser === 'ff' && uaInfo.mobile) {
        await Promise.resolve(() => stopAllStreams(currentStream)).then(async () => {
          try {
            await getPermissions(mediaType, deviceId ?? devices[0].deviceId);
          } catch (e) {
            console.error('Firefox camera issue on getting new permissions', e);
            throw new Error('Camera - Firefox issue with camera permissions');
          }
        }).catch((e) => {
          console.error('Firefox camera issue on stopping current Stream', e);
          throw new Error('Camera - Firefox issues with Initial setup');
        });
      }
    }

    try {
      const { stream, usedDeviceIndex } = await getStream(devices, deviceId, mediaType, nativeMediaRecorderSupport, deviceId, currentStream);

      return { stream, devices: devices.map((d) => d.deviceId), usedDeviceIndex };
    } catch (e) {
      throw new Error('Camera - Could not get stream from camera. Might not have permissions.');
    }
  } else {
    throw new Error('Camera - Browser does not support enumerateDevices');
  }
};
