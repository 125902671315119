import { LabelFontType, ColorNames } from '@myblueprint-spaces/papier-core';
import CovalentGrid from 'src/Atoms/CovalentGrid';
import * as PropTypes from 'prop-types';
import * as React from 'react';
import Icon from '../Icon';
import { IconContainer, TextContainer, LabelText } from './styles';
import { ILabelProps } from './types';

const Label = (props: ILabelProps): React.ReactElement => {
  const { size: size = 'medium', htmlFor, icon, children, right, id, required } = props;

  const iconProps = typeof icon === 'string'
    ? {
      type: icon,
      color: 'grey1' as ColorNames,
      size
    }
    : {
      color: 'grey1' as ColorNames,
      size,
      ...icon,
    };

  return (
    <label htmlFor={htmlFor} id={id}>
      <CovalentGrid collapse verticalAlign="middle">
        {icon
          && <IconContainer size={size}>
            <Icon {...iconProps} />
          </IconContainer>
        }
        <CovalentGrid collapse verticalAlign="middle" align="right">
          <TextContainer>
            <LabelText type={LabelFontType[size]} color="black2" required={required}>
              {children}
            </LabelText>
          </TextContainer>
          {right}
        </CovalentGrid>
      </CovalentGrid>
    </label>
  );
};

Label.propTypes = {
  size: PropTypes.oneOf(['medium', 'small']),
  htmlFor: PropTypes.string.isRequired,
  children: PropTypes.string,
  icon: PropTypes.string,
  right: PropTypes.node,
  required: PropTypes.bool
};

export default Label;
