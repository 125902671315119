import { Confirm } from '@myblueprint-spaces/papier-web/lib/Common/Dialog';
import * as PropTypes from 'prop-types';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { IConfirmState, IConfirmProps, ConfirmationProps } from './types';

export default class ConfirmationManager extends React.PureComponent<ConfirmationProps, IConfirmState> {
  public static propTypes = {
    portal: PropTypes.object
  };

  public constructor(props: ConfirmationProps) {
    super(props);

    this.state = {
      visible: false,
      confirmProps: null
    };
  }

  public setConfirm(confirm: IConfirmProps) {
    this.setState({ visible: true, confirmProps: confirm });
  }

  public render() {
    const { portal, t } = this.props;
    const { visible, confirmProps } = this.state;
    const { okButtonProps, cancelButtonProps, ...rest } = confirmProps || {};

    const confirm = {
      title: t('Common:ConfirmLeave'),
      subtext: t('Common:ConfirmLeave.Subtitle'),
      ...rest,
      visible: visible
    };

    const okButton = {
      text: t('Common:Answers.Yes'),
      ...okButtonProps,
      onClick: () => Promise.resolve(okButtonProps?.onClick()).then(() => this.setState({ visible: false }))
    };

    const cancelButton = {
      text: t('Common:Answers.No'),
      ...cancelButtonProps,
      onClick: () => Promise.resolve(cancelButtonProps?.onClick?.()).then(() => this.setState({ visible: false }))
    };

    if (!portal) return null;

    return (
      <React.Fragment>
        {ReactDOM.createPortal((
          <Confirm type="error" {...confirm}
            onClose={() => Promise.resolve(cancelButtonProps?.onClick?.()).finally(() => this.setState({ visible: false }))} okButtonProps={okButton} cancelButtonProps={cancelButton} />
        ), portal)}
      </React.Fragment>
    );
  }
}
