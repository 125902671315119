import { flexAlignMap, flexJustifyMap } from '@myblueprint-spaces/papier-core';
import * as PropTypes from 'prop-types';
import * as React from 'react';
import styled, { CSSProperties } from 'styled-components';

export type VerticalAlign = 'top' | 'middle' | 'bottom';
export type HorizontalAlign = 'left' | 'right' | 'center' | 'justify';

interface ICovalentBondProps {
  verticalAlign: VerticalAlign;
  align: HorizontalAlign;
}

interface IMoleculeProps {
  collapse: boolean;
}

const CovalentBond = styled.div(({ verticalAlign, align }: ICovalentBondProps) => `
  align-items: ${flexAlignMap[verticalAlign] || flexAlignMap.top};
  justify-content: ${flexJustifyMap[align] || flexJustifyMap.left};
  display: flex;
  flex-direction: row;
  width: 100%;
`);

const LeftMolecule = styled.div`
  ${({ collapse }: IMoleculeProps) => collapse ? '' : 'margin-right: 0.5rem;'}
`;

const RightMolecule = styled.div`
  ${({ collapse }: IMoleculeProps) => collapse ? '' : 'margin-left: 0.5rem;'}
`;

interface ICovalentGridProps {
  as?: keyof JSX.IntrinsicElements | React.ComponentType<unknown> | string;
  align?: HorizontalAlign;
  verticalAlign?: VerticalAlign;
  children: React.ReactNode;
  collapse?: boolean;
  style?: CSSProperties;
}

function CovalentGrid({ align, collapse, verticalAlign, children, ...rest }: ICovalentGridProps & unknown): React.ReactElement {
  if (React.Children.count(children) > 2) {
    throw new Error('Covalent grid is limited to 2 children!');
  }

  const [left, right] = React.Children.toArray(children);
  if(!left && !right) return null;

  return align === 'right' ? (
    <CovalentBond verticalAlign={verticalAlign} align={align} {...rest}>
      {left}
      {right && <RightMolecule collapse={collapse}>{right}</RightMolecule>}
    </CovalentBond>
  ) : (
    <CovalentBond verticalAlign={verticalAlign} align={align} {...rest}>
      {left && right ? <LeftMolecule collapse={collapse}>{left}</LeftMolecule> : left}
      {right}
    </CovalentBond>
  );
}

CovalentGrid.propTypes = {
  collapse: PropTypes.bool,
  children: PropTypes.any,
  align: PropTypes.oneOf(Object.keys(flexJustifyMap)),
  verticalAlign: PropTypes.oneOf(Object.keys(flexAlignMap)),
  style: PropTypes.object
};

export default CovalentGrid;
