import * as PropTypes from 'prop-types';
import * as React from 'react';
import { AvatarWrapper, AvatarImg } from './styles';
import { IAvatarProps } from './types';
import { useTranslation } from 'react-i18next';
import defaultAvatar from 'src/Assets/Asset_defaultAvatar.svg';
import loadingAvatar from 'src/Assets/Asset_LoadingAvatar.png';

export function Avatar(props: IAvatarProps): React.ReactElement {
  const { size, avatarSource = null, description, disabled, noMargin = false, error = false, useGenericAlt = false, borderColor } = props;
  const { t } = useTranslation(['Papier.Common']);
  const { url, mimeType } = (typeof avatarSource === 'string' ? { url: avatarSource, mimeType: null } : avatarSource) ?? {};

  const [imageError, setImageError] = React.useState(error); // error comming from props, default error to false

  React.useEffect(() => {
    setImageError(false);
  }, [avatarSource]);

  const defaultAvatarImg = {
    src: defaultAvatar,
    alt: useGenericAlt ? t('Papier.Common:AvatarAlt') : description || t('Papier.Common:AvatarAlt'),
  };

  const validImagesWithoutConversion = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif'];

  return (
    <AvatarWrapper size={size} noMargin={noMargin} aria-hidden="true" borderColor={borderColor}>
      {/* no error, show avatar */}
      {!imageError && url
        ? <AvatarImg src={url} alt={useGenericAlt ? t('Papier.Common:AvatarAlt') : description} disabled={disabled} onError={() => setImageError(true)} size={size} />
        /* If there's an error, check first if the image is not being converted. If the image is not _supported_ we show loadingDots until conversion is ready. Otherwise we default to defaultAvatar */
        : (!mimeType || validImagesWithoutConversion.includes(mimeType))
          ? <AvatarImg src={defaultAvatarImg.src} alt={defaultAvatarImg.alt} disabled={disabled} onError={() => setImageError(true)} size={size} />
          : <AvatarImg src={loadingAvatar} alt={defaultAvatarImg.alt} disabled={disabled} onError={() => setImageError(true)} size={size} />}
    </AvatarWrapper>
  );
}

Avatar.defaultProps = {
  size: 'medium'
};

Avatar.propTypes = {
  size: PropTypes.oneOf(['xxxsmall', 'xsmall', 'small', 'medium', 'large', 'xlarge', 'xxlarge']),
  avatarSource: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  description: PropTypes.string,
  disabled: PropTypes.bool,
  noMargin: PropTypes.bool,
  useGenericAlt: PropTypes.bool
};

export default React.memo(Avatar);
